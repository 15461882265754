/* eslint-disable react/jsx-max-depth */
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  InputAdornment,
  FormHelperText
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ArrowDropDown } from '@material-ui/icons';
import classNames from 'classnames';
import { DateField, Hours, Money2 } from '~/modules/common/components';
import { useDialogState, useIsBreakpointDown } from '~/modules/common/hooks';
import { mapRepliconDateToMidnightUTCString } from '~/modules/common/dates/convert';
import { useProjectTemplateSettings } from '~/modules/common/hooks/project/useProjectTemplateSettings';
import { isNumeric } from '~/modules/common/numbers';
import { useMeContext } from '~/modules/me';
import {
  RoleDropdown,
  TaskOwnerDropDown,
  TimeAndExpenseEntryTypeDropdown
} from '~/modules/tasks/components';
import TimeAndExpenseEntryTypeDropdown2 from '~/modules/tasks/components/TimeAndExpenseEntryTypeDropdown/TimeAndExpenseEntryTypeDropdown2';
import { useSetMessage, useCreateTask } from '~/modules/projects/tasks/hooks';
import AddTaskDialog from '~/modules/projects/tasks/components/AddTaskDialog';
import { getError, hasError } from '~/util';
import { CostTypeDropdown } from '~/modules/projects/project/common/components';
import { useTaskCustomFieldDefinitions } from '~/modules/tasks/modecs/useTaskCustomFieldDefinitions';
import ErrorCard from '~/modules/tasks/modecs/ErrorCard';
import { TASK_ESTIMATE_CALCULATION_TYPES } from '~/modules/common/enums';
import { MobileDrawerToolbar } from './MobileDrawerToolbar';
import DeleteConfirmationDialog from './DeleteTask';
import FormContainer from './FormContainer';
import FormLoading from './FormLoading';
import TaskCustomFields from './TaskCustomFields';
import TaskExtensionFields from './TaskExtensionFields';
import useChangeHandlers from './useChangeHandlers';
import useStyles from './useStyles';
import useFormState from './useFormState';
import useDeleteTask from './useDeleteTask';
import { useParentTaskIds } from './useParentTaskIds';

const milestoneLabelText = <FormattedMessage id="taskDrawerEdit.milestone" />;

export const areDatesRolledup = ({
  task,
  isPsaPswatTaskDateRollupEnabled,
  parentTaskIds
}) => {
  const isTaskDateRollupEnabled =
    task.project?.isTaskDateRollupEnabled || false;
  const hasChildren = (task.children || []).length > 0;
  const isParentTask = hasChildren || (parentTaskIds || []).includes(task.id);

  return (
    isPsaPswatTaskDateRollupEnabled && isTaskDateRollupEnabled && isParentTask
  );
};

export const EditTaskForm = ({
  classes: classesOverrides,
  task,
  stopEditing,
  dispatch,
  onClose
}) => {
  const isMobile = useIsBreakpointDown('xs');
  const intl = useIntl();
  const me = useMeContext();
  const {
    featureFlags: {
      PSAPRPTaskEstimateCalculation,
      isPsaPrpPsaPpmMergerEnabled,
      isPsaPrpTaskCodeOnTaskCreationEnabled,
      isPsaPswatTaskDateRollupEnabled,
      isPsaPrpEnhancedAllowEntryWithoutTasksDropdownEnabled
    }
  } = me;

  const isTaskAssignmentsEnabled =
    PSAPRPTaskEstimateCalculation ===
      TASK_ESTIMATE_CALCULATION_TYPES.TASKASSIGNMENT && !isMobile;
  const areStartAndEndDatesRequired =
    isPsaPswatTaskDateRollupEnabled && task.project.isTaskDateRollupEnabled;
  const { permittedActionUris, billingType } = task.project;
  const classes = useStyles({ classes: classesOverrides });
  const { setMessage } = useSetMessage();
  const {
    open: dialogOpen,
    openDialog,
    closeDialog: onDialogCancel
  } = useDialogState(false);

  const { deleteTask, deleteTaskError } = useDeleteTask({
    task,
    onClose
  });

  const {
    loading: customFieldDefsLoading,
    error: customFieldDefsError,
    customFieldDefinitions
  } = useTaskCustomFieldDefinitions();

  const parentTaskIds = useParentTaskIds({
    projectId: task.project?.id,
    isPsaPswatTaskDateRollupEnabled
  });
  const datesRolledup = areDatesRolledup({
    task,
    isPsaPswatTaskDateRollupEnabled,
    parentTaskIds
  });

  const {
    values,
    errors,
    dirty,
    isSubmitting,
    setFieldValue,
    setStatus,
    handleSubmit,
    status,
    initialValues
  } = useFormState({
    task,
    customFieldDefinitions,
    areStartAndEndDatesRequired: datesRolledup
      ? false
      : areStartAndEndDatesRequired,
    stopEditing,
    dispatch
  });

  const {
    id,
    name,
    code,
    startDate,
    endDate,
    description,
    isTimeEntryAllowed,
    isMilestone,
    initialEstimatedHours,
    initialEstimatedCost,
    assignedUser,
    assignedRole,
    estimatedCost,
    timeEntryType,
    timeAndExpenseEntryType,
    extensionFieldValues,
    assignedUserRoleId,
    costType,
    resourceAllocations: resourceAllocationValues,
    ...customFields
  } = values;

  const {
    open: isAddTaskDialogOpen,
    closeDialog: closeAddTaskDialog,
    openDialog: openAddTaskDialog
  } = useDialogState(false);

  const {
    onNameChange,
    onCodeChange,
    onStartDateChange,
    onEndDateChange,
    onDescriptionChange,
    onIsMilestoneChange,
    onInitialEstimatedHoursChange,
    onInitialEstimatedCostAmountChange,
    onInitialEstimatedCostCurrencyChange,
    onTaskOwnerChange,
    onExtensionFieldsChange,
    onAssignedRoleChange,
    onTimeAndExpenseEntryTypeChange,
    onTimeAndExpenseEntryTypeChange2,
    onAllowTimeEntryChange,
    onCostTypeChange,
    customFieldHandlers
  } = useChangeHandlers({
    values,
    setFieldValue,
    setStatus,
    customFieldDefinitions,
    initialValues
  });

  const createTask = useCreateTask({ costType });

  const projectSlug = task.projectReference.slug;

  const hasErrors = useMemo(() => errors && Object.keys(errors).length > 0, [
    errors
  ]);

  const dateFieldInputProps = useMemo(
    () => ({
      endAdornment: (
        <InputAdornment position="end">
          <ArrowDropDown />
        </InputAdornment>
      )
    }),
    []
  );

  const taskCustomFieldsDateProps = useMemo(
    () => ({
      InputProps: dateFieldInputProps
    }),
    [dateFieldInputProps]
  );

  const useDateFieldInputProps = ariaLabel =>
    useMemo(
      () => ({
        inputProps: {
          'aria-label': intl.formatMessage({
            id: ariaLabel
          })
        },
        ...dateFieldInputProps
      }),
      [ariaLabel]
    );

  const startDateInputProps = useDateFieldInputProps(
    'taskDrawerEdit.startDate'
  );
  const endDateInputProps = useDateFieldInputProps('taskDrawerEdit.endDate');
  const { isExpenseProductEnabled, hasViewProjectBillingOptions } = me;

  const timeEntryLabel = isExpenseProductEnabled
    ? intl.formatMessage({
        id: 'taskDrawerEdit.timeAndExpenseEntryAllowed'
      })
    : intl.formatMessage({
        id: 'taskDrawerEdit.timeEntryAllowed'
      });

  const timeAndExpenseEntryLabel = intl.formatMessage({
    id: 'taskDrawerEdit.timeAndExpenseEntry'
  });

  const nameFieldProps = useMemo(
    () => ({
      'aria-label': intl.formatMessage({ id: 'taskDrawerEdit.name' })
    }),
    [intl]
  );

  const codeFieldProps = useMemo(
    () => ({
      'aria-label': intl.formatMessage({ id: 'taskDrawerEdit.code' })
    }),
    [intl]
  );

  const descriptionFieldProps = useMemo(
    () => ({
      'aria-label': intl.formatMessage({ id: 'taskDrawerEdit.description' })
    }),
    [intl]
  );

  const templateSettings = useProjectTemplateSettings({
    projectTemplateSetting: task?.project?.projectTemplateSetting,
    isPsaPrpPsaPpmMergerEnabled
  });

  const { hasBilling, hasCostType } = templateSettings || {};

  const canEditCostType = isPsaPrpPsaPpmMergerEnabled
    ? hasCostType &&
      permittedActionUris.includes('urn:replicon:project-action:edit-cost-type')
    : permittedActionUris.includes(
        'urn:replicon:project-action:edit-cost-type'
      );

  const mdWidthMilestoneSection = canEditCostType ? 12 : 6;

  const showTimeAndExpenseEntryTypeField =
    (isPsaPrpPsaPpmMergerEnabled ? hasBilling : true) &&
    hasViewProjectBillingOptions &&
    billingType?.id !== 'urn:replicon:billing-type:non-billable';

  const descriptionField = (
    <TextField
      data-qe-id="TaskDescriptionField"
      label={intl.formatMessage({
        id: 'taskDrawerEdit.description'
      })}
      inputProps={descriptionFieldProps}
      value={description}
      onChange={onDescriptionChange}
      variant="filled"
      error={hasError(errors, 'description')}
      helperText={getError(errors, 'description')}
      fullWidth
      multiline
      rows={isPsaPrpEnhancedAllowEntryWithoutTasksDropdownEnabled ? 1 : 4}
      rowsMax={10}
    />
  );

  return customFieldDefsLoading ? (
    <FormLoading />
  ) : customFieldDefsError ? (
    <ErrorCard error={customFieldDefsError} />
  ) : (
    <div>
      <FormContainer>
        <div className={classes.formFields}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                data-qe-id="TaskNameEditField"
                label={intl.formatMessage({ id: 'taskDrawerEdit.name' })}
                inputProps={nameFieldProps}
                value={name}
                required
                onChange={onNameChange}
                variant="filled"
                error={hasError(errors, 'name') || Boolean(status)}
                helperText={getError(errors, 'name') || status}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                data-qe-id="TaskCodeEditField"
                label={intl.formatMessage({ id: 'taskDrawerEdit.code' })}
                inputProps={codeFieldProps}
                value={code}
                onChange={onCodeChange}
                variant="filled"
                error={hasError(errors, 'code')}
                helperText={getError(errors, 'code')}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={6}
              className={datesRolledup ? classes.rolledUpDate : null}
            >
              <DateField
                data-qe-id="TaskStartDateField"
                editable={!datesRolledup}
                label={intl.formatMessage({
                  id: 'taskDrawerEdit.startDate'
                })}
                value={startDate}
                error={hasError(errors, 'startDate')}
                helperText={getError(errors, 'startDate')}
                required={areStartAndEndDatesRequired}
                onChange={onStartDateChange}
                variant="filled"
                InputProps={startDateInputProps}
              />
            </Grid>
            <Grid
              item
              xs={6}
              className={datesRolledup ? classes.rolledUpDate : null}
            >
              <DateField
                data-qe-id="TaskEndDateField"
                editable={!datesRolledup}
                label={intl.formatMessage({
                  id: 'taskDrawerEdit.endDate'
                })}
                value={endDate}
                error={hasError(errors, 'endDate')}
                helperText={getError(errors, 'endDate')}
                required={areStartAndEndDatesRequired}
                onChange={onEndDateChange}
                variant="filled"
                InputProps={endDateInputProps}
              />
            </Grid>
            {datesRolledup ? (
              <Grid item xs={12} className={classes.rolledUpDateHelperText}>
                <FormHelperText id="date-rollup-helperText">
                  {intl.formatMessage({
                    id: 'taskDrawerEdit.dateRollupHelperText'
                  })}
                </FormHelperText>
              </Grid>
            ) : null}
            {!isTaskAssignmentsEnabled && (
              <>
                <Grid item xs={12} sm={6} data-qe-id="RolesDropdown">
                  <RoleDropdown
                    value={assignedRole}
                    onChange={onAssignedRoleChange}
                    label={intl.formatMessage({
                      id: 'taskDrawerEdit.role'
                    })}
                    projectSlug={projectSlug}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TaskOwnerDropDown
                    variant="filled"
                    projectSlug={projectSlug}
                    assignedUser={assignedUser}
                    assignedRole={assignedRole}
                    onChange={onTaskOwnerChange}
                  />
                </Grid>
              </>
            )}
            {!isPsaPrpTaskCodeOnTaskCreationEnabled && (
              <Grid item xs={12}>
                {descriptionField}
              </Grid>
            )}
            {permittedActionUris.includes(
              'urn:replicon:project-action:view-project-estimates'
            ) && (
              <Grid item xs={6}>
                <Hours
                  data-qe-id="EstimatedHoursField"
                  label={intl.formatMessage({
                    id: 'taskDrawerEdit.initialEstimatedHours'
                  })}
                  ariaLabel={intl.formatMessage({
                    id: 'taskDrawerEdit.initialEstimatedHours'
                  })}
                  margin="none"
                  isEditible={permittedActionUris.includes(
                    'urn:replicon:project-action:edit-project-estimates'
                  )}
                  onChange={onInitialEstimatedHoursChange}
                  value={
                    isNumeric(initialEstimatedHours)
                      ? initialEstimatedHours
                      : ''
                  }
                  error={hasError(errors, 'initialEstimatedHours')}
                  helperText={getError(errors, 'initialEstimatedHours')}
                  precision={2}
                />
              </Grid>
            )}
            {permittedActionUris.includes(
              'urn:replicon:project-action:edit-project-estimates'
            ) && (
              <Grid item xs={6} sm={6}>
                <Money2
                  ariaLabel={intl.formatMessage({
                    id: 'projectTasksPage.estimatedCost'
                  })}
                  label={intl.formatMessage({
                    id: 'projectTasksPage.estimatedCost'
                  })}
                  variant="filled"
                  amount={initialEstimatedCost && initialEstimatedCost.amount}
                  currency={
                    initialEstimatedCost && initialEstimatedCost.currency
                  }
                  editable={permittedActionUris.includes(
                    'urn:replicon:project-action:edit-cost-data'
                  )}
                  onAmountChange={onInitialEstimatedCostAmountChange}
                  error={hasError(errors, 'initialEstimatedCost')}
                  helperText={getError(errors, 'initialEstimatedCost.amount')}
                  onCurrencyChange={onInitialEstimatedCostCurrencyChange}
                />
              </Grid>
            )}
            {isTaskAssignmentsEnabled && (
              <Grid item xs={12} sm={6} data-qe-id="RolesDropdown">
                <RoleDropdown
                  value={assignedRole}
                  onChange={onAssignedRoleChange}
                  label={intl.formatMessage({
                    id: 'taskDrawerEdit.role'
                  })}
                  projectSlug={projectSlug}
                />
              </Grid>
            )}

            {isPsaPrpEnhancedAllowEntryWithoutTasksDropdownEnabled ? (
              <>
                {isPsaPrpTaskCodeOnTaskCreationEnabled && (
                  <Grid item xs={12} sm={6}>
                    {descriptionField}
                  </Grid>
                )}
                {showTimeAndExpenseEntryTypeField && (
                  <Grid item xs={12} sm={6}>
                    <TimeAndExpenseEntryTypeDropdown2
                      fullWidth
                      variant="filled"
                      value={timeAndExpenseEntryType.id}
                      onChange={onTimeAndExpenseEntryTypeChange2}
                      label={timeAndExpenseEntryLabel}
                      ariaLabel={timeAndExpenseEntryLabel}
                      dataQeId="TimeAndExpenseEntryTypeDropdown"
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isTimeEntryAllowed}
                        data-qe-id="AllowTimeEntryCheckbox"
                        onChange={onAllowTimeEntryChange}
                      />
                    }
                    label={intl.formatMessage({
                      id: 'taskDrawerEdit.allowTimeEntry.label'
                    })}
                  />
                </Grid>
              </>
            ) : (
              <Grid
                item
                xs={12}
                sm={6}
                data-qe-id="TimeAndExpenseEntryTypeDropdown"
              >
                <TimeAndExpenseEntryTypeDropdown
                  fullWidth
                  variant="filled"
                  showBillingOptions={
                    isPsaPrpPsaPpmMergerEnabled
                      ? hasBilling && hasViewProjectBillingOptions
                      : hasViewProjectBillingOptions
                  }
                  value={timeAndExpenseEntryType && timeAndExpenseEntryType.id}
                  onChange={onTimeAndExpenseEntryTypeChange}
                  label={timeEntryLabel}
                  disabled={Boolean(
                    billingType &&
                      billingType.id ===
                        'urn:replicon:billing-type:non-billable'
                  )}
                />
              </Grid>
            )}

            {isPsaPrpTaskCodeOnTaskCreationEnabled ? (
              canEditCostType && (
                <Grid item xs={12} sm={6}>
                  <CostTypeDropdown
                    value={costType}
                    onChange={onCostTypeChange}
                    fullWidth
                    dataQeId="CostTypeDropdown"
                  />
                </Grid>
              )
            ) : canEditCostType ? (
              <Grid item xs={12} sm={6}>
                <CostTypeDropdown
                  value={costType}
                  onChange={onCostTypeChange}
                  fullWidth
                  dataQeId="CostTypeDropdown"
                />
              </Grid>
            ) : (
              isTaskAssignmentsEnabled && <Grid item xs={12} sm={6} />
            )}
            {!isPsaPrpEnhancedAllowEntryWithoutTasksDropdownEnabled &&
              isPsaPrpTaskCodeOnTaskCreationEnabled && (
                <Grid item xs={12}>
                  {descriptionField}
                </Grid>
              )}
            {isPsaPrpTaskCodeOnTaskCreationEnabled &&
              !canEditCostType &&
              isTaskAssignmentsEnabled && <Grid item xs={12} sm={6} />}
            <Grid
              container
              item
              sm={mdWidthMilestoneSection}
              alignItems="center"
              justifyContent={
                isTaskAssignmentsEnabled ? 'flex-end' : 'flex-start'
              }
            >
              <FormControlLabel
                className={classNames({
                  [classes.controlLabel]: isTaskAssignmentsEnabled
                })}
                control={
                  <Checkbox
                    className={classes.checkbox}
                    data-qe-id="MilestoneCheckbox"
                    checked={isMilestone}
                    onChange={onIsMilestoneChange}
                    color="primary"
                  />
                }
                label={milestoneLabelText}
                labelPlacement="end"
              />
            </Grid>
            <TaskCustomFields
              editable
              customFieldDefinitions={customFieldHandlers}
              values={customFields}
              errors={errors}
              dateProps={taskCustomFieldsDateProps}
            />
            <TaskExtensionFields
              extensionFieldValues={extensionFieldValues}
              onExtensionFieldsChange={onExtensionFieldsChange}
            />
          </Grid>
          {isSubmitting && (
            <div className={classes.loadingOverlay}>
              <CircularProgress data-qe-id="editTaskForm_isSubmitting" />
            </div>
          )}
        </div>
        {isMobile ? (
          <MobileDrawerToolbar
            taskIndex={task.path.length}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            stopEditing={stopEditing}
            openDialog={openDialog}
            openAddTaskDialog={openAddTaskDialog}
            disabled={isSubmitting || !dirty || hasErrors}
          />
        ) : (
          <Grid container spacing={2}>
            <Grid item>
              <Button
                data-qe-id="TaskDrawerSaveButton"
                onClick={handleSubmit}
                disabled={isSubmitting || !dirty || hasErrors}
                color="primary"
                variant="contained"
              >
                <FormattedMessage id="taskDrawerEdit.save" />
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={stopEditing}
                disabled={isSubmitting}
                variant="contained"
              >
                <FormattedMessage id="taskDrawerEdit.cancel" />
              </Button>
            </Grid>
            <Grid className={classes.removeButton} item>
              <Button
                data-qe-id="TaskDrawerRemoveButton"
                onClick={openDialog}
                disabled={isSubmitting}
                color="secondary"
              >
                <FormattedMessage id="taskDrawerEdit.remove" />
              </Button>
            </Grid>
          </Grid>
        )}
      </FormContainer>
      <DeleteConfirmationDialog
        taskName={name}
        deleteTask={deleteTask}
        open={dialogOpen}
        deleteTaskError={deleteTaskError}
        onCancel={onDialogCancel}
      />
      {isAddTaskDialogOpen && (
        <AddTaskDialog
          projectSlug={projectSlug}
          open={isAddTaskDialogOpen}
          onClose={closeAddTaskDialog}
          initStartDate={
            startDate && mapRepliconDateToMidnightUTCString(startDate)
          }
          initEndDate={endDate && mapRepliconDateToMidnightUTCString(endDate)}
          parentUri={id}
          createTask={createTask}
          fullScreen
          isSubtask
          parentName={task.displayText}
          setMessage={setMessage}
        />
      )}
    </div>
  );
};

EditTaskForm.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func,
  task: PropTypes.object.isRequired,
  stopEditing: PropTypes.func,
  onClose: PropTypes.func
};

export default EditTaskForm;
