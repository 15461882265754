import { mixed, number, object, string } from 'yup';
import { DateTime } from 'luxon';
import { isNumeric } from '~/modules/common/numbers';
import {
  CODE_LENGTH_MAX,
  ESTIMATED_COST_MAX,
  ESTIMATED_HOURS_MAX,
  NUMERIC_UDF_MAX_LENGTH,
  STRING_MAX_LENGTH
} from './constants';

export const createTextFieldValidator = definition => {
  let validator = string();

  const {
    textConfiguration: { maximumLength }
  } = definition;

  validator = validator.max(maximumLength || STRING_MAX_LENGTH);

  return validator;
};

export const createNumericFieldValidator = definition => {
  let validator = number();

  const {
    numericConfiguration: { minimumNumericValue, maximumNumericValue }
  } = definition;

  validator = validator.max(maximumNumericValue || NUMERIC_UDF_MAX_LENGTH);

  if (minimumNumericValue) {
    validator = validator.min(minimumNumericValue);
  }

  return validator;
};

export const createDateFieldValidator = (definition, intl) => {
  let validator = mixed();

  const {
    dateConfiguration: { minimumDate, maximumDate }
  } = definition;

  const parseDate = value =>
    value &&
    DateTime.fromObject(value)
      .startOf('day')
      .toMillis();

  if (minimumDate) {
    const min = DateTime.fromObject(minimumDate).startOf('day');

    validator = validator.test(
      'is-min-date',
      intl.formatMessage(
        {
          id: 'taskDrawerEdit.minDate'
        },
        {
          path: definition.displayText,
          date: min.toISODate()
        }
      ),
      value => parseDate(value) >= min.toMillis()
    );
  }

  if (maximumDate) {
    const max = DateTime.fromObject(maximumDate).endOf('day');

    validator = validator.test(
      'is-max-date',
      intl.formatMessage(
        {
          id: 'taskDrawerEdit.maxDate'
        },
        {
          path: definition.displayText,
          date: max.toISODate()
        }
      ),
      value => parseDate(value) <= max.toMillis()
    );
  }

  return validator;
};

export const createDropdownFieldValidator = () => mixed();

export const createCustomFieldValidation = (definition, intl) => {
  let validator = null;

  switch (definition.type.uri) {
    case 'urn:replicon:custom-field-type:text': {
      validator = createTextFieldValidator(definition);
      break;
    }
    case 'urn:replicon:custom-field-type:numeric': {
      validator = createNumericFieldValidator(definition);
      break;
    }
    case 'urn:replicon:custom-field-type:date': {
      validator = createDateFieldValidator(definition, intl);
      break;
    }
    case 'urn:replicon:custom-field-type:drop-down': {
      validator = createDropdownFieldValidator(definition);
      break;
    }
    default: {
      validator = mixed();
    }
  }

  if (definition.isRequired) {
    validator = validator.required();
  } else {
    validator = validator.nullable();
  }

  validator = validator.label(definition.displayText);

  return validator;
};

export const validationMessage = max =>
  `Exceeds max allowed value: ${max.toLocaleString('en')}`;

export const validationSchema = ({ customFieldDefinitions = [], intl }) =>
  object().shape({
    name: mixed()
      .required()
      .label(intl.formatMessage({ id: 'taskDrawerEdit.name' })),
    ...customFieldDefinitions.reduce(
      (fields, definition) => ({
        ...fields,
        [definition.uri]: createCustomFieldValidation(definition, intl)
      }),
      {}
    ),
    code: string()
      .max(
        CODE_LENGTH_MAX,
        intl.formatMessage(
          { id: 'taskDrawerEdit.codeExceedsMaxLength' },
          { max: CODE_LENGTH_MAX }
        )
      )
      .nullable(),
    startDate: mixed().test(
      'startDate',
      intl.formatMessage({
        id: 'taskDrawerEdit.validations.startDateRequired'
      }),
      function validate(value) {
        return (
          value ||
          !(this.parent.isMilestone || this.parent.areStartAndEndDatesRequired)
        );
      }
    ),
    endDate: mixed().test(
      'endDate',
      intl.formatMessage({
        id: 'taskDrawerEdit.validations.endDateRequired'
      }),
      function validate(value) {
        return (
          value ||
          !(this.parent.isMilestone || this.parent.areStartAndEndDatesRequired)
        );
      }
    ),
    // can be removed with psa-rmp-TaskAllocation1 ff cleanup
    initialEstimatedHours: number()
      .transform((v, o) => (isNumeric(v) ? v : 0))
      .max(ESTIMATED_HOURS_MAX, validationMessage(ESTIMATED_HOURS_MAX)),
    // can be removed with psa-rmp-TaskAllocation1 ff cleanup
    initialEstimatedCost: object().shape({
      amount: number()
        .max(ESTIMATED_COST_MAX, validationMessage(ESTIMATED_COST_MAX))
        .nullable(true)
    })
  });

export default validationSchema;
