import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  InputAdornment
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ArrowDropDown } from '@material-ui/icons';
import classNames from 'classnames';
import { DateField, Hours, Money2 } from '~/modules/common/components';
import { useDialogState, useIsBreakpointDown } from '~/modules/common/hooks';
import {
  mapRepliconDateToMidnightUTCString,
  mapRepliconDateToUtcObject
} from '~/modules/common/dates/convert';
import { useProjectTemplateSettings } from '~/modules/common/hooks/project/useProjectTemplateSettings';
import { isNumeric } from '~/modules/common/numbers';
import { useMeContext } from '~/modules/me';
import RescheduleTaskDialog, {
  MODE,
  getDurationSummaryValues
} from '~/modules/tasks/components/RescheduleTaskDialog';
import {
  RoleDropdown,
  TaskOwnerDropDown,
  TimeAndExpenseEntryTypeDropdown
} from '~/modules/tasks/components';
import { useSetMessage, useCreateTask } from '~/modules/projects/tasks/hooks';
import { useProjectObjectPermissions } from '~/modules/common/hooks/project';
import AddTaskDialog from '~/modules/projects/tasks/components/AddTaskDialog';
import { getError, hasError } from '~/util';
import { useTaskCustomFieldDefinitions } from '~/modules/tasks/modecs/useTaskCustomFieldDefinitions';
import ErrorCard from '~/modules/tasks/modecs/ErrorCard';
import { GroupTimesheetAccessDropdown } from '~/modules/common/components/TaskDrawer/EditTask/components';
import Divider from '../../TaskStatusSection/Table/components/Divider';
import { MobileDrawerToolbar } from './MobileDrawerToolbar';
import DeleteConfirmationDialog from './DeleteTask';
import FormContainer from './FormContainer';
import FormLoading from './FormLoading';
import TaskCustomFields from './TaskCustomFields';
import TaskExtensionFields from './TaskExtensionFields';
import useChangeHandlers from './useChangeHandlers';
import useStyles from './useStyles2';
import useFormState from './useFormState';
import useDeleteTask from './useDeleteTask';
import useRescheduleTask from './useRescheduleTask';

const useNumberInputWithoutValueStyles = makeStyles(theme => ({
  label: {
    right: theme.spacing(3),
    left: 0,
    transformOrigin: 'left top',
    '&.Mui-focused': {
      right: 'unset',
      left: 0,
      transformOrigin: 'left top'
    }
  }
}));

const useNumberInputWithValueStyles = makeStyles(theme => ({
  label: {
    left: 0,
    right: 'unset',
    transformOrigin: 'left top'
  }
}));

const useMilestoneCheckboxStyles = makeStyles(theme => ({
  checkbox: {
    marginLeft: theme.spacing(1)
  },
  controlLabel: {
    marginRight: theme.spacing(0)
  }
}));

const dateFieldInputProps = {
  endAdornment: (
    <InputAdornment position="end">
      <ArrowDropDown />
    </InputAdornment>
  )
};

const checkBox = ({ classes, isMilestone, onIsMilestoneChange }) => (
  <Checkbox
    className={classes.checkbox}
    data-qe-id="MilestoneCheckbox"
    checked={isMilestone}
    onChange={onIsMilestoneChange}
    color="primary"
  />
);

const milestoneCheckbox = ({
  milestoneCheckboxClasses: classes,
  isMilestone,
  isTaskAssignmentsEnabled,
  onIsMilestoneChange,
  label,
  widthValue
}) => {
  return (
    <Grid
      item
      xs={widthValue}
      container
      alignItems="center"
      justifyContent="flex-start"
    >
      <FormControlLabel
        className={classNames({
          [classes.controlLabel]: isTaskAssignmentsEnabled
        })}
        control={checkBox({ classes, isMilestone, onIsMilestoneChange })}
        label={label}
        labelPlacement="end"
      />
    </Grid>
  );
};

const taskCustomFieldsDateProps = {
  InputProps: dateFieldInputProps
};

const getFieldLabels = isExpenseProductEnabled => formatMessage => ({
  code: formatMessage({ id: 'taskDrawerEdit.code' }),
  description: formatMessage({ id: 'taskDrawerEdit.description' }),
  endDate: formatMessage({ id: 'taskDrawerEdit.endDate' }),
  estimatedCost: formatMessage({ id: 'projectTasksPage.estimatedCost' }),
  estimatedHours: formatMessage({ id: 'projectTasksPage.estimatedHours' }),
  milestone: formatMessage({ id: 'taskDrawerEdit.milestone' }),
  name: formatMessage({ id: 'taskDrawerEdit.name' }),
  startDate: formatMessage({ id: 'taskDrawerEdit.startDate' }),
  taskRole: formatMessage({
    id: 'taskDrawerEdit.taskRole'
  }),
  timeAndExpenseEntryType: formatMessage({
    id: isExpenseProductEnabled
      ? 'taskDrawerEdit.timeAndExpenseEntryAllowed'
      : 'taskDrawerEdit.timeEntryAllowed'
  })
});

const getInputProps = ariaLabel => ({ 'aria-label': ariaLabel });

export const calculateInitialDurationMode = (
  initialValues,
  startDate,
  endDate
) => {
  if (!initialValues?.startDate || !initialValues?.endDate) return null;

  const existingDuration = getDurationSummaryValues(
    mapRepliconDateToUtcObject(initialValues?.startDate),
    mapRepliconDateToUtcObject(initialValues?.endDate)
  );
  const newDuration = getDurationSummaryValues(
    startDate && mapRepliconDateToUtcObject(startDate),
    endDate && mapRepliconDateToUtcObject(endDate)
  );

  if (existingDuration.workDays === newDuration.workDays) {
    return MODE.KEEP_TASK_WORK_DAYS;
  }
  if (existingDuration.calendarDays === newDuration.calendarDays) {
    return MODE.KEEP_TASK_LENGTH;
  }

  return MODE.CUSTOM;
};

export const EditTaskForm = ({
  classes: classesOverrides,
  task,
  stopEditing,
  dispatch,
  onClose
}) => {
  const isMobile = useIsBreakpointDown('xs');
  const { formatMessage } = useIntl();
  const me = useMeContext();
  const {
    isExpenseProductEnabled,
    hasViewProjectBillingOptions,
    featureFlags: {
      isPsaPrpPsaPpmMergerEnabled,
      isPsaPswatTaskDateRollupEnabled
    }
  } = me;

  const isTaskAssignmentsEnabled = !isMobile;
  const areStartAndEndDatesRequired =
    isPsaPswatTaskDateRollupEnabled && task.project.isTaskDateRollupEnabled;
  const { project } = task;
  const { billingType } = project;
  const classes = useStyles({ classes: classesOverrides });
  const numberInputWithoutValueClasses = useNumberInputWithoutValueStyles();
  const numberInputWithValueClasses = useNumberInputWithValueStyles();
  const milestoneCheckboxClasses = useMilestoneCheckboxStyles();

  const {
    canViewEstimate,
    canEditEstimate,
    canViewCost,
    canEditCost
  } = useProjectObjectPermissions({ project });

  const canViewCostAndEstimate = canViewCost && canViewEstimate;
  const { setMessage } = useSetMessage();
  const {
    open: dialogOpen,
    openDialog,
    closeDialog: onDialogCancel
  } = useDialogState(false);

  const {
    open: isRescheduleTaskDialogOpen,
    openDialog: openRescheduleTaskDialog,
    closeDialog: closeRescheduleTaskDialog
  } = useDialogState(false);

  const { deleteTask, deleteTaskError } = useDeleteTask({
    task,
    onClose
  });
  const [isFormSubmitting, setFormSubmitting] = useState(false);

  const {
    loading: customFieldDefsLoading,
    error: customFieldDefsError,
    customFieldDefinitions
  } = useTaskCustomFieldDefinitions();

  const {
    values,
    errors,
    dirty,
    setFieldValue,
    setStatus,
    handleSubmit,
    status,
    initialValues
  } = useFormState({
    task,
    customFieldDefinitions,
    areStartAndEndDatesRequired,
    stopEditing,
    dispatch,
    setFormSubmitting,
    openRescheduleTaskDialog
  });

  const {
    id,
    name,
    code,
    startDate,
    endDate,
    description,
    isTimeEntryAllowed,
    isMilestone,
    initialEstimatedHours,
    initialEstimatedCost,
    assignedUser,
    assignedRole,
    estimatedCost,
    timeEntryType,
    timeAndExpenseEntryType,
    extensionFieldValues,
    assignedUserRoleId,
    costType,
    resourceAllocations,
    assignedTimesheetAccessUris,
    unAssignedTimesheetAccessUris,
    ...customFields
  } = values;

  const {
    open: isAddTaskDialogOpen,
    closeDialog: closeAddTaskDialog,
    openDialog: openAddTaskDialog
  } = useDialogState(false);

  const {
    onNameChange,
    onCodeChange,
    onStartDateChange,
    onEndDateChange,
    onDescriptionChange,
    onIsMilestoneChange,
    onInitialEstimatedHoursChange,
    onInitialEstimatedCostAmountChange,
    onInitialEstimatedCostCurrencyChange,
    onTaskOwnerChange,
    onExtensionFieldsChange,
    onAssignedRoleChange,
    onTimeAndExpenseEntryTypeChange,
    customFieldHandlers,
    onTimesheetAccessChange
  } = useChangeHandlers({
    values,
    setFieldValue,
    setStatus,
    customFieldDefinitions,
    initialValues,
    isPsaRmpTaskAllocation1Enabled: true
  });

  const initialDurationMode = calculateInitialDurationMode(
    initialValues,
    startDate,
    endDate
  );

  const createTask = useCreateTask({ costType });

  const projectSlug = task.projectReference.slug;

  const hasErrors = useMemo(() => errors && Object.keys(errors).length > 0, [
    errors
  ]);

  const {
    onRescheduleTaskSubmit,
    loading,
    onRescheduleTaskClose
  } = useRescheduleTask({
    task,
    closeRescheduleTaskDialog,
    stopEditing,
    setFormSubmitting
  });

  const initialTaskDateRange = useMemo(
    () => ({
      startDate: startDate ? mapRepliconDateToUtcObject(startDate) : null,
      endDate: endDate ? mapRepliconDateToUtcObject(endDate) : null
    }),
    [endDate, startDate]
  );

  const useDateFieldInputProps = messageKey =>
    useMemo(
      () => ({
        inputProps: {
          'aria-label': formatMessage({
            id: messageKey
          })
        },
        ...dateFieldInputProps
      }),
      [messageKey]
    );

  const startDateInputProps = useDateFieldInputProps(
    'taskDrawerEdit.startDate'
  );
  const endDateInputProps = useDateFieldInputProps('taskDrawerEdit.endDate');

  const fieldLabels = getFieldLabels(isExpenseProductEnabled)(formatMessage);

  const templateSettings = useProjectTemplateSettings({
    projectTemplateSetting: task?.project?.projectTemplateSetting,
    isPsaPrpPsaPpmMergerEnabled
  });

  const { hasBilling } = templateSettings || {};

  const initialEstimatedCostAmount = isNumeric(initialEstimatedCost?.amount)
    ? initialEstimatedCost.amount
    : null;

  return customFieldDefsLoading ? (
    <FormLoading />
  ) : customFieldDefsError ? (
    <ErrorCard error={customFieldDefsError} />
  ) : (
    <div>
      <FormContainer>
        <div className={classes.formFields}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                data-qe-id="TaskNameEditField"
                label={fieldLabels.name}
                inputProps={getInputProps(fieldLabels.name)}
                value={name}
                required
                onChange={onNameChange}
                variant="outlined"
                error={hasError(errors, 'name') || Boolean(status)}
                helperText={getError(errors, 'name') || status}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                data-qe-id="TaskCodeEditField"
                label={fieldLabels.code}
                inputProps={getInputProps(fieldLabels.code)}
                value={code}
                onChange={onCodeChange}
                variant="outlined"
                error={hasError(errors, 'code')}
                helperText={getError(errors, 'code')}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <DateField
                data-qe-id="TaskStartDateField"
                editable
                label={fieldLabels.startDate}
                value={startDate}
                error={hasError(errors, 'startDate')}
                helperText={getError(errors, 'startDate')}
                required={areStartAndEndDatesRequired}
                onChange={onStartDateChange}
                variant="outlined"
                InputProps={startDateInputProps}
              />
            </Grid>
            <Grid item xs={6}>
              <DateField
                data-qe-id="TaskEndDateField"
                editable
                label={fieldLabels.endDate}
                value={endDate}
                error={hasError(errors, 'endDate')}
                helperText={getError(errors, 'endDate')}
                required={areStartAndEndDatesRequired}
                onChange={onEndDateChange}
                variant="outlined"
                InputProps={endDateInputProps}
              />
            </Grid>
            <Grid
              item
              xs={isMobile ? 12 : 6}
              data-qe-id="TimeAndExpenseEntryTypeDropdown"
            >
              <TimeAndExpenseEntryTypeDropdown
                fullWidth
                variant="outlined"
                showBillingOptions={
                  isPsaPrpPsaPpmMergerEnabled
                    ? hasBilling && hasViewProjectBillingOptions
                    : hasViewProjectBillingOptions
                }
                value={timeAndExpenseEntryType && timeAndExpenseEntryType.id}
                onChange={onTimeAndExpenseEntryTypeChange}
                label={fieldLabels.timeAndExpenseEntryType}
                disabled={Boolean(
                  billingType &&
                    billingType.id === 'urn:replicon:billing-type:non-billable'
                )}
              />
            </Grid>
            {!isMobile &&
              milestoneCheckbox({
                isMilestone,
                isTaskAssignmentsEnabled,
                label: fieldLabels.milestone,
                milestoneCheckboxClasses,
                onIsMilestoneChange,
                widthValue: 6
              })}
            <Grid item xs={12}>
              <Divider variant="fullWidth" classes={classes.divider} />
            </Grid>
            <Grid item xs={12} sm={6} data-qe-id="RolesDropdown">
              <RoleDropdown
                variant="outlined"
                value={assignedRole}
                onChange={onAssignedRoleChange}
                label={fieldLabels.taskRole}
                projectSlug={projectSlug}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TaskOwnerDropDown
                avatarClassName={classes.avatar}
                variant="outlined"
                projectSlug={projectSlug}
                assignedUser={assignedUser}
                assignedRole={assignedRole}
                onChange={onTaskOwnerChange}
              />
            </Grid>

            {canViewEstimate && (
              <Grid item xs={6}>
                <Hours
                  classes={
                    initialEstimatedHours
                      ? numberInputWithValueClasses
                      : numberInputWithoutValueClasses
                  }
                  variant="outlined"
                  data-qe-id="EstimatedHoursField"
                  label={fieldLabels.estimatedHours}
                  ariaLabel={fieldLabels.estimatedHours}
                  margin="none"
                  isEditible={canEditEstimate}
                  onChange={onInitialEstimatedHoursChange}
                  value={
                    isNumeric(initialEstimatedHours)
                      ? initialEstimatedHours
                      : ''
                  }
                  precision={2}
                />
              </Grid>
            )}
            {canViewCostAndEstimate && (
              <Grid item xs={6} sm={6}>
                <Money2
                  numberInputClasses={
                    initialEstimatedCostAmount &&
                    initialEstimatedCost?.amount > 0
                      ? numberInputWithValueClasses
                      : numberInputWithoutValueClasses
                  }
                  ariaLabel={fieldLabels.estimatedCost}
                  label={fieldLabels.estimatedCost}
                  variant="outlined"
                  amount={initialEstimatedCostAmount}
                  currency={initialEstimatedCost?.currency}
                  editable={canEditCost}
                  onAmountChange={onInitialEstimatedCostAmountChange}
                  onCurrencyChange={onInitialEstimatedCostCurrencyChange}
                  showStartAdornment={isNumeric(initialEstimatedCost?.amount)}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <GroupTimesheetAccessDropdown
                variant="outlined"
                projectId={task.projectReference.id}
                timesheetAccess={assignedTimesheetAccessUris}
                onTimesheetAccessChange={onTimesheetAccessChange}
              />
            </Grid>
            {isMobile &&
              milestoneCheckbox({
                isMilestone,
                isTaskAssignmentsEnabled,
                label: fieldLabels.milestone,
                milestoneCheckboxClasses,
                onIsMilestoneChange,
                widthValue: 12
              })}
            <Grid item xs={12}>
              <Divider variant="fullWidth" classes={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                data-qe-id="TaskDescriptionField"
                label={fieldLabels.description}
                inputProps={getInputProps(fieldLabels.description)}
                value={description}
                onChange={onDescriptionChange}
                variant="outlined"
                error={hasError(errors, 'description')}
                helperText={getError(errors, 'description')}
                fullWidth
                multiline
                minRows={1}
                maxRows={5}
              />
            </Grid>
            <TaskCustomFields
              variant="outlined"
              align="left"
              editable
              customFieldDefinitions={customFieldHandlers}
              values={customFields}
              errors={errors}
              dateProps={taskCustomFieldsDateProps}
            />
            <TaskExtensionFields
              align="left"
              variant="outlined"
              extensionFieldValues={extensionFieldValues}
              onExtensionFieldsChange={onExtensionFieldsChange}
            />
          </Grid>
          {isFormSubmitting && (
            <div className={classes.loadingOverlay}>
              <CircularProgress data-qe-id="editTaskForm_isSubmitting" />
            </div>
          )}
        </div>
        {isMobile ? (
          <MobileDrawerToolbar
            taskIndex={task.path.length}
            handleSubmit={handleSubmit}
            isSubmitting={isFormSubmitting}
            stopEditing={stopEditing}
            openDialog={openDialog}
            openAddTaskDialog={openAddTaskDialog}
            disabled={isFormSubmitting || !dirty || hasErrors}
          />
        ) : (
          <Grid container spacing={2}>
            <Grid item>
              <Button
                data-qe-id="TaskDrawerSaveButton"
                onClick={handleSubmit}
                disabled={isFormSubmitting || !dirty || hasErrors}
                color="primary"
                variant="contained"
              >
                <FormattedMessage id="taskDrawerEdit.save" />
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={stopEditing}
                disabled={isFormSubmitting}
                variant="contained"
              >
                <FormattedMessage id="taskDrawerEdit.cancel" />
              </Button>
            </Grid>
            <Grid className={classes.removeButton} item>
              <Button
                data-qe-id="TaskDrawerRemoveButton"
                onClick={openDialog}
                disabled={isFormSubmitting}
                color="secondary"
              >
                <FormattedMessage id="taskDrawerEdit.remove" />
              </Button>
            </Grid>
          </Grid>
        )}
      </FormContainer>
      {isRescheduleTaskDialogOpen && (
        <RescheduleTaskDialog
          onSubmit={onRescheduleTaskSubmit}
          onCancel={onRescheduleTaskClose}
          isSetTaskTimeEntryDateRangeLoading={loading}
          task={task}
          initialTaskDateRange={initialTaskDateRange}
          initialDurationMode={initialDurationMode}
        />
      )}
      <DeleteConfirmationDialog
        taskName={name}
        deleteTask={deleteTask}
        open={dialogOpen}
        deleteTaskError={deleteTaskError}
        onCancel={onDialogCancel}
      />
      {isAddTaskDialogOpen && (
        <AddTaskDialog
          projectSlug={projectSlug}
          open={isAddTaskDialogOpen}
          onClose={closeAddTaskDialog}
          initStartDate={
            startDate && mapRepliconDateToMidnightUTCString(startDate)
          }
          initEndDate={endDate && mapRepliconDateToMidnightUTCString(endDate)}
          parentUri={id}
          createTask={createTask}
          fullScreen
          isSubtask
          parentName={task.displayText}
          setMessage={setMessage}
        />
      )}
    </div>
  );
};

EditTaskForm.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func,
  task: PropTypes.object.isRequired,
  stopEditing: PropTypes.func,
  onClose: PropTypes.func
};

export default EditTaskForm;
