import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const DEFAULT_PAGE_SIZE = 200;

export const RESOURCE_USERS_QUERY = gql`
  query pageOfResourceUsersDropdownOptions(
    $matchingUsersInput: PageOfResourceUsersDropdownOptionsInput!
    $nonMatchingUsersInput: PageOfResourceUsersDropdownOptionsInput!
    $isAvailabilityEnabled: Boolean!
    $isProjectRoleEnabled: Boolean!
    $fetchNonMatchingUsers: Boolean!
  ) {
    matchingUsers: pageOfResourceUsersDropdownOptions(
      input: $matchingUsersInput
    ) {
      id
      displayText
      isAssignedToTask
      availableDuration: netAvailableDuration
        @include(if: $isAvailabilityEnabled)
      roles @include(if: $isProjectRoleEnabled) {
        projectRole {
          uri
          name
        }
        isPrimary
      }
    }
    nonMatchingUsers: pageOfResourceUsersDropdownOptions(
      input: $nonMatchingUsersInput
    ) @include(if: $fetchNonMatchingUsers) {
      id
      displayText
      isAssignedToTask
      availableDuration: netAvailableDuration
        @include(if: $isAvailabilityEnabled)
      roles @include(if: $isProjectRoleEnabled) {
        projectRole {
          uri
          name
        }
        isPrimary
      }
    }
  }
`;

const usePageOfResourceUsersDropdownOptions = ({
  sort,
  isProjectRoleEnabled,
  isAvailabilityEnabled,
  userSearchText,
  userRole,
  availabilityPercentageInRange,
  skip = false,
  assignedTaskId
}) => {
  const fetchNonMatchingUsers = Boolean(userRole?.id);
  const pageSize = fetchNonMatchingUsers
    ? DEFAULT_PAGE_SIZE / 2
    : DEFAULT_PAGE_SIZE;

  let sortInput;

  if (sort) {
    sortInput = {
      ...sort,
      field:
        sort.field === 'availableDuration' ? 'netAvailableDuration' : sort.field
    };
  }
  const input = {
    page: 1,
    pagesize: pageSize + 1,
    sort: sortInput,
    assignedTaskId
  };

  const { data, loading } = useQuery(RESOURCE_USERS_QUERY, {
    variables: {
      matchingUsersInput: {
        ...input,
        filter: {
          name: userSearchText || undefined,
          availabilityPercentageInRange,
          roles: userRole ? [userRole.id] : undefined
        }
      },
      nonMatchingUsersInput: {
        ...input,
        filter: {
          name: userSearchText || undefined,
          availabilityPercentageInRange,
          excludeRoles: userRole ? [userRole.id] : undefined
        }
      },
      fetchNonMatchingUsers,
      isAvailabilityEnabled,
      isProjectRoleEnabled
    },
    context: {
      debounceKey: 'resource-users-dropdown-options',
      debounceTimeout: 250
    },
    fetchPolicy: 'network-only',
    skip
  });

  const resourceUsers = (data?.matchingUsers || []).slice(0, pageSize);
  const resourceUsersLength = resourceUsers.length;
  const userWithExcludeRoles = (data?.nonMatchingUsers || []).slice(
    0,
    pageSize
  );

  const includeUsersWithExcludeRoles =
    userRole && resourceUsers.length < pageSize;

  const hasMoreRows = includeUsersWithExcludeRoles
    ? data?.nonMatchingUsers?.length > pageSize
    : data?.matchingUsers?.length > pageSize;

  return {
    resourceUsers: loading
      ? []
      : [
          ...resourceUsers.map(r => ({ ...r, isRoleExists: true })),
          ...(includeUsersWithExcludeRoles
            ? userWithExcludeRoles.map(r => ({ ...r, isRoleExists: false }))
            : [])
        ],
    isLoading: loading,
    hasMoreRows,
    showAllUsers: userRole ? resourceUsersLength < pageSize : true
  };
};

export default usePageOfResourceUsersDropdownOptions;
