import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemText, Radio, makeStyles } from '@material-ui/core';

const useTextStyles = makeStyles(() => ({
  primary: {
    fontWeight: '500'
  }
}));

const OverAllocationOptionItem = ({
  value,
  primaryText,
  secondaryText,
  onSelect,
  id
}) => {
  const textClasses = useTextStyles();

  const handleClick = useCallback(() => {
    if (onSelect) onSelect(value);
  }, [onSelect, value]);

  const inputProps = useMemo(() => ({ 'aria-labelledby': id }), [id]);

  return (
    <ListItem
      button
      key={value}
      dense={false}
      onClick={handleClick}
      role="presentation"
      tabIndex={-1}
    >
      <Radio
        color="primary"
        value={value}
        disableRipple
        inputProps={inputProps}
      />
      <ListItemText
        id={id}
        primary={primaryText}
        secondary={secondaryText}
        classes={textClasses}
        aria-hidden
      />
    </ListItem>
  );
};

OverAllocationOptionItem.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  onSelect: PropTypes.func
};

export default OverAllocationOptionItem;
