import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { NoValue, Decimal, FormattedNumber } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    alignItems: 'center',
    columnGap: theme.spacing(0.5)
  },
  valueCell: {
    fontWeight: theme.typography.fontWeightBold,
    justifySelf: 'end'
  },
  labelCell: {
    justifySelf: 'start'
  }
}));

export const useHoursVarianceStyles = makeStyles(theme => ({
  diffPositive: {
    marginRight: theme.spacing(-0.5),
    paddingRight: theme.spacing(0.5),
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark
  },
  diffNegative: {
    marginRight: theme.spacing(-0.5),
    paddingRight: theme.spacing(0.5),
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark
  }
}));

export const ActualSummaryPeriodPopupRow2 = ({
  totalHours,
  hoursType,
  scheduledHours
}) => {
  const classes = useStyles();

  const hourVarianceStyles = useHoursVarianceStyles();

  const totalValue = useMemo(
    () => ({
      value: <Decimal value={totalHours} />
    }),
    [totalHours]
  );

  const totalPercent = (totalHours / scheduledHours) * 100;

  const totalPercentValue = useMemo(
    () => ({
      value: <Decimal value={totalPercent} />
    }),
    [totalPercent]
  );

  return (
    <>
      <div className={classes.row}>
        <span className={classes.labelCell}>
          <FormattedMessage id={`actualsSummaryPeriodPopup.${hoursType}`} />
        </span>
        <span className={classes.valueCell} data-qe-id={`${hoursType}_hours`}>
          {totalHours ? (
            hoursType === 'totalDifference' ? (
              <FormattedNumber
                classes={hourVarianceStyles}
                value={totalHours}
                showAddIcon={totalHours < 0}
                isHigher={totalHours < 0}
                fixedDecimalScale
                formattedMessageKey="actualsSummaryPeriodPopup.hours"
              />
            ) : (
              <FormattedMessage
                id="actualsSummaryPeriodPopup.hours"
                values={totalValue}
              />
            )
          ) : (
            <NoValue />
          )}
        </span>
        <span className={classes.valueCell}>
          {totalHours ? (
            hoursType === 'totalDifference' ? (
              <FormattedNumber
                classes={hourVarianceStyles}
                value={totalPercent}
                showAddIcon={totalPercent < 0}
                isHigher={totalPercent < 0}
                fixedDecimalScale
                formattedMessageKey="actualsSummaryPeriodPopup.percentage"
              />
            ) : (
              <FormattedMessage
                id="actualsSummaryPeriodPopup.percentage"
                values={totalPercentValue}
              />
            )
          ) : (
            <NoValue />
          )}
        </span>
      </div>
    </>
  );
};

ActualSummaryPeriodPopupRow2.propTypes = {
  totalHours: PropTypes.number,
  hoursType: PropTypes.string,
  scheduledHours: PropTypes.number
};

export default ActualSummaryPeriodPopupRow2;
