import { TaskStatus } from '~/types';

export const getEquivalentTaskStatus = status =>
  ({
    'In Progress': TaskStatus.Inprogress,
    Completed: TaskStatus.Completed
  }[status] || TaskStatus.Notstarted);

export const getEquivalentRolledUpTaskStatus = ({ status, actualHours }) =>
  status && status.toUpperCase() === TaskStatus.Completed
    ? TaskStatus.Completed
    : actualHours
    ? TaskStatus.Inprogress
    : TaskStatus.Notstarted;

export const getRolledUpEstimatedAtCompletionHours = ({
  status,
  actualHours,
  totalEstimatedAtCompletionHours
}) =>
  status && status.toUpperCase() === TaskStatus.Completed
    ? actualHours
    : totalEstimatedAtCompletionHours;

export const isProjectClosed = workflow =>
  workflow?.uri === 'urn:replicon-psa:project-stage:close-out' ||
  workflow?.uri === 'urn:replicon-psa:project-stage:archived';
