import { gql } from 'graphql-tag';
import { projectBasicInfoFragment } from './projectBasicInfoFragment';

export const UPDATE_PROJECT_BASIC_INFO_MUTATION = gql`
  mutation UpdateProjectBasicInfo(
    $projectInput: ProjectInput!
    $isPsaEarnedRevenueEnabled: Boolean = false
  ) {
    updateProject2(projectInput: $projectInput) {
      ...ProjectBasicInfo
      earnedRevenueScript @include(if: $isPsaEarnedRevenueEnabled) {
        displayText: name
        name
        id
      }
    }
  }
  ${projectBasicInfoFragment}
`;
