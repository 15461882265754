import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { TaskStatus } from '~/types';
import { ESTIMATED_HOURS_VARIATION_STATUS } from '~/modules/common/enums';
import {
  HoursValue,
  VariationPillbox,
  CostVariationPillbox,
  getEstimatedHoursVariation,
  getEstimatedCostVariation,
  isHoursDefined
} from '../../../Gantt/components';

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(2.25),
    paddingLeft: theme.spacing(2),
    margin: theme.spacing(1, 0, 1, 0),
    backgroundColor: theme.palette.grey[50]
  },
  textContainer: {
    height: theme.spacing(2.25),
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(0.25)
  },
  hours: {
    fontSize: theme.typography.caption.fontSize,
    marginRight: theme.spacing(0.5),
    fontWeight: 600,
    pointerEvents: 'none'
  },
  hoursWithoutVariationStatus: {
    marginLeft: theme.spacing(0.5),
    fontWeight: 400
  },
  divider: {
    color: theme.palette.taskRollUp.text.subHeading,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5)
  },
  dividerWithoutRTL: {
    marginLeft: 0
  },
  timeValue: {
    fontWeight: 600,
    fontSize: theme.typography.body2.fontSize
  },
  subTimeValue: {
    fontWeight: 500,
    fontSize: theme.typography.body2.fontSize
  },
  noBorderRightRadius: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  }
}));

export const usePillStylesOverride = makeStyles(theme => ({
  variationIcon: {
    height: theme.spacing(2),
    fontSize: theme.spacing(2),
    paddingBottom: theme.spacing(0.25)
  },
  variationContainer: {
    height: theme.spacing(2.55)
  },
  variationPill: {
    fontSize: theme.typography.caption.fontSize,
    height: theme.spacing(2.55)
  },
  variationValue: {
    fontSize: theme.typography.caption.fontSize
  },
  variationLabel: {
    fontSize: theme.typography.caption.fontSize
  }
}));

const useVariationPillBoxStyles = makeStyles(() => ({
  container: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  }
}));

const TaskCardFooter = ({
  canViewEstimate,
  canViewCostData,
  estimatedHours,
  intl,
  isRTL,
  isPsaPpmCostEacEnhancementsEnabled,
  isPsaPpmCostEacEnhancements2Enabled,
  isPsaRmpTaskAllocation1Enabled,
  isProject,
  missingEstimatesInProject,
  numberFormat,
  rolledUpSummary: { actualHours, totalEstimatedAtCompletionHours } = {},
  rolledUpCostSummary,
  taskStatus
}) => {
  const classes = useStyles();
  const pillClasses = usePillStylesOverride();
  const variationPillBoxClasses = useVariationPillBoxStyles();

  const { variationStatus, variation } = getEstimatedHoursVariation({
    estimatedHours,
    totalEstimatedAtCompletionHours,
    missingEstimatesInProject
  });

  const { costVariationStatus, variationCost } = getEstimatedCostVariation({
    estimatedCost: isProject
      ? rolledUpCostSummary?.budgetedCostInProjectCurrency
      : rolledUpCostSummary?.initialEstimatedCostInProjectCurrency,
    totalEstimatedAtCompletionCost:
      rolledUpCostSummary?.totalEstimatedAtCompletionCostInProjectCurrency
  });

  const isCostVariationPillBoxVisible = Boolean(
    isPsaPpmCostEacEnhancements2Enabled &&
      canViewCostData &&
      canViewEstimate &&
      variationCost
  );
  const showMissingEstimate =
    variationStatus === ESTIMATED_HOURS_VARIATION_STATUS.MISSING_ESTIMATE;
  const hrsLabel = intl.formatMessage({ id: 'projectTasksPage.h' });

  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
        {taskStatus === TaskStatus.Notstarted &&
          canViewEstimate &&
          !showMissingEstimate && (
            <div>
              <HoursValue
                className={classes.timeValue}
                value={totalEstimatedAtCompletionHours}
                fixedDecimalScale={false}
                numberFormat={numberFormat}
                isPsaPpmCostEacEnhancements2Enabled={
                  isPsaPpmCostEacEnhancements2Enabled
                }
              />
              <span
                className={classNames(
                  classes.hours,
                  !variationStatus
                    ? classes.hoursWithoutVariationStatus
                    : undefined
                )}
              >
                {hrsLabel}
              </span>
            </div>
          )}
        {taskStatus === TaskStatus.Inprogress && (
          <>
            <div>
              <HoursValue
                className={classes.timeValue}
                value={actualHours}
                fixedDecimalScale={false}
                numberFormat={numberFormat}
                isPsaPpmCostEacEnhancements2Enabled={
                  isPsaPpmCostEacEnhancements2Enabled
                }
              />
              <span
                className={classNames(
                  classes.hours,
                  !variationStatus
                    ? classes.hoursWithoutVariationStatus
                    : undefined
                )}
              >
                {hrsLabel}
              </span>
            </div>
            {canViewEstimate &&
              isHoursDefined(totalEstimatedAtCompletionHours) && (
                <>
                  <span
                    className={classNames(
                      classes.divider,
                      !isRTL ? classes.dividerWithoutRTL : undefined
                    )}
                  >
                    /
                  </span>
                  <div>
                    <HoursValue
                      className={classes.subTimeValue}
                      value={totalEstimatedAtCompletionHours}
                      fixedDecimalScale={false}
                      numberFormat={numberFormat}
                      isPsaPpmCostEacEnhancements2Enabled={
                        isPsaPpmCostEacEnhancements2Enabled
                      }
                    />
                    <span
                      className={classNames(
                        classes.hours,
                        !variationStatus
                          ? classes.hoursWithoutVariationStatus
                          : undefined
                      )}
                    >
                      {hrsLabel}
                    </span>
                  </div>
                </>
              )}
          </>
        )}
        {taskStatus === TaskStatus.Completed && canViewEstimate && (
          <>
            <HoursValue
              className={classes.timeValue}
              value={totalEstimatedAtCompletionHours}
              fixedDecimalScale={false}
              numberFormat={numberFormat}
              isPsaPpmCostEacEnhancements2Enabled={
                isPsaPpmCostEacEnhancements2Enabled
              }
            />
            <span>{hrsLabel}</span>
          </>
        )}
      </div>
      {canViewEstimate && showMissingEstimate ? (
        taskStatus !== TaskStatus.Completed && (
          <VariationPillbox
            classes={variationPillBoxClasses}
            pillClasses={pillClasses}
            intl={intl}
            variationStatus={variationStatus}
            isRTL={isRTL}
            isPsaPpmCostEacEnhancementsEnabled={
              isPsaPpmCostEacEnhancementsEnabled
            }
            isPsaPpmCostEacEnhancements2Enabled={
              isPsaPpmCostEacEnhancements2Enabled
            }
            numberFormat={numberFormat}
            isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
          />
        )
      ) : (
        <VariationPillbox
          classes={variationPillBoxClasses}
          pillClasses={pillClasses}
          intl={intl}
          variationStatus={variationStatus}
          variationValue={variation}
          isRTL={isRTL}
          isPsaPpmCostEacEnhancementsEnabled={
            isPsaPpmCostEacEnhancementsEnabled
          }
          isPsaPpmCostEacEnhancements2Enabled={
            isPsaPpmCostEacEnhancements2Enabled
          }
          numberFormat={numberFormat}
          isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
        />
      )}
      {isCostVariationPillBoxVisible ? (
        <CostVariationPillbox
          intl={intl}
          variationStatus={costVariationStatus}
          variationValue={variationCost}
          isRTL={isRTL}
          isPsaPpmCostEacEnhancementsEnabled={
            isPsaPpmCostEacEnhancementsEnabled
          }
          isPsaPpmCostEacEnhancements2Enabled={
            isPsaPpmCostEacEnhancements2Enabled
          }
          numberFormat={numberFormat}
          pillClassesOverride={pillClasses}
          isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
        />
      ) : null}
    </div>
  );
};

TaskCardFooter.propTypes = {
  canViewEstimate: PropTypes.bool,
  canViewCostData: PropTypes.bool,
  estimatedHours: PropTypes.number,
  intl: PropTypes.object,
  isProject: PropTypes.bool,
  isRTL: PropTypes.bool,
  isPsaPpmCostEacEnhancementsEnabled: PropTypes.bool,
  isPsaPpmCostEacEnhancements2Enabled: PropTypes.bool,
  isPsaRmpTaskAllocation1Enabled: PropTypes.bool,
  missingEstimatesInProject: PropTypes.bool,
  numberFormat: PropTypes.object,
  rolledUpSummary: PropTypes.object,
  rolledUpCostSummary: PropTypes.object,
  taskStatus: PropTypes.string
};

export default TaskCardFooter;
