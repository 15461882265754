import React from 'react';
import PropTypes from 'prop-types';
import { Divider, makeStyles } from '@material-ui/core';
import ActualSummaryPeriodPopupRow2 from './ActualSummaryPeriodPopupRow2';

const useStyles = makeStyles(theme => ({
  divider: {
    width: '100%',
    margin: theme.spacing(0.5, 0, 0.5, 0)
  }
}));

export const ActualSummaryPeriodPopupContent = ({
  totalActualHours,
  totalAllocatedHours,
  scheduledHours
}) => {
  const classes = useStyles();

  return (
    <>
      <ActualSummaryPeriodPopupRow2
        totalHours={totalAllocatedHours}
        hoursType="totalAllocated"
        scheduledHours={scheduledHours}
      />
      <ActualSummaryPeriodPopupRow2
        totalHours={totalActualHours}
        hoursType="totalActuals"
        scheduledHours={scheduledHours}
      />
      <Divider className={classes.divider} />
      <ActualSummaryPeriodPopupRow2
        totalHours={totalAllocatedHours - totalActualHours}
        hoursType="totalDifference"
        scheduledHours={scheduledHours}
      />
    </>
  );
};

ActualSummaryPeriodPopupContent.propTypes = {
  totalActualHours: PropTypes.number,
  totalAllocatedHours: PropTypes.number,
  scheduledHours: PropTypes.number
};

export default ActualSummaryPeriodPopupContent;
