import { useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';
import { DEFAULT_PAGE_SIZE } from '~/modules/projects/tasks/hooks/useProjectTasks';

const projectTasksFragment = gql`
  fragment ParentTasks on Project {
    id
    tasks(page: $page, pageSize: $pageSize) {
      id
      parent {
        id
        uri
        displayText
      }
    }
  }
`;

export const createProjectFragmentId = projectId =>
  `Project:{"id":"${projectId}"}`;

export const useParentTaskIds = ({
  projectId,
  isPsaPswatTaskDateRollupEnabled
}) => {
  const { cache } = useApolloClient();

  if (!isPsaPswatTaskDateRollupEnabled) {
    return [];
  }
  const projectTasksFragmentFromCache = cache.readFragment({
    id: createProjectFragmentId(projectId),
    fragment: projectTasksFragment,
    variables: {
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE
    }
  });

  if (projectTasksFragmentFromCache) {
    const { tasks } = projectTasksFragmentFromCache;

    return tasks.map(task => task.parent?.id).filter(Boolean);
  }

  return [];
};
