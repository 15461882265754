import { object, string, number, mixed, array } from 'yup';
import { createCustomFieldValidation } from '~/modules/common/components/TaskDrawer/EditTask/validationSchema';

const MAX_NAME_LENGTH = 255;
const MAX_CODE_LENGTH = 50;
const HOURS_MAX = 100000;

export const validationMessage = max =>
  `Exceeds max allowed value: ${max.toLocaleString('en')}`;

export const buildValidationSchema = ({
  intl,
  customFieldDefinitions,
  isPsaRmpTaskAllocation1Enabled
}) =>
  object().shape({
    name: string()
      .required(intl.formatMessage({ id: 'createTaskForm.nameRequired' }))
      .trim()
      .max(
        MAX_NAME_LENGTH,
        intl.formatMessage(
          { id: 'createTaskForm.nameExceedsMaxLength' },
          { max: MAX_NAME_LENGTH }
        )
      ),
    code: string()
      .max(
        MAX_CODE_LENGTH,
        intl.formatMessage(
          { id: 'createTaskForm.codeExceedsMaxLength' },
          { max: MAX_CODE_LENGTH }
        )
      )
      .nullable(),
    ...(!isPsaRmpTaskAllocation1Enabled && {
      estimatedHours: number().max(
        HOURS_MAX,
        intl.formatMessage(
          { id: 'createTaskForm.estimatedHoursExceedsMax' },
          { max: HOURS_MAX }
        )
      )
    }),
    startDate: mixed().test(
      'startDate',
      intl.formatMessage({
        id: 'taskDrawerEdit.validations.startDateRequired'
      }),
      function validate(value) {
        return (
          value ||
          !(this.parent.isMilestone || this.parent.areStartAndEndDatesRequired)
        );
      }
    ),
    endDate: mixed().test(
      'endDate',
      intl.formatMessage({
        id: 'taskDrawerEdit.validations.endDateRequired'
      }),
      function validate(value) {
        return (
          value ||
          !(this.parent.isMilestone || this.parent.areStartAndEndDatesRequired)
        );
      }
    ),
    ...customFieldDefinitions.reduce(
      (fields, definition) => ({
        ...fields,
        [definition.uri]: createCustomFieldValidation(definition, intl)
      }),
      {}
    ),
    resourceAllocations: array().of(
      object().shape({
        allocatedHours: number()
          .max(HOURS_MAX, validationMessage(HOURS_MAX))
          .nullable()
      })
    ),
    resourceEstimates: array().of(
      object().test(
        'roleOrResourceRequired',
        intl.formatMessage({
          id: 'createTaskForm.roleOrResourceRequired'
        }),
        ({ role, resource }) => role?.id || resource?.id
      )
    )
  });

export default buildValidationSchema;
