import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useProjectActualSeriesByProjectRole } from '~/modules/resourcing/common/hooks';
import {
  HolidaySummaryBlocks,
  TimeOffSummaryBlocks
} from '~/modules/resourcing/common/components';
import {
  useQuickAllocationContext,
  useProjectDateRangeDetails
} from '~/modules/quickAllocation/components/QuickAllocationUserChart/hooks';
import {
  getUserActualTotalHoursByPeriod,
  getUserAllocatedTotalHoursPeriod
} from '~/modules/resourcing/util';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { RangeBoundaries } from '~/modules/common/charts/timeline/components';
import { useChartDisplayPeriods } from '~/modules/resourcing/common/chart/hooks';
import QuickAllocationChartTimeline from './QuickAllocationChartTimeline';

const getActualSummaryBlockPopupProps = ({
  roleDisplayText,
  userActualTotalHours,
  userAllocatedTotalHours
}) => ({
  userActualTotalHours,
  userAllocatedTotalHours,
  roleDisplayText
});

export const getAllocationByAllocationId = entries =>
  (entries || []).reduce(
    (acc, entry) => ({
      ...acc,
      [entry.id]: entry
    }),
    {}
  );

const useStyles = makeStyles(theme => ({
  timeLinesContainer: {
    display: 'flex',
    flexBasis: theme.spacing(17.5),
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: theme.spacing(12.5)
  }
}));

const useRowStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  }
}));

const useTimeoffSummaryBlocksStyles = makeStyles(() => ({
  root: {
    height: '100%',
    top: '0px !important'
  },
  container: {
    height: '100%'
  }
}));

const useHolidaySummaryBlocksStyles = makeStyles(() => ({
  root: {
    height: '100%',
    top: '0px !important'
  }
}));

export const QuickAllocationChartTimelines2 = ({
  allocatedProject,
  handleRemoveResourceAllocation,
  resourceUser,
  holidaySummaryPeriods,
  timeoffSummaryPeriods,
  resourceAllocations,
  loadingAllocations,
  rangeMinDate,
  rangeMaxDate,
  setSnackBarState
}) => {
  const classes = useStyles();
  const rowClasses = useRowStyles();

  const {
    scale,
    chartDates,
    dateRange,
    isShowActualEnabled
  } = useQuickAllocationContext();
  const [{ start: chartStartDate }] = chartDates;

  const timeOffSummaryBlocksClasses = useTimeoffSummaryBlocksStyles();
  const holidaySummaryBlocksClasses = useHolidaySummaryBlocksStyles();

  const {
    isLoading: isActualsLoading,
    projectActualSeriesByProjectRole
  } = useProjectActualSeriesByProjectRole({
    dateRange,
    scale,
    projectId: allocatedProject.project.id,
    userUri: resourceUser.id,
    isShowActualEnabled
  });

  const skipDateRangeQuery = Boolean(
    !isShowActualEnabled || loadingAllocations || resourceAllocations?.length
  );
  const {
    loading: isLoadingDateRange,
    project: projectDateRange
  } = useProjectDateRangeDetails({
    projectId: allocatedProject.project.id,
    skip: skipDateRangeQuery
  });

  const chartDisplayPeriods = useChartDisplayPeriods({
    chartDisplayDateRange: dateRange,
    scale
  });

  const resourceAllocationsById = getAllocationByAllocationId(
    resourceAllocations
  );

  const userAllocatedTotalHours = getUserAllocatedTotalHoursPeriod({
    allocations: resourceAllocations,
    chartDisplayDateRange: dateRange,
    chartDisplayPeriods
  });

  const userActualTotalHours = getUserActualTotalHoursByPeriod({
    actualSeriesByProjectRole: projectActualSeriesByProjectRole,
    scale
  });

  const { timeoffs, holidays } = resourceUser;

  const loading = loadingAllocations || isActualsLoading || isLoadingDateRange;

  const { startDate, endDate } =
    (skipDateRangeQuery
      ? resourceAllocations?.[0]?.project
      : projectDateRange) || {};
  const minDate = startDate ? mapIsoStringtoUtcObject(startDate) : rangeMinDate;
  const maxDate = endDate ? mapIsoStringtoUtcObject(endDate) : rangeMaxDate;

  return (
    <div className={classes.timeLinesContainer}>
      {!loading && (
        <>
          <RangeBoundaries
            scale={scale}
            chartStartDate={chartStartDate}
            start={minDate?.toISO()}
            end={maxDate?.toISO()}
          />
          <TimeOffSummaryBlocks
            scale={scale}
            chartStartDate={dateRange.startDate}
            timeoffSummaryPeriods={timeoffSummaryPeriods}
            chartDisplayDateRange={dateRange}
            timeOffs={timeoffs}
            showTooltip
            clickable
            classes={timeOffSummaryBlocksClasses}
          />
          <HolidaySummaryBlocks
            scale={scale}
            chartStartDate={dateRange.startDate}
            holidaySummaryPeriods={holidaySummaryPeriods}
            chartDisplayDateRange={dateRange}
            holidays={holidays}
            showTooltip
            clickable
            classes={holidaySummaryBlocksClasses}
          />
        </>
      )}
      {(allocatedProject.roles || []).map(
        ({ role, resourceAllocationReference }, index) => {
          const allocations = resourceAllocationReference
            .map(({ id }) => resourceAllocationsById[id])
            .filter(x => x);

          return (
            <div key={role?.id || `${resourceUser.uri}-no-role`}>
              <QuickAllocationChartTimeline
                classes={rowClasses}
                chartDisplayPeriods={chartDisplayPeriods}
                index={index}
                loading={loading}
                resourceAllocations={allocations}
                resourceUser={resourceUser}
                allocatedProject={allocatedProject}
                actualsData={projectActualSeriesByProjectRole.find(
                  e => e.role?.id === role?.id
                )}
                actualSummaryBlockPopupProps={getActualSummaryBlockPopupProps({
                  roleDisplayText: role?.displayText,
                  userActualTotalHours,
                  userAllocatedTotalHours
                })}
                handleRemoveResourceAllocation={handleRemoveResourceAllocation}
                setSnackBarState={setSnackBarState}
              />
            </div>
          );
        }
      )}
    </div>
  );
};

QuickAllocationChartTimelines2.propTypes = {
  allocatedProject: PropTypes.object,
  handleRemoveResourceAllocation: PropTypes.func,
  resourceUser: PropTypes.object,
  holidaySummaryPeriods: PropTypes.array,
  timeoffSummaryPeriods: PropTypes.array,
  resourceAllocations: PropTypes.array,
  loadingAllocations: PropTypes.bool,
  rangeMinDate: PropTypes.object,
  rangeMaxDate: PropTypes.object,
  setSnackBarState: PropTypes.func
};

export default QuickAllocationChartTimelines2;
