import { useCallback } from 'react';
import {
  toRepliconDate,
  objectToUTCString,
  mapJsDateToLuxonUtcDate,
  dateToMidnightUTCObject
} from '~/modules/common/dates/convert';
import {
  MODE,
  calculateNewEndDate,
  getDurationSummaryValues
} from '~/modules/tasks/components/RescheduleTaskDialog';
import { removeAllTooltips } from './useOnTaskHoverTooltips';

export const TASK_DRAG_MODE_RESIZE = 'resize';
export const TASK_DRAG_MODE_MOVE = 'move';

export const calculateStartEndDatesOnResize = task => {
  const startDateTime = mapJsDateToLuxonUtcDate(task.start_date);
  const endDateTime = mapJsDateToLuxonUtcDate(task.end_date);

  return {
    startDate: startDateTime.startOf('day'),
    endDate: endDateTime.minus({ days: 1 }).startOf('day')
  };
};

export const calculateStartEndDatesOnMove = task => {
  const startDateTime = mapJsDateToLuxonUtcDate(task.start_date);
  const endDateTime = mapJsDateToLuxonUtcDate(task.end_date);

  const durationBetween = endDateTime.diff(startDateTime, 'days');
  const daysBetween = durationBetween.days - 1;
  const roundedStartDate = startDateTime.startOf('day');
  const updatedEndDate = roundedStartDate.plus({ days: daysBetween });

  return {
    startDate: roundedStartDate,
    endDate: updatedEndDate
  };
};

export const updateTaskDates = ({
  task,
  setTaskTimeEntryDateRange,
  gantt,
  startDate,
  endDate,
  rescheduleTaskModificationOption,
  shouldRollupTaskDates
}) => {
  const localStartDate = toRepliconDate(startDate);
  const localEndDate = toRepliconDate(endDate);

  setTaskTimeEntryDateRange({
    taskId: task.id,
    startDate: localStartDate && objectToUTCString(localStartDate),
    endDate: localEndDate && objectToUTCString(localEndDate),
    rescheduleTaskModificationOption,
    shouldRollupTaskDates
  });

  // eslint-disable-next-line no-param-reassign
  task.start_date = startDate && startDate.toJSDate();
  // eslint-disable-next-line no-param-reassign
  task.end_date = endDate && endDate.toJSDate();
  gantt.updateTask(task.id);
};

export const useOnAfterTaskDrag = ({
  isRescheduleTaskDialogEnabled,
  openRescheduleTaskDialog,
  setTaskId,
  setTaskTimeEntryDateRange,
  setRescheduleTaskState
}) =>
  useCallback(
    (gantt, isPsaPswatTaskDateRollupEnabled, isTaskDateRollupEnabled) => (
      id,
      mode
    ) => {
      switch (mode) {
        case TASK_DRAG_MODE_RESIZE: {
          const task = gantt.getTask(id);
          const { startDate, endDate } = calculateStartEndDatesOnResize(task);

          setTaskId(id);

          if (!isRescheduleTaskDialogEnabled) {
            updateTaskDates({
              task,
              setTaskTimeEntryDateRange,
              startDate,
              endDate,
              gantt,
              shouldRollupTaskDates:
                isPsaPswatTaskDateRollupEnabled && isTaskDateRollupEnabled
            });
          } else {
            setRescheduleTaskState({
              durationMode: MODE.CUSTOM,
              updatedTaskDateRange: { startDate, endDate }
            });
            openRescheduleTaskDialog();
            removeAllTooltips();
          }

          break;
        }

        case TASK_DRAG_MODE_MOVE: {
          const task = gantt.getTask(id);
          const { startDate, endDate } = calculateStartEndDatesOnMove(task);

          setTaskId(id);

          if (!isRescheduleTaskDialogEnabled) {
            updateTaskDates({
              task,
              setTaskTimeEntryDateRange,
              startDate,
              endDate,
              gantt,
              shouldRollupTaskDates:
                isPsaPswatTaskDateRollupEnabled && isTaskDateRollupEnabled
            });
          } else {
            const durationValues = getDurationSummaryValues(
              dateToMidnightUTCObject(task.startDate),
              dateToMidnightUTCObject(task.endDate)
            );

            const newCalculatedEndDate = calculateNewEndDate({
              durationValues,
              newStartDate: startDate,
              mode: MODE.KEEP_TASK_WORK_DAYS,
              newTaskDateRange: { startDate, endDate }
            });

            setRescheduleTaskState({
              durationMode: MODE.KEEP_TASK_WORK_DAYS,
              updatedTaskDateRange: {
                startDate,
                endDate: newCalculatedEndDate
              }
            });

            openRescheduleTaskDialog();
            removeAllTooltips();
          }

          break;
        }
        default:
          return false;
      }

      return true;
    },
    [
      isRescheduleTaskDialogEnabled,
      openRescheduleTaskDialog,
      setRescheduleTaskState,
      setTaskId,
      setTaskTimeEntryDateRange
    ]
  );

export default useOnAfterTaskDrag;
