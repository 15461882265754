import React, { useMemo, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse, Checkbox, Tooltip } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormattedMessage } from 'react-intl';
import HelpIcon from '@material-ui/icons/Help';
import Alert from '@material-ui/lab/Alert';
import TasksToRescheduleDropDown from './TasksToRescheduleDropDown';
import { TASKS_TO_RESCHEDULE_TYPE } from './enum';
import ResourceRequestsInfo from './ResourceRequestsInfo';
import ResourceRequestsToRescheduleDropDown from './ResourceRequestsToRescheduleDropDown';

const useStyles = makeStyles(theme => ({
  rescheduleOptions: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
    '& li:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.table.border}`
    }
  },
  option: {
    padding: theme.spacing(2, 0),
    margin: 0
  },
  message: {
    padding: 0
  },
  header: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  dropdown: {
    margin: theme.spacing(0, 0, 0, 3.5),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    }
  },
  alert: {
    marginLeft: theme.spacing(3.5),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    },
    whiteSpace: 'pre-wrap'
  },
  helpIcon: {
    color: theme.palette.text.secondary,
    cursor: 'help',
    opacity: 0.3,
    verticalAlign: 'middle',
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    }),
    marginLeft: theme.spacing(-1.5),
    '&:hover': {
      opacity: 1
    }
  }
}));

const rescheduleTasks = (
  <FormattedMessage id="rescheduleProject.rescheduleTasks" />
);
const rescheduleAllTasks = (
  <FormattedMessage id="rescheduleProject.rescheduleAllTasks" />
);
const rescheduleBillPlan = (
  <FormattedMessage id="rescheduleProject.rescheduleBillPlan" />
);
const rescheduleResourceRequests = (
  <FormattedMessage id="rescheduleProject.rescheduleResourceRequests" />
);
const noTaskDefinedInfo = (
  <FormattedMessage id="rescheduleProject.noTaskDefined" />
);

const hasBillingInfo = (
  <FormattedMessage id="rescheduleProject.hasBillingInfo" />
);

const tasksPermissionErrorMessage = (
  <FormattedMessage id="rescheduleProject.permissionErrorMessage.tasks" />
);
const billPlanPermissionErrorMessage = (
  <FormattedMessage id="rescheduleProject.permissionErrorMessage.billPlan" />
);

export const RescheduleProjectOptions = ({
  project,
  setFieldValue,
  tasksToReschedule,
  showBillPlan,
  canRescheduleTasks,
  canRescheduleBillPlan,
  tasksStatusCountSummary,
  isRescheduleTasksChecked,
  isRescheduleBillPlanChecked,
  canRescheduleResourceRequests,
  resourceRequestsStatusCountSummary,
  isRescheduleResourceRequestsChecked,
  resourceRequestsToRescheduleByStatus,
  scheduleAllTasks
}) => {
  const classes = useStyles();
  const { permittedActionUris, billingDetails } = project;

  const isTimeAndMaterialType =
    billingDetails?.billingTypeUri ===
    'urn:replicon:billing-type:time-and-material';

  const rescheduleBillPlanTooltipMessage = useMemo(
    () => (
      <FormattedMessage id="rescheduleProject.RescheduleBillPlanDisabledInfo" />
    ),
    []
  );

  const canViewTasks = permittedActionUris.includes(
    'urn:replicon:project-action:view-tasks'
  );
  const canEditTasks = permittedActionUris.includes(
    'urn:replicon:project-action:edit-tasks'
  );

  const canEditBillingContracts = permittedActionUris.includes(
    'urn:replicon:project-action:edit-billing-contracts'
  );

  const canViewBillingData = permittedActionUris.includes(
    'urn:replicon:project-action:view-billing-data'
  );

  const hasTasks =
    tasksStatusCountSummary &&
    (tasksStatusCountSummary.inProgressTasksCount > 0 ||
      tasksStatusCountSummary.completedTasksCount > 0 ||
      tasksStatusCountSummary.notStartedTasksCount > 0);

  const canShowTasksErrorAlert = !canEditTasks && !(canViewTasks && !hasTasks);

  const handleRescheduleTasksChecked = useCallback(
    (_, checked) => {
      setFieldValue('isRescheduleTasksChecked', checked);
    },
    [setFieldValue]
  );

  const handleRescheduleBillPlanChecked = useCallback(
    (_, checked) => {
      setFieldValue('isRescheduleBillPlanChecked', checked);
    },
    [setFieldValue]
  );

  const handleRescheduleResourceRequestsChecked = useCallback(
    (_, checked) => {
      setFieldValue('isRescheduleResourceRequestsChecked', checked);
    },
    [setFieldValue]
  );

  const handleTasksToRescheduleChange = useCallback(
    value => {
      setFieldValue('tasksToReschedule', value.id);
    },
    [setFieldValue]
  );

  const handleResourcerRequestsToRescheduleChange = useCallback(
    value => {
      setFieldValue('resourceRequestsToRescheduleByStatus', value.id);
    },
    [setFieldValue]
  );

  const taskCheckBox = useMemo(
    () => (
      <Checkbox
        checked={isRescheduleTasksChecked}
        onChange={handleRescheduleTasksChecked}
        name="isRescheduleTasksChecked"
      />
    ),
    [isRescheduleTasksChecked, handleRescheduleTasksChecked]
  );

  const billPlanCheckBox = useMemo(
    () => (
      <Checkbox
        checked={canRescheduleBillPlan ? isRescheduleBillPlanChecked : false}
        onChange={handleRescheduleBillPlanChecked}
        name="isRescheduleBillPlanChecked"
      />
    ),
    [
      canRescheduleBillPlan,
      isRescheduleBillPlanChecked,
      handleRescheduleBillPlanChecked
    ]
  );

  const resourceRequestsCheckBox = useMemo(
    () => (
      <Checkbox
        checked={isRescheduleResourceRequestsChecked}
        onChange={handleRescheduleResourceRequestsChecked}
        name="isRescheduleResourceRequestsChecked"
      />
    ),
    [
      isRescheduleResourceRequestsChecked,
      handleRescheduleResourceRequestsChecked
    ]
  );

  const statusClasses = useMemo(() => ({ message: classes.message }), [
    classes.message
  ]);

  const alertClasses = useMemo(
    () => ({ message: classes.message, root: classes.alert }),
    [classes.message, classes.alert]
  );

  const tasksInfo = useMemo(
    () => (
      <>
        {hasTasks
          ? Object.entries(tasksStatusCountSummary)
              .filter(([_, v]) => typeof v === 'number' && v > 0)
              .sort()
              .map(([k, v], i, array) => (
                <FormattedMessage
                  key={k}
                  id={`rescheduleProject.${k}`}
                  values={{
                    value: v,
                    separator: array[i + 1] ? ', ' : null
                  }}
                />
              ))
          : noTaskDefinedInfo}
      </>
    ),
    [hasTasks, tasksStatusCountSummary]
  );

  return (
    <ul className={classes.rescheduleOptions}>
      <li className={classes.option}>
        <div className={classes.header}>
          <FormControlLabel
            control={resourceRequestsCheckBox}
            label={rescheduleResourceRequests}
            disabled={!canRescheduleResourceRequests}
          />
          <ResourceRequestsInfo
            resourceRequestsStatusCountSummary={
              resourceRequestsStatusCountSummary
            }
          />
        </div>
        <ResourceRequestsToRescheduleDropDown
          value={resourceRequestsToRescheduleByStatus}
          onChange={handleResourcerRequestsToRescheduleChange}
          resourceRequestsStatusCountSummary={
            resourceRequestsStatusCountSummary
          }
          isRescheduleResourceRequestsChecked={
            isRescheduleResourceRequestsChecked
          }
        />
      </li>
      <li className={classes.option}>
        <div>
          <div className={classes.header}>
            <FormControlLabel
              control={taskCheckBox}
              label={scheduleAllTasks ? rescheduleAllTasks : rescheduleTasks}
              disabled={!canRescheduleTasks}
            />
            {canViewTasks ? (
              <Alert
                icon={false}
                severity="info"
                size="small"
                classes={statusClasses}
              >
                {tasksInfo}
              </Alert>
            ) : null}
          </div>
          {canShowTasksErrorAlert ? (
            <Alert severity="error" size="small" classes={alertClasses}>
              {tasksPermissionErrorMessage}
            </Alert>
          ) : null}
          <Collapse
            in={
              isRescheduleTasksChecked &&
              (tasksStatusCountSummary.inProgressTasksCount > 0 ||
                tasksStatusCountSummary.completedTasksCount > 0)
            }
            timeout="auto"
            unmountOnExit
          >
            {scheduleAllTasks === false && (
              <div className={classes.dropdown}>
                <TasksToRescheduleDropDown
                  variant="filled"
                  margin="dense"
                  value={tasksToReschedule}
                  onChange={handleTasksToRescheduleChange}
                />
              </div>
            )}
          </Collapse>
        </div>
      </li>
      {showBillPlan && (
        <li className={classes.option}>
          <FormControlLabel
            control={billPlanCheckBox}
            label={rescheduleBillPlan}
            disabled={!canRescheduleBillPlan}
          />
          {isTimeAndMaterialType ? (
            <Tooltip title={rescheduleBillPlanTooltipMessage}>
              <HelpIcon fontsize="small" className={classes.helpIcon} />
            </Tooltip>
          ) : null}
          {!canRescheduleBillPlan && !isTimeAndMaterialType ? (
            canEditBillingContracts && canViewBillingData ? (
              <Alert
                icon={false}
                severity="info"
                size="small"
                classes={alertClasses}
              >
                {hasBillingInfo}
              </Alert>
            ) : (
              <Alert severity="error" size="small" classes={alertClasses}>
                {billPlanPermissionErrorMessage}
              </Alert>
            )
          ) : null}
        </li>
      )}
    </ul>
  );
};

RescheduleProjectOptions.propTypes = {
  project: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func,
  showBillPlan: PropTypes.bool,
  canRescheduleTasks: PropTypes.bool,
  canRescheduleBillPlan: PropTypes.bool,
  isRescheduleTasksChecked: PropTypes.bool,
  isRescheduleBillPlanChecked: PropTypes.bool,
  canRescheduleResourceRequests: PropTypes.bool,
  isRescheduleResourceRequestsChecked: PropTypes.bool,
  tasksStatusCountSummary: PropTypes.object,
  tasksToReschedule: PropTypes.oneOf([
    TASKS_TO_RESCHEDULE_TYPE.ALL_TASKS,
    TASKS_TO_RESCHEDULE_TYPE.IN_PROGRESS,
    TASKS_TO_RESCHEDULE_TYPE.NOT_STARTED
  ]),
  resourceRequestsStatusCountSummary: PropTypes.object,
  resourceRequestsToRescheduleByStatus: PropTypes.string,
  scheduleAllTasks: PropTypes.bool.isRequired
};

export default RescheduleProjectOptions;
