import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import {
  actionsWidth,
  itemWidth
} from '~/modules/common/charts/timeline/calculations';
import { StyledChartRangeItem2 } from '~/modules/common/charts/timeline/components';
import { useResourceRequestToolbarContext } from '~/modules/projects/resourcing-plan/hooks';
import { AllocationRowLoading } from '~/modules/resourcing/common/components/ProjectChartRowsLoading/ProjectChartRowsLoading';
import { useBulkGetTaskResourceUserAllocations } from '~/modules/resourcing/common/hooks';
import { useUserAllocationsSummaryContext } from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceAllocationChart/components/ResourceAllocationChartRow/UserAllocationsSummaryContext.js';
import { hasOverlap } from '~/modules/resourcing/common/util';
import {
  RowLeftItem,
  RowRightItem,
  TaskAssignmentTimeline
} from './components';

const useStyles = makeStyles(() => ({
  itemContainer: {
    alignItems: 'normal',
    zIndex: 3,
    justifyContent: 'space-between'
  },
  totalContainer: {
    zIndex: 4
  }
}));

const useTimelineStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexBasis: theme.spacing(17.5),
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: theme.spacing(0.5),
    minWidth: theme.spacing(12.5)
  }
}));

export const isTaskAllocationOverDistributed = ({
  userOverDistributedPeriodsMap,
  taskResourceUserAllocation: { startDate, endDate }
}) =>
  Object.values(userOverDistributedPeriodsMap).some(
    ({ periodStartDate, periodEndDate }) =>
      hasOverlap({ start: periodStartDate, end: periodEndDate })({
        dateRange: {
          startDate,
          endDate
        }
      })
  );

const UserTaskAssignmentRow = ({
  taskResourceEstimate,
  userScheduleDetails,
  user,
  chartDisplayPeriods,
  projectId,
  handleRemoveTaskAssignment,
  resetResourceAllocationChart
}) => {
  const classes = useStyles();
  const timelineClasses = useTimelineStyles();
  const { scale, chartDates } = useResourceRequestToolbarContext();
  const [{ start: chartStartDate }] = chartDates;

  const { fullPath, task, projectRole } = taskResourceEstimate;
  const { id: userUri } = user;
  const { userOverDistributedPeriodsMap } = useUserAllocationsSummaryContext();
  const {
    loading,
    taskResourceUserAllocations
  } = useBulkGetTaskResourceUserAllocations({
    userUri,
    projectUri: projectId,
    taskUris: [task.id]
  });

  const hasTaskAllocation = Boolean(taskResourceUserAllocations?.[0]);

  return (
    <StyledChartRangeItem2
      classes={classes}
      scale={scale}
      scaleItemCount={chartDates.length}
      chartStartDate={chartStartDate}
      leftComponentWidth={actionsWidth + itemWidth * 2}
    >
      <RowLeftItem
        fullPath={fullPath}
        task={task}
        projectRole={projectRole}
        user={user}
        projectId={projectId}
        hasTaskAllocation={hasTaskAllocation}
        handleRemoveTaskAssignment={handleRemoveTaskAssignment}
        isRowOverDistributed={
          hasTaskAllocation
            ? isTaskAllocationOverDistributed({
                userOverDistributedPeriodsMap,
                taskResourceUserAllocation: taskResourceUserAllocations[0]
              })
            : false
        }
        resetResourceAllocationChart={resetResourceAllocationChart}
      />
      {loading ? (
        <AllocationRowLoading />
      ) : (
        <div className={timelineClasses.root}>
          <TaskAssignmentTimeline
            chartDisplayPeriods={chartDisplayPeriods}
            handleRemoveTaskAssignment={handleRemoveTaskAssignment}
            user={user}
            userScheduleDetails={userScheduleDetails}
            taskResourceEstimate={taskResourceEstimate}
            taskResourceUserAllocation={taskResourceUserAllocations[0]}
          />
        </div>
      )}
      <RowRightItem
        totalHours={taskResourceUserAllocations[0]?.totalHours || 0}
        loadingAllocation={loading}
      />
    </StyledChartRangeItem2>
  );
};

UserTaskAssignmentRow.propTypes = {
  taskResourceEstimate: PropTypes.object,
  userScheduleDetails: PropTypes.object,
  user: PropTypes.object,
  chartDisplayPeriods: PropTypes.array.isRequired,
  projectId: PropTypes.string.isRequired,
  handleRemoveTaskAssignment: PropTypes.func.isRequired,
  resetResourceAllocationChart: PropTypes.func.isRequired
};

export default UserTaskAssignmentRow;
