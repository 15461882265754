import { PropTypes } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';
import { Typography, Icon, makeStyles } from '@material-ui/core';
import EditableCard, {
  Edit,
  EditContent,
  ReadOnly,
  ReadOnlyContent
} from '~/modules/common/components/EditableCard';
import { TimeAndExpensesIcon } from '~/modules/common/components/Icons';
import { TimeAndExpenseData } from '~/modules/projects/project/BillPlanV2/TimeAndExpenseData';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { Expense } from '../Expenses';
import TimeAndExpenseCardContent from './TimeAndExpenseCardContent';

const cardIcon = <TimeAndExpensesIcon />;
const cardTitle = <FormattedMessage id="projectTimeAndExpenseCard.title" />;

const useStyles = makeStyles(theme => ({
  titleRoot: {
    display: 'flex'
  },
  expenseLabel: {
    marginLeft: theme.spacing(2)
  },
  icon: {
    marginTop: theme.spacing(0.3)
  },
  root: {
    paddingBottom: theme.spacing(1.6)
  }
}));

export const TimeAndExpense = ({
  projectPermissions = {},
  editable,
  isProjectLoading
}) => {
  const { handleReset, handleSubmit } = useFormikContext();

  return (
    <EditableCard
      editable={editable}
      dataQeId="Project_TimeAndExpense"
      ariaLabelKey="projectTimeAndExpenseCard.timeAndExpenseCardDialog"
    >
      <ReadOnly
        avatar={cardIcon}
        title={cardTitle}
        isLoading={isProjectLoading}
      >
        <ReadOnlyContent>
          <TimeAndExpenseCardContent
            editable={false}
            projectPermissions={projectPermissions}
          />
        </ReadOnlyContent>
      </ReadOnly>
      <Edit onSave={handleSubmit} onCancel={handleReset}>
        <EditContent>
          <TimeAndExpenseCardContent
            editable
            projectPermissions={projectPermissions}
          />
        </EditContent>
      </Edit>
    </EditableCard>
  );
};

TimeAndExpense.propTypes = {
  editable: PropTypes.bool,
  projectPermissions: PropTypes.object,
  isProjectLoading: PropTypes.bool
};

const getTitle = classes => {
  return (
    <div className={classes.titleRoot}>
      <Icon className={classes.icon}>
        <TimeAndExpensesIcon />
      </Icon>
      <Typography className={classes.expenseLabel} variant="h6">
        <FormattedMessage id="projectTimeAndExpenseCard.expenses" />
      </Typography>
    </div>
  );
};

const TimeAndExpenseForm = ({
  projectUri,
  expenseCodes,
  isProjectLoading,
  projectPermissions,
  editable,
  projectCurrency,
  projectDetails: { expenseBudgetedCost, startDate, endDate },
  onSetBillPlanChanged,
  isProjectCurrencyChanged,
  canRecalculateBillingData,
  hasBillingContractAssignment,
  isBillingContractType
}) => {
  const isPsaPrpExpenseApprovalInExpenseBillPlanEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpExpenseApprovalInExpenseBillPlanEnabled'
  });

  const classes = useStyles();
  const [viewSummary, setViewSummary] = useState(false);

  const onViewSummaryClick = useCallback(() => {
    setViewSummary(!viewSummary);
  }, [viewSummary]);

  const { canViewExpenseCodes } = projectPermissions;

  if (isPsaPrpExpenseApprovalInExpenseBillPlanEnabled)
    return (
      <div className={classes.root} data-qe-id="ExpensesCard">
        {canViewExpenseCodes && (
          <Expense
            editable={editable}
            endDate={endDate}
            expenseBudgetedCost={expenseBudgetedCost}
            expenseCodes={expenseCodes}
            hasBillingContractAssignment={hasBillingContractAssignment}
            isBillingContractType={isBillingContractType}
            onSetBillPlanChanged={onSetBillPlanChanged}
            projectCurrency={projectCurrency}
            projectId={projectUri}
            projectPermissions={projectPermissions}
            startDate={startDate}
          />
        )}
      </div>
    );

  return (
    <div className={classes.root}>
      {canViewExpenseCodes && (
        <TimeAndExpenseData
          projectId={projectUri}
          projectCurrency={projectCurrency}
          editable={editable}
          context="timeAndExpense"
          expenseBudgetedCost={expenseBudgetedCost}
          startDate={startDate}
          endDate={endDate}
          expenseCodes={expenseCodes}
          title={getTitle(classes)}
          onViewSummaryClick={onViewSummaryClick}
          hasBillingContractAssignment={hasBillingContractAssignment}
          canRecalculateBillingData={canRecalculateBillingData}
          isProjectCurrencyChanged={isProjectCurrencyChanged}
          onSetBillPlanChanged={onSetBillPlanChanged}
          isBillingContractType={isBillingContractType}
          projectPermissions={projectPermissions}
        />
      )}
      {viewSummary && canViewExpenseCodes && (
        <TimeAndExpenseData
          projectId={projectUri}
          projectCurrency={projectCurrency}
          context="timeAndExpense"
          viewSummary={viewSummary}
          startDate={startDate}
          endDate={endDate}
          expenseCodes={expenseCodes}
          editable={false}
          onCancelCallback={onViewSummaryClick}
          hasBillingContractAssignment={hasBillingContractAssignment}
          canRecalculateBillingData={canRecalculateBillingData}
          isProjectCurrencyChanged={isProjectCurrencyChanged}
          onSetBillPlanChanged={onSetBillPlanChanged}
          isBillingContractType={isBillingContractType}
          projectPermissions={projectPermissions}
        />
      )}
    </div>
  );
};

TimeAndExpenseForm.propTypes = {
  editable: PropTypes.bool,
  projectUri: PropTypes.string.isRequired,
  expenseCodes: PropTypes.array,
  projectPermissions: PropTypes.object,
  isProjectLoading: PropTypes.bool,
  projectCurrency: PropTypes.object,
  projectDetails: PropTypes.object,
  hasBillingContractAssignment: PropTypes.bool,
  canRecalculateBillingData: PropTypes.bool,
  isProjectCurrencyChanged: PropTypes.bool,
  onSetBillPlanChanged: PropTypes.func,
  isBillingContractType: PropTypes.bool
};

export default TimeAndExpenseForm;
