import PropTypes from 'prop-types';
import React, {
  useCallback,
  useMemo,
  useState,
  useImperativeHandle
} from 'react';
import { dateToMidnightUTCString } from '~/modules/common/dates/convert';
import { useDialogState, useMenuState } from '~/modules/common/hooks';
import { useMeContext } from '~/modules/me';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import {
  buildScheduleRulesWithDefaultConstraint,
  getResourceRequestDatesFromProjectDate
} from '~/modules/resourcing/common/util';
import ShowSmartFitDialogOrLoading from '~/modules/resourcing/components/ResourceAssignmentDialog/components/ResourceUsersChartPanel/components/ShowSmartFitDialogOrLoading/ShowSmartFitDialogOrLoading';
import { useProjectResourcePlanPermissions } from '~/modules/resourcing/hooks';
import { ResourceAllocationChangeSnackBar } from '~/modules/resourcing/common/components';
import { useResourceRequestToolbarContext } from '../hooks';
import { ResourceAllocationChartContent2 } from './ResourceAllocationChartContent2';
import { useFetchResourcePlanUsers } from './hooks';
import TotalsFooterContainer from './components/TotalsFooterContainer';
import { ResourceAllocationChartHeader } from './ResourceAllocationChartHeader';
import useProjectResourceAllocationEventHandlers from './components/ResourceAllocationChart/hooks/useProjectResourceAllocationEventHandlers';

const PAGE_SIZE = 20;

export const getVisibleAllocatedUsers = ({
  userData,
  currentPage,
  isResourceActualModeEnabled
}) => {
  const start = (currentPage - 1) * PAGE_SIZE;
  const end = currentPage * PAGE_SIZE;
  const users = Object.values(userData).slice(start, end) || [];

  return isResourceActualModeEnabled
    ? users
    : users.filter(x => !x.isActualEntry);
};

export const ResourceAllocationChart = React.forwardRef(
  ({ isEditSwitchEnabled }, ref) => {
    const { project } = useProjectContext();
    const [currentPage, setCurrentPage] = useState(1);
    const me = useMeContext();
    const {
      featureFlags: { isPsaRmpTaskAllocation1Enabled }
    } = me;
    const [sort, setSort] = useState({ direction: 'ASC' });
    const [
      resourceRequestAcceptedLoading,
      setResourceRequestAcceptedLoading
    ] = useState(false);
    const {
      scale,
      dateRange: chartDisplayDateRange,
      filter,
      isResourceActualModeEnabled,
      chartDates
    } = useResourceRequestToolbarContext();
    const { permissionsMap } = me;

    const [snackBarState, setSnackBarState] = useState({
      open: false,
      message: ''
    });

    const {
      isViewAdvancedSettingsEnabled: showTimeOff,
      isViewAvailabilitySettingsEnabled: showHolidays
    } = useProjectResourcePlanPermissions(permissionsMap);

    const [
      smartFitResourceAllocation,
      setSmartFitResourceAllocation
    ] = useState({});

    const [autofocusedUserId, setAutofocusedUserId] = useState(null);
    const [
      existingResourceAllocation,
      setExistingResourceAllocation
    ] = useState(null);

    const {
      open: openSmartFitDialogOrLoadingState,
      closeDialog: closeShowSmartFitDialogOrLoading,
      openDialog: openShowSmartFitDialogOrLoading
    } = useDialogState(false);

    const {
      requestStartDate: allocationStartDate,
      requestEndDate: allocationEndDate
    } = getResourceRequestDatesFromProjectDate({
      projectStartDate: project.startDate,
      projectEndDate: project.endDate,
      scale
    });

    const defaultSmartFitScheduleRules = useMemo(
      () =>
        buildScheduleRulesWithDefaultConstraint({
          startDate: dateToMidnightUTCString(allocationStartDate),
          endDate: dateToMidnightUTCString(allocationEndDate),
          defaultScheduleRule: project.defaultScheduleRule
        }),
      [allocationEndDate, allocationStartDate, project.defaultScheduleRule]
    );

    const chartDateRange = useMemo(
      () =>
        chartDisplayDateRange
          ? {
              startDate: dateToMidnightUTCString(
                chartDisplayDateRange.startDate
              ),
              endDate: dateToMidnightUTCString(chartDisplayDateRange.endDate)
            }
          : null,
      [chartDisplayDateRange]
    );

    const {
      userData,
      hasNext,
      loading: allocatedUsersLoading,
      reset: resetPagination,
      handleRemoveResourceAllocation,
      handleResourceAllocationUserChange,
      refetch: refetchResourcePlanUsers
    } = useFetchResourcePlanUsers({
      projectId: project.id,
      pageSize: PAGE_SIZE,
      currentPage,
      filter,
      setCurrentPage,
      sort,
      isResourceActualModeEnabled,
      setSnackBarState,
      setAutofocusedUserId
    });

    const visibleAllocatedUsers = useMemo(
      () =>
        getVisibleAllocatedUsers({
          userData,
          currentPage,
          isResourceActualModeEnabled
        }),
      [userData, currentPage, isResourceActualModeEnabled]
    );

    const loadingUsers = Boolean(
      allocatedUsersLoading ||
        (hasNext && PAGE_SIZE > visibleAllocatedUsers.length)
    );
    const {
      anchorEl: allocateResourcePopupAnchorEl,
      onMenuClose: allocateResourcePopupMenuClose,
      onMenuClick: allocateResourcePopupClick
    } = useMenuState();

    const {
      createAllocationWithUserAndOverrideRules,
      handleDirectResourceAllocationSubmit,
      handleEditResourceAllocation,
      createAllocationExistingUserLoading,
      updateAllocationLoadingUserUri,
      handleLoadingStateOnResourceRequestAccept
    } = useProjectResourceAllocationEventHandlers({
      project,
      sort,
      chartDateRange,
      setSnackBarState,
      filter,
      me,
      scale,
      showTimeOff,
      showHolidays,
      allocateResourcePopupMenuClose,
      smartFitResourceAllocation,
      setSmartFitResourceAllocation,
      openShowSmartFitDialogOrLoading,
      closeShowSmartFitDialogOrLoading,
      handleResourceAllocationUserChange,
      setResourceRequestAcceptedLoading,
      existingResourceAllocation,
      setExistingResourceAllocation
    });

    const handleAllocateResourcePopupClose = useCallback(() => {
      allocateResourcePopupMenuClose();
      setExistingResourceAllocation(null);
    }, [allocateResourcePopupMenuClose]);

    useImperativeHandle(ref, () => ({
      handleLoadingStateOnResourceRequestAccept,
      setResourceRequestAcceptedLoading,
      handleResourceAllocationUserChange
    }));

    return (
      <>
        <ResourceAllocationChartHeader
          sort={sort}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loadingUsers={loadingUsers}
          pageSize={PAGE_SIZE}
          setSort={setSort}
          resetPagination={resetPagination}
          currentPageRowCount={visibleAllocatedUsers.length}
          scale={scale}
          chartDates={chartDates}
        />
        <ResourceAllocationChartContent2
          allocatedUsers={visibleAllocatedUsers}
          loadingUsers={loadingUsers}
          isEditSwitchEnabled={isEditSwitchEnabled}
          projectId={project.id}
          currentPage={currentPage}
          handleEditResourceAllocation={handleEditResourceAllocation}
          handleRemoveResourceAllocation={handleRemoveResourceAllocation}
          refetchResourcePlanUsers={refetchResourcePlanUsers}
          setSnackBarState={setSnackBarState}
          setCurrentPage={setCurrentPage}
          updateAllocationLoadingUserUri={updateAllocationLoadingUserUri}
          resourceRequestAcceptedLoading={resourceRequestAcceptedLoading}
          isResourceActualModeEnabled={isResourceActualModeEnabled}
          autofocusedUserId={autofocusedUserId}
          setAutofocusedUserId={setAutofocusedUserId}
        />
        <TotalsFooterContainer
          handleDirectResourceAllocationSubmit={
            handleDirectResourceAllocationSubmit
          }
          isEditSwitchEnabled={isEditSwitchEnabled}
          allocateResourcePopupAnchorEl={allocateResourcePopupAnchorEl}
          allocateResourcePopupMenuClose={
            isPsaRmpTaskAllocation1Enabled
              ? handleAllocateResourcePopupClose
              : allocateResourcePopupMenuClose
          }
          allocateResourcePopupClick={allocateResourcePopupClick}
          createAllocationExistingUserLoading={
            createAllocationExistingUserLoading
          }
          setExistingResourceAllocation={setExistingResourceAllocation}
          existingResourceAllocation={existingResourceAllocation}
        />
        {openSmartFitDialogOrLoadingState && (
          <ShowSmartFitDialogOrLoading
            scale={scale}
            resourceUser={smartFitResourceAllocation.user}
            scheduleRules={
              smartFitResourceAllocation.scheduleRules ||
              defaultSmartFitScheduleRules
            }
            closeShowSmartFitDialogOrLoading={closeShowSmartFitDialogOrLoading}
            chartStartDate={
              chartDisplayDateRange && chartDisplayDateRange.startDate
            }
            createAllocationWithUserAndOverrideRules={
              createAllocationWithUserAndOverrideRules
            }
            projectDefaultScheduleRule={project.defaultScheduleRule}
          />
        )}
        {snackBarState.open && (
          <ResourceAllocationChangeSnackBar
            snackBarState={snackBarState}
            setSnackBarState={setSnackBarState}
          />
        )}
      </>
    );
  }
);

ResourceAllocationChart.propTypes = {
  isEditSwitchEnabled: PropTypes.bool.isRequired
};

export default ResourceAllocationChart;
