import { gql } from 'graphql-tag';
import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  allocationTotalsFragment,
  specificResourceAllocationFragment,
  specificResourceAllocationUserFragment
} from '~/modules/resourcing/common/fragments';
import { getTotalHoursFromScheduleRule } from '~/modules/resourcing/common/util/scheduleUtil';
import {
  ResourceRequestAccessLevel,
  PeriodResolutionOption,
  ResourceAllocationStatus
} from '~/types';
import {
  buildUpdatedScheduleRules,
  getTotalHours
} from '~/modules/resourcing/common/util';
import {
  mapIsoStringtoUtcObject,
  mapRepliconDateToUtcObject
} from '~/modules/common/dates/convert';
import {
  appendScheduleRuleTypeNameFields,
  omitForbiddenUpdateFields
} from '~/modules/resourcing/common/util/resourceAllocationUtil';
import { useMeContext } from '~/modules/me';
import {
  convertDurationToHours,
  mapHoursToDuration
} from '~/modules/common/util';
import {
  RESOURCE_USER_SCHEDULE_DETAILS_VARIABLES,
  RESOURCE_USER_SCHEDULE_DETAILS_QUERY
} from '~/modules/resourcing/common/hooks/useScheduleDetailsForResourceUser';
import { getSessionStorage } from '~/modules/common/hooks';
import { useGetResourceRequestFilter } from '../common/hooks/useGetResourceRequestFilter';
import { resourceAllocationSeriesDataQuery } from '../common/hooks/useResourceAllocationSeriesData';
import { processAllocationAndUpdateCache } from '../enhancers/processAllocationAndUpdateCache';
import { useProjectContext } from '../common/contexts';
import {
  ALLOCATION_STATUS_LIST,
  RESOURCE_REQUEST_STATUS_LIST
} from '../common/fragments/projectTotalsFragment2';
import { invokeUpdateCache } from './useCreateResourceAllocationHandler';

const replaceProjectResourceAllocation = (
  existingResourceAllocations,
  newAllocation
) =>
  existingResourceAllocations.map(sr =>
    sr.id === newAllocation.id ? newAllocation : sr
  );

export const invokeUpdateResourceAllocation = async ({
  updateResourceAllocation,
  originalAllocation,
  allocation: allocationInput,
  updateAllocationCache,
  showTimeOff,
  showHolidays,
  chartDateRange,
  me,
  apolloClient,
  filter,
  invokeUpdateFunction,
  isResourceActualModeEnabled,
  userScheduleDetailsQueryProps
}) => {
  const {
    updatedAllocation,
    totalHours,
    totalUserCostByCurrency,
    totalRoleCostByCurrency
  } = processAllocationAndUpdateCache({
    proxy: apolloClient.cache,
    allocation: allocationInput,
    me,
    filter,
    invokeUpdateFunction,
    isResourceActualModeEnabled
  });

  const allocation = updatedAllocation || allocationInput;

  await updateResourceAllocation({
    variables: {
      input: omitForbiddenUpdateFields(allocation),
      showTimeOff: Boolean(showTimeOff),
      showHolidays: Boolean(showHolidays),
      chartDateRange,
      requestStatusList: RESOURCE_REQUEST_STATUS_LIST,
      allocationStatusList: ALLOCATION_STATUS_LIST,
      filter
    },
    context: {
      debounceKey: allocation.id
    },
    optimisticResponse: {
      __typename: 'Mutation',
      updateResourceAllocation2: {
        __typename: 'UpdateResourceAllocationResult',
        isOptimistic: true,
        resourceAllocation: {
          __typename: 'ResourceAllocation',
          id: allocation.id,
          resourceRequestId: allocation.resourceRequestId,
          projectUri: allocation.projectUri,
          user: {
            ...allocation.user,
            __typename: 'ResourceAllocationUser'
          },
          allocationStatus: allocation.allocationStatus,
          requestStatus: allocation.requestStatus,
          comment: allocation.comment,
          role: allocation.role || null,
          scheduleRules: (allocation.scheduleRules || []).map(
            appendScheduleRuleTypeNameFields
          ),
          endDate: allocation.endDate,
          startDate: allocation.startDate,
          load: allocation.load,
          isAdjustedLoading: allocation.isAdjustedLoading,
          exchangeRateValues: allocation.exchangeRateValues || null,
          requestedRoleUri: allocation.requestedRoleUri || null,
          roleCostExchangeRateValues:
            allocation.roleCostExchangeRateValues || null,
          totalHours: totalHours || allocation.totalHours || null,
          totalUserCostByCurrency: totalUserCostByCurrency ||
            allocation.totalUserCostByCurrency || {
              baseCurrency: null,
              projectBudgetCurrency: null
            },
          totalRoleCostByCurrency: totalRoleCostByCurrency ||
            allocation.totalRoleCostByCurrency || {
              baseCurrency: null,
              projectBudgetCurrency: null
            }
        }
      }
    },
    update:
      (updateAllocationCache && updateAllocationCache) ||
      updateCache({
        updateFunction: replaceProjectResourceAllocation,
        projectId: allocation.projectUri,
        originalAllocation,
        userScheduleDetailsQueryProps
      })
  });
};

export const patchResourceAllocation = ({
  updateResourceAllocation,
  allocation,
  project: { defaultScheduleRule },
  openReleaseResourcesDialog,
  openRecallResourcesDialog,
  deleteResourceAllocationFromProject,
  originalAllocation,
  me,
  project,
  apolloClient,
  filter,
  updateAllocationCache,
  invokeUpdateFunction,
  isResourceActualModeEnabled,
  userScheduleDetailsQueryProps
}) => {
  const overrideSchedule = {
    ...defaultScheduleRule,
    do: {
      ...defaultScheduleRule.do,
      load: allocation.load || 100
    }
  };

  const newScheduleRules = buildUpdatedScheduleRules({
    start: mapIsoStringtoUtcObject(allocation.startDate),
    end: mapIsoStringtoUtcObject(allocation.endDate),
    scheduleRules: allocation.scheduleRules,
    defaultScheduleRule: overrideSchedule
  });

  if (
    allocation.allocationStatus === ResourceAllocationStatus.Committed &&
    !newScheduleRules.some(({ do: { setHours } }) => setHours > 0) &&
    openReleaseResourcesDialog
  ) {
    openReleaseResourcesDialog();

    return;
  }

  if (
    allocation.allocationStatus === ResourceAllocationStatus.Proposed &&
    !newScheduleRules.some(({ do: { setHours } }) => setHours > 0) &&
    openRecallResourcesDialog
  ) {
    openRecallResourcesDialog();

    return;
  }

  if (
    (allocation.allocationStatus === ResourceAllocationStatus.Rejected ||
      allocation.allocationStatus === ResourceAllocationStatus.Draft) &&
    !newScheduleRules.some(({ do: { setHours } }) => setHours > 0) &&
    deleteResourceAllocationFromProject
  ) {
    deleteResourceAllocationFromProject({
      projectUri: allocation.projectUri,
      resourceAllocationId: allocation.id,
      resourceRequestId: allocation.resourceRequestId
    });

    return;
  }

  const totalHours = getTotalHours({
    scheduleRules: newScheduleRules,
    defaultScheduleRule: overrideSchedule
  });

  const [
    {
      dateRange: { startDate: scheduleStartDate }
    }
  ] = newScheduleRules;

  const actualScheduleTotalHours = getTotalHours({
    scheduleRules: [
      {
        dateRange: {
          startDate: scheduleStartDate,
          endDate:
            newScheduleRules[newScheduleRules.length - 1].dateRange.endDate
        },
        do: defaultScheduleRule.do
      }
    ],
    defaultScheduleRule
  });

  invokeUpdateResourceAllocation({
    allocation: {
      ...allocation,
      scheduleRules: newScheduleRules,
      load: (totalHours * 100.0) / actualScheduleTotalHours || 100
    },
    updateResourceAllocation,
    originalAllocation,
    me,
    project,
    apolloClient,
    filter,
    updateAllocationCache,
    invokeUpdateFunction,
    isResourceActualModeEnabled,
    userScheduleDetailsQueryProps
  });
};
export const UPDATE_RESOURCE_ALLOCATION = gql`
  mutation UpdateResourceAllocation($input: UpdateResourceAllocationInput!) {
    updateResourceAllocation2(input: $input) {
      resourceAllocation {
        ...SpecificResourceAllocation
        role {
          uri
          id
          displayText
        }
        user {
          ...SpecificResourceAllocationUser
        }
        ...AllocationTotalsFragment
      }
    }
  }
  ${specificResourceAllocationFragment}
  ${specificResourceAllocationUserFragment}
  ${allocationTotalsFragment}
`;

export const tryLoadCachedAllocationSummaryQuery = ({ proxy, variables }) => {
  try {
    return proxy.readQuery({
      query: resourceAllocationSeriesDataQuery,
      variables
    });
  } catch (e) {
    return null;
  }
};

export const tryLoadCachedResourceUserScheduleDetailsQuery = ({
  proxy,
  variables
}) => {
  try {
    return proxy.readQuery({
      query: RESOURCE_USER_SCHEDULE_DETAILS_QUERY,
      variables
    });
  } catch (e) {
    return null;
  }
};

export const updateResourceAvailabilitySummarySeriesForResourceUsersQuery = ({
  proxy,
  userUri,
  userScheduleDetailsQueryProps: {
    variables,
    periodStartDate,
    allocatedHoursDiff
  }
}) => {
  const results = tryLoadCachedResourceUserScheduleDetailsQuery({
    proxy,
    variables
  });

  if (!results) return;

  proxy.writeQuery({
    query: RESOURCE_USER_SCHEDULE_DETAILS_QUERY,
    variables,
    data: {
      resourceUsers2: results.resourceUsers2.map(user =>
        user.id === userUri
          ? {
              ...user,
              resourceAvailabilitySummarySeries: user.resourceAvailabilitySummarySeries.map(
                summary =>
                  summary.dateRange.startDate === periodStartDate.toISO()
                    ? {
                        ...summary,

                        allocatedDuration: mapHoursToDuration(
                          allocatedHoursDiff +
                            convertDurationToHours(summary.allocatedDuration)
                        )
                      }
                    : summary
              )
            }
          : user
      )
    }
  });
};

export const getUpdatedAllocationSummary = ({
  originalAllocation,
  newAllocation,
  summary
}) => {
  const { endDate, startDate } = originalAllocation.periodDetails;

  let date = startDate;

  const updatedQueryResults = [];

  while (summary && date <= endDate) {
    const summaryDate = date;

    const foundSummary = summary.find(s =>
      mapRepliconDateToUtcObject(s.dateRange.startDate).equals(summaryDate)
    );

    const totalPreviousAllocatedHoursForDay =
      foundSummary.allocated + foundSummary.overAllocated;

    const foundDayForPreviousAllocation = originalAllocation.scheduleRules.find(
      r =>
        summaryDate >= mapIsoStringtoUtcObject(r.dateRange.startDate) &&
        summaryDate <= mapIsoStringtoUtcObject(r.dateRange.endDate)
    );

    const previousAllocatedHoursForDay = foundDayForPreviousAllocation
      ? getTotalHoursFromScheduleRule({
          dateRange: {
            startDate: summaryDate.toISO(),
            endDate: summaryDate.toISO()
          },
          do: foundDayForPreviousAllocation.do
        })
      : 0;

    const foundDayForNewAllocation = newAllocation.scheduleRules.find(
      r =>
        summaryDate >= mapIsoStringtoUtcObject(r.dateRange.startDate) &&
        summaryDate <= mapIsoStringtoUtcObject(r.dateRange.endDate)
    );

    const newAllocatedHoursForDay = foundDayForNewAllocation
      ? getTotalHoursFromScheduleRule({
          dateRange: {
            startDate: summaryDate.toISO(),
            endDate: summaryDate.toISO()
          },
          do: foundDayForNewAllocation.do
        })
      : 0;

    const totalNewAllocatedHours =
      totalPreviousAllocatedHoursForDay -
      previousAllocatedHoursForDay +
      newAllocatedHoursForDay;

    updatedQueryResults.push({
      ...foundSummary,
      overAllocated: 0,
      allocated: totalNewAllocatedHours
    });

    date = date.plus({ days: 1 });
  }

  return updatedQueryResults;
};

export const updateAllocationSeriesData = ({
  proxy,
  newAllocation,
  originalAllocation
}) => {
  const startDate =
    originalAllocation.periodDetails?.startDate || originalAllocation.startDate;
  const endDate =
    originalAllocation.periodDetails?.endDate || originalAllocation.startDate;
  const queryVariables = {
    filter: { userIds: [newAllocation.user.userUri] },
    dateRange: {
      startDate: mapIsoStringtoUtcObject(startDate).toISODate(),
      endDate: mapIsoStringtoUtcObject(endDate).toISODate()
    },
    includeToBeHiredAndRequested: false,
    periodResolution: PeriodResolutionOption.Daily,
    requestAccessLevel: ResourceRequestAccessLevel.ResourceManager
  };
  const results = tryLoadCachedAllocationSummaryQuery({
    proxy,
    variables: queryVariables
  });

  if (!results) return;

  const { resourceAllocationSeriesData: summary } = results;

  proxy.writeQuery({
    query: resourceAllocationSeriesDataQuery,
    variables: queryVariables,
    data: {
      resourceAllocationSeriesData: getUpdatedAllocationSummary({
        summary,
        originalAllocation,
        newAllocation
      })
    }
  });
};

export const updateCache = ({
  updateFunction,
  projectId,
  originalAllocation,
  userScheduleDetailsQueryProps
}) => (proxy, mutationResponse) => {
  const {
    data: {
      updateResourceAllocation2: {
        isOptimistic,
        resourceAllocation: newAllocation
      }
    }
  } = mutationResponse;

  invokeUpdateCache({
    proxy,
    updateFunction,
    projectId,
    newAllocation,
    isOptimistic
  });

  if (originalAllocation) {
    updateAllocationSeriesData({ originalAllocation, newAllocation, proxy });
  }

  const resourceUserScheduleDetailsCacheVariables = getSessionStorage(
    RESOURCE_USER_SCHEDULE_DETAILS_VARIABLES
  );

  if (
    resourceUserScheduleDetailsCacheVariables?.includeResourceAvailabilitySummary
  ) {
    updateResourceAvailabilitySummarySeriesForResourceUsersQuery({
      proxy,
      userUri: newAllocation.user.userUri,
      userScheduleDetailsQueryProps: {
        ...userScheduleDetailsQueryProps,
        variables: resourceUserScheduleDetailsCacheVariables
      }
    });
  }
};

export const useUpdateResourceAllocationInternal = ({
  updateAllocationCache,
  refetchQueries,
  invokeUpdateFunction,
  isResourceActualModeEnabled
}) => {
  const me = useMeContext();

  const [updateResourceAllocation, { data, loading }] = useMutation(
    UPDATE_RESOURCE_ALLOCATION,
    {
      refetchQueries
    }
  );

  const apolloClient = useApolloClient();
  const { project: projectContext } = useProjectContext();
  const filter = useGetResourceRequestFilter();

  const onUpdateResourceAllocation = useCallback(
    ({ originalAllocation, allocation }) =>
      invokeUpdateResourceAllocation({
        updateResourceAllocation,
        originalAllocation,
        allocation,
        updateAllocationCache,
        me,
        project: projectContext,
        apolloClient,
        filter,
        invokeUpdateFunction,
        isResourceActualModeEnabled
      }),
    [
      updateResourceAllocation,
      updateAllocationCache,
      me,
      projectContext,
      apolloClient,
      filter,
      invokeUpdateFunction,
      isResourceActualModeEnabled
    ]
  );

  const onPatchResourceAllocation = useCallback(
    ({
      originalAllocation,
      allocation,
      project,
      openReleaseResourcesDialog,
      openRecallResourcesDialog,
      deleteResourceAllocationFromProject,
      userScheduleDetailsQueryProps
    }) =>
      patchResourceAllocation({
        updateResourceAllocation,
        project,
        openReleaseResourcesDialog,
        openRecallResourcesDialog,
        deleteResourceAllocationFromProject,
        allocation,
        originalAllocation,
        me,
        apolloClient,
        filter,
        invokeUpdateFunction,
        isResourceActualModeEnabled,
        userScheduleDetailsQueryProps
      }),
    [
      updateResourceAllocation,
      me,
      apolloClient,
      filter,
      invokeUpdateFunction,
      isResourceActualModeEnabled
    ]
  );

  return {
    data,
    loading,
    onPatchResourceAllocation,
    onUpdateResourceAllocation
  };
};

export const useUpdateResourceAllocation = updateAllocationCache => {
  return useUpdateResourceAllocationInternal({
    updateAllocationCache,
    refetchQueries: ['getProjectTotalsBySlug']
  });
};

export default useUpdateResourceAllocation;
