const { useCallback } = require('react');
const { useReducer } = require('react');

export const ALLOCATED_USER_EXPAND = 'ALLOCATED_USER_EXPAND';
export const ALLOCATED_USER_COLLAPSE = 'ALLOCATED_USER_COLLAPSE';
export const COLLAPSE_ALL = 'COLLAPSE_ALL';

export const handleAllocatedUsersExpandedState = (state, action) => {
  switch (action.type) {
    case ALLOCATED_USER_EXPAND:
      return { ...state, [action.value]: action.value };

    case ALLOCATED_USER_COLLAPSE:
      return { ...state, [action.value]: undefined };

    case COLLAPSE_ALL:
      return {};

    default:
      throw new Error();
  }
};

export const useUserRowExpansionPanelStateReducer = () => {
  const [userExpandState, dispatch] = useReducer(
    handleAllocatedUsersExpandedState,
    {}
  );

  const onUserRowExpansionPanelClick = useCallback(
    allocatedUserId =>
      dispatch({
        type: userExpandState[allocatedUserId]
          ? ALLOCATED_USER_COLLAPSE
          : ALLOCATED_USER_EXPAND,
        value: allocatedUserId
      }),
    [userExpandState, dispatch]
  );

  const resetExpansionPanelStateForAllUserRows = useCallback(() => {
    dispatch({ type: COLLAPSE_ALL });
  }, [dispatch]);

  return {
    userExpandState,
    onUserRowExpansionPanelClick,
    resetExpansionPanelStateForAllUserRows
  };
};

export default useUserRowExpansionPanelStateReducer;
