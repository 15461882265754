import { CHANGE_TASK_RESOURCE_USER_ALLOCATION_MODE } from '~/modules/tasks/components/ChangeTaskResourceDialog/enum';

export const useOverAllocationOptions = ({
  formatMessage,
  isResourceManager,
  existingUserTaskAllocationHours,
  availableHours
}) => {
  const allocateFullLabel = formatMessage({
    id: 'changeTaskResourceDialog.overAllocationOptions.allocateFullAmount'
  });

  const allocateFitsLabel = formatMessage({
    id: 'changeTaskResourceDialog.overAllocationOptions.allocateWhatFits'
  });

  const removeTaskAllocationLabel = formatMessage({
    id: 'changeTaskResourceDialog.removeTaskAllocation'
  });

  const allocateWhatFitsSecondaryText = isResourceManager
    ? 'changeTaskResourceDialog.overAllocationOptions.resourceManagerAllocateWhatFits'
    : 'changeTaskResourceDialog.overAllocationOptions.projectManagerAllocateWhatFits';

  const allocateFullSecondaryText = isResourceManager
    ? 'changeTaskResourceDialog.overAllocationOptions.resourceManagerOverAllocateFullAmount'
    : 'changeTaskResourceDialog.overAllocationOptions.projectManagerAllocateFullAmount';

  return [
    {
      optionId: CHANGE_TASK_RESOURCE_USER_ALLOCATION_MODE.ALLOCATE_FULL_AMOUNT,
      primaryText: allocateFullLabel,
      secondaryText: formatMessage(
        {
          id: allocateFullSecondaryText
        },
        { hours: existingUserTaskAllocationHours.toFixed(2) }
      )
    },
    {
      optionId: CHANGE_TASK_RESOURCE_USER_ALLOCATION_MODE.ALLOCATE_WHAT_FITS,
      primaryText: allocateFitsLabel,
      secondaryText: formatMessage(
        {
          id: allocateWhatFitsSecondaryText
        },
        { hours: availableHours.toFixed(2) }
      )
    },
    {
      optionId: CHANGE_TASK_RESOURCE_USER_ALLOCATION_MODE.REMOVE_ALLOCATION,
      primaryText: removeTaskAllocationLabel,
      secondaryText: formatMessage({
        id: 'changeTaskResourceDialog.dontAllocateMessage'
      })
    }
  ].filter(
    option =>
      !(
        option.optionId ===
          CHANGE_TASK_RESOURCE_USER_ALLOCATION_MODE.ALLOCATE_WHAT_FITS &&
        availableHours <= 0
      )
  );
};
