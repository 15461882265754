import PropTypes from 'prop-types';
import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { ClickAwayListener } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import ArrowPopup from '~/modules/common/components/ArrowPopup';
import { useMenuState } from '~/modules/common/hooks';
import LegendItem from './LegendItemV2';

const useStyles = makeStyles(theme => ({
  popperClasses: {},
  infoIcon: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(0.5, 0.5, 0, 0)
  },
  legendList: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 1, 1, 0)
  }
}));

const LegendsPopup = ({ legends }) => {
  const { anchorEl, onMenuClose, onMenuClick } = useMenuState();
  const classes = useStyles({});

  return (
    <>
      <ClickAwayListener onClickAway={onMenuClose}>
        <span className={classes.infoIcon}>
          <InfoIcon
            fontSize="small"
            onClick={onMenuClick}
            onKeyDown={onMenuClick}
          />
        </span>
      </ClickAwayListener>
      {anchorEl && (
        <ArrowPopup
          id="legends-popup"
          anchorEl={anchorEl}
          placement="bottom"
          preventOverflow
          classes={classes.popperClasses}
          onClose={onMenuClose}
        >
          <div className={classes.legendList}>
            {legends.map((legend, index) => (
              <LegendItem
                key={legend.key}
                label={legend.label}
                color={legend.color}
                legendClassName={legend.className}
                barType={legend.barType}
                isProgressBarLegend={legend.isProgressBarLegend}
              />
            ))}
          </div>
        </ArrowPopup>
      )}
    </>
  );
};

LegendsPopup.propTypes = {
  legends: PropTypes.arrayOf(
    PropTypes.shape({
      isProgressBarLegend: PropTypes.bool,
      barType: PropTypes.oneOf(['dashedBar', 'squareBar']),
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
      className: PropTypes.string,
      color: PropTypes.string
    }).isRequired
  ).isRequired
};

export default LegendsPopup;
