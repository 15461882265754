import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '@mui/material';
import AvailabilityPeriod from '~/modules/resourcing/common/components/AvailabilityPeriod';
import CompleteAvailabilityPeriod from '~/modules/resourcing/common/components/AvailabilityPeriod/CompleteAvailabilityPeriod';
import { ResourceIcon, TaskIcon } from '~/modules/common/components';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import useStyles from './useStyles';

const ResourceAllocationEditorContent = ({
  classes: classesOverrides,
  targetWidth,
  targetHeight,
  isAllocationComplete,
  availableValue,
  initialAllocatedHours,
  allocatedHours,
  resourceAllocationSeriesData,
  isAvailabilityLoading,
  isHoursMode,
  seriesRefetch,
  seriesLoading
}) => {
  const classes = useStyles({
    targetWidth,
    targetHeight,
    classes: classesOverrides
  });

  const isRmpTaskAllocationPhase2Enabled = useHasFeatureFlag({
    featureFlag: 'isRmpTaskAllocationPhase2Enabled'
  });

  const tooltipContent = useMemo(
    () => (
      <>
        <div className={classes.tooltipHeader}>
          <FormattedMessage id="resourceAssignmentDialog.resourceAvailability" />
        </div>
        <span className={classes.tooltipDescription}>
          <FormattedMessage id="resourceAssignmentDialog.resourceAvailabilityDetails" />
        </span>
        <div className={classes.tooltipHeader}>
          <FormattedMessage id="resourceAssignmentDialog.taskAllocation" />
        </div>
        <span className={classes.tooltipDescription}>
          <FormattedMessage id="resourceAssignmentDialog.taskAllocationDetails" />
        </span>
      </>
    ),
    [classes]
  );
  const availabilityComponent = useMemo(
    () => (
      <div className={classes.availability}>
        {isRmpTaskAllocationPhase2Enabled && (
          <ResourceIcon fontSize="small" className={classes.icon} />
        )}
        <FormattedMessage id="resourceAssignmentDialog.availability" />:
        {isAllocationComplete ? (
          <CompleteAvailabilityPeriod
            availableValue={availableValue}
            originalAllocatedHours={initialAllocatedHours}
            allocatedHours={allocatedHours}
            classes={classes}
            resourceAllocationSeriesData={resourceAllocationSeriesData}
            isAvailabilityLoading={isAvailabilityLoading}
            isHoursMode={isHoursMode}
            seriesRefetch={seriesRefetch}
            seriesLoading={seriesLoading}
          />
        ) : (
          <AvailabilityPeriod
            classes={classes}
            isHoursMode={isHoursMode}
            availableHours={availableValue}
          />
        )}
      </div>
    ),
    [
      allocatedHours,
      availableValue,
      classes,
      initialAllocatedHours,
      isAllocationComplete,
      isAvailabilityLoading,
      isHoursMode,
      isRmpTaskAllocationPhase2Enabled,
      resourceAllocationSeriesData,
      seriesLoading,
      seriesRefetch
    ]
  );

  return isRmpTaskAllocationPhase2Enabled ? (
    <Tooltip title={tooltipContent} placement="right">
      <div className={classes.editorDetails}>
        {availabilityComponent}
        <div className={classes.tasks}>
          <TaskIcon fontSize="small" className={classes.icon} />
          <FormattedMessage id="resourceAssignmentDialog.tasks" />:
        </div>
      </div>
    </Tooltip>
  ) : (
    <div className={classes.details}>{availabilityComponent}</div>
  );
};

ResourceAllocationEditorContent.propTypes = {
  classes: PropTypes.object,
  targetWidth: PropTypes.number,
  targetHeight: PropTypes.number,
  isAllocationComplete: PropTypes.bool,
  availableValue: PropTypes.number,
  initialAllocatedHours: PropTypes.number,
  allocatedHours: PropTypes.number,
  resourceAllocationSeriesData: PropTypes.array,
  isAvailabilityLoading: PropTypes.bool,
  isHoursMode: PropTypes.bool,
  seriesRefetch: PropTypes.func,
  seriesLoading: PropTypes.bool
};

export default ResourceAllocationEditorContent;
