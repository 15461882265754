import { Dialog, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Formik } from 'formik';
import { Skeleton } from '@mui/material';
import { ChangeTaskResourceDialogTitle } from './ChangeTaskResourceDialogTitle';
import { ChangeTaskResourceDialogContent } from './ChangeTaskResourceDialogContent';
import { ChangeTaskResourceDialogFooter } from './ChangeTaskResourceDialogFooter';
import { useFormState } from './hooks';

const dialogTitleId = 'change-task-resource-dialog-title';
const dialogContentId = 'change-task-resource-dialog-content';

const useStyles = makeStyles(theme => ({
  skeleton: {
    padding: theme.spacing(0, 1.5)
  }
}));

export const ChangeTaskResourceDialogContentSkeleton = ({ classes }) => (
  <div className={classes.skeleton}>
    <Skeleton width="25%" height={35} />
    <Skeleton width="100%" height={65} />
    <Skeleton width="100%" height={90} />
  </div>
);

export const ChangeTaskResourceDialog = ({
  isLoading,
  onClose,
  onChangeTaskResourceSuccess,
  open,
  resourceEstimate,
  task
}) => {
  const skeletonClasses = useStyles();
  const { initialValues } = useFormState({
    hasTaskResourceUserAllocation: Boolean(
      resourceEstimate?.taskResourceUserAllocation
    )
  });
  const [isNewUserInfoLoading, setIsNewUserInfoLoading] = useState(false);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby={dialogTitleId}
      aria-describedby={dialogContentId}
    >
      <ChangeTaskResourceDialogTitle
        taskName={task.displayText}
        isParentTask={task.hasChildren}
        dialogTitleId={dialogTitleId}
      />
      <Formik initialValues={initialValues}>
        <>
          {isLoading ? (
            <ChangeTaskResourceDialogContentSkeleton
              classes={skeletonClasses}
            />
          ) : (
            <ChangeTaskResourceDialogContent
              isNewUserInfoLoading={isNewUserInfoLoading}
              resourceEstimate={resourceEstimate}
              setIsNewUserInfoLoading={setIsNewUserInfoLoading}
              task={task}
              dialogContentId={dialogContentId}
            />
          )}
          <ChangeTaskResourceDialogFooter
            isNewUserInfoLoading={isNewUserInfoLoading}
            onClose={onClose}
            onChangeTaskResourceSuccess={onChangeTaskResourceSuccess}
            resourceEstimate={resourceEstimate}
            task={task}
          />
        </>
      </Formik>
    </Dialog>
  );
};

ChangeTaskResourceDialog.propTypes = {
  isLoading: PropTypes.bool,
  task: PropTypes.object,
  onClose: PropTypes.func,
  onChangeTaskResourceSuccess: PropTypes.func,
  open: PropTypes.bool.isRequired,
  resourceEstimate: PropTypes.object
};

ChangeTaskResourceDialogContentSkeleton.propTypes = {
  classes: PropTypes.object
};

export default ChangeTaskResourceDialog;
