import React, { useMemo, useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { DescriptionSharp as DescriptionIcon } from '@material-ui/icons';
import { DialogActions, Typography, makeStyles } from '@material-ui/core';
import { Formik } from 'formik';
import { v4 } from 'uuid';
import { useIsBreakpointDown, useScrollToBottom } from '~/modules/common/hooks';
import { validationSchema } from './validationSchema';
import useUpdateProjectContacts from './hooks/useUpdateProjectContacts';
import ContactInfoActionComponent from './ContactInfoActionComponent';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: `${theme.spacing(1.25)}px !important`,
      paddingRight: `${theme.spacing(1.25)}px !important`
    }
  },
  dialogContent: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: `${theme.spacing(1.25)}px !important`,
      paddingRight: `${theme.spacing(1.25)}px !important`
    }
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  typography: {
    '&:focus': {
      outline: 'none',
      backgroundColor: theme.palette.grey[200]
    }
  },
  dialogAction: {
    display: 'block',
    margin: theme.spacing(0.5, 3),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1.25),
      marginRight: theme.spacing(1.25)
    }
  }
}));

const useDialogStyles = makeStyles(theme => ({
  paper: {
    minWidth: 'max-content',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'fit-content'
    }
  }
}));

const EditableContactInfoDialog = ({
  projectId,
  open,
  onClose,
  contacts,
  isReadOnly,
  children
}) => {
  const isMobile = useIsBreakpointDown('sm');

  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { onSubmit } = useUpdateProjectContacts({
    projectId,
    setIsSubmitting,
    onClose
  });
  const initialValues = useMemo(
    () => ({
      contacts: (contacts || []).map(contact => ({
        id: v4(),
        name: contact?.name || '',
        email: contact?.email || '',
        phoneNumber: contact?.phoneNumber || '',
        website: contact?.website || '',
        faxNumber: contact?.faxNumber || '',
        contactType: contact?.contactType
      }))
    }),
    [contacts]
  );

  const {
    containerRef,
    shouldScrollToBottom,
    setShouldScrollToBottom,
    scrollToBottom
  } = useScrollToBottom();

  useEffect(() => shouldScrollToBottom && scrollToBottom(), [
    shouldScrollToBottom,
    scrollToBottom
  ]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <Dialog
        classes={useDialogStyles()}
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        fullScreen={isMobile}
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.dialogTitle}>
          <DescriptionIcon className={classes.icon} />
          <Typography
            variant="h6"
            component="h2"
            tabIndex={0}
            className={classes.typography}
          >
            <FormattedMessage id="projectBasicInfoCard.contactInfo.contacts" />
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div ref={containerRef}>{children}</div>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <ContactInfoActionComponent
            isReadOnly={isReadOnly}
            isSubmitting={isSubmitting}
            onClose={onClose}
            setShouldScrollToBottom={setShouldScrollToBottom}
          />
        </DialogActions>
      </Dialog>
    </Formik>
  );
};

EditableContactInfoDialog.propTypes = {
  projectId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  contacts: PropTypes.array,
  isReadOnly: PropTypes.bool,
  children: PropTypes.node
};

export default EditableContactInfoDialog;
