import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { compareISOStrings } from '~/modules/common/dates/compare';
import { TaskStatus } from '~/types';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import {
  getEquivalentRolledUpTaskStatus,
  getRolledUpEstimatedAtCompletionHours
} from '~/modules/common/components/ProjectDrawer/util';

const assignToButton = ({ className, label }) =>
  `<div class="${className}"><span class="gantt_assignto" role="button">${label}</span></div>`;

const assignRoleButton = ({ className, label }) =>
  `<div class="${className}"><span class="gantt_assignRole" role="button">${label}</span></div>`;

export const assignedUserText = ({
  isRolledUpTaskEstimateCalculationMethodEnabled,
  assignedUser,
  classes,
  intl,
  editable,
  hasEditTaskPermissions
}) => {
  const { displayText } = assignedUser || {};

  if (editable && hasEditTaskPermissions) {
    return assignToButton({
      className: displayText
        ? classes.gantColumnName
        : isRolledUpTaskEstimateCalculationMethodEnabled
        ? classes.ganttAssignToLabel2
        : classes.ganttAssignToLabel,
      label:
        displayText || intl.formatMessage({ id: 'projectTasksPage.assignTo' })
    });
  }

  return (
    displayText || intl.formatMessage({ id: 'projectTasksPage.notAssigned' })
  );
};

export const assignedRoleDisplayText = ({
  isRolledUpTaskEstimateCalculationMethodEnabled,
  assignedRole,
  classes,
  intl,
  editable,
  hasEditTaskPermissions
}) => {
  const { displayText } = assignedRole || {};

  if (editable && hasEditTaskPermissions) {
    return assignRoleButton({
      className: displayText
        ? classes.gantColumnName
        : isRolledUpTaskEstimateCalculationMethodEnabled
        ? classes.ganttAssignToLabel2
        : classes.ganttAssignToLabel,
      label:
        displayText || intl.formatMessage({ id: 'projectTasksPage.assignRole' })
    });
  }

  return (
    displayText || intl.formatMessage({ id: 'projectTasksPage.notAssigned' })
  );
};

export const mapTaskEndDateDisplayValue = endDate => {
  if (!endDate) {
    return undefined;
  }

  return DateTime.fromISO(endDate, { setZone: true })
    .plus({ days: 1 })
    .toISO();
};

export const getProgressAsFractionValue = (
  actualHours,
  estimatedAtCompletionHours
) => {
  if (!estimatedAtCompletionHours) {
    return 0;
  }

  return actualHours / estimatedAtCompletionHours;
};

const getTaskBarColors = taskStatus => ({
  color:
    taskStatus === TaskStatus.Inprogress
      ? theme.palette.taskStatus[taskStatus]?.lightColor
      : theme.palette.taskStatus[taskStatus]?.color,
  progressColor: theme.palette.taskStatus[taskStatus]?.color
});

export const getMinDateFromTaskHierarchyOrDefault = data =>
  data.reduce(
    (minDate, { startDate, endDate }) => {
      const itemMin = startDate || endDate;

      if (itemMin && compareISOStrings(itemMin, minDate) === -1) return itemMin;

      return minDate;
    },
    DateTime.local()
      .toUTC()
      .toISO()
  );

const getEffectiveTaskStatus = ({
  isRolledUpTaskEstimateCalculationMethodEnabled,
  taskStatus,
  actualHours
}) => {
  if (taskStatus === TaskStatus.Completed) {
    return taskStatus;
  }

  if (isRolledUpTaskEstimateCalculationMethodEnabled) {
    return actualHours ? TaskStatus.Inprogress : TaskStatus.Notstarted;
  }

  return taskStatus ?? TaskStatus.Notstarted;
};

const mapToGanttTask = ({
  classes,
  intl,
  editable,
  hasEditTaskPermissions,
  isRolledUpTaskEstimateCalculationMethodEnabled,
  includeRolledUpCostSummary,
  parentId,
  projectStartDate,
  projectEndDate,
  minDateFromTaskHierarchyOrDefault
}) => task => {
  const {
    id,
    name,
    code,
    parent,
    isMilestone,
    taskStatus,
    startDate,
    endDate,
    assignedUser,
    assignedRole,
    assignedUserRoleId,
    totalActualHours,
    initialEstimatedHours,
    estimatedCompletionHours,
    estimatedCompletionDate,
    rolledUpSummary = {},
    rolledUpCostSummary = {},
    resourceEstimatesSummary
  } = task;

  const { actualHours, totalEstimatedAtCompletionHours } = rolledUpSummary;
  const effectiveTaskStatus = getEffectiveTaskStatus({
    isRolledUpTaskEstimateCalculationMethodEnabled,
    taskStatus,
    actualHours
  });
  const adjustedEndDate = mapTaskEndDateDisplayValue(endDate);

  return {
    id,
    text: name,
    code,
    parent: parent ? parent.id : parentId,
    taskStatus: effectiveTaskStatus,
    projectStartDate,
    projectEndDate,
    startDate,
    endDate,
    start_date: isRolledUpTaskEstimateCalculationMethodEnabled
      ? startDate || adjustedEndDate || minDateFromTaskHierarchyOrDefault
      : startDate,
    end_date: isRolledUpTaskEstimateCalculationMethodEnabled
      ? adjustedEndDate || startDate || minDateFromTaskHierarchyOrDefault
      : adjustedEndDate,
    unscheduled: isRolledUpTaskEstimateCalculationMethodEnabled
      ? false
      : !startDate || !endDate,
    assignee: assignedUser,
    assigneeText: assignedUserText({
      isRolledUpTaskEstimateCalculationMethodEnabled,
      assignedUser,
      classes,
      intl,
      editable,
      hasEditTaskPermissions
    }),
    assignedRole,
    assignedRoleText: assignedRoleDisplayText({
      isRolledUpTaskEstimateCalculationMethodEnabled,
      assignedRole,
      classes,
      intl,
      editable,
      hasEditTaskPermissions
    }),
    assignedUserRoleId,
    estimatedCompletionHours,
    estimatedCompletionDate,
    isMilestone,
    actualHours: totalActualHours,
    estimatedHours: isRolledUpTaskEstimateCalculationMethodEnabled
      ? initialEstimatedHours
      : initialEstimatedHours || 0,
    progress: isRolledUpTaskEstimateCalculationMethodEnabled
      ? getProgressAsFractionValue(actualHours, totalEstimatedAtCompletionHours)
      : getProgressAsFractionValue(totalActualHours, estimatedCompletionHours),
    ...getTaskBarColors(effectiveTaskStatus),
    ...(isRolledUpTaskEstimateCalculationMethodEnabled && {
      rolledUpSummary
    }),
    rolledUpCostSummary:
      includeRolledUpCostSummary && rolledUpCostSummary
        ? {
            ...rolledUpCostSummary,
            initialEstimatedCostOrBudgetedCostInProjectCurrency:
              rolledUpCostSummary.initialEstimatedCostInProjectCurrency
          }
        : undefined,
    resourceEstimatesSummary
  };
};

const getProjectGanttTask = ({
  isRolledUpTaskEstimateCalculationMethodEnabled,
  includeRolledUpCostSummary,
  rolledUpSummary: rolledUpSummaryInput,
  rolledUpCostSummary,
  projectDetails,
  minDateFromTaskHierarchyOrDefault
}) => {
  if (!isRolledUpTaskEstimateCalculationMethodEnabled) return [];

  const {
    id,
    projectName,
    projectCode,
    startDate,
    endDate,
    status,
    budgetHours
  } = projectDetails;
  const rolledUpSummary = {
    ...rolledUpSummaryInput,
    totalEstimatedAtCompletionHours: getRolledUpEstimatedAtCompletionHours({
      status: status.name,
      actualHours: rolledUpSummaryInput.actualHours,
      totalEstimatedAtCompletionHours:
        rolledUpSummaryInput.totalEstimatedAtCompletionHours
    })
  };
  const { actualHours, totalEstimatedAtCompletionHours } = rolledUpSummary;
  const projectStatus = getEquivalentRolledUpTaskStatus({
    status: status.name,
    actualHours
  });
  const formattedEndDate = mapTaskEndDateDisplayValue(endDate);

  return [
    {
      id,
      text: projectName,
      code: projectCode,
      editable: false,
      unscheduled: false,
      taskStatus: projectStatus,
      estimatedHours: budgetHours,
      startDate,
      endDate,
      start_date:
        startDate || formattedEndDate || minDateFromTaskHierarchyOrDefault,
      end_date:
        formattedEndDate || startDate || minDateFromTaskHierarchyOrDefault,
      progress: getProgressAsFractionValue(
        actualHours,
        totalEstimatedAtCompletionHours
      ),
      ...getTaskBarColors(projectStatus),
      rolledUpSummary,
      rolledUpCostSummary:
        includeRolledUpCostSummary && rolledUpCostSummary
          ? {
              ...rolledUpCostSummary,
              initialEstimatedCostOrBudgetedCostInProjectCurrency:
                rolledUpCostSummary.budgetedCostInProjectCurrency
            }
          : undefined
    }
  ];
};

export const useGanttData = ({
  me,
  intl,
  classes,
  tasks,
  projectDetails,
  rolledUpSummary,
  rolledUpCostSummary,
  editable,
  hasEditTaskPermissions
}) => {
  const {
    isRolledUpTaskEstimateCalculationMethodEnabled,
    featureFlags: { isPsaPpmCostEacEnhancements2Enabled }
  } = me;

  const includeRolledUpCostSummary =
    isPsaPpmCostEacEnhancements2Enabled &&
    isRolledUpTaskEstimateCalculationMethodEnabled;

  return useMemo(() => {
    const { id, startDate, endDate } = projectDetails;
    const minDateFromTaskHierarchyOrDefault = isRolledUpTaskEstimateCalculationMethodEnabled
      ? getMinDateFromTaskHierarchyOrDefault([{ startDate, endDate }, ...tasks])
      : undefined;
    const parentId = isRolledUpTaskEstimateCalculationMethodEnabled
      ? id
      : undefined;

    return {
      data: getProjectGanttTask({
        isRolledUpTaskEstimateCalculationMethodEnabled,
        includeRolledUpCostSummary,
        rolledUpSummary,
        rolledUpCostSummary,
        projectDetails,
        minDateFromTaskHierarchyOrDefault
      }).concat(
        tasks.map(
          mapToGanttTask({
            classes,
            intl,
            editable,
            hasEditTaskPermissions,
            isRolledUpTaskEstimateCalculationMethodEnabled,
            includeRolledUpCostSummary,
            parentId,
            projectStartDate: startDate,
            projectEndDate: endDate,
            minDateFromTaskHierarchyOrDefault
          })
        )
      )
    };
  }, [
    classes,
    editable,
    hasEditTaskPermissions,
    includeRolledUpCostSummary,
    intl,
    isRolledUpTaskEstimateCalculationMethodEnabled,
    projectDetails,
    rolledUpCostSummary,
    rolledUpSummary,
    tasks
  ]);
};

export default useGanttData;
