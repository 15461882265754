import { gql } from 'graphql-tag';
import { CustomFieldValueFragmentDoc } from '~/types';
import { extensionFieldValueFragment } from '~/modules/extensionFields/fragments';

export const fullProjectCommonFragment = gql`
  fragment FullProjectCommon on Project {
    id
    name
    displayText
    status {
      id
      displayText
      name
      uri
    }
    slug
    code
    defaultScheduleRule {
      dateRange {
        startDate
        endDate
      }
      do
    }
    description
    division {
      id
      displayText
    }
    serviceCenter {
      id
      displayText
    }
    location {
      id
      displayText
    }
    department {
      id
      displayText
    }
    employeeType {
      id
      displayText
    }
    clients {
      client {
        displayText
        id
      }
    }
    clientRepresentative {
      displayText
      id
    }
    program {
      id
      slug
      displayText
    }
    portfolio {
      id
      displayText
    }
    projectManagerReference {
      displayText
      id
    }
    coManagers {
      displayText
      id
    }
    isProjectLeaderApprovalRequired
    isTaskDateRollupEnabled
    budgetHours
    totalEstimatedContract {
      amount
      currency {
        id
        displayText
      }
    }
    budgetedCost {
      amount
      currency {
        id
        displayText
      }
    }
    resourceBudgetedCost {
      amount
      currency {
        id
        displayText
      }
    }
    expenseBudgetedCost {
      amount
      currency {
        id
        displayText
      }
    }
    estimatedHours
    estimatedCost {
      amount
      currency {
        id
        displayText
      }
    }
    billingDetails {
      id
      billingTypeUri
      details
    }
    billingType {
      id
      displayText
    }
    timeAndExpenseEntryType {
      id
      displayText
    }
    isTimeEntryAllowed
    expenseCodes {
      id
      displayText
      isExpenseEntryToThisCodeAllowed
      expenseEntryType
    }
    projectManagementType
    extensionFieldValues {
      ...ExtensionFieldValue
    }
    customFieldValues {
      ...CustomFieldValue
    }
    costCenter {
      id
      displayText
    }
    department {
      id
      displayText
    }
    employeeType {
      id
      displayText
    }
    slackChannel
    rescheduleProjectJob {
      jobId
      projectId
    }
    permittedActionUris
    defaultBillingCurrency {
      id
      displayText
    }
    costType {
      id
      displayText
    }
    projectBillSettings {
      billLineItemsBy
      description
      internalNotes
      showComments
      poNumber
      defaultInvoicePaymentTerm
      invoiceCurrency {
        id
        symbol
      }
      taxProfile {
        id
        displayText
      }
      invoiceTemplate {
        id
        displayText
      }
    }
  }
  ${extensionFieldValueFragment}
  ${CustomFieldValueFragmentDoc}
`;

export const fullProjectFragmentLegacy = gql`
  fragment FullProjectLegacy on Project {
    ...FullProjectCommon
    startDate
    endDate
  }
  ${fullProjectCommonFragment}
`;

export const fullProjectDate2Fragment = gql`
  fragment FullProjectDate2 on Project {
    ...FullProjectCommon
    startDate: startDate2
    endDate: endDate2
  }
  ${fullProjectCommonFragment}
`;
