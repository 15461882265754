import omit from 'lodash/fp/omit';
import flow from 'lodash/fp/flow';
import update from 'lodash/fp/update';
import map from 'lodash/fp/map';
import { DateTime } from 'luxon';
import {
  mapIsoStringtoUtcObject,
  dateToMidnightUTCString
} from '~/modules/common/dates/convert';

export const omitForbiddenCreateFields = flow(
  omit('__typename'),
  omit('startDate'),
  omit('endDate'),
  omit('totalHours'),
  omit('allocationStatus'),
  omit('roleCostExchangeRateValues'),
  omit('exchangeRateValues'),
  omit('totalUserCostByCurrency'),
  omit('totalRoleCostByCurrency'),
  update(
    'user',
    flow(
      omit('__typename'),
      omit('user'),
      omit('userAllocationId'),
      omit('costCurrency'),
      omit('slug'),
      omit('symbol'),
      omit('permittedActionUris'),
      omit('roles'),
      omit('primaryRoleCostRate'),
      omit('primaryRoleCostCurrency'),
      omit('primaryRoleCostCurrencyUri'),
      omit('scheduleDurationByDay'),
      omit('scheduleDurationByPeriodMap'),
      omit('resourceAvailabilitySummaryByPeriodMap')
    )
  ),
  update(
    'scheduleRules',
    map(
      flow(
        omit('__typename'),
        update('dateRange', omit('__typename')),
        update('do', flow(omit('__typename')))
      )
    )
  )
);

export const omitForbiddenUpdateFields = flow(
  omitForbiddenCreateFields,
  omit('projectUri'),
  omit('resourceRequestId'),
  omit('allocationStatus'),
  omit('requestStatus'),
  omit('status'),
  omit('roleCostExchangeRateValues'),
  omit('exchangeRateValues'),
  omit('project'),
  omit('role'),
  update(
    'user',
    flow(
      omit('__typename'),
      omit('user'),
      omit('userAllocationId'),
      omit('costCurrency'),
      omit('uri'),
      omit('id'),
      omit('amount'),
      omit('displayText'),
      omit('loginName'),
      omit('slug'),
      omit('symbol'),
      omit('permittedActionUris'),
      omit('roles'),
      omit('primaryRoleCostRate'),
      omit('primaryRoleCostCurrency'),
      omit('scheduleDurationByDay'),
      omit('scheduleDurationByPeriodMap'),
      omit('resourceAvailabilitySummaryByPeriodMap'),
      omit('scheduleDurationSeries'),
      omit('primaryRoleCostCurrencyUri'),
      omit('timeoffs'),
      omit('holidays')
    )
  ),
  omit('matchScore'),
  omit('matchScoreDimensions'),
  omit('exchangeRateValues'),
  omit('roleCostExchangeRateValues'),
  omit('totalUserCostByCurrency'),
  omit('totalRoleCostByCurrency')
);

export const appendScheduleRuleTypeNameFields = rule => ({
  ...rule,
  __typename: 'ScheduleRule',
  dateRange: {
    ...rule.dateRange,
    __typename: 'DateRange'
  }
});

export const getRangeBoundaries = ({
  resourceUser: { startDate, endDate },
  dateRange
}) => {
  const userStart = startDate && mapIsoStringtoUtcObject(startDate);
  const rangeMinDate = userStart
    ? DateTime.max(userStart, dateRange.startDate)
    : dateRange.startDate;

  const userEnd = endDate && mapIsoStringtoUtcObject(endDate);
  const rangeMaxDate = userEnd
    ? DateTime.min(userEnd, dateRange.endDate)
    : dateRange.endDate;

  return {
    rangeMinDate,
    rangeMaxDate
  };
};

export const getUserIdsFromRequests = resourceRequests =>
  Array.from(
    getUserIdsFromAllocations(
      [].concat(...resourceRequests.map(r => r.resourceAllocations))
    )
  );

export const getUserIdsFromAllocations = resourceAllocations =>
  Array.from(
    new Set(
      (resourceAllocations || [])
        .map(r => r.user && r.user.userUri)
        .filter(uri => uri)
    )
  );

export const mergeUserScheduleDetailsToRequests = (
  scheduleDetailsByUserMap,
  requests
) =>
  requests.map(request => ({
    ...request,
    resourceAllocations: (request.resourceAllocations || []).map(alloc => {
      const { scheduleDurationByPeriodMap, holidays, timeoffs } =
        scheduleDetailsByUserMap[alloc.user.userUri] || {};

      return {
        ...alloc,
        user: {
          ...alloc.user,
          scheduleDurationByPeriodMap,
          holidays,
          timeoffs
        }
      };
    })
  }));

export const getAllocationDatesFromProjectDate = ({
  projectEndDate,
  projectStartDate,
  scale
}) => {
  const projEndDate = projectEndDate && mapIsoStringtoUtcObject(projectEndDate);

  const today = DateTime.utc();
  const allocationStartDate = DateTime.max(
    ...(projectStartDate ? [mapIsoStringtoUtcObject(projectStartDate)] : []),
    today
  );
  const allocationEndDateScale = scale === 'days' ? 'weeks' : scale || 'months';
  const allocationEndDate =
    projEndDate && allocationStartDate < projEndDate
      ? projEndDate
      : allocationStartDate
          .plus({ [allocationEndDateScale]: 1 })
          .minus({ days: scale ? 1 : 0 });

  return {
    allocationStartDate: dateToMidnightUTCString(allocationStartDate),
    allocationEndDate: dateToMidnightUTCString(allocationEndDate)
  };
};
