import React from 'react';
import { PropTypes } from 'prop-types';
import { ResourceRequestsToRescheduleByStatus } from '~/types';
import { useMeContext } from '~/modules/me/useMeContext';
import { useForm } from './useForm';
import {
  useResourceRequestsStatusCountSummary,
  useTasksStatusCountSummary,
  useBillingItemsCount
} from './hooks';
import ReschedulingProjectDialogContentData from './ReschedulingProjectDialogContentData';
import RescheduleProjectDialogContentData from './RescheduleProjectDialogContentData';
import RescheduleProjectSkeleton from './RescheduleProjectSkeleton';

export const RescheduleProjectDialogContent = ({
  project,
  onClose,
  newStartDate
}) => {
  const {
    hasEditProjectBillingContracts,
    featureFlags: { isPsaPswatTaskDateRollupEnabled }
  } = useMeContext();
  const {
    id: projectId,
    billingDetails,
    isTaskDateRollupEnabled = false
  } = project;
  const scheduleAllTasks =
    isPsaPswatTaskDateRollupEnabled && isTaskDateRollupEnabled;
  const { permittedActionUris } = project;

  const canEditTasks = permittedActionUris.includes(
    'urn:replicon:project-action:edit-tasks'
  );

  const canEditBillingContracts = permittedActionUris.includes(
    'urn:replicon:project-action:edit-billing-contracts'
  );

  const canViewBillingData = permittedActionUris.includes(
    'urn:replicon:project-action:view-billing-data'
  );

  const {
    loading: isRequestsStatusCountSummaryLoading,
    resourceRequestsStatusCountSummary
  } = useResourceRequestsStatusCountSummary({ projectId });
  const {
    data: { tasksStatusCountSummary } = {},
    loading: tasksStatusCountSummaryLoading
  } = useTasksStatusCountSummary(projectId);

  const canRescheduleResourceRequests =
    resourceRequestsStatusCountSummary.total > 0;

  const {
    data: { billingItemsCount = 0 } = {},
    loading: isBillingItemsCountLoading
  } = useBillingItemsCount({
    projectId,
    skip: !hasEditProjectBillingContracts
  });

  const canRescheduleTasks = canEditTasks
    ? Boolean(
        tasksStatusCountSummary &&
          (tasksStatusCountSummary.notStartedTasksCount > 0 ||
            tasksStatusCountSummary.inProgressTasksCount > 0 ||
            tasksStatusCountSummary.completedTasksCount > 0)
      )
    : false;

  const canRescheduleBillPlan =
    canEditBillingContracts &&
    canViewBillingData &&
    billingDetails?.billingTypeUri !==
      'urn:replicon:billing-type:time-and-material'
      ? Boolean(billingItemsCount === 0)
      : false;

  const { completed, proposed } = resourceRequestsStatusCountSummary;

  const { form, jobState } = useForm({
    project,
    newStartDate,
    canRescheduleTasks,
    canRescheduleBillPlan,
    resourceRequestsToReschedule:
      completed > 0 || proposed > 0
        ? ResourceRequestsToRescheduleByStatus.WithoutProposedOrAcceptedResourcesRequests
        : null,
    canRescheduleResourceRequests,
    scheduleAllTasks
  });

  const isLoading =
    tasksStatusCountSummaryLoading ||
    isRequestsStatusCountSummaryLoading ||
    isBillingItemsCountLoading;

  const { jobId, jobInProgress } = jobState;

  return (
    <>
      {jobInProgress ? (
        <ReschedulingProjectDialogContentData jobId={jobId} onClose={onClose} />
      ) : isLoading ? (
        <RescheduleProjectSkeleton />
      ) : (
        <RescheduleProjectDialogContentData
          form={form}
          project={project}
          onClose={onClose}
          canRescheduleBillPlan={canRescheduleBillPlan}
          canRescheduleTasks={canRescheduleTasks}
          canRescheduleResourceRequests={canRescheduleResourceRequests}
          tasksStatusCountSummary={tasksStatusCountSummary}
          resourceRequestsStatusCountSummary={
            resourceRequestsStatusCountSummary
          }
          scheduleAllTasks={scheduleAllTasks}
        />
      )}
    </>
  );
};

RescheduleProjectDialogContent.propTypes = {
  project: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  newStartDate: PropTypes.object
};

export default RescheduleProjectDialogContent;
