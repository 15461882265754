import React, { useCallback, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { IconButton } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import { RemoveCircleSharp as RemoveCircle } from '@material-ui/icons';
import classnames from 'classnames';
import { SearchableProjectDropdown } from '~/modules/common/components/ProjectDropdown/SearchableProjectDropdown';
import { useDialogState, useIsBreakpointDown } from '~/modules/common/hooks';
import { DeleteConfirmationDialog } from './DeleteConfirmationDialog';
import { useStyles } from './useStyles';
import ProjectLinkTypeDropdown from './ProjectLinkTypeDropdown';

const projectToLink = <FormattedMessage id="projectLink.projectToLink" />;
const to = <FormattedMessage id="projectLink.to" />;

export const ProjectLinkRow = ({
  link,
  index,
  linkedProjectIds,
  onLinkTypeChange,
  onProjectChange,
  onProjectLinkRemove,
  baseProjectId
}) => {
  const { formatMessage } = useIntl();
  const isMobile = useIsBreakpointDown('sm');
  const classes = useStyles();
  const dropDownClasses = useMemo(
    () => ({ inputRoot: classes.rightDropDown, root: classes.search }),
    [classes.rightDropDown, classes.search]
  );

  const { open, openDialog, closeDialog } = useDialogState(false);

  const handleOnLinkTypeChange = useCallback(
    type => onLinkTypeChange(type.id, index),
    [index, onLinkTypeChange]
  );

  const handleOnProjectChange = useCallback(e => onProjectChange(e, index), [
    index,
    onProjectChange
  ]);

  const handleOnRemoveClick = useCallback(() => onProjectLinkRemove(link), [
    link,
    onProjectLinkRemove
  ]);

  const readOnly = link.linkType && link?.targetProject?.id && !link.isDirty;

  const excludeProjectIds = useMemo(
    () => [...linkedProjectIds, baseProjectId].filter(Boolean),
    [linkedProjectIds, baseProjectId]
  );

  return (
    <>
      <div className={classes.row}>
        <div className={classes.link}>
          <ProjectLinkTypeDropdown
            disabled={readOnly}
            value={link.linkType}
            onChange={handleOnLinkTypeChange}
          />
          {!isMobile && (
            <Typography
              variant="subtitle2"
              className={classnames(classes.to, {
                [classes.toDisabled]: readOnly
              })}
            >
              {to}
            </Typography>
          )}
          <SearchableProjectDropdown
            classes={dropDownClasses}
            disabled={readOnly}
            label={projectToLink}
            value={link.targetProject || null}
            onChange={handleOnProjectChange}
            excludeProjectIds={excludeProjectIds}
          />
        </div>
        <IconButton
          onClick={link.isDirty ? handleOnRemoveClick : openDialog}
          aria-label={formatMessage({ id: 'button.remove' })}
        >
          <RemoveCircle color="action" fontSize="small" />
        </IconButton>
      </div>
      {open && (
        <DeleteConfirmationDialog
          open={open}
          link={link}
          onCancel={closeDialog}
          onRemove={handleOnRemoveClick}
        />
      )}
    </>
  );
};

ProjectLinkRow.propTypes = {
  linkedProjectIds: PropTypes.array,
  link: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onLinkTypeChange: PropTypes.func.isRequired,
  onProjectChange: PropTypes.func.isRequired,
  onProjectLinkRemove: PropTypes.func.isRequired,
  baseProjectId: PropTypes.string
};

export default ProjectLinkRow;
