import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles, Divider } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { get, compact } from 'lodash';
import { useIntl } from 'react-intl';
import { Money2 } from '~/modules/common/components';
import { ExpenseEntryType } from '~/types';

const useStyles = makeStyles(theme => ({
  actuals: {
    fontSize: theme.typography.body2.fontSize,
    height: 'auto',
    width: theme.spacing(16.5),
    paddingLeft: theme.spacing(0.5),
    '& input': {
      fontSize: 'small'
    },
    borderLeft: `1px solid ${theme.palette.divider}`
  },
  estimates: {
    fontSize: theme.typography.body2.fontSize,
    height: 'auto',
    paddingRight: theme.spacing(0.5),
    '& input': {
      fontSize: 'medium'
    }
  },
  estimatesWithActuals: {
    fontSize: theme.typography.body2.fontSize,
    height: 'auto',
    width: theme.spacing(16.5),
    paddingRight: theme.spacing(0.5),
    '& input': {
      fontSize: 'small'
    }
  },
  currency: {
    fontSize: theme.typography.body2.fontSize,
    '& div': {
      paddingRight: 'unset !important'
    }
  },
  divider: {
    height: 'auto',
    color: theme.palette.grey[100]
  },
  row: {
    display: 'flex'
  }
}));

const totalEstimatesField = 'totalEstimates';

const keys = {
  actualBillableAmount: 'actualBillableAmount',
  estimatedBillableAmount: 'estimatedBillableAmount',
  actualNonBillableAmount: 'actualNonBillableAmount',
  estimatedNonBillableAmount: 'estimatedNonBillableAmount'
};

const editableDataPoints = [
  keys.estimatedBillableAmount,
  keys.estimatedNonBillableAmount
];

const getAmountPath = (field, path) => `${field}.${path}`;

const updateAmountValue = (setFieldValue, index) => (field, path, val) =>
  setFieldValue(`allowedExpensesAndEstimates[${index}].${field}.${path}`, val);

export const ExpenseAmount = ({
  column: { disabled, projectPeriodKeys },
  editable,
  field,
  index,
  record
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const {
    values: { showActuals, currency },
    setFieldValue
  } = useFormikContext();

  const getAmount = useCallback(
    (path, defaultValue = 0) =>
      get(record, getAmountPath(field, path), defaultValue),
    [field, record]
  );

  const updateAmount = useMemo(() => updateAmountValue(setFieldValue, index), [
    index,
    setFieldValue
  ]);

  const onAmountChange = useCallback(
    path => e => {
      const val = e.target.value || 0;
      const prevAmount = getAmount(path);

      if (!isNaN(val) && val !== prevAmount) {
        updateAmount(field, path, val);

        if (field === totalEstimatesField) {
          const periodLength = projectPeriodKeys.length;

          projectPeriodKeys.forEach(f => {
            updateAmount(f, path, val / periodLength);
          });
        } else {
          const currentTotal = get(
            record,
            getAmountPath(totalEstimatesField, path),
            0
          );

          updateAmount(
            totalEstimatesField,
            path,
            currentTotal - prevAmount + val
          );
        }
      }
    },
    [field, getAmount, projectPeriodKeys, record, updateAmount]
  );

  const currencyClasses = useMemo(() => ({ currency: classes.currency }), [
    classes.currency
  ]);

  const getExpenseDataPoints = useCallback(
    (estimatedKey, actualKey) => {
      return compact([
        {
          key: estimatedKey,
          className: showActuals
            ? classes.estimatesWithActuals
            : classes.estimates
        },
        showActuals && {
          key: actualKey,
          className: classes.actuals,
          divider: (
            <Divider className={classes.divider} orientation="vertical" />
          )
        }
      ]);
    },
    [
      classes.actuals,
      classes.divider,
      classes.estimates,
      classes.estimatesWithActuals,
      showActuals
    ]
  );

  const expenseCategories = useMemo(
    () =>
      compact([
        record.billableType !== ExpenseEntryType.NonBillable && {
          key: 'billable',
          dataPoints: getExpenseDataPoints(
            keys.estimatedBillableAmount,
            keys.actualBillableAmount
          )
        },
        record.billableType !== ExpenseEntryType.Billable && {
          key: 'nonBillable',
          dataPoints: getExpenseDataPoints(
            keys.estimatedNonBillableAmount,
            keys.actualNonBillableAmount
          )
        }
      ]),
    [getExpenseDataPoints, record.billableType]
  );

  return (
    <Grid container spacing={0}>
      {expenseCategories.map(({ key, dataPoints }) => (
        <Grid key={key} item xs={12}>
          <div className={classes.row}>
            {dataPoints.map(({ key: dataPointKey, className }) => (
              <React.Fragment key={dataPointKey}>
                <Money2
                  classes={currencyClasses}
                  className={className}
                  variant="standard"
                  amount={getAmount(dataPointKey)}
                  currency={currency}
                  onAmountChange={onAmountChange(dataPointKey)}
                  currencyEditable={false}
                  editable={
                    editable &&
                    editableDataPoints.includes(dataPointKey) &&
                    !disabled
                  }
                  ariaLabel={intl.formatMessage({
                    id: `expenseBillPlan.${dataPointKey}`
                  })}
                />
              </React.Fragment>
            ))}
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

ExpenseAmount.propTypes = {
  column: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  field: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  record: PropTypes.object.isRequired
};

export const ExpenseAmountEditor = props => (
  <ExpenseAmount {...props} editable />
);

export const ExpenseAmountFormatter = props => (
  <ExpenseAmount {...props} editable={false} />
);
