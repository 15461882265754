import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { DateField } from '~/modules/common/components';
import { hasError, getError } from '~/util';

export const TaskDateFields = ({
  errors,
  startDate,
  endDate,
  required = false,
  onStartDateChange,
  onEndDateChange,
  fullScreen,
  variant = 'standard'
}) => {
  const { formatMessage } = useIntl();

  const labels = useMemo(
    () => ({
      startDate: formatMessage({ id: 'projectAddForm.startDate' }),
      endDate: formatMessage({ id: 'projectAddForm.endDate' })
    }),
    [formatMessage]
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      wrap={fullScreen ? 'wrap' : 'nowrap'}
    >
      <Grid item xs={12} sm={12}>
        <DateField
          variant={variant}
          error={hasError(errors, 'startDate')}
          helperText={getError(errors, 'startDate')}
          required={required}
          editable
          fullWidth
          ariaLabel={labels.startDate}
          label={labels.startDate}
          value={startDate}
          onChange={onStartDateChange}
          dataQeId="TaskDateField_StartDate"
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <DateField
          variant={variant}
          error={hasError(errors, 'endDate')}
          helperText={getError(errors, 'endDate')}
          required={required}
          editable
          ariaLabel={labels.endDate}
          label={labels.endDate}
          value={endDate}
          onChange={onEndDateChange}
          dataQeId="TaskDateField_EndDate"
        />
      </Grid>
    </Grid>
  );
};

TaskDateFields.propTypes = {
  errors: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  required: PropTypes.bool,
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
  fullScreen: PropTypes.bool,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled'])
};

export default TaskDateFields;
