import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TextField, makeStyles } from '@material-ui/core';

// Styles overrides are required
// Dependant on https://github.com/mui-org/material-ui/issues/21409

const useStyles = makeStyles({
  hiddenLabel: {
    '&>div': {
      paddingTop: '0px!important'
    }
  },
  hiddenLabelInput: {
    '&>input.MuiFilledInput-inputHiddenLabel.MuiFilledInput-inputMarginDense': {
      padding: '10px 12px 11px !important'
    }
  },
  multilineInput: {
    '&.MuiInputBase-multiline.MuiInputBase-marginDense': {
      paddingRight: '0px',
      paddingBottom: '0px',
      '& div': {
        alignSelf: 'flex-end'
      }
    }
  }
});

export const AutocompleteInput = ({
  label,
  hiddenLabel,
  variant,
  showError,
  errorMessage,
  placeholder,
  helperText,
  alwaysShowHelperText,
  multiline = false,
  dataQeId,
  ariaLabel,
  ariaControls,
  ...textFieldInputProps
}) => {
  const classes = useStyles();

  const endAdornment = multiline
    ? {
        ...textFieldInputProps.InputProps.endAdornment,
        props: {
          children: [
            textFieldInputProps.inputProps.value === ''
              ? textFieldInputProps.InputProps.endAdornment.props.children[1]
              : textFieldInputProps.InputProps.endAdornment.props.children[0]
          ]
        }
      }
    : textFieldInputProps.InputProps.endAdornment;
  const { InputProps, className, inputProps, ...rest } = textFieldInputProps;

  InputProps.endAdornment = endAdornment;

  const autocompleteInputProps = {
    ...InputProps,
    className: classNames(InputProps.className, {
      [classes.hiddenLabelInput]: hiddenLabel,
      [classes.multilineInput]: multiline
    }),
    inputProps: {
      ...inputProps,
      'aria-label': label || ariaLabel,
      'aria-controls': ariaControls,
      ...(dataQeId && { 'data-qe-id': dataQeId })
    }
  };

  return (
    <TextField
      multiline={multiline}
      error={showError}
      helperText={
        (showError && errorMessage) || (alwaysShowHelperText && helperText)
      }
      className={classNames(className, {
        [classes.hiddenLabelInput]: hiddenLabel,
        [classes.multilineInput]: multiline
      })}
      placeholder={placeholder}
      variant={variant}
      InputProps={autocompleteInputProps}
      hiddenLabel={hiddenLabel}
      label={hiddenLabel ? undefined : label}
      {...rest}
    />
  );
};

export default AutocompleteInput;

AutocompleteInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hiddenLabel: PropTypes.bool,
  variant: PropTypes.string,
  showError: PropTypes.bool,
  errorMessage: PropTypes.any,
  params: PropTypes.object,
  placeholder: PropTypes.string,
  helperText: PropTypes.any,
  alwaysShowHelperText: PropTypes.bool,
  multiline: PropTypes.bool,
  dataQeId: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaControls: PropTypes.string
};
