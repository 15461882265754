import { useIntl } from 'react-intl';
import { ProjectLinkType } from '~/types';

const projectLinkTypes = intl =>
  Object.values(ProjectLinkType).map(v => ({
    id: v,
    value: v,
    label: intl.formatMessage({ id: `projectLink.${v}` }),
    key: intl.formatMessage({ id: `projectLink.${v}` }),
    name: intl.formatMessage({ id: `projectLink.${v}` })
  }));

export const useProjectLinkTypes = intl => ({
  projectLinkTypes: projectLinkTypes(intl)
});

export const searchProjectLinkTypes = intl => (searchTerm = '') =>
  projectLinkTypes(intl).filter(t =>
    t.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

export const useSearchableProjectLinkTypes = () => ({
  fetchOptions: searchProjectLinkTypes(useIntl())
});

export default useSearchableProjectLinkTypes;
