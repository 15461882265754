import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

export const PROJECT_RESOURCE_DROPDOWN_OPTIONS_QUERY = gql`
  query pageOfProjectResourceDropdownOptions(
    $input: PageOfProjectResourceDropdownOptionsInput
    $isAvailabilityEnabled: Boolean!
  ) {
    pageOfProjectResourceDropdownOptions(input: $input) {
      dropdownOptions {
        id
        displayText
        roles {
          isPrimary
          projectRole {
            uri
            name
          }
        }
        availableDuration: netAvailableDuration
          @include(if: $isAvailabilityEnabled)
        isAllocatedResource
        isAssignedToTask
      }
      paginationToken
    }
  }
`;

const DEFAULT_PAGE_SIZE = 200;

export const usePageOfProjectResourceDropdownOptions = ({
  isAvailabilityEnabled,
  projectId,
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  searchTerm,
  availabilityPercentageInRange,
  skip = false,
  assignedTaskId
}) => {
  const { loading, data } = useQuery(PROJECT_RESOURCE_DROPDOWN_OPTIONS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        projectId,
        page,
        pageSize,
        assignedTaskId,
        filter: {
          searchTerm,
          availabilityPercentageInRange
        }
      },
      isAvailabilityEnabled
    },
    context: {
      debounceKey: 'project-resource-dropdown-options',
      debounceTimeout: 250
    },
    skip
  });

  return {
    loading,
    users: data?.pageOfProjectResourceDropdownOptions.dropdownOptions || [],
    hasMore: Boolean(data?.pageOfProjectResourceDropdownOptions.paginationToken)
  };
};

export default usePageOfProjectResourceDropdownOptions;
