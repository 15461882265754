import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useResourceRequestToolbarContext } from '~/modules/projects/resourcing-plan/hooks';
import { TimelineEditorOverlay } from '~/modules/resourcing/common/components';
import {
  useSessionStorage,
  getSessionStorage,
  useDialogState
} from '~/modules/common/hooks';
import ReleaseAllocationsDialog from '~/modules/resourcing/common/components/ReleaseResourceRequestDialog/ReleaseAllocationsDialog';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import useBulkReleaseResourceAllocations from '~/modules/resourcing/common/hooks/useBulkReleaseResourceAllocations';
import useResourceTimelineEditorChangeHandlers from '~/modules/resourcing/common/hooks/useResourceTimelineEditorChangeHandlers';
import { ResourceAllocationTimelineBlocks } from '~/modules/resourcing/common/components/ResourceRequestUserAllocationBlock';
import {
  useAvailabilityContext,
  withAvailabilityContextProvider
} from '~/modules/resourcing/common/hooks/useAvailabilityContext';
import {
  useProjectResourcePlanPermissions,
  useUpdateResourceAllocation
} from '~/modules/resourcing/hooks';
import { deepPure } from '~/util';
import { useMeContext } from '~/modules/me';
import { DISPLAY_UNIT_ENUM } from '~/modules/resourcing/common/enums';
import { GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES_VARIABLES } from '../../../../hooks/useFetchResourcePlanUsersAndRoles';
import { getFilterInput } from '../../hooks/useCompletedProjectResourceAllocations';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: -theme.spacing(0.5)
  }
}));

export const ResourceAllocationChartTimeLineItem = ({
  allocatedUser,
  chartDisplayPeriods,
  handleRemoveResourceAllocation,
  resourceAllocation,
  isEditEnabled,
  setSnackBarState
}) => {
  const {
    scale,
    chartDates,
    dateRange: chartDisplayDateRange,
    filter,
    sort,
    displayUnit
  } = useResourceRequestToolbarContext();

  const isPercentageMode = displayUnit === DISPLAY_UNIT_ENUM.PERCENTAGE;
  const classes = useStyles();
  const {
    open: showReleaseResourcesDialog,
    openDialog: openReleaseResourcesDialog,
    closeDialog: closeReleaseResourcesDialog
  } = useDialogState(false);

  const resourcePlanAllocatedUsersAndRolesCacheVariables = getSessionStorage(
    GET_RESOURCE_PLAN_ALLOCATED_USERS_AND_ROLES_VARIABLES
  );

  const { storedValue: resourceRequestsQueryVariables } = useSessionStorage(
    'RESOURCE-REQUESTS-QUERY-VARIABLES',
    null
  );

  const { project } = useProjectContext();
  const { setAvailabilityLoading } = useAvailabilityContext();
  const [allocationPeriodEditTarget, setAllocationPeriodEditTarget] = useState(
    null
  );

  const { onPatchResourceAllocation } = useUpdateResourceAllocation();
  const onPeriodClose = useCallback(
    (evt, allocatedHoursDiff) => {
      setAvailabilityLoading();
      onPatchResourceAllocation({
        project,
        allocation: {
          ...evt.values,
          requestedRoleUri: resourceAllocation.role?.uri
        },
        userScheduleDetailsQueryProps: {
          allocatedHoursDiff,
          periodStartDate: allocationPeriodEditTarget.startDate
        },
        openReleaseResourcesDialog
      });
    },
    [
      setAvailabilityLoading,
      onPatchResourceAllocation,
      project,
      resourceAllocation.role?.uri,
      allocationPeriodEditTarget,
      openReleaseResourcesDialog
    ]
  );

  const newPeriodEditorChangeHandlers = useResourceTimelineEditorChangeHandlers(
    {
      allocationPeriodEditTarget,
      chartDates,
      isPercentageMode,
      setAllocationPeriodEditTarget,
      resourceAllocation
    }
  );

  const { permissionsMap } = useMeContext();

  const {
    handleAllocationPeriodClick,
    handleAllocationPeriodClose,
    setPreviousPeriod,
    setNextPeriod
  } = newPeriodEditorChangeHandlers;

  const {
    isViewAvailabilitySettingsEnabled,
    isViewAdvancedSettingsEnabled
  } = useProjectResourcePlanPermissions(permissionsMap);

  const showTimeOff = Boolean(
    isViewAvailabilitySettingsEnabled && Boolean(chartDisplayDateRange)
  );

  const showHolidays =
    isViewAdvancedSettingsEnabled && Boolean(chartDisplayDateRange);

  const {
    releaseResourceAllocation,
    loading: releaseAllocationLoading
  } = useBulkReleaseResourceAllocations({
    handleRemoveResourceAllocation,
    resourcePlanAllocatedUsersAndRolesCacheVariables,
    userId: allocatedUser.user.id,
    userName: allocatedUser.user.displayText,
    allocatedUserCacheUpdateProps: {
      filter: getFilterInput(filter),
      projectId: project.id,
      chartDateRange: chartDisplayDateRange,
      showTimeOff,
      showHolidays,
      sort
    },
    resourceRequestCacheUpdateProps: {
      resourceRequestsQueryVariables,
      projectId: project.id,
      showHolidays,
      showTimeOff
    },
    setSnackBarState,
    sendNotification: true
  });

  const onAllocationChange = useCallback(
    ({ values: allocation }) => {
      setAvailabilityLoading();
      onPatchResourceAllocation({
        project,
        allocation,
        originalAllocation: resourceAllocation,
        openReleaseResourcesDialog
      });
    },
    [
      setAvailabilityLoading,
      onPatchResourceAllocation,
      project,
      resourceAllocation,
      openReleaseResourcesDialog
    ]
  );

  return (
    <>
      <div className={classes.root}>
        <ResourceAllocationTimelineBlocks
          allocation={resourceAllocation}
          chartDisplayPeriods={chartDisplayPeriods}
          scale={scale}
          isEditable={isEditEnabled}
          chartDisplayDateRange={chartDisplayDateRange}
          onAllocationChange={onAllocationChange}
          handleAllocationPeriodClick={handleAllocationPeriodClick}
        />
        {isEditEnabled && allocationPeriodEditTarget?.anchorEl && (
          <TimelineEditorOverlay
            chartDisplayDateRange={chartDisplayDateRange}
            scale={scale}
            allocationPeriodEditTarget={allocationPeriodEditTarget}
            onChange={onPeriodClose}
            handleAllocationPeriodClose={handleAllocationPeriodClose}
            setNextPeriod={setNextPeriod}
            setPreviousPeriod={setPreviousPeriod}
            resourceAllocation={resourceAllocation}
            isPersistedAllocation
          />
        )}
      </div>
      {showReleaseResourcesDialog && (
        <ReleaseAllocationsDialog
          allocatedUser={allocatedUser}
          canEditTask={project.permittedActionUris.includes(
            'urn:replicon:project-action:edit-tasks'
          )}
          onCancelClick={closeReleaseResourcesDialog}
          open={showReleaseResourcesDialog}
          onReleaseResourceAllocation={releaseResourceAllocation}
          projectUri={project.id}
          releaseAllocationLoading={releaseAllocationLoading}
          selectedAllocationId={resourceAllocation.id}
        />
      )}
    </>
  );
};

ResourceAllocationChartTimeLineItem.propTypes = {
  allocatedUser: PropTypes.object,
  handleRemoveResourceAllocation: PropTypes.func,
  resourceAllocation: PropTypes.object.isRequired,
  isEditEnabled: PropTypes.bool,
  setSnackBarState: PropTypes.func,
  chartDisplayPeriods: PropTypes.array
};

export default deepPure(
  withAvailabilityContextProvider(ResourceAllocationChartTimeLineItem)
);
