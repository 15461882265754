import PropTypes from 'prop-types';
import React from 'react';

const ConditionalWrapper = ({
  condition,
  WrapperComponent,
  children,
  ...rest
}) =>
  condition ? (
    <WrapperComponent {...rest}>{children}</WrapperComponent>
  ) : (
    children
  );

ConditionalWrapper.propTypes = {
  condition: PropTypes.bool,
  WrapperComponent: PropTypes.func,
  children: PropTypes.node
};

export default ConditionalWrapper;
