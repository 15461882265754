import { useCallback } from 'react';
import get from 'lodash.get';
import { TIME_AND_EXPENSE_ENTRY_TYPE } from '~/modules/common/enums/TimeAndExpenseEntryType';
import {
  hasBillingSettingsEnabled,
  hasPortfolioSettingsEnabled
} from '~/modules/common/hooks/project/useProjectTemplateSettings';

const transformDropdownResult = result => ({
  displayText: result && result.label,
  id: result && result.value
});

const transformCurrencyResult = result => ({
  id: result.id,
  uri: result.uri,
  displayText: result.displayText,
  name: result.name,
  symbol: result.symbol
});

const transformTimeAndExpenseEntryTypeResult = result => ({
  id: result.id
});

export const handleClientChange = ({ setFieldValue }) => value => {
  setFieldValue('client', transformDropdownResult(value));
};

export const handleCurrencyChange = ({ setFieldValue }) => currency => {
  setFieldValue('budgetedCost.currency', transformCurrencyResult(currency));

  setFieldValue(
    'totalEstimatedContract.currency',
    transformCurrencyResult(currency)
  );

  setFieldValue('estimatedCost.currency', transformCurrencyResult(currency));
  setFieldValue('defaultBillingCurrency', transformCurrencyResult(currency));
};

export const handleProgramChange = ({ setFieldValue }) => value => {
  setFieldValue('program', transformDropdownResult(value));
};

export const handleEarnedRevenueScriptChange = ({ setFieldValue }) => value => {
  setFieldValue('earnedRevenueScript', value);
};

export const handleProjectManagerChange = ({ setFieldValue }) => (_, value) => {
  setFieldValue('projectManager', value);
};

export const handlePortfolioChange = ({ setFieldValue }) => value => {
  setFieldValue('portfolio', value);
};
export const handleProjectTemplateChange = ({ setFieldValue }) => value => {
  setFieldValue('projectTemplate', value);
};

export const handleAllowEntryWithoutTasks = ({ setFieldValue }) => value => {
  if (value.id === TIME_AND_EXPENSE_ENTRY_TYPE.NO) {
    setFieldValue('isTimeEntryAllowed', false);
  } else {
    setFieldValue('isTimeEntryAllowed', true);
  }
  setFieldValue(
    'timeAndExpenseEntryType',
    transformTimeAndExpenseEntryTypeResult(value)
  );
};

export const handleClientAndCurrencyChange = async (
  client,
  me,
  getClientCurrency,
  setFieldValue
) => {
  const {
    featureFlags: { isPsaPrpAccessibilityIssuesEnabled }
  } = me;
  const canViewClient = Boolean(
    me.permissionsMap['urn:replicon:client-action:view-client']
  );

  if (isPsaPrpAccessibilityIssuesEnabled)
    handleClientChange({ setFieldValue })(client);

  let invoiceCurrency = null;

  if (client?.value && canViewClient) {
    let result = null;

    try {
      result = await getClientCurrency({
        uri: client.value
      });
    } finally {
      invoiceCurrency = get(result, 'data.client.invoiceCurrency', null);
    }
  }

  if (!isPsaPrpAccessibilityIssuesEnabled)
    handleClientChange({ setFieldValue })(client);
  handleCurrencyChange({ setFieldValue })(invoiceCurrency || me.baseCurrency);
};

export const useFormOnChange = ({ setFieldValue, values, refetch, me }) => ({
  onClientChange: useCallback(
    value => {
      handleClientChange({ setFieldValue })(value);
    },
    [setFieldValue]
  ),
  onEarnedRevenueScriptChange: useCallback(
    value => {
      handleEarnedRevenueScriptChange({ setFieldValue })(value);
    },
    [setFieldValue]
  ),
  onCurrencyChange: useCallback(
    value => {
      handleCurrencyChange({ setFieldValue })(value);
    },
    [setFieldValue]
  ),
  onProgramChange: useCallback(
    value => {
      handleProgramChange({ setFieldValue })(value);
    },
    [setFieldValue]
  ),
  onProjectManagerChange: useCallback(
    (_, value) => {
      handleProjectManagerChange({ setFieldValue })(_, value);
    },
    [setFieldValue]
  ),
  onPortfolioChange: useCallback(
    value => {
      handlePortfolioChange({ setFieldValue })(value);
    },
    [setFieldValue]
  ),
  onProjectTemplateChange: useCallback(
    async value => {
      const hasBilling = hasBillingSettingsEnabled(value?.settings);

      const hasPortfolio = hasPortfolioSettingsEnabled(value?.settings);

      if (hasBilling)
        setFieldValue('timeAndExpenseEntryType', {
          id: TIME_AND_EXPENSE_ENTRY_TYPE.BILLABLE_AND_NON_BILLABLE
        });
      else
        setFieldValue('timeAndExpenseEntryType', {
          id: TIME_AND_EXPENSE_ENTRY_TYPE.NON_BILLABLE
        });

      if (!hasPortfolio) setFieldValue('portfolio', null);
      handleProjectTemplateChange({ setFieldValue })(value);
    },
    [setFieldValue]
  ),
  onClientAndCurrencyChange: useCallback(
    async client => {
      handleClientAndCurrencyChange(client, me, refetch, setFieldValue);
    },
    [refetch, me, setFieldValue]
  ),
  onProjectManagerApprovalChange: useCallback(() => {
    setFieldValue(
      'isProjectLeaderApprovalRequired',
      !values.isProjectLeaderApprovalRequired
    );
  }, [setFieldValue, values.isProjectLeaderApprovalRequired]),
  onTimeAndExpenseEntryChange: useCallback(
    value => {
      handleAllowEntryWithoutTasks({ setFieldValue })(value);
    },
    [setFieldValue]
  ),
  onTimeAndExpenseEntryChange2: useCallback(
    value => {
      setFieldValue(
        'timeAndExpenseEntryType',
        transformTimeAndExpenseEntryTypeResult(value)
      );
    },
    [setFieldValue]
  ),
  onAllowTimeEntryAgainstTasksOnlyChange: useCallback(
    event => {
      setFieldValue('isTimeEntryAllowed', !event.target.checked);
    },
    [setFieldValue]
  )
});

export default useFormOnChange;
