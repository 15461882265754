import React, { useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import classNames from 'classnames';
import { gantt } from '@replicon/dhtmlx-gantt';
import '@replicon/dhtmlx-gantt/codebase/ext/dhtmlxgantt_tooltip';
import '@replicon/dhtmlx-gantt/codebase/ext/dhtmlxgantt_undo';
import {
  isoStringToFormattedISOString,
  mapIsoStringtoUtcObject
} from '~/modules/common/dates/convert';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

export const isSameYear = (a, b) => a.year === b.year;

const getClassNames = ({
  classes,
  taskBarBaseClass,
  statusClass,
  ganttScale
}) =>
  classNames(
    classes.ganttFontFamily,
    taskBarBaseClass,
    {
      [classes.ganttTaskBarYear]: ganttScale === 'year',
      [classes.ganttTaskBarQuarter]: ganttScale === 'quarter'
    },
    statusClass
  );

const getHoursText = (intl, hours) =>
  hours
    ? intl.formatNumber(hours, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    : '–';

const taskBarText = (intl, classes, canViewEstimate) => (start, end, task) => {
  const actualHours = getHoursText(intl, task.actualHours);
  const estimatedHours = getHoursText(intl, task.estimatedHours);
  const estimatedCompletionHours = getHoursText(
    intl,
    task.estimatedCompletionHours
  );

  const isActualOverEstimatedHours =
    task.actualHours > task.estimatedHours && task.estimatedHours > 0;
  const isEstCompletionOverEstimatedHours =
    task.estimatedCompletionHours > task.estimatedHours &&
    task.estimatedHours > 0;

  const actualHoursMessageValue = isActualOverEstimatedHours ? (
    <span className={classes.overEstimate}>{actualHours}</span>
  ) : (
    actualHours
  );

  const estimatedHoursMessageValue =
    task.taskStatus === 'INPROGRESS' ? (
      isEstCompletionOverEstimatedHours ? (
        <span className={classes.overEstimate}>{estimatedCompletionHours}</span>
      ) : (
        estimatedCompletionHours
      )
    ) : (
      estimatedHours
    );

  const messageValues = {
    actual: actualHoursMessageValue,
    estimate: canViewEstimate ? estimatedHoursMessageValue : ''
  };

  const showActualAndEstimatedHours =
    canViewEstimate &&
    (task.estimatedHours ||
      task.estimatedCompletionHours ||
      (!task.estimatedHours && !task.actualHours));

  const progressValue = showActualAndEstimatedHours
    ? intl.formatMessage(
        {
          id: 'projectTasksPage.progressValue'
        },
        messageValues
      )
    : intl.formatMessage(
        { id: 'projectTasksPage.progressValueWithoutEstimate' },
        messageValues
      );

  return renderToString(
    <span className={classes.ganttTaskContentContainer}>
      <span className={classes.ganttTaskProgress}>{progressValue}</span>
      {task.isMilestone && <span className={classes.milestoneMark} />}
    </span>
  );
};

const taskBarMilestone = classes => (start, end, task) =>
  renderToString(
    <span>
      {task.isMilestone && <span className={classes.milestoneMarkRollUp} />}
    </span>
  );

const taskTooltip = (intl, canViewEstimate) => (startDate, endDate, task) => {
  const formattedStartDate = isSameYear(
    mapIsoStringtoUtcObject(task.startDate),
    mapIsoStringtoUtcObject(task.endDate)
  )
    ? isoStringToFormattedISOString(task.startDate, 'LLL d')
    : isoStringToFormattedISOString(task.startDate, 'DD');
  const formattedEndDate = isoStringToFormattedISOString(task.endDate, 'DD');

  const dateLine = `${formattedStartDate} — ${formattedEndDate}`;
  const taskLabel = intl.formatMessage({
    id: 'projectTasksPage.task'
  });
  const estimatedHoursLabel = intl.formatMessage({
    id: 'projectTasksPage.estimatedHours'
  });
  const estimatedHours = task.estimatedHours
    ? intl.formatNumber(task.estimatedHours, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    : '–';

  const actualHoursLabel = intl.formatMessage({
    id: 'projectTasksPage.actualHours'
  });
  const actualHours = task.actualHours
    ? intl.formatNumber(task.actualHours, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    : '–';
  const estimatedHoursAtCompletionLabel = intl.formatMessage({
    id: 'projectTasksPage.estimatedHoursAtCompletion'
  });

  const estimatedCompletionHours = task.estimatedCompletionHours
    ? intl.formatNumber(task.estimatedCompletionHours, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    : '–';

  return renderToString(
    <span>
      <span>
        {taskLabel}: {task.text}
      </span>
      <br />
      <span>{dateLine}</span>
      {canViewEstimate && (
        <>
          <br />
          <span>
            {estimatedHoursLabel}: {estimatedHours}
          </span>
        </>
      )}
      <br />
      <span>
        {actualHoursLabel}: {actualHours}
      </span>
      <br />
      {canViewEstimate && (
        <span>
          {estimatedHoursAtCompletionLabel}: {estimatedCompletionHours}
        </span>
      )}
    </span>
  );
};

export const gridRowClass = (me, classes) => (start, end, task) => {
  const { $level } = task;
  const hideAddButton = $level === 10;

  return classNames(
    classes.ganttFontFamily,
    hideAddButton && classes.ganttGridRowNoAdd,
    me.isRolledUpTaskEstimateCalculationMethodEnabled
      ? task.parent
        ? classes.ganttGridRow2
        : classes.ganttGridRowProject
      : classes.ganttGridRow
  );
};

export const taskRowClass = (me, classes) => (start, end, task) =>
  classNames(
    classes.ganttFontFamily,
    me.isRolledUpTaskEstimateCalculationMethodEnabled
      ? task.parent
        ? classes.ganttGridRow2
        : classes.ganttGridRowProject
      : classes.ganttGridRow
  );

const getIndentationWidth = ({
  taskLevel,
  isRolledUpTaskEstimateCalculationMethodEnabled
}) => ({
  width: isRolledUpTaskEstimateCalculationMethodEnabled
    ? theme.spacing(taskLevel + 1)
    : theme.spacing(2.25) + theme.spacing(taskLevel) // theme.spacing(2.25) is the width of expand/collapse icon
});

const gridIndent = ({
  taskLevel,
  isRolledUpTaskEstimateCalculationMethodEnabled
}) =>
  renderToString(
    <div
      style={getIndentationWidth({
        taskLevel,
        isRolledUpTaskEstimateCalculationMethodEnabled
      })}
    ></div>
  );

export const getGridTaskClass = (me, classes, isTaskDateRollupEnabled) => (
  start,
  end,
  task
) => {
  const disableTaskDrag =
    me.featureFlags.isPsaPswatTaskDateRollupEnabled && isTaskDateRollupEnabled;

  if (me.isRolledUpTaskEstimateCalculationMethodEnabled) {
    if (!task.startDate || !task.endDate || task.startDate === task.endDate) {
      return classNames(classes.ganttFontFamily, classes.ganttTaskBar);
    }
    if (gantt.hasChild(task.id)) {
      return task.taskStatus === 'COMPLETED'
        ? classNames(
            classes.ganttFontFamily,
            classes.ganttTaskBar2,
            classes.ganttTaskBar2Completed,
            {
              [classes.disableTaskDrag]: disableTaskDrag
            }
          )
        : task.rolledUpSummary.actualHours
        ? classNames(
            classes.ganttFontFamily,
            classes.ganttTaskBar2,
            classes.ganttTaskBar2InProgress,
            {
              [classes.disableTaskDrag]: disableTaskDrag
            }
          )
        : classNames(
            classes.ganttFontFamily,
            classes.ganttTaskBar2,
            classes.ganttTaskBar2NotStarted,
            {
              [classes.disableTaskDrag]: disableTaskDrag
            }
          );
    }
    if (task.taskStatus === 'INPROGRESS') {
      return classNames(
        classes.ganttFontFamily,
        classes.ganttTaskBar2RoundedBorder
      );
    }

    return classNames(classes.ganttFontFamily, classes.ganttTaskBar2Rounded);
  }

  return classNames(classes.ganttFontFamily, classes.ganttTaskBar, {
    [classes.disableTaskDrag]: disableTaskDrag && gantt.hasChild(task.id)
  });
};

export const getGridTaskClass2 = (me, classes, ganttScale) => (
  start,
  end,
  task
) => {
  if (me.isRolledUpTaskEstimateCalculationMethodEnabled) {
    if (!task.startDate || !task.endDate || task.startDate === task.endDate) {
      return getClassNames({
        classes,
        taskBarBaseClass: classes.ganttTaskBar,
        ganttScale
      });
    }
    if (gantt.hasChild(task.id)) {
      return task.taskStatus === 'COMPLETED'
        ? getClassNames({
            classes,
            taskBarBaseClass: classes.ganttTaskBar2,
            statusClass: classes.ganttTaskBar2Completed,
            ganttScale
          })
        : task.rolledUpSummary.actualHours
        ? getClassNames({
            classes,
            taskBarBaseClass: classes.ganttTaskBar2,
            statusClass: classes.ganttTaskBar2InProgress,
            ganttScale
          })
        : getClassNames({
            classes,
            taskBarBaseClass: classes.ganttTaskBar2,
            statusClass: classes.ganttTaskBar2NotStarted,
            ganttScale
          });
    }
    if (task.taskStatus === 'INPROGRESS') {
      return getClassNames({
        classes,
        taskBarBaseClass: classes.ganttTaskBar2RoundedBorder,
        ganttScale
      });
    }

    return getClassNames({
      classes,
      taskBarBaseClass: classes.ganttTaskBar2Rounded,
      ganttScale
    });
  }

  return getClassNames({
    classes,
    taskBarBaseClass: classes.ganttTaskBar,
    ganttScale
  });
};

export const useGanttTemplates = ({
  me,
  intl,
  classes,
  canViewEstimate,
  isRTL,
  ganttScale,
  isTaskDateRollupEnabled
}) =>
  useMemo(
    () => ({
      task_class: me.featureFlags.isPsaRmpTaskAllocation1Enabled
        ? getGridTaskClass2(me, classes, ganttScale)
        : getGridTaskClass(me, classes, isTaskDateRollupEnabled),
      scale_cell_class: () =>
        classNames(classes.ganttFontFamily, classes.ganttScaleCell, {
          [classes.ganttChartTimelineHeader]:
            me.featureFlags.isPsaRmpTaskAllocation1Enabled
        }),
      grid_row_class: gridRowClass(me, classes),
      task_row_class: taskRowClass(me, classes),
      grid_header_class: columnName =>
        classNames(classes.ganttFontFamily, {
          [classes.ganttAssigneeColumnHeader]:
            columnName === 'assigneeText' || columnName === 'assignedRoleText',
          [classes.ganttTaskNameHeaderCell]: columnName === 'text'
        }),
      task_text: me.isRolledUpTaskEstimateCalculationMethodEnabled
        ? taskBarMilestone(classes)
        : taskBarText(intl, classes, canViewEstimate),
      ...(!me.isRolledUpTaskEstimateCalculationMethodEnabled && {
        tooltip_text: taskTooltip(intl, canViewEstimate)
      }),
      ...(me.featureFlags.isPsaRmpTaskAllocation1Enabled && {
        grid_indent: task =>
          gridIndent({
            taskLevel: task.$level,
            isRolledUpTaskEstimateCalculationMethodEnabled:
              me.isRolledUpTaskEstimateCalculationMethodEnabled
          })
      })
    }),
    [canViewEstimate, classes, ganttScale, intl, me, isTaskDateRollupEnabled]
  );

export default useGanttTemplates;
