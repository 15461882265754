import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DateRange from '~/modules/common/components/DateRange';
import {
  SideCardSectionDetail,
  SideCardSectionHeader
} from '~/modules/common/components/SideCard';
import User from '~/modules/common/components/User';
import { useDialogState } from '~/modules/common/hooks';
import { useMeContext } from '~/modules/me/useMeContext';
import RescheduleProjectDialog from '~/modules/projects/project/ProjectPageHeader/RescheduleProjectDialog';
import EditBasicInfoDialog from './EditBasicInfoDialog';
import ProjectType from './ProjectType';
import SlackChannel from './SlackChannel';
import { getSelectedTimeAndExpenseEntryType } from './BasicInfo.util';

export const basicInfoLabel = (
  <FormattedMessage id="projectBasicInfoCard.basicInfo" />
);
export const nameLabel = <FormattedMessage id="projectBasicInfoCard.name" />;
export const codeLabel = <FormattedMessage id="projectBasicInfoCard.code" />;
export const datesLabel = <FormattedMessage id="projectBasicInfoCard.dates" />;
export const projectManagerLabel = (
  <FormattedMessage id="projectBasicInfoCard.projectManager" />
);
export const contractTypeLabel = (
  <FormattedMessage id="earnedRevenue.contractType" />
);
export const coManagerLabel = (
  <FormattedMessage id="projectBasicInfoCard.coManagers" />
);
export const programLabel = (
  <FormattedMessage id="projectBasicInfoCard.program" />
);
export const portfolioLabel = (
  <FormattedMessage id="projectBasicInfoCard.portfolio" />
);
export const descriptionLabel = (
  <FormattedMessage id="projectBasicInfoCard.description" />
);
export const allowEntryWithoutTasksLabel = (
  <FormattedMessage id="projectBasicInfoCard.allowEntryWithoutTasks" />
);
export const timeAndExpenseEntryLabel = (
  <FormattedMessage id="projectBasicInfo.timeAndExpenseEntry" />
);
export const allowTimeEntryAgainstTasksOnlyLabel = (
  <FormattedMessage id="projectBasicInfo.allowTimeEntryAgainstTasksOnly.label" />
);

const getAllowTimeEntryAgainstTasksOnly = isTimeEntryAllowed => (
  <FormattedMessage
    id={
      isTimeEntryAllowed
        ? 'projectBasicInfo.allowTimeEntryAgainstTasksOnly.options.no'
        : 'projectBasicInfo.allowTimeEntryAgainstTasksOnly.options.yes'
    }
  />
);

const useSlackDetailStyles = makeStyles({
  detail: {
    display: 'flex',
    alignItems: 'center'
  }
});
const useDateStyles = makeStyles(theme => ({
  date: {
    fontSize: theme.typography.body2.fontSize
  }
}));
const useUserStyles = makeStyles(theme => ({
  container: {
    padding: 0,
    marginBottom: theme.spacing(0.5)
  },
  label: {
    fontSize: theme.typography.body2.fontSize,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

const coManagersStyles = makeStyles(theme => ({
  listItem: {
    padding: theme.spacing(0.25, 0),
    display: 'flex',
    flexDirection: 'column'
  }
}));

export const BasicInfo = ({
  showEdit,
  projectId,
  slackChannel,
  projectName,
  projectSlug,
  earnedRevenueScript,
  code,
  startDate,
  endDate,
  defaultScheduleRule,
  projectManagementType,
  projectManagerReference,
  coManagers,
  isProjectLeaderApprovalRequired,
  program,
  portfolio,
  description,
  projectPermissions,
  permittedActionUris,
  timeAndExpenseEntryType,
  isTimeEntryAllowed,
  templateSettings,
  billingType
}) => {
  const slackDetailClasses = useSlackDetailStyles();
  const datesClasses = useDateStyles();
  const userClasses = useUserStyles();
  const { formatMessage } = useIntl();
  const { hasViewProjectBillingOptions, featureFlags } = useMeContext();

  const { open, openDialog, closeDialog } = useDialogState(false, {
    disabledBackDropClick: true
  });

  const {
    open: rescheduleProjectDialogOpen,
    openDialog: openRescheduleProjectDialog,
    closeDialog: rescheduleProjectDialogCancel
  } = useDialogState(false, {
    disabledBackDropClick: true,
    disableEscapeKeyDown: true
  });

  const onReschedule = useCallback(() => {
    closeDialog();
    openRescheduleProjectDialog();
  }, [closeDialog, openRescheduleProjectDialog]);

  const hasDates = startDate || endDate;

  const slackChannelNode = useMemo(
    () => slackChannel && <SlackChannel slackChannel={slackChannel} />,
    [slackChannel]
  );

  const dateRangeNode = useMemo(
    () =>
      hasDates && (
        <DateRange classes={datesClasses} start={startDate} end={endDate} />
      ),
    [datesClasses, endDate, hasDates, startDate]
  );

  const projectManagerNode = useMemo(
    () =>
      projectManagerReference && (
        <User
          classes={userClasses}
          dataQeId="ProjectManagerUser"
          user={projectManagerReference}
        />
      ),
    [projectManagerReference, userClasses]
  );

  const cssClasses = coManagersStyles();

  const coManagerNode = useMemo(
    () =>
      coManagers && (
        <div className={cssClasses.listItem}>
          {coManagers.map(user => (
            <User
              key={user.id}
              data-qe-id={user.displayText}
              user={user}
              classes={userClasses}
            />
          ))}
        </div>
      ),
    [coManagers, userClasses, cssClasses]
  );

  const showTimeAndExpenseEntryTypeField =
    (featureFlags.isPsaPrpPsaPpmMergerEnabled
      ? templateSettings?.hasBilling
      : true) &&
    hasViewProjectBillingOptions &&
    billingType?.id !== 'urn:replicon:billing-type:non-billable';

  const projectTimeAndExpenseEntryType = getSelectedTimeAndExpenseEntryType({
    isTimeEntryAllowed,
    hasViewProjectBillingOptions: featureFlags?.isPsaPrpPsaPpmMergerEnabled
      ? templateSettings?.hasBilling && hasViewProjectBillingOptions
      : hasViewProjectBillingOptions,
    timeAndExpenseEntryType,
    isPsaPrpEnhancedAllowEntryWithoutTasksDropdownEnabled:
      featureFlags.isPsaPrpEnhancedAllowEntryWithoutTasksDropdownEnabled
  });
  const project = { projectId, startDate, endDate, permittedActionUris };

  return (
    <>
      {showEdit && (
        <SideCardSectionHeader
          title={basicInfoLabel}
          onEditClick={openDialog}
          dataQeId="EditBasicSection"
          showEdit={showEdit}
          ariaLabelKey="button.basicInfoEditButton"
        />
      )}
      {slackChannel && (
        <SideCardSectionDetail
          classes={slackDetailClasses}
          detail={slackChannelNode}
        />
      )}
      {showEdit && (
        <SideCardSectionDetail label={nameLabel} detail={projectName} />
      )}
      {(showEdit || code) && (
        <SideCardSectionDetail label={codeLabel} detail={code} />
      )}
      <SideCardSectionDetail label={datesLabel} detail={dateRangeNode} />
      {projectManagementType && <ProjectType typeUri={projectManagementType} />}
      <>
        <SideCardSectionDetail
          label={projectManagerLabel}
          detail={projectManagerNode}
        />
        {featureFlags?.isPsaEarnedRevenueEnabled && (
          <SideCardSectionDetail
            label={contractTypeLabel}
            detail={
              earnedRevenueScript?.name ||
              formatMessage({
                id: 'earnedRevenue.none'
              })
            }
          />
        )}
        {coManagers &&
          coManagers.length !== 0 &&
          projectPermissions.canViewCoManagers && (
            <SideCardSectionDetail
              label={coManagerLabel}
              detail={coManagerNode}
            />
          )}
        {projectPermissions.canViewPortfolio && (
          <SideCardSectionDetail
            label={portfolioLabel}
            detail={portfolio && portfolio.displayText}
          />
        )}
        {projectPermissions.canViewProgram && (
          <SideCardSectionDetail
            label={programLabel}
            detail={program && program.displayText}
          />
        )}
      </>
      {(showEdit || description) && (
        <SideCardSectionDetail
          label={descriptionLabel}
          detail={description}
          isDescription
        />
      )}
      {featureFlags.isPsaPrpEnhancedAllowEntryWithoutTasksDropdownEnabled ? (
        <>
          {showTimeAndExpenseEntryTypeField && (
            <SideCardSectionDetail
              label={timeAndExpenseEntryLabel}
              detail={projectTimeAndExpenseEntryType}
            />
          )}
          <SideCardSectionDetail
            label={allowTimeEntryAgainstTasksOnlyLabel}
            detail={getAllowTimeEntryAgainstTasksOnly(isTimeEntryAllowed)}
          />
        </>
      ) : (
        <SideCardSectionDetail
          label={allowEntryWithoutTasksLabel}
          detail={projectTimeAndExpenseEntryType}
        />
      )}
      <EditBasicInfoDialog
        title={basicInfoLabel}
        open={open}
        onClose={closeDialog}
        projectId={projectId}
        canViewProgram={projectPermissions.canViewProgram}
        slackChannel={slackChannel}
        projectName={projectName}
        projectSlug={projectSlug}
        earnedRevenueScript={earnedRevenueScript}
        code={code}
        startDate={startDate}
        endDate={endDate}
        defaultScheduleRule={defaultScheduleRule}
        projectManagementType={projectManagementType}
        projectManagerReference={projectManagerReference}
        coManagers={coManagers}
        isProjectLeaderApprovalRequired={isProjectLeaderApprovalRequired}
        program={program}
        portfolio={portfolio}
        description={description}
        projectPermissions={projectPermissions}
        timeAndExpenseEntryType={timeAndExpenseEntryType}
        isTimeEntryAllowed={isTimeEntryAllowed}
        onReschedule={onReschedule}
        templateSettings={templateSettings}
        billingType={billingType}
      />
      {openRescheduleProjectDialog && (
        <RescheduleProjectDialog
          open={rescheduleProjectDialogOpen}
          onClose={rescheduleProjectDialogCancel}
          project={project}
        />
      )}
    </>
  );
};

BasicInfo.propTypes = {
  showEdit: PropTypes.bool,
  projectPermissions: PropTypes.object,
  projectId: PropTypes.string,
  slackChannel: PropTypes.string,
  projectName: PropTypes.string,
  projectSlug: PropTypes.string,
  earnedRevenueScript: PropTypes.object,
  code: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  projectManagementType: PropTypes.string,
  projectManagerReference: PropTypes.object,
  isProjectLeaderApprovalRequired: PropTypes.bool,
  timeAndExpenseEntryType: PropTypes.object,
  coManagers: PropTypes.array,
  program: PropTypes.object,
  portfolio: PropTypes.object,
  description: PropTypes.string,
  isTimeEntryAllowed: PropTypes.bool,
  defaultScheduleRule: PropTypes.object,
  permittedActionUris: PropTypes.array,
  templateSettings: PropTypes.object,
  billingType: PropTypes.object
};

export default BasicInfo;
