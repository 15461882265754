import { PROJECT_PERMISSION } from '~/modules/common/enums';

export const hasPermittedAction = (
  permittedActionUris = [],
  permittedActionUri
) => permittedActionUris.includes(permittedActionUri);

export const useProjectObjectPermissions = input => {
  const { project: { permittedActionUris = [] } = {} } = input || {};

  return {
    canViewEstimate: hasPermittedAction(
      permittedActionUris,
      PROJECT_PERMISSION.VIEW_PROJECT_ESTIMATES
    ),
    canEditEstimate: hasPermittedAction(
      permittedActionUris,
      PROJECT_PERMISSION.EDIT_PROJECT_ESTIMATES
    ),
    canViewCost: hasPermittedAction(
      permittedActionUris,
      PROJECT_PERMISSION.VIEW_COST_DATA
    ),
    canEditCost: hasPermittedAction(
      permittedActionUris,
      PROJECT_PERMISSION.EDIT_COST_DATA
    ),
    canEditClient: hasPermittedAction(
      permittedActionUris,
      PROJECT_PERMISSION.EDIT_CLIENT
    ),
    canViewClient: hasPermittedAction(
      permittedActionUris,
      PROJECT_PERMISSION.VIEW_CLIENT
    ),
    canEditProgram: hasPermittedAction(
      permittedActionUris,
      PROJECT_PERMISSION.EDIT_PROGRAM
    ),
    canViewProgram: hasPermittedAction(
      permittedActionUris,
      PROJECT_PERMISSION.VIEW_PROGRAM
    ),
    canViewBillingContracts: hasPermittedAction(
      permittedActionUris,
      PROJECT_PERMISSION.VIEW_BILLING_CONTRACTS
    ),
    canViewTasks: hasPermittedAction(
      permittedActionUris,
      PROJECT_PERMISSION.VIEW_TASK
    ),
    canEditTasks: hasPermittedAction(
      permittedActionUris,
      PROJECT_PERMISSION.EDIT_TASK
    ),
    canEditTeam: hasPermittedAction(
      permittedActionUris,
      PROJECT_PERMISSION.EDIT_TEAM
    ),
    canEditBillingRates: hasPermittedAction(
      permittedActionUris,
      PROJECT_PERMISSION.EDIT_BILLING_RATES
    ),
    canEditExpenseCodes: hasPermittedAction(
      permittedActionUris,
      PROJECT_PERMISSION.EDIT_EXPENSE_CODES
    ),
    canEditBillingContracts: hasPermittedAction(
      permittedActionUris,
      PROJECT_PERMISSION.EDIT_BILLING_CONTRACTS
    ),
    canEditRevenueContract: hasPermittedAction(
      permittedActionUris,
      PROJECT_PERMISSION.EDIT_REVENUE_CONTRACT
    )
  };
};

export default useProjectObjectPermissions;
