import React from 'react';
import { PropTypes } from 'prop-types';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { useMeContext } from '~/modules/me';
import { TaskStatus } from '~/types';
import {
  objectToFormatString,
  isoStringToFormattedISOString,
  getLuxonJsDateFormatFromMe
} from '~/modules/common/dates/convert';
import { useTaskSummaryContext } from '../TaskDrawer/TaskSummaryContext';
import TaskStatusSkeleton from './TaskStatusSkeleton';
import MobileTaskStatusSection from './MobileTaskStatusSection/MobileTaskStatusSection';
import DesktopTaskStatusSection from './DesktopTaskStatusSection/DesktopTaskStatusSection';
import RollupStatusSection from './RollupStatusSection/RollupStatusSection';
import MobileRollUpStatusSection from './MobileRollupStatusSection/MobileRollUpStatusSection';
import useTaskStatusFacts from './useTaskStatusFacts';

export const TaskStatusSection = ({ task, hasMore, loadMore, loadingMore }) => {
  const { taskStatus, rolledUpSummary } = task;
  const me = useMeContext();
  const {
    isRolledUpTaskEstimateCalculationMethodEnabled,
    featureFlags: {
      isPsaPpmEstimatedCostAtCompletionEnabled,
      isPsaRmpTaskAllocation1Enabled
    }
  } = me;

  const facts = useTaskStatusFacts(task);
  const isMobile = useIsBreakpointDown('sm');

  const { taskSummaryLoading } = useTaskSummaryContext();

  const isRollupSectionLoading =
    isPsaRmpTaskAllocation1Enabled &&
    isRolledUpTaskEstimateCalculationMethodEnabled &&
    taskSummaryLoading;

  const dateFormat = getLuxonJsDateFormatFromMe(me);
  const endDate = objectToFormatString(task?.endDate, dateFormat);
  const closedOnDate = objectToFormatString(task?.closedOnDate, dateFormat);
  const estimatedCompletionDate = isoStringToFormattedISOString(
    rolledUpSummary?.estimatedCompletionDate,
    dateFormat
  );

  const estimatedAtCompletionDate =
    taskStatus === TaskStatus.Completed
      ? closedOnDate
      : estimatedCompletionDate || endDate;

  const canViewEstimate = Boolean(
    task.project?.permittedActionUris?.includes(
      'urn:replicon:project-action:view-project-estimates'
    )
  );

  return isRolledUpTaskEstimateCalculationMethodEnabled ? (
    isMobile && isPsaPpmEstimatedCostAtCompletionEnabled ? (
      <MobileRollUpStatusSection
        source={task}
        hasMore={hasMore}
        loadMore={loadMore}
        loadingMore={loadingMore}
        estimatedCompletionDate={estimatedAtCompletionDate}
      />
    ) : isRollupSectionLoading ? (
      <TaskStatusSkeleton
        task={task}
        isPsaPpmEstimatedCostAtCompletionEnabled={
          isPsaPpmEstimatedCostAtCompletionEnabled
        }
      />
    ) : (
      <RollupStatusSection
        source={task}
        hasMore={hasMore}
        loadMore={loadMore}
        loadingMore={loadingMore}
        estimatedCompletionDate={estimatedAtCompletionDate}
      />
    )
  ) : isMobile || !canViewEstimate ? (
    <MobileTaskStatusSection
      task={task}
      facts={facts}
      canViewEstimate={canViewEstimate}
    />
  ) : (
    <DesktopTaskStatusSection task={task} facts={facts} />
  );
};

TaskStatusSection.propTypes = {
  task: PropTypes.object.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  loadingMore: PropTypes.bool.isRequired
};

export default TaskStatusSection;
