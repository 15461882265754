import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Checkbox } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useIntl } from 'react-intl';
import WarningIcon from '@material-ui/icons/WarningSharp';
import { TaskStatus } from '~/types';

const useStyles = makeStyles(theme => ({
  alert: {
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(9),
    display: 'flex',
    backgroundColor: theme.palette.common.highlight
  },
  alertText: {
    ...theme.typography.body2,
    textAlign: 'left'
  },
  permissionContainer: {
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(9),
    display: 'flex',
    backgroundColor: theme.palette.common.highlight
  },
  tasksSummaryContainer: {
    ...theme.typography.body2,
    color: theme.palette.grey[500],
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(9),
    marginRight: theme.spacing(2)
  },
  button: { alignSelf: 'start', marginTop: theme.spacing(0.5) },
  alertIcon: {
    marginRight: theme.spacing(1),
    alignSelf: 'center'
  },
  changeTask: {
    fontWeight: theme.typography.fontWeightBold
  }
}));

const ChangeAllocationImpactedTasksConfirmation = ({
  tasksChecked,
  setTasksChecked,
  existingResourceUser,
  permissions,
  newResourceUser,
  impactedTasks,
  classes: classesOverride
}) => {
  const classes = useStyles({ classes: classesOverride });
  const { formatMessage } = useIntl();
  const { displayText: currentUser } = existingResourceUser;

  const { displayText: futureUser } = newResourceUser;

  const inProgressTaskCount = impactedTasks.filter(
    task => task.status === TaskStatus.Inprogress
  ).length;

  const notStartedTasksCount = impactedTasks.filter(
    task => task.status === TaskStatus.Notstarted
  ).length;

  const tasksCount =
    inProgressTaskCount > 0 && notStartedTasksCount > 0
      ? formatMessage(
          {
            id: 'changeAllocationUserAsOfDateDialog.inProgressAndNotStarted'
          },
          {
            inProgressCount: inProgressTaskCount,
            notStartedCount: notStartedTasksCount
          }
        )
      : notStartedTasksCount > 0
      ? formatMessage(
          {
            id: 'changeAllocationUserAsOfDateDialog.notStarted'
          },
          { notStartedCount: notStartedTasksCount }
        )
      : inProgressTaskCount > 0
      ? formatMessage(
          {
            id: 'changeAllocationUserAsOfDateDialog.inProgress'
          },
          { inProgressCount: inProgressTaskCount }
        )
      : '';

  const viewPermission = permissions.includes(
    'urn:replicon:project-action:view-tasks'
  );
  const editPermission = permissions.includes(
    'urn:replicon:project-action:edit-tasks'
  );

  const handleClick = useCallback(() => setTasksChecked(!tasksChecked), [
    setTasksChecked,
    tasksChecked
  ]);

  const changeTask = useMemo(
    () => (
      <span className={classes.changeTask}>
        {formatMessage(
          {
            id: 'changeAllocationUserAsOfDateDialog.changeTask'
          },
          {
            currentUser,
            futureUser
          }
        )}
      </span>
    ),
    [classes, formatMessage, currentUser, futureUser]
  );

  if (viewPermission && editPermission)
    return notStartedTasksCount && notStartedTasksCount ? (
      <div>
        <p className={classes.tasksSummaryContainer}>
          {formatMessage(
            {
              id:
                'changeAllocationUserAsOfDateDialog.entireAllocationTasksPrompt'
            },
            {
              currentUser,
              tasksCount
            }
          )}
        </p>
        <div className={classes.permissionContainer}>
          <ButtonBase className={classes.button} onClick={handleClick}>
            <Checkbox color="primary" checked={tasksChecked} disableRipple />
          </ButtonBase>
          <p className={classes.alertText}>
            {formatMessage(
              {
                id:
                  'changeAllocationUserAsOfDateDialog.entireAllocationConfirmation'
              },
              {
                changeTask
              }
            )}
          </p>
        </div>
      </div>
    ) : null;

  return viewPermission ? (
    <div className={classes.alert}>
      <WarningIcon className={classes.alertIcon} />

      <p className={classes.alertText}>
        {formatMessage({
          id: 'changeAllocationUserAsOfDateDialog.noTaskEditPermission'
        })}
      </p>
    </div>
  ) : (
    <div className={classes.alert}>
      <WarningIcon className={classes.alertIcon} />

      <p className={classes.alertText}>
        {formatMessage({
          id: 'changeAllocationUserAsOfDateDialog.noTaskViewEditPermission'
        })}
      </p>
    </div>
  );
};

ChangeAllocationImpactedTasksConfirmation.propTypes = {
  tasksChecked: PropTypes.bool,
  setTasksChecked: PropTypes.func,
  existingResourceUser: PropTypes.object,
  permissions: PropTypes.array,
  newResourceUser: PropTypes.object,
  impactedTasks: PropTypes.array,
  classes: PropTypes.object
};

export default ChangeAllocationImpactedTasksConfirmation;
