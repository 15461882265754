export const PROJECT_PERMISSION = {
  VIEW_BILLING_CONTRACTS: 'urn:replicon:project-action:view-billing-contracts',
  EDIT_BILLING_CONTRACTS: 'urn:replicon:project-action:edit-billing-contracts',
  EDIT_BILLING_RATES: 'urn:replicon:project-action:edit-billing-rates',
  VIEW_CLIENT: 'urn:replicon:project-action:view-client',
  EDIT_CLIENT: 'urn:replicon:project-action:edit-client',
  VIEW_COST_DATA: 'urn:replicon:project-action:view-cost-data',
  EDIT_COST_DATA: 'urn:replicon:project-action:edit-cost-data',
  EDIT_EXPENSE_CODES: 'urn:replicon:project-action:edit-expense-codes',
  EDIT_PORTFOLIO: 'urn:replicon:project-action:edit-portfolio',
  VIEW_PROGRAM: 'urn:replicon:project-action:view-program',
  EDIT_PROGRAM: 'urn:replicon:project-action:edit-program',
  EDIT_PROJECT: 'urn:replicon:project-action:edit-project',
  VIEW_TASK: 'urn:replicon:project-action:view-tasks',
  EDIT_TASK: 'urn:replicon:project-action:edit-tasks',
  VIEW_PROJECT: 'urn:replicon:project-action:view-project',
  VIEW_PROJECT_ESTIMATES: 'urn:replicon:project-action:view-project-estimates',
  EDIT_PROJECT_ESTIMATES: 'urn:replicon:project-action:edit-project-estimates',
  EDIT_TEAM: 'urn:replicon:project-action:edit-team',
  EDIT_REVENUE_CONTRACT: 'urn:replicon:project-action:edit-revenue-contract'
};

export const PROJECT_DAL = {
  PORTFOLIO_MANAGER: 'urn:replicon:project-data-access-level:portfolio-manager',
  PROGRAM_MANAGER: 'urn:replicon:project-data-access-level:program-manager',
  PROJECT_ADMIN:
    'urn:replicon:project-data-access-level:project-management-data-access-scope',
  PROJECT_MANAGER: 'urn:replicon:project-data-access-level:project-manager',
  CLIENT_MANAGER: 'urn:replicon:project-data-access-level:client-manager',
  ALL: 'urn:replicon:project-data-access-level:all'
};
