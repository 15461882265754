import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { TextField, InputAdornment } from '@material-ui/core';
import { DISPLAY_UNIT_ENUM } from '~/modules/resourcing/common/enums';
import { useDisplayUnitContext } from '~/modules/resourcing/common/hooks/useDisplayUnitContext';
import { convertToComparableValue } from '~/modules/resourcing/common/util';
import { useTaskAllocationOnChangeHandlers } from './useTaskAllocationOnChangeHandlers';
import { TaskAllocationEditorContent } from './TaskAllocationEditorContent';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.caption
  },
  hoursField: ({ targetWidth }) => ({
    width: Math.max(targetWidth || 0, theme.spacing(4.5))
  }),
  hoursFieldInput: ({ targetHeight }) => ({
    textAlign: 'right',
    padding: theme.spacing(0.5, 0),
    '-moz-appearance': 'textfield',
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    height: Math.max(targetHeight || 0, theme.spacing(2))
  }),
  inputAdornment: {
    ...theme.typography.caption,
    padding: theme.spacing(0, 0.5)
  }
}));

const highlightTarget = event => event.target.select();

export const TaskAllocationEditor = ({
  handlePeriodUpdate,
  handlePreviousPeriod,
  handleNextPeriod,
  allocatedHours: initialAllocatedHours,
  targetWidth,
  targetHeight,
  scheduledHours = 0,
  isLoading,
  onAllocationChange,
  fetchRemainingAndAvailableValues
}) => {
  const classes = useStyles({
    targetWidth,
    targetHeight
  });

  const { displayUnit } = useDisplayUnitContext();
  const [allocatedHours, setAllocatedHours] = useState(initialAllocatedHours);

  const isHoursMode =
    displayUnit === DISPLAY_UNIT_ENUM.HOURS || scheduledHours === 0;

  const value = convertToComparableValue({
    isHoursMode,
    hours: allocatedHours,
    scheduledHours
  });

  const { availableValue, remainingValue } = fetchRemainingAndAvailableValues({
    updatedAllocatedHours: allocatedHours,
    isHoursMode
  });

  const inputProps = useMemo(
    () => ({
      classes: {
        input: classes.hoursFieldInput
      },
      disableUnderline: true,
      inputProps: {
        min: 0
      },
      endAdornment: (
        <InputAdornment className={classes.inputAdornment} position="start">
          {isHoursMode ? 'h' : '%'}
        </InputAdornment>
      )
    }),
    [classes.inputAdornment, classes.hoursFieldInput, isHoursMode]
  );

  const {
    handleOnBlur,
    handleHoursChange,
    handleHoursKeyDown,
    handleHoursKeyPress
  } = useTaskAllocationOnChangeHandlers({
    setAllocatedHours,
    isHoursMode,
    scheduledHours,
    initialAllocatedHours,
    onAllocationChange,
    handlePeriodUpdate,
    handleNextPeriod,
    handlePreviousPeriod
  });

  return isLoading ? (
    <div className={classes.root}>
      <Skeleton width="80%" height={20} />
    </div>
  ) : (
    <div className={classes.root} data-qe-id="popoverInput">
      <TextField
        type="number"
        step="0.01"
        min="0"
        variant="standard"
        value={value}
        onChange={handleHoursChange}
        onBlur={handleOnBlur}
        onKeyPress={handleHoursKeyPress}
        onKeyDown={handleHoursKeyDown}
        autoFocus
        InputProps={inputProps}
        className={classes.hoursField}
        onFocus={highlightTarget}
      />
      <TaskAllocationEditorContent
        classes={classes}
        targetHeight={targetHeight}
        targetWidth={targetWidth}
        scheduledHours={scheduledHours}
        availableHours={availableValue}
        remainingHours={remainingValue}
        allocatedHours={allocatedHours}
      />
    </div>
  );
};

TaskAllocationEditor.propTypes = {
  allocatedHours: PropTypes.number,
  isLoading: PropTypes.bool,
  scheduledHours: PropTypes.number,
  targetHeight: PropTypes.number,
  targetWidth: PropTypes.number,
  handlePeriodUpdate: PropTypes.func,
  onAllocationChange: PropTypes.func,
  handlePreviousPeriod: PropTypes.func,
  handleNextPeriod: PropTypes.func,
  fetchRemainingAndAvailableValues: PropTypes.func
};
