import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { StyledChartRangeItem2 } from '~/modules/common/charts/timeline/components';
import {
  useQuickAllocationContext,
  useQuickAllocationProjectAllocationsByIds
} from '~/modules/quickAllocation/components/QuickAllocationUserChart/hooks';
import useStyles from '~/modules/common/charts/timeline/components/ChartRangeItem/useStyles';

import QuickAllocationProjectGroupLeftComponent2 from './QuickAllocationProjectGroupLeftComponent2';
import QuickAllocationChartTimelines2 from './QuickAllocationChartTimelines2';

const useOverrideStyles = makeStyles(() => ({
  itemContainer: {
    alignItems: 'normal'
  }
}));

export const getAllocationIdsForProject = ({ allocatedProject }) =>
  allocatedProject.roles.reduce(
    (acc, role) => [
      ...acc,
      ...(role.resourceAllocationReference || []).map(ref => ref.id)
    ],
    []
  );

const QuickAllocationProjectGroupDetailRow = ({
  allocatedProject,
  projectsSlugLoading,
  handleRemoveResourceAllocation,
  resourceUser,
  holidaySummaryPeriods,
  timeoffSummaryPeriods,
  rangeMinDate,
  rangeMaxDate,
  setSnackBarState
}) => {
  const {
    scale,
    chartDates,
    leftComponentWidth,
    chartStartDate
  } = useQuickAllocationContext();
  const overrideClasses = useOverrideStyles();
  const chartRangeItemClasses = useStyles({ classes: overrideClasses });

  const allocationIds = getAllocationIdsForProject({
    allocatedProject
  });

  const {
    loadingAllocations,
    resourceAllocations
  } = useQuickAllocationProjectAllocationsByIds({
    allocationIds,
    skip: !allocationIds.length
  });

  return (
    <StyledChartRangeItem2
      classes={chartRangeItemClasses}
      scale={scale}
      scaleItemCount={chartDates.length}
      chartStartDate={chartStartDate}
      leftComponentWidth={leftComponentWidth}
    >
      <QuickAllocationProjectGroupLeftComponent2
        allocatedProject={allocatedProject}
        projectsSlugLoading={projectsSlugLoading}
        resourceAllocations={resourceAllocations}
        resourceUser={resourceUser}
        handleRemoveResourceAllocation={handleRemoveResourceAllocation}
        setSnackBarState={setSnackBarState}
      />
      <QuickAllocationChartTimelines2
        allocatedProject={allocatedProject}
        setSnackBarState={setSnackBarState}
        resourceUser={resourceUser}
        holidaySummaryPeriods={holidaySummaryPeriods}
        timeoffSummaryPeriods={timeoffSummaryPeriods}
        resourceAllocations={resourceAllocations}
        loadingAllocations={loadingAllocations}
        handleRemoveResourceAllocation={handleRemoveResourceAllocation}
        rangeMinDate={rangeMinDate}
        rangeMaxDate={rangeMaxDate}
      />
    </StyledChartRangeItem2>
  );
};

QuickAllocationProjectGroupDetailRow.propTypes = {
  allocatedProject: PropTypes.object,
  projectsSlugLoading: PropTypes.bool,
  handleRemoveResourceAllocation: PropTypes.func,
  timeoffSummaryPeriods: PropTypes.array,
  holidaySummaryPeriods: PropTypes.array,
  rangeMinDate: PropTypes.object,
  rangeMaxDate: PropTypes.object,
  resourceUser: PropTypes.object,
  setSnackBarState: PropTypes.func,
  isUserScheduleDetailsLoading: PropTypes.bool,
  userScheduleDetails: PropTypes.object.isRequired
};

export default QuickAllocationProjectGroupDetailRow;
