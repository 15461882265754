import { useState, useCallback } from 'react';
import { ProjectManagementType } from '~/types';
import { useMeContext } from '~/modules/me/useMeContext';

export const useProjectTypeToggleProps = (
  defaultType = ProjectManagementType.Managed
) => {
  const {
    featureFlags: { isPsaPrpProjectLinkEnabled }
  } = useMeContext();
  const [projectType, setProjectType] = useState(defaultType);

  const onProjectTypeClick = useCallback(
    (_, newValue) => {
      if (isPsaPrpProjectLinkEnabled && !newValue) return;
      setProjectType(newValue);
    },
    [isPsaPrpProjectLinkEnabled]
  );

  return {
    projectType,
    onProjectTypeClick
  };
};
