import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import MoreResult from '~/modules/common/components/MoreResult';
import { CreatableAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import { useDialogState } from '~/modules/common/hooks/useDialogState';
import useRoles from '~/modules/common/hooks/useRoles';
import { useHasPermission } from '~/modules/common/permissions';
import {
  AddRoleDialog,
  RoleDropdownItem
} from '~/modules/resourcing/common/components/SearchableRoleDropDown';
import useTaskOwnerSearch from '../TaskOwnerDropDown/useTaskOwnerSearch';

const filter = createFilterOptions();
const getOptionLabel = o => o.displayText;
const getOptionSelected = (o, v) => o.id === v.id;

const getOptionDisabled = option => option.id === MORE_AVAILABLE_OPTION_ID;

export const RoleDropdown = ({
  label,
  value,
  onChange,
  disabled,
  projectSlug,
  variant,
  placeholder,
  className,
  disableClearable,
  onAddRoleDialogCancelButtonClick,
  classes,
  autoFocus
}) => {
  const intl = useIntl();
  const canCreateRole = useHasPermission({
    actionUri: 'urn:replicon:project-role-action:edit-project-role'
  });
  const [searchTerm, setSearchTerm] = useState('');
  const handleInputChange = useCallback((_, v) => setSearchTerm(v), []);

  const { loading: isTaskOwnersLoading, users } = useTaskOwnerSearch({
    projectSlug
  });

  const { hasMore, isLoading, defaultOptions: roles } = useRoles({
    searchTerm: value ? '' : searchTerm
  });
  const { open, closeDialog, openDialog } = useDialogState();
  const [newRoleName, setNewRoleName] = useState('');

  const openDialogAndSetInitialName = useCallback(
    name => {
      setNewRoleName(name);
      openDialog();
    },
    [openDialog, setNewRoleName]
  );

  const filterOptions = useCallback(
    (options, params) => {
      const filtered = filter(options, params);

      if (
        canCreateRole &&
        params.inputValue !== '' &&
        !options.some(o => o.displayText === params.inputValue)
      ) {
        filtered.push({
          isCreatable: true,
          id: params.inputValue,
          displayText: params.inputValue
        });
      }

      return filtered;
    },
    [canCreateRole]
  );

  const renderOption = useCallback(
    option =>
      option.id === MORE_AVAILABLE_OPTION_ID ? (
        <MoreResult message={option.displayText} />
      ) : (
        <RoleDropdownItem
          option={option}
          onCreate={openDialogAndSetInitialName}
        />
      ),
    [openDialogAndSetInitialName]
  );

  const handleRoleChange = useCallback(role => onChange(role, users), [
    users,
    onChange
  ]);

  return (
    <>
      <CreatableAutocomplete
        dataQeId="RoleDropdown"
        hasMore={hasMore}
        loading={isLoading && isTaskOwnersLoading}
        fullWidth
        canAddNewOption={canCreateRole}
        classes={classes}
        clearOnBlur
        value={value}
        onChange={handleRoleChange}
        onInputChange={handleInputChange}
        options={roles}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        filterOptions={filterOptions}
        inputLabel={label}
        renderOption={renderOption}
        disabled={disabled}
        loadingText={intl.formatMessage({ id: 'taskBeatTable.loading' })}
        getOptionDisabled={getOptionDisabled}
        optionTypeText={intl.formatMessage({
          id: 'moreOptions.roles'
        })}
        placeholder={placeholder}
        variant={variant}
        className={className}
        disableClearable={disableClearable}
        dropdownListId="RoleDropdownListBox"
        autoFocus={autoFocus}
      />
      <AddRoleDialog
        open={open}
        onClose={closeDialog}
        onChange={handleRoleChange}
        onCancel={onAddRoleDialogCancelButtonClick}
        initialName={newRoleName}
        canUserEditSkillAssignment
      />
    </>
  );
};

RoleDropdown.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  projectSlug: PropTypes.string,
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disableClearable: PropTypes.bool,
  onAddRoleDialogCancelButtonClick: PropTypes.func,
  classes: PropTypes.object,
  autoFocus: PropTypes.bool
};

export default RoleDropdown;
