import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useIntl } from 'react-intl';
import { getRTLPopperStyles } from '~/modules/common/util';

const useStyles = makeStyles(theme => ({
  tag: {
    background: theme.palette.grey[400]
  },
  loading: {
    fontSize: theme.typography.body2.fontSize
  },
  noOptions: {
    fontSize: theme.typography.body2.fontSize
  },
  option: {
    fontSize: theme.typography.body2.fontSize
  },
  input: {},
  popper: ({ curr }) => getRTLPopperStyles({ curr, theme }),
  inputRoot: {},
  paper: {
    boxShadow: theme.shadows[8]
  },
  root: {}
}));

export const MORE_AVAILABLE_OPTION_ID = 'searchAutocomplete.moreAvailable';

export const getOptionsToDisplay = ({
  options,
  intl,
  optionTypeText,
  shouldAddMoreAvailableOption
}) => {
  if (!shouldAddMoreAvailableOption) return options;

  const moreAvailableText = intl.formatMessage(
    {
      id: MORE_AVAILABLE_OPTION_ID
    },
    {
      option: optionTypeText
    }
  );

  return [
    ...options,
    {
      displayText: moreAvailableText,
      id: MORE_AVAILABLE_OPTION_ID,
      isActive: false,
      key: moreAvailableText,
      label: moreAvailableText,
      value: MORE_AVAILABLE_OPTION_ID
    }
  ];
};

export const SearchAutocomplete = ({
  value,
  onChange,
  loading = false,
  options,
  loadingText,
  fullWidth,
  getOptionSelected,
  disabled = false,
  freeSolo = false,
  getOptionLabel,
  renderInput,
  dataQeId,
  multiple = false,
  renderOption,
  renderTags,
  clearOnBlur,
  classes: classesOverride,
  optionTypeText,
  hasMore = false,
  forwardedRef,
  ariaLabel,
  dropdownListId,
  onOpen,
  onClose,
  ...rest
}) => {
  const classes = useStyles({ classes: classesOverride });
  const intl = useIntl();

  const shouldAddMoreAvailableOption =
    !loading && hasMore && optionTypeText && options && options.length;

  const optionsToDisplay = getOptionsToDisplay({
    options,
    shouldAddMoreAvailableOption,
    intl,
    optionTypeText
  });

  return (
    <Autocomplete
      onOpen={onOpen}
      onClose={onClose}
      renderTags={renderTags}
      disabled={disabled}
      classes={classes}
      data-qe-id={dataQeId || null}
      loading={loading}
      ref={forwardedRef}
      options={optionsToDisplay}
      fullWidth={fullWidth}
      renderOption={renderOption}
      freeSolo={freeSolo}
      clearOnBlur={clearOnBlur}
      loadingText={loadingText}
      multiple={multiple}
      forcePopupIcon
      value={value}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      renderInput={renderInput}
      getOptionSelected={getOptionSelected}
      aria-label={ariaLabel}
      ListboxProps={{ id: dropdownListId }}
      aria-controls={dropdownListId}
      aria-owns={dropdownListId}
      {...rest}
    />
  );
};

SearchAutocomplete.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  options: PropTypes.array.isRequired,
  freeSolo: PropTypes.bool,
  getOptionLabel: PropTypes.func.isRequired,
  renderInput: PropTypes.func.isRequired,
  dataQeId: PropTypes.string,
  getOptionSelected: PropTypes.func,
  multiple: PropTypes.bool,
  classes: PropTypes.object,
  loadingText: PropTypes.string,
  renderOption: PropTypes.func,
  disabled: PropTypes.bool,
  renderTags: PropTypes.func,
  fullWidth: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  optionTypeText: PropTypes.node,
  hasMore: PropTypes.bool,
  ariaLabel: PropTypes.string,
  forwardedRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  dropdownListId: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func
};

export default SearchAutocomplete;
