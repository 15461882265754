import { roundToDecimals } from './scheduleUtil';

export const convertToComparableValue = ({
  hours,
  scheduledHours,
  isHoursMode
}) => {
  const value = isHoursMode ? hours : (hours * 100) / scheduledHours;

  return roundToDecimals(value);
};

export const compareEditorValue = ({
  isHoursMode,
  initialAllocatedHours,
  scheduledHours,
  inputValue
}) => {
  const previousValue = convertToComparableValue({
    isHoursMode,
    hours: initialAllocatedHours,
    scheduledHours
  });
  const currentValue = inputValue;

  return roundToDecimals(previousValue) === roundToDecimals(currentValue);
};

export const calculateProjectAllocationHours = ({
  initialAllocatedHours,
  initialProjectAllocation,
  initialTaskAllocationSum
}) => ({ updatedAllocatedHours }) => {
  const allocatedHoursDelta = updatedAllocatedHours - initialAllocatedHours;

  if (
    allocatedHoursDelta > 0 &&
    initialProjectAllocation > initialTaskAllocationSum
  ) {
    return Math.max(
      0,
      initialTaskAllocationSum + allocatedHoursDelta - initialProjectAllocation
    );
  }

  if (
    allocatedHoursDelta < 0 &&
    initialProjectAllocation < initialTaskAllocationSum
  ) {
    return Math.min(
      0,
      initialTaskAllocationSum + allocatedHoursDelta - initialProjectAllocation
    );
  }

  return allocatedHoursDelta;
};

export const getRemainingAndAvailableHours = ({
  scheduledHours,
  otherProjectAllocationHours,
  initialAllocatedHours,
  initialProjectAllocation,
  initialTaskAllocationSum,
  initialRemainingHours,
  isRmpTaskAllocationPhase2Enabled
}) => ({ updatedAllocatedHours }) => {
  const updatedProjectAllocation = isRmpTaskAllocationPhase2Enabled
    ? calculateProjectAllocationHours({
        initialAllocatedHours,
        initialProjectAllocation,
        initialTaskAllocationSum
      })({ updatedAllocatedHours })
    : 0;

  return {
    availableHours: isRmpTaskAllocationPhase2Enabled
      ? scheduledHours -
        otherProjectAllocationHours -
        Math.max(0, initialProjectAllocation + updatedProjectAllocation)
      : 0,
    remainingHours: initialRemainingHours - updatedAllocatedHours
  };
};

export const getRemainingAndAvailableComparableValues = ({
  scheduledHours,
  otherProjectAllocationHours,
  initialAllocatedHours,
  initialProjectAllocation,
  initialTaskAllocationSum,
  remainingHours: initialRemainingHours,
  isRmpTaskAllocationPhase2Enabled
}) => ({ updatedAllocatedHours, isHoursMode }) => {
  const { availableHours, remainingHours } = getRemainingAndAvailableHours({
    scheduledHours,
    otherProjectAllocationHours,
    initialAllocatedHours,
    initialProjectAllocation,
    initialTaskAllocationSum,
    initialRemainingHours,
    isRmpTaskAllocationPhase2Enabled
  })({ updatedAllocatedHours });

  return {
    ...(isRmpTaskAllocationPhase2Enabled && {
      availableValue: convertToComparableValue({
        hours: availableHours,
        isHoursMode,
        scheduledHours
      })
    }),
    remainingValue: convertToComparableValue({
      hours: remainingHours,
      isHoursMode,
      scheduledHours
    })
  };
};
