import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { FormattedMessage } from 'react-intl';

const AddTaskDialogErrors = ({ errorTypes, onClose }) => {
  return (
    <>
      <DialogTitle>
        <FormattedMessage id="projectTasksPage.addTasksWarningDialogTitle" />
      </DialogTitle>
      <DialogContent>
        <Alert icon={false} severity="warning">
          <b>
            <FormattedMessage id="projectTasksPage.addTasksWarningDialogMessage" />
          </b>
          <ul>
            {errorTypes.map(errorType => (
              <li key={errorType}>
                <FormattedMessage id={`projectTasksPage.${errorType}`} />
              </li>
            ))}
          </ul>
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          <FormattedMessage id="button.ok" />
        </Button>
      </DialogActions>
    </>
  );
};

AddTaskDialogErrors.propTypes = {
  errorTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired
};

export default AddTaskDialogErrors;
