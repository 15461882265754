/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import LegendItem from './LegendItem';
import LegendsPopup from './LegendsPopup';
import useStyles from './useStyles';

export const ResourcingLegends = memo(
  ({ classes: overriddenClasses, legends, showNewLegends = false }) => {
    const classes = useStyles({
      classes: overriddenClasses
    });

    return (
      <div className={classes.root}>
        {showNewLegends ? (
          <>
            <LegendsPopup legends={legends} />
            <Typography
              className={classes.legendLabel}
              variant="body2"
              component="div"
            >
              <FormattedMessage id="resourcingLegends.legend" />
            </Typography>
          </>
        ) : (
          <>
            {legends.map((legend, index) => (
              <LegendItem key={index} classes={classes} {...legend} />
            ))}
          </>
        )}
      </div>
    );
  }
);

ResourcingLegends.propTypes = {
  legends: PropTypes.arrayOf(
    PropTypes.shape({
      isProgressBarLegend: PropTypes.bool,
      barType: PropTypes.oneOf(['dashedBar', 'roundBar']),
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
      className: PropTypes.string,
      color: PropTypes.string
    }).isRequired
  ).isRequired,
  showNewLegends: PropTypes.bool,
  classes: PropTypes.object
};

export default ResourcingLegends;
