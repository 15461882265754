import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useLoadMore } from '~/modules/common/hooks/useLoadMoreResults';

export const RESOURCE_USERS_AVAILABILITY_QUERY = gql`
  query Eager_GetResourceUserAvailability(
    $page: Int!
    $pageSize: Int!
    $dateRange: DateRangeInput!
    $periodResolution: PeriodResolutionOption!
    $sort: ResourceUserAvailabilitySort!
    $groupBy: ResourceUserAvailabilityGroupType!
    $filter: ResourceUserAvailabilitySeriesFilter
  ) {
    resourceUserAvailabilitySeries(
      page: $page
      pageSize: $pageSize
      dateRange: $dateRange
      periodResolution: $periodResolution
      sort: $sort
      groupBy: $groupBy
      filter: $filter
    ) {
      id
      role {
        uri
        displayText
      }
      location {
        uri
        displayText
        fullPath
      }
      division {
        uri
        displayText
        fullPath
      }
      serviceCenter {
        uri
        displayText
        fullPath
      }
      costCenter {
        uri
        displayText
        fullPath
      }
      department {
        uri
        displayText
        fullPath
      }
      employeeType {
        uri
        displayText
        fullPath
      }
      count
      periods {
        dateRange {
          startDate: startDate2
          endDate: endDate2
        }
        availableDuration {
          hours
          minutes
          seconds
        }
        scheduledDuration {
          hours
          minutes
          seconds
        }
        timeOffDuration {
          hours
          minutes
          seconds
        }
        holidayDuration {
          hours
          minutes
          seconds
        }
        overAllocatedDuration {
          hours
          minutes
          seconds
        }
        allocatedDuration {
          hours
          minutes
          seconds
        }
      }
    }
  }
`;

const DEFAULT_PAGE_SIZE = 20;

export const hasAction = permissions => actionUri =>
  permissions.includes(actionUri);

const useResourceUserAvailability = ({
  groupBy,
  dateRange,
  periodResolution,
  sort,
  filter,
  page = 1
}) => {
  const variables = {
    page,
    pageSize: DEFAULT_PAGE_SIZE,
    dateRange,
    periodResolution,
    sort,
    groupBy,
    filter
  };

  const { loading, error, data, fetchMore, networkStatus } = useQuery(
    RESOURCE_USERS_AVAILABILITY_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables
    }
  );

  const resourceUserAvailabilitySeries =
    data && data.resourceUserAvailabilitySeries;

  const { loadMore, hasMore, loadingMore } = useLoadMore({
    variables: { pageSize: DEFAULT_PAGE_SIZE },
    fetchMore,
    fetchMoreResultPath: 'resourceUserAvailabilitySeries',
    loading,
    networkStatus,
    initialHasMore:
      resourceUserAvailabilitySeries &&
      resourceUserAvailabilitySeries.length > 0 &&
      resourceUserAvailabilitySeries.length % DEFAULT_PAGE_SIZE === 0
  });

  return {
    loadingRows: loading,
    loadingMore,
    resourceUserAvailability: resourceUserAvailabilitySeries || [],
    error,
    loadMoreRows: loadMore,
    hasMoreRows: hasMore
  };
};

export default useResourceUserAvailability;
