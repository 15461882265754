import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import UserWithAvailability from '~/modules/common/components/User/UserWithAvailability';
import MoreResult from '~/modules/common/components/MoreResult';
import { NoDataItem, User } from '~/modules/common/components';
import { useMeContext } from '~/modules/me/useMeContext';
import { USER_DAL, USER_PERMISSION } from '~/modules/common/enums';
import { hasPermission } from '~/modules/common/permissions';
import { roundToDecimals } from '~/modules/resourcing/common/util/scheduleUtil';
import { useDropdownOptions } from './useDropdownOptions';
import { ResourceUsersAvailabilityDropdownItem } from './ResourceUsersAvailabilityDropdownItem';
import {
  useTabStyles,
  NoDataItemStyles,
  useUserStyles,
  useInputStyles,
  useUserColumnStyles,
  useDropdownItemStyles,
  useAvailabilityColumnStyles,
  useMoreOptionsStyles,
  useUserWithRoleColumnStyles
} from './useStyles';
import { useColumnSettings } from './hooks/useColumnSettings';
import ResourceUsersDropdownTabs from './ResourceUsersDropdownTabs';

export const tabs = {
  ALL_RESOURCES: 'ALL_RESOURCES',
  PROJECT_RESOURCES: 'PROJECT_RESOURCES'
};

export const TAB_ID = 'resourceUsersDropdown.tabId';
export const NO_OPTION_ID = 'resourceUsersDropdown.noOption';

export const filterOptions = (options, { inputValue, loading }) => {
  const filteredOptions = options.filter(option => {
    const lowercasedInput = inputValue.toLowerCase();

    return option.displayText.toLowerCase().includes(lowercasedInput);
  });

  if (!loading && filteredOptions.length === 0) {
    filteredOptions.push({
      id: NO_OPTION_ID,
      displayText: NO_OPTION_ID,
      filter: false
    });
  }

  return [{ id: TAB_ID, displayText: '', filter: false }, ...filteredOptions];
};

export const getOptionLabel = option => option.displayText;
export const groupBy = option => option.group;

export const getOptionSelected = (option, selected) =>
  option.displayText === selected.displayText;

export const renderOption = ({
  option,
  selectedTab,
  tabClasses,
  NoDataItemClasses,
  getFormattedMessage,
  handleTabChange,
  isAvailabilityColumnEnabled,
  isProjectRoleEnabled,
  formatMessage,
  userColumnClasses,
  initialEstimatedHours,
  availabilityColumnClasses,
  moreOptionsClasses,
  userWithRolesColumnClasses
}) => {
  if (option.id === TAB_ID) {
    return (
      <ResourceUsersDropdownTabs
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
        tabClasses={tabClasses}
      />
    );
  }

  if (option.id === NO_OPTION_ID) {
    return (
      <NoDataItem fontStyle="normal" classes={NoDataItemClasses}>
        <FormattedMessage id={getFormattedMessage()} />
      </NoDataItem>
    );
  }

  if (option.id === MORE_AVAILABLE_OPTION_ID) {
    return (
      <MoreResult message={option.displayText} classes={moreOptionsClasses} />
    );
  }

  const isAvailabilityNotSufficient =
    initialEstimatedHours &&
    roundToDecimals(option?.availableDuration) <
      roundToDecimals(initialEstimatedHours);

  return (
    <UserWithAvailability
      user={option}
      classes={
        isProjectRoleEnabled ? userWithRolesColumnClasses : userColumnClasses
      }
      availabilityClassName={classNames(availabilityColumnClasses.root, {
        [availabilityColumnClasses.insufficientAvailability]: isAvailabilityNotSufficient
      })}
      isAvailabilityEnabled={isAvailabilityColumnEnabled}
      isProjectRoleEnabled={isProjectRoleEnabled}
      hoursSuffix={formatMessage({
        id: 'taskResourceEstimates.hoursSuffix'
      })}
      variant="hours"
    />
  );
};

export const ResourceUsersDropdown = ({
  autoFocus,
  projectId,
  assignedRole,
  classes: classesOverride,
  onResourceChange,
  value,
  selectedResources,
  placeholder,
  variant = 'outlined',
  ariaLabel,
  label,
  taskId,
  disabled,
  startDate,
  endDate,
  initialEstimatedHours,
  hideAvailability,
  hasError,
  helperText
}) => {
  const tabClasses = useTabStyles();
  const inputClasses = useInputStyles({ classes: classesOverride });
  const NoDataItemClasses = NoDataItemStyles();
  const dropDownItemClasses = useDropdownItemStyles();
  const userClasses = useUserStyles();
  const userWithRolesColumnClasses = useUserWithRoleColumnStyles();
  const userColumnClasses = useUserColumnStyles();
  const moreOptionsClasses = useMoreOptionsStyles();
  const availabilityColumnClasses = useAvailabilityColumnStyles();
  const [open, setOpen] = useState(false);

  const { formatMessage } = useIntl();

  const me = useMeContext();
  const { permissionsMap } = me;

  const isProjectRoleEnabled = Boolean(
    permissionsMap['urn:replicon:user-action:view-project-role']
  );

  const hasPermissionWithMap = hasPermission(permissionsMap);

  const hasViewAvailabilityPermission =
    hasPermissionWithMap({
      actionUri: USER_PERMISSION.VIEW_AVAILABILITY,
      dataAccessLevelUri: USER_DAL.RESOURCE_MANAGER
    }) ||
    hasPermissionWithMap({
      actionUri: USER_PERMISSION.VIEW_AVAILABILITY,
      dataAccessLevelUri: USER_DAL.PROJECT_MANAGER
    });

  const {
    isAvailabilityColumnEnabled,
    sort,
    toggleAvailabilityColumnVisibility,
    onSortChange
  } = useColumnSettings({
    initialValue: !hideAvailability && hasViewAvailabilityPermission
  });

  const {
    handleTabChange,
    handleInputChange,
    users,
    hasMore,
    getOptionDisabled,
    resourceUsers,
    selectedTab,
    getFormattedMessage,
    handleOnChange,
    loading,
    searchTerm
  } = useDropdownOptions({
    projectId,
    assignedRole,
    onResourceChange,
    selectedResources,
    taskId,
    isAvailabilityColumnEnabled,
    isProjectRoleEnabled,
    startDate,
    endDate,
    sort,
    open
  });

  const options = useMemo(() => {
    const resources =
      selectedTab === tabs.ALL_RESOURCES ? resourceUsers : users;

    return resources;
  }, [resourceUsers, selectedTab, users]);

  const renderOptionWithParams = useCallback(
    option =>
      renderOption({
        option,
        selectedTab,
        tabClasses,
        NoDataItemClasses,
        getFormattedMessage,
        handleTabChange,
        isAvailabilityColumnEnabled,
        isProjectRoleEnabled,
        formatMessage,
        userColumnClasses,
        initialEstimatedHours,
        availabilityColumnClasses,
        moreOptionsClasses,
        userWithRolesColumnClasses
      }),
    [
      selectedTab,
      tabClasses,
      NoDataItemClasses,
      getFormattedMessage,
      handleTabChange,
      isAvailabilityColumnEnabled,
      isProjectRoleEnabled,
      formatMessage,
      userColumnClasses,
      initialEstimatedHours,
      availabilityColumnClasses,
      moreOptionsClasses,
      userWithRolesColumnClasses
    ]
  );

  const renderGroup = useCallback(
    params => (
      <ResourceUsersAvailabilityDropdownItem
        params={params}
        key={params.key}
        classes={dropDownItemClasses}
        sort={sort}
        onSortChange={onSortChange}
        isAvailabilityEnabled={isAvailabilityColumnEnabled}
        loading={loading}
        selectedTab={selectedTab}
        toggleAvailabilityColumnVisibility={toggleAvailabilityColumnVisibility}
        hideAvailability={hideAvailability || !hasViewAvailabilityPermission}
        assignedRole={assignedRole}
        isProjectRoleEnabled={isProjectRoleEnabled}
        tabClasses={tabClasses}
        handleTabChange={handleTabChange}
      />
    ),
    [
      dropDownItemClasses,
      sort,
      onSortChange,
      isAvailabilityColumnEnabled,
      loading,
      selectedTab,
      isProjectRoleEnabled,
      toggleAvailabilityColumnVisibility,
      hideAvailability,
      hasViewAvailabilityPermission,
      assignedRole,
      tabClasses,
      handleTabChange
    ]
  );

  const renderTags = useCallback(
    (values, getTagProps) =>
      values.map((option, index) => (
        <User
          classes={userClasses}
          user={option}
          disablePadding
          key={option.displayText}
          rootProps={getTagProps({ index })}
        />
      )),
    [userClasses]
  );

  const dropdownValue = useMemo(() => (value ? [value] : []), [value]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <SimpleAutocomplete
      autoFocus={autoFocus}
      isDropdownListOpen={open}
      clearOnBlur
      size="small"
      fullWidth
      multiple
      value={dropdownValue}
      options={options}
      onOpen={onOpen}
      variant={variant}
      onClose={onClose}
      renderGroup={renderGroup}
      placeholder={value ? '' : placeholder}
      filterOptions={({ inputValue }) =>
        filterOptions(options, { inputValue: searchTerm, loading })
      }
      groupBy={groupBy}
      classes={inputClasses}
      renderTags={renderTags}
      onChange={handleOnChange}
      getOptionLabel={getOptionLabel}
      onInputChange={handleInputChange}
      getOptionDisabled={getOptionDisabled}
      getOptionSelected={getOptionSelected}
      renderOption={renderOptionWithParams}
      hasMore={hasMore}
      disabled={disabled}
      ariaLabel={ariaLabel}
      inputLabel={label}
      dropdownListId="ResourceDropdownListBox"
      hasError={hasError}
      helperText={helperText}
    />
  );
};
ResourceUsersDropdown.propTypes = {
  autoFocus: PropTypes.bool,
  onResourceChange: PropTypes.func.isRequired,
  assignedRole: PropTypes.object,
  classes: PropTypes.object,
  value: PropTypes.object,
  selectedResources: PropTypes.array,
  projectId: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  ariaLabel: PropTypes.string,
  label: PropTypes.string,
  taskId: PropTypes.string,
  disabled: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  initialEstimatedHours: PropTypes.number,
  hideAvailability: PropTypes.bool,
  hasError: PropTypes.bool,
  helperText: PropTypes.string
};
export default ResourceUsersDropdown;
