import flow from 'lodash/fp/flow';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import { useResourcingDragIndicators } from '~/modules/resourcing/hooks';
import { getAvailabilityPeriodsFromDates } from '../../../common/components/TaskDrawer/common/hooks/taskAllocationSaveUtil';
import {
  isoStringToObject,
  mapIsoStringtoUtcObject,
  mapRepliconDateToUtcObject
} from '../../../common/dates/convert';
import { hasOverlap, truncateRuleBoundaries } from '../util';
import { getScheduleRulesFromAllocationPeriods } from '../../../common/hooks/resourcing/scheduleUtil.merge';
import { getDragIndicatorsInChart } from './useResourceAllocationDragIndicators';

export const calculateExtendedTaskAllocationScheduleRules = ({
  startDate,
  endDate,
  taskAllocation: {
    startDate: allocationStartDate,
    endDate: allocationEndDate,
    scheduleRules
  },
  resourceAllocationScheduleRules,
  userTaskAllocationsSummaryScheduleRules,
  isLeftExpanded,
  isRmpTaskAllocationPhase2Enabled
}) => {
  const {
    availabilityPeriods: extendedAllocationPeriods
  } = getAvailabilityPeriodsFromDates({
    startDate: isoStringToObject(
      isLeftExpanded
        ? startDate.toISO()
        : mapIsoStringtoUtcObject(allocationEndDate)
            .plus({ days: 1 })
            .toISO()
    ),
    endDate: isoStringToObject(
      isLeftExpanded
        ? mapIsoStringtoUtcObject(allocationStartDate)
            .minus({ days: 1 })
            .toISO()
        : endDate.toISO()
    ),
    resourceAllocationScheduleRules,
    otherTaskAllocationsSummaryScheduleRules:
      userTaskAllocationsSummaryScheduleRules || []
  });

  const extendedAllocationScheduleRules = getScheduleRulesFromAllocationPeriods(
    extendedAllocationPeriods
  ).filter(
    rule =>
      isRmpTaskAllocationPhase2Enabled ||
      (!isRmpTaskAllocationPhase2Enabled && rule.do.setHours > 0)
  );

  return isLeftExpanded
    ? [...extendedAllocationScheduleRules, ...scheduleRules]
    : [...scheduleRules, ...extendedAllocationScheduleRules];
};

export const calculateCollapsedTaskAllocationScheduleRules = ({
  startDate,
  endDate,
  scheduleRules
}) => {
  const start = mapRepliconDateToUtcObject(startDate);
  const end = mapRepliconDateToUtcObject(endDate);

  return flow(
    filter(hasOverlap({ start, end })),

    map(truncateRuleBoundaries({ start, end }))
  )(scheduleRules);
};

export const calculateTaskAllocationScheduleRulesOnDrag = ({
  startDate: dragStartDate,
  endDate: dragEndDate,
  taskAllocation,
  resourceAllocationScheduleRules,
  userTaskAllocationsSummaryScheduleRules,
  isRmpTaskAllocationPhase2Enabled
}) => {
  const {
    startDate: allocationStartDate,
    endDate: allocationEndDate,
    scheduleRules
  } = taskAllocation;

  const isLeftExpanded =
    dragStartDate < mapIsoStringtoUtcObject(allocationStartDate);

  const isRightExpanded =
    dragEndDate > mapIsoStringtoUtcObject(allocationEndDate);

  return isRightExpanded || isLeftExpanded
    ? calculateExtendedTaskAllocationScheduleRules({
        startDate: dragStartDate,
        endDate: dragEndDate,
        taskAllocation,
        isLeftExpanded,
        resourceAllocationScheduleRules,
        userTaskAllocationsSummaryScheduleRules,
        useTaskAllocationDragIndicators,
        isRmpTaskAllocationPhase2Enabled
      })
    : calculateCollapsedTaskAllocationScheduleRules({
        startDate: dragStartDate,
        endDate: dragEndDate,
        scheduleRules
      });
};

export const taskAllocationUpdateHandler = onAllocationChange => ({
  entity: taskAllocation,
  estimatedDate
}) => {
  const { startDate, endDate } = estimatedDate;

  onAllocationChange({
    startDate,
    endDate,
    taskAllocation
  });
};

export const useTaskAllocationDragIndicators = ({
  taskAllocation,
  chartStartDate,
  scale,
  onAllocationChange,
  chartDisplayDateRange
}) => {
  const dragIndicatorsInChart = getDragIndicatorsInChart({
    chartDisplayDateRange,
    resourceAllocation: taskAllocation
  });

  const {
    gestureBindEvents,
    dragDelta,
    currentResizeDirection,
    dates
  } = useResourcingDragIndicators({
    entity: taskAllocation,
    chartStartDate,
    scale,
    entityUpdateHandler: taskAllocationUpdateHandler(onAllocationChange)
  });

  return {
    gestureBindEvents,
    dragDelta,
    currentResizeDirection,
    dragIndicatorsInChart,
    dates
  };
};

export default useTaskAllocationDragIndicators;
