import React, { useMemo, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { useIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import { MoneyValue } from '~/modules/common/components';
import { TaskStatus } from '~/types';
import { CHILD_INFO_DIALOG_COLUMN_TYPE } from '~/modules/common/enums';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import {
  useDialogState,
  useMenuState,
  useIsBreakpointOnly
} from '~/modules/common/hooks';
import {
  TaskVariationPill,
  TaskVariationDatePill
} from '~/modules/common/components/TaskVariationPill';
import { useStyles as usePillStyles } from '~/modules/common/components/TaskVariationPill/hooks';
import { useMeContext } from '~/modules/me';
import NotStartedVariationValue from '../NotStartedVariationValue';
import useCalculateVariationDetails from './useCalculateVariationDetails';
import NoDataCell from './components/NoDataCell';
import ChildInfoDialog from './components/ChildInfoDialog/ChildInfoDialog';
import HoursVariationCell from './components/HoursVariationCell';

const useStyles = makeStyles({
  row: {
    display: 'contents'
  },
  cell: {
    display: 'flex',
    padding: theme.spacing(0, 2),
    whiteSpace: 'nowrap',
    paddingTop: theme.spacing(2)
  },
  labelCell: ({ isXsBreakPoint }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    fontSize: isXsBreakPoint
      ? theme.spacing(1.5)
      : theme.typography.body2.fontSize,
    padding: theme.spacing(2, 1, 0, 2),
    whiteSpace: 'nowrap'
  }),
  pillsContainer: {
    display: 'contents'
  },
  costCell: ({
    isVariationCostInBold,
    isCostVariationPillNotDisplayed,
    isLeafTask,
    isCostTooltipVisible,
    isProjectVariant
  }) => ({
    textAlign: 'right',
    justifyContent: 'flex-end',
    fontWeight: isVariationCostInBold
      ? theme.typography.fontWeightBold
      : theme.typography.body2.fontWeight,
    alignSelf: isCostVariationPillNotDisplayed ? 'baseline' : 'self-end',
    justifySelf: 'flex-end',
    paddingRight:
      (isCostVariationPillNotDisplayed &&
        (!isLeafTask || isCostTooltipVisible)) ||
      (isProjectVariant &&
        !isCostVariationPillNotDisplayed &&
        isCostTooltipVisible)
        ? theme.spacing(3.75)
        : 0,
    paddingLeft: theme.spacing(4)
  }),
  data: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    fontWeight: 'inherit'
  },
  datesCell: ({ shouldNotRenderVariancePill, showCost }) => ({
    paddingLeft: showCost
      ? shouldNotRenderVariancePill
        ? theme.spacing(5)
        : theme.spacing(4)
      : theme.spacing(12.5),
    alignSelf: shouldNotRenderVariancePill ? 'baseline' : 'self-end'
  }),
  variationPillContainer: {
    display: 'flex'
  },
  icon: {
    cursor: 'pointer',
    marginTop: '1px',
    marginLeft: theme.spacing(1.75),
    color: 'rgba(0,0,0,0.38)',
    fontSize: theme.spacing(2.75)
  }
});

const usePillStylesOverride = makeStyles({
  variationPill: {
    padding: theme.spacing(0.375, 1, 0.125),
    borderRadius: theme.spacing(0.5)
  },
  variationText: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize
  },
  variationValue: ({ isVariationInBold }) => ({
    fontSize: theme.typography.body2.fontSize,
    fontWeight: isVariationInBold
      ? theme.typography.fontWeightBold
      : theme.typography.body2.fontWeight
  }),
  variationLabel: {
    display: 'none'
  }
});

export const TaskVariationRow = ({
  classes: classesOverride,
  hasMore,
  hideCostTooltip = false,
  hideHoursTooltip = false,
  hideDatesTooltip = false,
  isCostTooltipVisible,
  atleastOneHoursRowHasTooltip,
  isProjectVariant,
  isPsaPpmEstimatedCostAtCompletionEnabled,
  loadMore,
  loadingMore,
  showCost,
  task
}) => {
  const {
    featureFlags: {
      isPsaPpmCostEacEnhancementsEnabled,
      isPsaPpmCostEacAlternateHoursEacEnabled
    }
  } = useMeContext();
  const intl = useIntl();
  const isXsBreakPoint = useIsBreakpointOnly('xs');
  const {
    open: openDates,
    openDialog: openDialogDates,
    closeDialog: closeDialogDates
  } = useDialogState();
  const {
    open: openCost,
    openDialog: openDialogCost,
    closeDialog: closeDialogCost
  } = useDialogState();
  const { anchorEl, onMenuClick, onMenuClose } = useMenuState();

  const {
    variationDays,
    variationCost: taskVariationCost,
    costVariationStatus: taskCostVariationStatus,
    projectVariationCost,
    projectCostVariationStatus
  } = useCalculateVariationDetails({ task });

  const { variationCost, costVariationStatus } = useMemo(() => {
    if (isProjectVariant) {
      return {
        variationCost: projectVariationCost,
        costVariationStatus: projectCostVariationStatus
      };
    }

    return {
      variationCost: taskVariationCost,
      costVariationStatus: taskCostVariationStatus
    };
  }, [
    isProjectVariant,
    projectCostVariationStatus,
    projectVariationCost,
    taskCostVariationStatus,
    taskVariationCost
  ]);

  const isLeafTask = task.children.length === 0;
  const isNotStarted = task.taskStatus === TaskStatus.Notstarted;
  const shouldNotRenderVariancePill = variationDays === null;

  const isCostVariationPillNotDisplayed =
    (isNotStarted && isLeafTask) || variationCost == null;

  const pillCostClassesOverride = usePillStylesOverride({
    isVariationInBold: variationCost?.amount > 0 || variationCost?.amount < 0
  });
  const pillCostClasses = usePillStyles({
    classes: pillCostClassesOverride,
    isPsaPpmCostEacEnhancementsEnabled
  });

  const { hoursCell, ...variationClassesOverride } = classesOverride || {};
  const hoursClasses = useMemo(
    () => ({
      hoursCell
    }),
    [hoursCell]
  );

  const classes = useStyles({
    classes: variationClassesOverride,
    isNotStartedChild: isNotStarted && isLeafTask,
    shouldNotRenderVariancePill,
    isCostVariationPillNotDisplayed,
    isPsaPpmEstimatedCostAtCompletionEnabled,
    isXsBreakPoint,
    showCost,
    isLeafTask,
    isCostTooltipVisible,
    isVariationCostInBold:
      variationCost?.amount > 0 || variationCost?.amount < 0,
    isProjectVariant
  });

  const iconClasses = useMemo(() => ({ icon: classes.icon }), [classes]);

  const datesHandleClick = useCallback(
    event => {
      onMenuClick(event);
      openDialogDates();
    },
    [onMenuClick, openDialogDates]
  );

  const datesHandleClosePopover = useCallback(() => {
    onMenuClose();
    closeDialogDates();
  }, [onMenuClose, closeDialogDates]);

  const costHandleClick = useCallback(
    event => {
      onMenuClick(event);
      openDialogCost();
    },
    [onMenuClick, openDialogCost]
  );

  const costHandleClosePopover = useCallback(() => {
    onMenuClose();
    closeDialogCost();
  }, [onMenuClose, closeDialogCost]);

  return (
    <div className={classes.row} role="row">
      <div className={classes.labelCell} role="rowheader">
        <FormattedMessage id="taskDrawer.variation" />:
      </div>
      <div className={classes.pillsContainer}>
        <HoursVariationCell
          classes={hoursClasses}
          hasMore={hasMore}
          loadMore={loadMore}
          loadingMore={loadingMore}
          task={task}
          atleastOneRowHasTooltip={atleastOneHoursRowHasTooltip}
          useWideVariant={!showCost}
          isPsaPpmEstimatedCostAtCompletionEnabled={
            isPsaPpmEstimatedCostAtCompletionEnabled
          }
          isPsaPpmCostEacEnhancementsEnabled={
            isPsaPpmCostEacEnhancementsEnabled
          }
          isPsaPpmCostEacAlternateHoursEacEnabled={
            isPsaPpmCostEacAlternateHoursEacEnabled
          }
          hideTooltip={hideHoursTooltip}
        />
        {showCost && (
          <div
            className={classNames(classes.cell, classes.costCell)}
            role="cell"
          >
            {variationCost === null ? (
              <NoDataCell />
            ) : isNotStarted && isLeafTask ? (
              isPsaPpmCostEacEnhancementsEnabled ? (
                <NotStartedVariationValue
                  className={classes.data}
                  variant="cost"
                  variation={variationCost}
                  variationStatus={costVariationStatus}
                />
              ) : (
                <MoneyValue className={classes.data} money={variationCost} />
              )
            ) : (
              <div className={classes.variationPillContainer}>
                <TaskVariationPill
                  intl={intl}
                  classes={pillCostClasses}
                  variationStatus={costVariationStatus}
                  value={variationCost}
                  displayType="text"
                  variant="cost"
                  hideStatusMessage
                />
                {!hideCostTooltip && !isLeafTask && (
                  <>
                    <InfoSharpIcon
                      className={classes.icon}
                      onClick={costHandleClick}
                    />
                    <ChildInfoDialog
                      childTasks={task.children}
                      classes={iconClasses}
                      selectedColumns={[
                        CHILD_INFO_DIALOG_COLUMN_TYPE.TASK_NAME,
                        CHILD_INFO_DIALOG_COLUMN_TYPE.COST_VARIATION
                      ]}
                      open={openCost}
                      onClose={costHandleClosePopover}
                      anchorEl={anchorEl}
                      hasMore={hasMore}
                      loadMore={loadMore}
                      loadingMore={loadingMore}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        )}
        <div
          className={classNames(classes.cell, classes.datesCell)}
          role="cell"
        >
          {shouldNotRenderVariancePill ? (
            <NoDataCell />
          ) : (
            <div className={classes.variationPillContainer}>
              <TaskVariationDatePill
                value={variationDays}
                isPsaPpmCostEacEnhancementsEnabled={
                  isPsaPpmCostEacEnhancementsEnabled
                }
              />
              {!hideDatesTooltip && !isLeafTask && (
                <>
                  <InfoSharpIcon
                    className={classes.icon}
                    onClick={datesHandleClick}
                  />
                  <ChildInfoDialog
                    childTasks={task.children}
                    classes={iconClasses}
                    selectedColumns={[
                      CHILD_INFO_DIALOG_COLUMN_TYPE.TASK_NAME,
                      CHILD_INFO_DIALOG_COLUMN_TYPE.DATE_VARIATION
                    ]}
                    open={openDates}
                    onClose={datesHandleClosePopover}
                    anchorEl={anchorEl}
                    hasMore={hasMore}
                    loadMore={loadMore}
                    loadingMore={loadingMore}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

TaskVariationRow.propTypes = {
  task: PropTypes.object.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  loadingMore: PropTypes.bool.isRequired,
  classes: PropTypes.object,
  hideCostTooltip: PropTypes.bool,
  hideHoursTooltip: PropTypes.bool,
  hideDatesTooltip: PropTypes.bool,
  showCost: PropTypes.bool,
  isPsaPpmEstimatedCostAtCompletionEnabled: PropTypes.bool,
  isProjectVariant: PropTypes.bool,
  atleastOneHoursRowHasTooltip: PropTypes.bool,
  isCostTooltipVisible: PropTypes.bool
};

export default TaskVariationRow;
