import { useMeContext } from '~/modules/me/useMeContext';

const getSupportedSettings = ({ section = null }) => {
  const {
    featureFlags: { isPsaPrp2024q4BugFixesEnabled },
    permissionsMap
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useMeContext();
  const canViewClient = Boolean(
    permissionsMap['urn:replicon:client-action:view-client'] ||
      permissionsMap['urn:replicon:project-action:view-client']
  );

  const canViewProgram = Boolean(
    permissionsMap['urn:replicon:program-action:view-program'] ||
      permissionsMap['urn:replicon:project-action:view-program']
  );

  const canViewProjectTasks = Boolean(
    permissionsMap['urn:replicon:project-action:view-tasks']
  );

  const canViewProjectTeam = Boolean(
    permissionsMap['urn:replicon:project-action:view-team']
  );

  const canViewProject = Boolean(
    permissionsMap['urn:replicon:project-action:view-project']
  );

  const canViewCostData = isPsaPrp2024q4BugFixesEnabled
    ? Boolean(
        {
          client: permissionsMap['urn:replicon:client-action:view-cost-data'],
          project: permissionsMap['urn:replicon:project-action:view-cost-data'],
          program: permissionsMap['urn:replicon:program-action:view-cost-data']
        }[section]
      )
    : Boolean(
        permissionsMap['urn:replicon:project-action:view-cost-data'] ||
          permissionsMap['urn:replicon:client-action:view-cost-data'] ||
          permissionsMap['urn:replicon:program-action:view-cost-data']
      );

  const canViewBillingData = isPsaPrp2024q4BugFixesEnabled
    ? Boolean(
        {
          client:
            permissionsMap['urn:replicon:client-action:view-billing-data'],
          project:
            permissionsMap['urn:replicon:project-action:view-billing-data'],
          program:
            permissionsMap['urn:replicon:program-action:view-billing-data']
        }[section]
      )
    : Boolean(
        permissionsMap['urn:replicon:project-action:view-billing-data'] ||
          permissionsMap['urn:replicon:client-action:view-billing-data'] ||
          permissionsMap['urn:replicon:program-action:view-billing-data']
      );

  const canViewExpenseCode = Boolean(
    permissionsMap['urn:replicon:combined-actuals-action:view-expense']
  );

  const canViewClientRow = (!section || section === 'program') && canViewClient;

  const canViewProgramRow =
    (!section || section === 'client') && canViewProgram;

  const canViewProjectnameRow =
    (!section ||
      section !== 'project' ||
      (section === 'project' && !canViewProjectTasks && !canViewProjectTeam)) &&
    canViewProject;

  const settings = {
    rows: [
      canViewClientRow && 'client',
      canViewProgramRow && 'program',
      canViewProjectnameRow && 'projectname',
      canViewProjectTasks && 'task',
      canViewProjectTeam && 'username',
      'entrydate'
    ].filter(e => e),
    groupings: [
      'entrydate__day',
      'entrydate__week',
      'entrydate__month',
      'entrydate__quarter',
      'entrydate__year'
    ],
    columns: [
      'billablehours',
      canViewExpenseCode ? 'expenseamount' : null,
      canViewCostData ? 'resourcecostamount' : null,
      canViewCostData ? 'costamount' : null,
      canViewBillingData ? 'billableamount' : null
    ].filter(e => e)
  };

  return { supportedSettings: settings };
};

export default getSupportedSettings;
