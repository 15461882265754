import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import SimpleDropdown from '~/modules/common/components/SimpleDropdown';
import { TIME_AND_EXPENSE_ENTRY_TYPE } from '~/modules/common/enums/TimeAndExpenseEntryType';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%'
  },
  select: {
    fontSize: theme.typography.body2.fontSize
  }
}));

const createOption = ({ id, formatMessage }) => ({
  id,
  displayText: formatMessage({ id: `timeAndExpenseEntryOptions.${id}` })
});

export const TimeAndExpenseEntryTypeDropdown = ({
  value,
  onChange,
  ...rest
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const options = useMemo(
    () => [
      createOption({ id: TIME_AND_EXPENSE_ENTRY_TYPE.BILLABLE, formatMessage }),
      createOption({
        id: TIME_AND_EXPENSE_ENTRY_TYPE.NON_BILLABLE,
        formatMessage
      }),
      createOption({
        id: TIME_AND_EXPENSE_ENTRY_TYPE.BILLABLE_AND_NON_BILLABLE,
        formatMessage
      })
    ],
    [formatMessage]
  );

  return (
    <SimpleDropdown
      classes={classes}
      options={options}
      onChange={onChange}
      value={useMemo(() => options.find(x => x.id === value), [options, value])}
      noneOption={false}
      disableSort
      {...rest}
    />
  );
};

TimeAndExpenseEntryTypeDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  labelWidth: PropTypes.number
};

export default TimeAndExpenseEntryTypeDropdown;
