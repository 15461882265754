import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@material-ui/core';
import LoadingButton from '~/modules/common/components/LoadingButton';
import { useDialogStyles } from './useStyles';

export const DeleteConfirmationDialog = ({
  open,
  link,
  onCancel,
  onRemove,
  isLoading = false
}) => {
  const classes = useDialogStyles();

  const messageValues = useMemo(
    () => ({
      title: { project: <strong>{link.baseProject.name}</strong> },
      content: {
        linkType: (
          <strong className={classes.highlighted}>
            <FormattedMessage id={`projectLink.${link.linkType}`} />
          </strong>
        ),
        project: (
          <strong className={classes.highlighted}>
            {link.targetProject.name}
          </strong>
        )
      }
    }),
    [link, classes.highlighted]
  );

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="xs"
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="remove-project-link-confirmation-dialog-title"
      aria-describedby="remove-project-link-confirmation-dialog-description"
    >
      <DialogTitle
        className={classes.title}
        id="remove-project-link-confirmation-dialog-title"
      >
        <FormattedMessage
          id="projectLink.removeLink.title"
          values={messageValues.title}
        />
      </DialogTitle>
      <DialogContent
        className={classes.content}
        id="remove-project-link-confirmation-dialog-description"
      >
        <Typography variant="body1">
          <FormattedMessage
            id="projectLink.removeLink.content"
            values={messageValues.content}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          <FormattedMessage id="projectLink.removeLink.cancel" />
        </Button>
        <LoadingButton
          onClick={onRemove}
          color="secondary"
          isLoading={isLoading}
          disabled={isLoading}
          autoFocus
        >
          <FormattedMessage id="projectLink.removeLink.remove" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DeleteConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  link: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default DeleteConfirmationDialog;
