import { useState, useMemo, useCallback } from 'react';
import { useFormik } from 'formik';
import { object } from 'yup';
import { useIntl } from 'react-intl';
import { isoStringToObjectWithCache as isoStringToObject } from '~/modules/common/dates/convert';
import { useRescheduleProjectBatchMutation } from './hooks';
import { TASKS_TO_RESCHEDULE_TYPE } from './enum';

export const useForm = ({
  project: { startDate, id: projectId },
  newStartDate,
  canRescheduleTasks,
  canRescheduleBillPlan,
  resourceRequestsToReschedule,
  canRescheduleResourceRequests,
  scheduleAllTasks = false
}) => {
  const [jobState, setJobState] = useState({
    jobId: null,
    jobInProgress: false
  });

  const initialValues = useMemo(
    () => ({
      projectId,
      newStartDate:
        newStartDate || startDate
          ? isoStringToObject(newStartDate || startDate)
          : null,
      originalStartDate: startDate ? isoStringToObject(startDate) : null,
      isRescheduleTasksChecked: scheduleAllTasks ? true : canRescheduleTasks,
      isAdjustWorkDaysChecked: true,
      isRescheduleBillPlanChecked: canRescheduleBillPlan,
      isRescheduleResourceRequestsChecked: canRescheduleResourceRequests,
      tasksToReschedule: scheduleAllTasks
        ? TASKS_TO_RESCHEDULE_TYPE.ALL_TASKS
        : TASKS_TO_RESCHEDULE_TYPE.NOT_STARTED,
      resourceRequestsToRescheduleByStatus: resourceRequestsToReschedule
    }),
    [
      startDate,
      projectId,
      newStartDate,
      canRescheduleTasks,
      canRescheduleBillPlan,
      resourceRequestsToReschedule,
      canRescheduleResourceRequests,
      scheduleAllTasks
    ]
  );

  const onSubmit = useOnSubmit({ setJobState });
  const { formatMessage } = useIntl();

  const validationSchema = useValidationSchema({ formatMessage });

  return {
    form: useFormik({
      initialValues,
      onSubmit,
      validationSchema,
      enableReinitialize: true
    }),
    jobState,
    setJobState
  };
};

const mapValuesOnSubmit = ({
  projectId,
  newStartDate,
  tasksToReschedule,
  originalStartDate,
  isAdjustWorkDaysChecked,
  isRescheduleTasksChecked,
  isRescheduleBillPlanChecked,
  isRescheduleResourceRequestsChecked,
  resourceRequestsToRescheduleByStatus
}) => ({
  projectId,
  newStartDate,
  tasksToReschedule,
  originalStartDate,
  isAdjustWorkDaysChecked,
  isRescheduleTasksChecked,
  isRescheduleBillPlanChecked,
  isRescheduleResourceRequestsChecked,
  resourceRequestsToRescheduleByStatus
});

export const useOnSubmit = ({ setJobState }) => {
  const [
    beginRescheduleProjectBackgroundJob
  ] = useRescheduleProjectBatchMutation();

  return useCallback(
    (values, bag) => {
      const rescheduleProjectBatch = async () => {
        bag.setSubmitting(true);
        const payload = mapValuesOnSubmit(values);

        try {
          const { data } = await beginRescheduleProjectBackgroundJob({
            variables: {
              rescheduleProjectInputs: payload
            }
          });

          const { beginRescheduleProjectBackgroundJob: jobId } = data;

          setJobState({
            jobId,
            jobInProgress: true
          });
        } finally {
          bag.setSubmitting(false);
        }
      };

      rescheduleProjectBatch();
    },
    [setJobState, beginRescheduleProjectBackgroundJob]
  );
};

export const useValidationSchema = ({ formatMessage }) =>
  object().shape({
    newStartDate: object()
      .nullable()
      .required(
        formatMessage({ id: 'rescheduleProject.newStartDateValidation' })
      ),
    originalStartDate: object()
      .nullable()
      .required(
        formatMessage({ id: 'rescheduleProject.originalStartDateValidation' })
      )
  });
