export const useOnBeforeTaskDrag = (
  gantt,
  isPsaPswatTaskDateRollupEnabled,
  isTaskDateRollupEnabled
) => (id, mode) => {
  const task = gantt.getTask(id);
  const isTask = gantt.getTaskByIndex(0).id !== id;
  const canResize = Boolean(isTask && task.startDate && task.endDate);

  if (!isPsaPswatTaskDateRollupEnabled || !isTaskDateRollupEnabled) {
    return canResize;
  }
  const hasChildren = gantt.hasChild(id);

  if (hasChildren) {
    return false;
  }

  return canResize;
};

export default useOnBeforeTaskDrag;
