import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Chip, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { TaskStatus } from '~/types';

const PROGRESSSTATUSTOTASKSTATUS = {
  IN_PROGRESS: 'INPROGRESS',
  NOT_STARTED: 'NOTSTARTED',
  COMPLETED: 'COMPLETED'
};

const useStyles = makeStyles(theme => ({
  chip: {
    color: theme.palette.text.primary,
    backgroundColor: ({ status }) => theme.palette.taskStatus[status].color,
    '&:hover': {
      backgroundColor: ({ status }) => theme.palette.taskStatus[status].color,
      boxShadow: theme.shadows[3]
    },
    '&:focus': {
      backgroundColor: ({ status }) => theme.palette.taskStatus[status].color
    }
  }
}));

export const TaskStatusBadge = ({
  status = TaskStatus.Notstarted,
  progressStatus
}) => {
  const coalescedStatus =
    status ||
    PROGRESSSTATUSTOTASKSTATUS[progressStatus] ||
    TaskStatus.Notstarted;

  const classes = useStyles({ status: coalescedStatus });

  const chipClasses = useMemo(
    () => ({
      root: classes.chip
    }),
    [classes.chip]
  );

  const label =
    coalescedStatus === TaskStatus.Inprogress ? (
      <FormattedMessage id="taskStatusBadge.inProgress" />
    ) : coalescedStatus === TaskStatus.Notstarted ? (
      <FormattedMessage id="taskStatusBadge.notStarted" />
    ) : (
      <FormattedMessage id="taskStatusBadge.completed" />
    );

  return <Chip classes={chipClasses} label={label} />;
};

TaskStatusBadge.propTypes = {
  status: PropTypes.oneOf([
    TaskStatus.Inprogress,
    TaskStatus.Notstarted,
    TaskStatus.Completed
  ]),
  progressStatus: PropTypes.string
};

export default TaskStatusBadge;
