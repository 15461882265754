import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { ProgressbarLegend } from '~/modules/common/charts/dashboard';
import ResourcingLegend from './ResourcingLegend';

const LegendItem = ({
  classes,
  isProgressBarLegend,
  barType,
  ...otherProps
}) => {
  const legendClasses = useMemo(
    () => ({
      rootLegend: classes[barType]
    }),
    [barType, classes]
  );

  return (
    <div className={classes.legendItem}>
      {isProgressBarLegend ? (
        <ProgressbarLegend {...otherProps} />
      ) : (
        <ResourcingLegend classes={legendClasses} {...otherProps} />
      )}
    </div>
  );
};

LegendItem.propTypes = {
  classes: PropTypes.object,
  isProgressBarLegend: PropTypes.bool,
  barType: PropTypes.oneOf(['dashedBar', 'roundBar', 'squareBar'])
};

export default LegendItem;
