import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { dateToMidnightUTCString } from '~/modules/common/dates/convert';
import { useResourceUserAvailabilityHoursSeriesForDateRange } from '~/modules/resourcing/common/hooks';
import { roundToDecimals } from '~/modules/resourcing/common/util';
import ResourceAllocationPeriodEditPopover from '../ResourceAllocationPeriodEditPopover';
import { getAllocationPeriodEditorDateRangeFromAllocation } from '../ResourceAllocationPeriodEditPopover/components/ResourceAllocationEditor/getAllocationPeriodEditorDateRange';
import getResourceAllocation, {
  getAvailabilityHoursSeriesForFullyAllocated
} from '../ResourceAllocationPeriodEditPopover/getResourceAllocation';

const TimelineEditorOverlay = ({
  allocationPeriodEditTarget,
  chartDisplayDateRange,
  handleAllocationPeriodClose,
  isPersistedAllocation,
  onChange,
  resourceAllocation,
  setNextPeriod,
  setPreviousPeriod
}) => {
  const {
    user: { userUri, scheduleDurationByPeriodMap },
    scheduleRules
  } = resourceAllocation;

  const {
    anchorEl,
    startDate,
    endDate,
    allocatedHours,
    hasAllocationPartialEnd,
    hasAllocationPartialStart
  } = allocationPeriodEditTarget;

  const getAllocationPeriodAvailabilityDateRange = useCallback(() => {
    const {
      periodStartDate,
      periodEndDate
    } = getAllocationPeriodEditorDateRangeFromAllocation({
      endDate,
      scheduleRules: resourceAllocation.scheduleRules,
      startDate,
      isPartial: hasAllocationPartialEnd || hasAllocationPartialStart
    });

    return {
      periodStartDate,
      periodEndDate
    };
  }, [
    endDate,
    hasAllocationPartialEnd,
    hasAllocationPartialStart,
    resourceAllocation.scheduleRules,
    startDate
  ]);

  const {
    isLoading,
    availabilityHoursSeriesByDay
  } = useResourceUserAvailabilityHoursSeriesForDateRange({
    userUri,
    dateRange: {
      startDate: dateToMidnightUTCString(chartDisplayDateRange.startDate),
      endDate: dateToMidnightUTCString(chartDisplayDateRange.endDate)
    }
  });

  const {
    periodStartDate,
    periodEndDate
  } = getAllocationPeriodAvailabilityDateRange();

  const allocatedHoursRoundedValue = roundToDecimals(allocatedHours);

  const availabilityHoursByDay = (availabilityHoursSeriesByDay || []).filter(
    series => series.date >= periodStartDate && series.date <= periodEndDate
  );
  const availableHours =
    (!isLoading &&
      roundToDecimals(
        availabilityHoursByDay.reduce((acc, curr) => curr.hours + acc, 0)
      ) + (isPersistedAllocation ? allocatedHoursRoundedValue : 0)) ||
    0;

  const getEditedAllocation = useCallback(
    ({ allocation, newAllocatedHours, resourceAllocationSeriesData }) => {
      const isFullyAllocated =
        availableHours - allocatedHoursRoundedValue === 0;

      const { alloc } = getResourceAllocation({
        allocation,
        newAllocatedHours,
        availabilityHoursSeriesByDay:
          isPersistedAllocation && isFullyAllocated
            ? getAvailabilityHoursSeriesForFullyAllocated({
                resourceAllocationSeriesData,
                scheduleRules
              })
            : availabilityHoursByDay
      });

      return alloc;
    },
    [
      isPersistedAllocation,
      availableHours,
      allocatedHoursRoundedValue,
      scheduleRules,
      availabilityHoursByDay
    ]
  );

  const onAllocationChange = useCallback(
    (allocation, allocatedHoursDiff) =>
      onChange(allocation, allocatedHoursDiff),
    [onChange]
  );

  const scheduledHours =
    scheduleDurationByPeriodMap[startDate.toISO()]?.hours || 0;

  return (
    <ResourceAllocationPeriodEditPopover
      anchorEl={anchorEl}
      isDataLoading={isLoading}
      periodDetails={allocationPeriodEditTarget}
      allocatedHours={allocatedHoursRoundedValue}
      useCreateAllocation={getEditedAllocation}
      handleAllocationPeriodClose={handleAllocationPeriodClose}
      setNextPeriod={setNextPeriod}
      scheduledHours={scheduledHours}
      availableHours={availableHours}
      isAllocationComplete={isPersistedAllocation}
      onAllocationChange={onAllocationChange}
      setPreviousPeriod={setPreviousPeriod}
      resourceAllocation={resourceAllocation}
      getAllocationPeriodAvailabilityDateRange={
        getAllocationPeriodAvailabilityDateRange
      }
    />
  );
};

TimelineEditorOverlay.propTypes = {
  isPersistedAllocation: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  allocationPeriodEditTarget: PropTypes.object,
  handleAllocationPeriodClose: PropTypes.func,
  setNextPeriod: PropTypes.func,
  setPreviousPeriod: PropTypes.func,
  chartDisplayDateRange: PropTypes.object,
  resourceAllocation: PropTypes.object
};

export default TimelineEditorOverlay;
