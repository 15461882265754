import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { mapScaleToPeriodResolution } from '~/modules/resourcing/common/util';
import { useLoadMore2 as useLoadMore } from '~/modules/common/hooks';

export const RESOURCE_USERS_QUERY = gql`
  query GetResourceUsersPaginated(
    $page: Int
    $pagesize: Int
    $filter: GetResourceUsersFilter
    $sort: [ResourceUserSort!]
    $dateRange: DateRangeInput!
    $isAvailabilityEnabled: Boolean!
    $periodResolution: PeriodResolutionOption!
    $isViewResourceActualsEnabled: Boolean!
    $skipMerge: Boolean
  ) {
    resourceUsers2(
      page: $page
      pagesize: $pagesize
      filter: $filter
      sort: $sort
      dateRange: $dateRange
      periodResolution: $periodResolution
      skipMerge: $skipMerge
    ) {
      id
      uri
      slug
      displayText
      startDate
      endDate
      roleSchedules {
        effectiveDate
        projectRoles {
          isPrimary
          projectRole {
            uri
            name
          }
        }
      }
      currentCostRate {
        amount
        currency {
          id
          symbol
          displayText
        }
      }
      holidays(dateRange: $dateRange) @include(if: $isAvailabilityEnabled) {
        date
        uri
        name
        duration {
          hours
          minutes
          seconds
        }
        effectiveDuration {
          hours
          minutes
          seconds
        }
      }
      timeoffs(dateRange: $dateRange) @include(if: $isAvailabilityEnabled) {
        dateRange {
          startDate
          endDate
        }
        timeOffType {
          displayText
        }
        hours
        days
        entries {
          date
          hours
          days
        }
      }
      resourceAvailabilitySummarySeries(
        dateRange: $dateRange
        periodResolution: $periodResolution
      ) {
        dateRange {
          startDate: startDate2
          endDate: endDate2
        }
        scheduledDuration {
          hours
          minutes
          seconds
        }
        timeOffDuration {
          hours
          minutes
          seconds
        }
        holidayDuration {
          hours
          minutes
          seconds
        }
        overAllocatedDuration {
          hours
          minutes
          seconds
        }
        allocatedDuration {
          hours
          minutes
          seconds
        }
      }
      actuals(dateRange: $dateRange, periodResolution: $periodResolution)
        @include(if: $isViewResourceActualsEnabled) {
        dateRange {
          startDate: startDate2
          endDate: endDate2
        }
        cost {
          amount
          currency {
            id
            symbol
            displayText
          }
        }
        hours {
          hours
          minutes
          seconds
        }
      }
    }
  }
`;

export const useResourceUsers = ({
  page = 1,
  pagesize = 20,
  filter,
  dateRange,
  scale,
  sort,
  isViewAvailabilityEnabled = false,
  isViewResourceActualsEnabled = false
}) => {
  const { loading, error, data, fetchMore, refetch } = useQuery(
    RESOURCE_USERS_QUERY,
    {
      variables: {
        page,
        pagesize,
        filter,
        sort,
        isAvailabilityEnabled: isViewAvailabilityEnabled,
        isViewResourceActualsEnabled,
        periodResolution: mapScaleToPeriodResolution(scale),
        dateRange,
        skipMerge: true
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first'
    }
  );

  const resourceUsers = (data && data.resourceUsers2) || [];

  const { hasMore, loadingMore, loadMore } = useLoadMore({
    totalFetchedItems: resourceUsers.length,
    fetchMore,
    loading,
    pageSize: pagesize
  });

  return {
    loadingRows: loading || false,
    loadingMore: loadingMore || false,
    resourceUsers: resourceUsers || [],
    error,
    loadMoreRows: loadMore,
    hasMoreRows: hasMore,
    refetch
  };
};

export default useResourceUsers;
