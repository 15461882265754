import { DateTime } from 'luxon';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { getPeriodsByScale } from '~/modules/resourcing/common/hooks/usePeriodsByScale';
import { getTotalHoursForPeriodFromScheduleRules } from '~/modules/resourcing/common/util/scheduleUtil';

export const getScheduleRulesInChartDisplayRange = ({
  rangeStart,
  rangeEnd,
  scheduleRules
}) =>
  scheduleRules
    .map(rule => ({
      ...rule,
      startDate: mapIsoStringtoUtcObject(rule.dateRange.startDate),
      endDate: mapIsoStringtoUtcObject(rule.dateRange.endDate)
    }))
    .filter(rule => rule.startDate <= rangeEnd && rangeStart <= rule.endDate);

export const getOverlappingDateRange = ({
  allocationStartDate,
  allocationEndDate,
  chartStartDate,
  chartEndDate
}) => {
  if (allocationEndDate < chartStartDate || allocationStartDate > chartEndDate)
    return undefined;

  const rangeStart = DateTime.max(allocationStartDate, chartStartDate);
  const rangeEnd = DateTime.min(allocationEndDate, chartEndDate);

  return { rangeStart, rangeEnd };
};

export const getAllocationTotalsByPeriods2 = ({
  chartDisplayDateRange: { startDate: chartStartDate, endDate: chartEndDate },
  resourceAllocation,
  scale,
  me
}) => {
  const { scheduleRules, startDate, endDate } = resourceAllocation || {};

  if (!startDate || !endDate) return { allocationPeriods: [] };

  const overlappingDateRange = getOverlappingDateRange({
    allocationStartDate: mapIsoStringtoUtcObject(startDate),
    allocationEndDate: mapIsoStringtoUtcObject(endDate),
    chartEndDate,
    chartStartDate
  });

  if (!overlappingDateRange) return { allocationPeriods: [] };

  const { rangeStart, rangeEnd } = overlappingDateRange;

  const scheduleRulesInRange = getScheduleRulesInChartDisplayRange({
    rangeStart,
    rangeEnd,
    scheduleRules
  });

  const { displayPeriods } = getPeriodsByScale({
    me,
    rangeStart,
    rangeEnd,
    scale
  });

  return {
    allocationPeriods: displayPeriods.map(range => {
      const { start, end, key } = range;

      return {
        key,
        totalHours: getTotalHoursForPeriodFromScheduleRules({
          start,
          end,
          scheduleRules: scheduleRulesInRange
        })
      };
    })
  };
};
