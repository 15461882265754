import React, { useCallback } from 'react';
import { Popover, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useGetTaskResourceUserAllocationsSummaryForUser } from '~/modules/common/components/TaskDrawer/common/hooks';
import { useMeContext } from '~/modules/me';
import useProjectAllocationForResourceUser from '../hooks/useProjectAllocationForResourceUser';
import ProjectManagerRoleEditorContent from './ProjectManagerRoleEditorContent';
import ResourceManagerRoleEditorContent from './ResourceManagerRoleEditorContent';

const usePopoverStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    width: theme.spacing(64)
  }
}));

const TaskAllocationEditor = ({
  anchorEl,
  setAnchorEl,
  userId,
  projectId,
  taskResourceUserAllocation
}) => {
  const {
    featureFlags: { isRmpTaskAllocationPhase2Enabled },
    permissionsMap
  } = useMeContext();

  const { loading, resourceAllocation } = useProjectAllocationForResourceUser({
    projectId,
    userId
  });

  const {
    loading: summaryLoading,
    taskResourceUserAllocationsSummary: otherTaskAllocationsSummary
  } = useGetTaskResourceUserAllocationsSummaryForUser({
    userId,
    projectId,
    taskAllocationId: taskResourceUserAllocation?.id
  });

  const isCompleteResourceAllocationPermissionEnabled = Boolean(
    permissionsMap['urn:replicon-webui:completed-resource-allocation:edit']
  );

  const popoverClasses = usePopoverStyles();

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <Popover
      id="task-allocation-editor"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      classes={popoverClasses}
    >
      {isRmpTaskAllocationPhase2Enabled &&
      isCompleteResourceAllocationPermissionEnabled ? (
        <ResourceManagerRoleEditorContent
          onClose={onClose}
          otherTaskAllocationsSummary={otherTaskAllocationsSummary}
          resourceAllocation={resourceAllocation}
          isLoading={loading || summaryLoading}
        />
      ) : (
        <ProjectManagerRoleEditorContent
          onClose={onClose}
          otherTaskAllocationsSummary={otherTaskAllocationsSummary}
          resourceAllocation={resourceAllocation}
          isLoading={loading || summaryLoading}
        />
      )}
    </Popover>
  );
};

TaskAllocationEditor.propTypes = {
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func,
  projectId: PropTypes.string,
  userId: PropTypes.string,
  taskResourceUserAllocation: PropTypes.object
};

export default TaskAllocationEditor;
