import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const REMOVE_RESOURCE_USER_TASK_ASSIGNMENT = gql`
  mutation removeResourceUserTaskAssignment(
    $input: RemoveResourceUserTaskAssignmentInput!
  ) {
    removeResourceUserTaskAssignment(input: $input) {
      userId
      taskId
    }
  }
`;

export const REMOVE_TASK_RESOURCE_ESTIMATES = gql`
  mutation removeTaskResourceEstimate(
    $input: RemoveTaskResourceEstimateInput!
  ) {
    removeTaskResourceEstimate(input: $input) {
      taskResourceEstimateId
    }
  }
`;

export const useRemoveTaskResourceEstimate = ({ projectId, taskId }) => {
  const [
    removeTaskResourceEstimate,
    { loading: loadingRemoveEstimate }
  ] = useMutation(REMOVE_TASK_RESOURCE_ESTIMATES);

  const [
    removeResourceUserTaskAssignment,
    { loading: loadingRemoveUserAssignment }
  ] = useMutation(REMOVE_RESOURCE_USER_TASK_ASSIGNMENT);

  const removeTaskResourceEstimateCallback = useCallback(
    async args => {
      const { resourceUserId, taskResourceEstimateId } = args;

      resourceUserId
        ? await removeResourceUserTaskAssignment({
            variables: {
              input: {
                userId: resourceUserId,
                projectId,
                taskId
              }
            }
          })
        : await removeTaskResourceEstimate({
            variables: {
              input: { taskId, taskResourceEstimateId }
            }
          });

      return {
        omitRemovedTaskEstimateFunc: taskResourceEstimateId
          ? re => re.id !== taskResourceEstimateId
          : re => re.resourceUser?.id !== resourceUserId
      };
    },
    [
      projectId,
      removeResourceUserTaskAssignment,
      removeTaskResourceEstimate,
      taskId
    ]
  );

  return {
    removeTaskResourceEstimate: removeTaskResourceEstimateCallback,
    loading: loadingRemoveEstimate || loadingRemoveUserAssignment
  };
};

export default useRemoveTaskResourceEstimate;
