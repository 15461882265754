import { useCallback } from 'react';
import { mapToTimesheetAccessInput } from '~/modules/common/components/TaskDrawer/EditTask/useFormState';
import { objectToUTCString } from '~/modules/common/dates/convert';
import { TIME_AND_EXPENSE_ENTRY_TYPE } from '~/modules/common/enums/TimeAndExpenseEntryType';

const getTimeAndExpenseEntryTypeForTask = ({
  values,
  project,
  isPsaPrpEnhancedAllowEntryWithoutTasksDropdownEnabled
}) => {
  if (project?.billingType?.id === 'urn:replicon:billing-type:non-billable')
    return undefined;

  if (isPsaPrpEnhancedAllowEntryWithoutTasksDropdownEnabled)
    return values.timeAndExpenseEntryType;

  return values.isTimeEntryAllowed
    ? values.timeAndExpenseEntryType
    : {
        id: TIME_AND_EXPENSE_ENTRY_TYPE.NON_BILLABLE,
        displayText: 'Non-Billable'
      };
};

/* eslint-disable max-depth */
export const useOnSubmit = ({
  validateTaskNameMutation,
  createTask,
  onClose,
  setMessage,
  parentUri,
  customFieldDefinitions,
  project,
  isPsaPrpEnhancedAllowEntryWithoutTasksDropdownEnabled,
  shouldRollupTaskDates
}) =>
  useCallback(
    async (values, { setSubmitting, handleReset, setFieldError }) => {
      setSubmitting(true);
      const {
        name,
        code,
        startDate,
        endDate,
        assignedUser,
        assignedRole,
        description,
        estimatedHours,
        estimatedCost,
        isMilestone,
        isTimeEntryAllowed,
        assignedUserRoleId,
        resourceAllocations = [],
        copyParentTaskResourceAllocations,
        timesheetAccess,
        resourceEstimates,
        ...customFieldValues
      } = values;

      const {
        data: {
          validateTaskName: { isValid }
        }
      } = await validateTaskNameMutation({
        variables: {
          input: {
            parentId: parentUri,
            taskName: name
          }
        }
      });

      if (!isValid) {
        setFieldError('nameExists', true);
        setSubmitting(false);
      } else {
        const hasEstimatedCostAmount = estimatedCost && estimatedCost.amount;

        const error = await createTask({
          parentUri,
          name,
          code,
          startDate: startDate ? objectToUTCString(startDate) : null,
          endDate: endDate ? objectToUTCString(endDate) : null,
          estimatedHours: estimatedHours ? Number(estimatedHours) : null,
          estimatedCost: hasEstimatedCostAmount ? estimatedCost : null,
          assignedUser,
          assignedRole,
          description,
          isMilestone,
          isTimeEntryAllowed,
          timeAndExpenseEntryType: getTimeAndExpenseEntryTypeForTask({
            values,
            project,
            isPsaPrpEnhancedAllowEntryWithoutTasksDropdownEnabled
          }),
          timesheetAccessUris: mapToTimesheetAccessInput(timesheetAccess),
          customFields: constructCustomFields(
            customFieldDefinitions,
            customFieldValues
          ),
          assignedUserRoleId,
          resourceAllocations: resourceAllocations.map(a => ({
            resourceId: a.resource.id,
            allocatedHours: a.allocatedHours
          })),
          doNotCopyParentTaskResourceAllocations: !copyParentTaskResourceAllocations,
          shouldRollupTaskDates,
          resourceEstimates: (resourceEstimates || []).map(a => ({
            resourceUserId: a.resource?.id,
            projectRoleId: a.role?.id,
            initialEstimatedHours: a.estimatedHours
          }))
        });

        setSubmitting(false);

        if (!error) {
          onClose();
          handleReset();
          setMessage(name);
        }

        setFieldError('submitError', error);
      }
    },
    [
      createTask,
      customFieldDefinitions,
      isPsaPrpEnhancedAllowEntryWithoutTasksDropdownEnabled,
      onClose,
      parentUri,
      project,
      setMessage,
      validateTaskNameMutation
    ]
  );

const constructCustomFields = (customFieldDefinitions, customFieldValues) =>
  customFieldDefinitions.map(definition => {
    const customField = { customField: { id: definition.uri } };

    switch (definition.type.uri) {
      case 'urn:replicon:custom-field-type:text': {
        customField.text = customFieldValues[definition.uri];
        break;
      }
      case 'urn:replicon:custom-field-type:numeric': {
        customField.number = customFieldValues[definition.uri];
        break;
      }
      case 'urn:replicon:custom-field-type:date': {
        customField.date = customFieldValues[definition.uri];
        break;
      }
      case 'urn:replicon:custom-field-type:drop-down': {
        customField.dropDownOptionUri = customFieldValues[definition.uri];
        break;
      }
      default:
        customField.text = customFieldValues[definition.uri];
        break;
    }

    customField.definition = definition;

    return customField;
  });

export default useOnSubmit;
