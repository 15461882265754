import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '@mui/material';
import { DISPLAY_UNIT_ENUM } from '~/modules/resourcing/common/enums';
import { useDisplayUnitContext } from '~/modules/resourcing/common/hooks/useDisplayUnitContext';
import AvailabilityPeriod from '~/modules/resourcing/common/components/AvailabilityPeriod';
import { useMeContext } from '~/modules/me';
import {
  ResourceIcon,
  ProjectAllocationIcon
} from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  detailsContainer: {
    padding: theme.spacing(0.25),
    textAlign: 'right',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary
  },
  details: {
    display: 'flex',
    textAlign: 'right',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  overAllocated: {
    color: theme.palette.error.dark,
    fontWeight: theme.typography.fontWeightBold
  },
  value: {
    marginLeft: theme.spacing(1)
  },
  tooltipHeader: {
    ...theme.typography.caption,
    fontWeight: theme.typography.fontWeightBold
  },
  tooltipDescription: {
    ...theme.typography.caption,
    fontWeight: theme.typography.fontWeightRegular
  },
  icon: {
    color: theme.palette.text.secondary
  }
}));

export const TaskAllocationEditorContent = ({
  classes: classesOverrides,
  scheduledHours,
  remainingHours,
  availableHours,
  targetHeight,
  targetWidth
}) => {
  const {
    featureFlags: { isRmpTaskAllocationPhase2Enabled },
    permissionsMap
  } = useMeContext();

  const isCompleteResourceAllocationPermissionEnabled = Boolean(
    permissionsMap['urn:replicon-webui:completed-resource-allocation:edit']
  );

  const classes = useStyles({
    targetWidth,
    targetHeight,
    classes: classesOverrides
  });

  const { displayUnit } = useDisplayUnitContext();

  const isHoursMode =
    displayUnit === DISPLAY_UNIT_ENUM.HOURS || scheduledHours === 0;

  const tooltipContent = useMemo(
    () => (
      <>
        {isCompleteResourceAllocationPermissionEnabled && (
          <>
            <div className={classes.tooltipHeader}>
              <FormattedMessage id="resourceAssignmentDialog.resourceAvailability" />
            </div>
            <span className={classes.tooltipDescription}>
              <FormattedMessage id="resourceAssignmentDialog.resourceAvailabilityDetails" />
            </span>
          </>
        )}
        <div className={classes.tooltipHeader}>
          <FormattedMessage id="resourceAssignmentDialog.projectAllocationRemaining" />
        </div>
        <span className={classes.tooltipDescription}>
          <FormattedMessage id="resourceAssignmentDialog.projectAllocationRemainingDetails" />
        </span>
      </>
    ),
    [
      classes.tooltipDescription,
      classes.tooltipHeader,
      isCompleteResourceAllocationPermissionEnabled
    ]
  );

  const remainingComponent = useMemo(
    () => (
      <div className={classes.details}>
        {isRmpTaskAllocationPhase2Enabled && (
          <ProjectAllocationIcon fontSize="small" className={classes.icon} />
        )}
        <FormattedMessage id="taskAllocationEditor.remaining" />:
        <AvailabilityPeriod
          availableHours={remainingHours}
          isHoursMode={isHoursMode}
          classes={classes}
        />
      </div>
    ),
    [classes, isHoursMode, isRmpTaskAllocationPhase2Enabled, remainingHours]
  );

  return isRmpTaskAllocationPhase2Enabled ? (
    <Tooltip title={tooltipContent} placement="right">
      <div className={classes.detailsContainer}>
        {isCompleteResourceAllocationPermissionEnabled && (
          <div className={classes.details}>
            <ResourceIcon fontSize="small" className={classes.icon} />
            <FormattedMessage id="taskAllocationEditor.availability" />:
            <AvailabilityPeriod
              availableHours={availableHours}
              isHoursMode={isHoursMode}
              classes={classes}
            />
          </div>
        )}
        {remainingComponent}
      </div>
    </Tooltip>
  ) : (
    <div className={classes.details}>{remainingComponent}</div>
  );
};

TaskAllocationEditorContent.propTypes = {
  classes: PropTypes.object,
  availableHours: PropTypes.number.isRequired,
  remainingHours: PropTypes.number.isRequired,
  scheduledHours: PropTypes.number.isRequired,
  targetHeight: PropTypes.number.isRequired,
  targetWidth: PropTypes.number.isRequired
};
