import { makeStyles } from '@material-ui/core';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';

const useStyles = makeStyles({
  root: {
    backgroundColor: theme.palette.common.white
  },
  fullWidth: {
    width: '100%'
  },
  fullHeight: {
    height: '100%'
  },
  showMoreButton: {
    display: 'flex',
    alignSelf: 'center'
  },
  ganttContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(0, 1)
  },
  ganttContainer2: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(0, 1),
    '& .gantt_task_line, .gantt_line_wrapper': {
      marginTop: '-15px'
    },
    '& .gantt_side_content.gantt_right': {
      overflow: 'visible',
      paddingLeft: 0
    },
    '& .gantt_side_content.gantt_left': {
      overflow: 'visible',
      paddingRight: 0
    },
    '& .gantt_task_row, .gantt_task_row.odd': {
      cursor: 'default',
      backgroundColor: '#f7f7f7'
    },
    '& $ganttTaskBar2Rounded:hover, $ganttTaskBar2RoundedBorder:hover': {
      boxShadow: 'rgba(0,0,0,1) 0 0 0 2px'
    },
    '& $ganttTaskBar2Rounded, $ganttTaskBar2RoundedBorder': {
      transition: 'box-shadow 0.2s',
      boxShadow: 'rgba(0,0,0,0) 0 0 0 2px'
    },
    '& .gantt_row_project': {
      cursor: 'default',
      '& .gantt_tree_content': {
        overflow: 'visible'
      },
      '& div:nth-child(n+2) > .gantt_tree_content': {
        display: 'none'
      }
    },
    '& .gantt_task_line > .gantt_task_drag.task_left': {
      left: theme.spacing(0.125)
    },
    '& .gantt_task_line > .gantt_task_drag.task_right': {
      right: theme.spacing(0.125)
    }
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60px',
    marginTop: '-9px'
  },
  ganttContent: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'auto'
  },
  ganttContainerFooter: {
    display: 'flex',
    flexGrow: 1,
    alignSelf: 'center',
    margin: theme.spacing(1),
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.information.light,
    color: theme.palette.text.primary
  },
  ganttFontFamily: {
    fontFamily: theme.typography.fontFamily
  },
  ganttTaskBar2RoundedBorder: ({ ganttScale }) => ({
    borderRadius: theme.spacing(3) / 2,
    border: '1px solid #26d293 !important',
    height: `${theme.spacing(3)}px`,
    '& .gantt_task_progress': {
      textAlign: 'left',
      boxSizing: 'border-box',
      fontWeight: theme.typography.fontWeightLight,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    '& .gantt_task_content': {
      overflow: 'visible',
      paddingRight: 0,
      marginLeft:
        ganttScale === 'year'
          ? theme.spacing(-0.8)
          : ganttScale === 'quarter'
          ? theme.spacing(-0.5)
          : 'unset'
    }
  }),
  ganttTaskBar2: ({ ganttScale }) => ({
    borderRadius: 0,
    height: `${theme.spacing(3)}px`,
    '& .gantt_task_progress': {
      textAlign: 'left',
      boxSizing: 'border-box',
      fontWeight: theme.typography.fontWeightLight,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      height: '33%',
      marginTop: '6px'
    },
    '& .gantt_task_content': {
      overflow: 'visible',
      paddingRight: 0,
      marginLeft:
        ganttScale === 'year'
          ? theme.spacing(-0.8)
          : ganttScale === 'quarter'
          ? theme.spacing(-0.5)
          : 'unset'
    },
    boxShadow: 'rgba(0,0,0,0) 0 0 0 2px !important'
  }),
  disableTaskDrag: {
    '& .gantt_task_drag': {
      cursor: 'not-allowed',
      background: 'none'
    }
  },
  ganttTaskBar2InProgress: {
    border: 'none !important',
    borderLeft: '4px solid #26d293 !important',
    borderRight: '4px solid #d2f9ea !important',
    background:
      'linear-gradient(180deg, #f7f7f7 33%,  #d2f9ea 33%, #d2f9ea 66%, #f7f7f7 66%)'
  },
  ganttTaskBar2NotStarted: {
    border: 'none !important',
    borderLeft: '4px solid #0bb8df !important',
    borderRight: '4px solid #0bb8df !important',
    background:
      'linear-gradient(180deg, #f7f7f7 33%,  #0bb8df 33%, #0bb8df 66%, #f7f7f7 66%)'
  },
  ganttTaskBar2Completed: {
    border: 'none !important',
    borderLeft: '4px solid #6c91a8 !important',
    borderRight: '4px solid #6c91a8 !important',
    background:
      'linear-gradient(180deg, #f7f7f7 33%,  #6c91a8 33%, #6c91a8 66%, #f7f7f7 66%)'
  },
  ganttTaskBar2Rounded: ({ ganttScale }) => ({
    borderRadius: theme.spacing(3) / 2,
    height: `${theme.spacing(3)}px`,
    background: '#0bb8df',
    '& .gantt_task_progress': {
      textAlign: 'left',
      boxSizing: 'border-box',
      fontWeight: theme.typography.fontWeightLight,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    '& .gantt_task_content': {
      overflow: 'visible',
      paddingRight: 0,
      marginLeft:
        ganttScale === 'year'
          ? theme.spacing(-0.8)
          : ganttScale === 'quarter'
          ? theme.spacing(-0.5)
          : 'unset'
    },
    border: 'none !important'
  }),
  ganttTaskBar: ({ ganttScale }) => ({
    borderRadius: theme.spacing(3) / 2,
    height: `${theme.spacing(3)}px`,

    '& .gantt_task_progress': {
      textAlign: 'left',
      boxSizing: 'border-box',
      fontWeight: theme.typography.fontWeightLight,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    '& .gantt_task_content': {
      overflow: 'visible',
      paddingRight: 0,
      marginLeft:
        ganttScale === 'year'
          ? theme.spacing(-0.8)
          : ganttScale === 'quarter'
          ? theme.spacing(-0.5)
          : 'unset'
    },
    border: 'none !important'
  }),
  ganttScaleCell: {
    minWidth: 60,
    textTransform: 'capitalize!important'
  },
  ganttGridRow: {
    cursor: 'pointer',
    maxWidth: '100%',
    '& .gantt_tree_content': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '100%',
      width: '80%'
    },
    '& .gantt_tree_icon': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  ganttGridRow2: {
    borderBottom: '1px solid #d7d7d7',
    cursor: 'pointer',
    maxWidth: '100%',
    '& .gantt_tree_content': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '100%',
      width: '80%'
    },
    '& .gantt_tree_icon': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  ganttGridRowProject: {
    borderBottom: '4px solid #d7d7d7',
    cursor: 'pointer',
    maxWidth: '100%',
    '& .gantt_tree_content': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '100%',
      width: '80%'
    },
    '& .gantt_tree_icon': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  ganttGridRowNoAdd: {
    '& .gantt_add': {
      visibility: 'hidden'
    }
  },
  ganttAssigneeColumnHeader: {
    textAlign: 'left !important',
    paddingLeft: 6
  },
  ganttTaskNameContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap'
  },
  ganttTaskName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  ganttProjectName: {
    fontSize: '16px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  ganttTaskNameBold: {
    fontWeight: 'bold',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  ganttTaskAdd: {
    marginRight: theme.spacing(1),
    '&>div': {
      width: 24,
      height: 35
    }
  },
  gantColumnName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    flexGrow: 1,
    flexShrink: 1
  },
  ganttAssignToLabel: ({ isPsaPrpTaskCodeOnTaskCreationEnabled }) => ({
    flexGrow: 1,
    flexShrink: 1,
    ...theme.typography.caption,
    color: theme.palette.text.primary,
    fontStyle: 'italic',
    paddingTop: isPsaPrpTaskCodeOnTaskCreationEnabled
      ? theme.spacing(2.7)
      : theme.spacing(1),
    width: theme.spacing(10)
  }),
  ganttAssignToLabel2: {
    flexGrow: 1,
    flexShrink: 1,
    ...theme.typography.caption,
    color: theme.palette.text.primary,
    fontStyle: 'italic',
    paddingTop: theme.spacing(2.7),
    width: theme.spacing(10)
  },
  ganttTaskContentContainer: {
    display: 'flex'
  },
  ganttTaskProgress: {
    marginLeft: theme.spacing(-1),
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightLight,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  ganttRowTaskName: {
    color: theme.palette.common.black,
    flexGrow: 1,
    fontWeight: theme.typography.fontWeightRegular
  },
  milestoneMark: {
    boxSizing: 'border-box',
    transform: 'rotate(45deg)',
    backgroundColor: theme.palette.task.milestone,
    border: `1px solid ${theme.palette.task.milestone}`,
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    margin: '4px -12px 0px auto',
    float: 'right',
    position: 'absolute',
    right: 0,
    borderRadius: '2px'
  },
  milestoneMarkRollUp: {
    boxSizing: 'border-box',
    transform: 'rotate(45deg)',
    backgroundColor: theme.palette.task.milestone,
    border: `1px solid ${theme.palette.task.milestone}`,
    width: theme.spacing(2),
    height: theme.spacing(2),
    margin: '0 -12px 0px auto',
    float: 'right',
    position: 'absolute',
    right: 0,
    borderRadius: '2px'
  },
  overEstimate: {
    color: theme.palette.error.dark,
    fontWeight: 'bold'
  },
  taskIcon: {
    margin: theme.spacing(1, 1, 0, 0),
    color: theme.palette.text.secondary
  }
});

export default useStyles;
