import { gql } from 'graphql-tag';

export const RESOURCE_USERS_QUERY = gql`
  query GetResourceUsersPaginated(
    $page: Int
    $pagesize: Int
    $filter: GetResourceUsersFilter
    $sort: [ResourceUserSort!]
    $dateRange: DateRangeInput!
    $isAvailabilityEnabled: Boolean!
    $periodResolution: PeriodResolutionOption!
    $isViewResourceAllocation: Boolean!
    $isViewResourceActualsEnabled: Boolean!
    $skipMerge: Boolean
  ) {
    resourceUsers2(
      page: $page
      pagesize: $pagesize
      filter: $filter
      sort: $sort
      periodResolution: $periodResolution
      dateRange: $dateRange
      skipMerge: $skipMerge
    ) {
      id
      uri
      slug
      displayText
      startDate
      endDate
      roleSchedules {
        effectiveDate
        projectRoles {
          isPrimary
          projectRole {
            uri
            name
          }
        }
      }
      currentCostRate {
        amount
        currency {
          id
          symbol
          displayText
        }
      }
      holidays(dateRange: $dateRange) @include(if: $isAvailabilityEnabled) {
        date
        uri
        name
        duration {
          hours
          minutes
          seconds
        }
        effectiveDuration {
          hours
          minutes
          seconds
        }
      }
      timeoffs(dateRange: $dateRange) @include(if: $isAvailabilityEnabled) {
        dateRange {
          startDate
          endDate
        }
        timeOffType {
          displayText
        }
        hours
        days
        entries {
          date
          hours
          days
        }
      }
      resourceAvailabilitySummarySeries(
        dateRange: $dateRange
        periodResolution: $periodResolution
      ) {
        dateRange {
          startDate: startDate2
          endDate: endDate2
        }
        scheduledDuration {
          hours
          minutes
          seconds
        }
        timeOffDuration {
          hours
          minutes
          seconds
        }
        holidayDuration {
          hours
          minutes
          seconds
        }
        overAllocatedDuration {
          hours
          minutes
          seconds
        }
        allocatedDuration {
          hours
          minutes
          seconds
        }
      }
      actuals(dateRange: $dateRange, periodResolution: $periodResolution)
        @include(if: $isViewResourceActualsEnabled) {
        dateRange {
          startDate: startDate2
          endDate: endDate2
        }
        cost {
          amount
          currency {
            id
            displayText
          }
        }
        hours {
          hours
          minutes
          seconds
        }
      }
      resourceAllocations(dateRange: $dateRange)
        @include(if: $isViewResourceAllocation) {
        id
        resourceRequestId
        projectUri
        project {
          id
          displayText
        }
        startDate
        endDate
        totalHours
        scheduledHours
        allocationStatus
        scheduleRules {
          dateRange {
            startDate
            endDate
          }
          do
        }
        load
      }
    }
  }
`;
