import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { deepPure } from '~/util';
import { TASK_RESOURCE_USER_ALLOCATIONS_SUMMARY_QUERY } from '~/modules/common/components/TaskDrawer/common/hooks/useGetTaskResourceUserAllocationsSummaryForUser';
import { RequestActions } from '~/modules/resourcing/common/components/RequestActions/RequestActions';
import { useTaskAssignmentRowActions } from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceAllocationChart/hooks';
import {
  RemoveTaskAssignmentConfirmationDialog,
  RemoveTaskAllocationConfirmationDialog,
  ChangeTaskResourceConfirmationDialog
} from '~/modules/projects/resourcing-plan/components';

const useRequestActionStyles = makeStyles(theme => ({
  secondaryActionsButton: {
    paddingRight: 0
  }
}));

export const RowLeftItemActions = ({
  projectId,
  task,
  user,
  hasTaskAllocation,
  handleRemoveTaskAssignment,
  resetResourceAllocationChart
}) => {
  const requestActionClasses = useRequestActionStyles();

  const {
    actions,
    removeTaskAssignmentDialogOpen,
    openRemoveTaskAssignmentDialog,
    closeRemoveTaskAssignmentDialog,
    removeTaskAllocationDialogOpen,
    closeRemoveTaskAllocationDialog,
    changeTaskResourceDialogOpen,
    closeChangeTaskResourceDialog
  } = useTaskAssignmentRowActions({ hasTaskAllocation });

  const onRemoveTaskAssignmentSuccess = useCallback(
    ({ taskId }) => {
      handleRemoveTaskAssignment({ taskId });
      closeRemoveTaskAssignmentDialog();
    },
    [handleRemoveTaskAssignment, closeRemoveTaskAssignmentDialog]
  );

  const refetchQueries = useMemo(
    () => [
      {
        query: TASK_RESOURCE_USER_ALLOCATIONS_SUMMARY_QUERY,
        variables: {
          userId: user.id,
          filter: {
            projectId
          }
        }
      }
    ],
    [projectId, user.id]
  );

  return (
    <>
      <RequestActions
        actions={actions}
        primaryActionPlaceHolder={false}
        classes={requestActionClasses}
      />
      {removeTaskAssignmentDialogOpen && (
        <RemoveTaskAssignmentConfirmationDialog
          projectId={projectId}
          task={task}
          user={user}
          open={removeTaskAssignmentDialogOpen}
          onClose={closeRemoveTaskAssignmentDialog}
          onRemoveTaskAssignmentSuccess={onRemoveTaskAssignmentSuccess}
          refetchQueries={refetchQueries}
        />
      )}
      {removeTaskAllocationDialogOpen && (
        <RemoveTaskAllocationConfirmationDialog
          projectId={projectId}
          task={task}
          user={user}
          open={removeTaskAllocationDialogOpen}
          onClose={closeRemoveTaskAllocationDialog}
          openRemoveTaskAssignmentDialog={openRemoveTaskAssignmentDialog}
        />
      )}
      {changeTaskResourceDialogOpen && (
        <ChangeTaskResourceConfirmationDialog
          projectId={projectId}
          changeTaskResourceDialogOpen={changeTaskResourceDialogOpen}
          closeChangeTaskResourceDialog={closeChangeTaskResourceDialog}
          onChangeTaskResourceSuccess={resetResourceAllocationChart}
          task={task}
          user={user}
        />
      )}
    </>
  );
};

RowLeftItemActions.propTypes = {
  projectId: PropTypes.string.isRequired,
  task: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  hasTaskAllocation: PropTypes.bool.isRequired,
  handleRemoveTaskAssignment: PropTypes.func.isRequired,
  resetResourceAllocationChart: PropTypes.func.isRequired
};

export default deepPure(RowLeftItemActions);
