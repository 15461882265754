import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { FormattedMessage } from 'react-intl';
import { Typography, makeStyles } from '@material-ui/core';
import { useDialogState } from '~/modules/common/hooks';
import { NoDataItem } from '~/modules/common/components';
import InfoSection from '~/modules/resource-management/resourceUserDetails/InfoSection';
import { CustomFieldValueText } from '~/modules/customFields/components';
import { hasValue } from '~/modules/customFields/utils';
import EditAdditionalInfoDialog from './EditAdditionalInfoDialog';

const title = <FormattedMessage id="resourceManagement.additionalInfo" />;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 0, 0, 0)
  },
  label: {
    color: theme.palette.text.secondary
  },
  value: {
    fontSize: theme.typography.body2.fontSize,
    overflow: 'hidden',
    overflowWrap: 'break-word',
    hyphens: 'auto'
  },
  editButton: {
    marginRight: theme.spacing(-2)
  }
}));

export const GetUserDefinedField = (field, cssClasses) => (
  <div key={field.customField.id} className={cssClasses.root}>
    <Typography
      noWrap
      variant="caption"
      component="label"
      color="textSecondary"
      className={cssClasses.label}
    >
      {field.customField.displayText}
    </Typography>
    <Typography className={cssClasses.value} component="div">
      <CustomFieldValueText customFieldValue={field} />
    </Typography>
  </div>
);

export const AdditionalInfoSection = ({
  details,
  customFieldDefinitions,
  editable
}) => {
  const cssClasses = useStyles();

  const customFieldValues = get(details, 'customFieldValues') || [];

  const hasCustomFieldValues =
    customFieldValues &&
    customFieldValues.length > 0 &&
    customFieldValues.some(hasValue);

  const sectionClasses = useMemo(
    () => ({
      editButton: cssClasses.editButton
    }),
    [cssClasses.editButton]
  );

  const {
    open: dialogOpen,
    openDialog,
    closeDialog: onDialogCancel
  } = useDialogState(false, {
    disabledBackDropClick: true
  });

  return (
    <InfoSection
      editable={editable}
      title={title}
      onOpen={openDialog}
      classes={sectionClasses}
    >
      {hasCustomFieldValues ? (
        customFieldValues.map(c => GetUserDefinedField(c, cssClasses))
      ) : (
        <NoDataItem>
          <FormattedMessage id="resourceManagement.noAdditionalInfo" />
        </NoDataItem>
      )}
      {dialogOpen && (
        <EditAdditionalInfoDialog
          details={details}
          open={dialogOpen}
          customFieldDefinitions={customFieldDefinitions}
          onClose={onDialogCancel}
        />
      )}
    </InfoSection>
  );
};

AdditionalInfoSection.propTypes = {
  details: PropTypes.object.isRequired,
  customFieldDefinitions: PropTypes.array,
  editable: PropTypes.bool.isRequired
};

export default AdditionalInfoSection;
