import React, { useCallback, useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TaskStatus } from '~/types';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import VariationCell from './VariationCell';

const useStyles = makeStyles({
  accordion: {
    width: '100%',
    margin: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
    '&.Mui-expanded': {
      margin: 0
    },
    '&:before': {
      content: 'none'
    },
    padding: '0px'
  },
  summary: {
    '&.Mui-expanded': {
      minHeight: 'unset'
    },
    padding: theme.spacing(0, 1)
  },
  summaryContent: {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0
    }
  },
  expandIcon: {
    marginRight: theme.spacing(-1),
    marginLeft: 0
  },
  summaryContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: theme.spacing(2)
  },
  summaryTitle: {
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary
  },
  details: {
    flexDirection: 'column',
    padding: theme.spacing(0, 1, 1, 1)
  }
});

const expandIcon = <ExpandMoreIcon />;

export const RolledUpSummaryAccordionContainer = ({
  variation,
  variationStatus,
  children,
  task,
  title,
  variant
}) => {
  const classes = useStyles();
  const { accordionClasses, summaryClasses } = useMemo(
    () => ({
      accordionClasses: {
        root: classes.accordion
      },
      summaryClasses: {
        root: classes.summary,
        expandIcon: classes.expandIcon,
        content: classes.summaryContent
      }
    }),
    [classes]
  );
  const [expanded, setExpanded] = useState(false);
  const onChange = useCallback(() => setExpanded(!expanded), [expanded]);

  const isChild = task.children.length === 0;
  const isNotStarted = task.taskStatus === TaskStatus.Notstarted;

  return (
    <Accordion
      elevation={0}
      expanded={expanded}
      onChange={onChange}
      classes={accordionClasses}
    >
      <AccordionSummary expandIcon={expandIcon} classes={summaryClasses}>
        <div className={classes.summaryContainer}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            className={classes.summaryTitle}
          >
            <FormattedMessage id={title} />
          </Typography>
          {expanded ? null : (
            <VariationCell
              variation={variation}
              isChild={isChild}
              isNotStarted={isNotStarted}
              variant={variant}
              variationStatus={variationStatus}
            />
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

RolledUpSummaryAccordionContainer.propTypes = {
  task: PropTypes.object.isRequired,
  variation: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  variationStatus: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  variant: PropTypes.oneOf(['hours', 'cost', 'dates'])
};
export default RolledUpSummaryAccordionContainer;
