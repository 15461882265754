import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { PAGE_SIZE } from '~/modules/common/const';

export const GET_PAGE_OF_PROJECT_TEMPLATES = gql`
  query getPageOfProjectTemplates(
    $page: Int!
    $pageSize: Int!
    $searchTerm: String
  ) {
    projectTemplates(
      page: $page
      pageSize: $pageSize
      searchTerm: $searchTerm
    ) {
      id
      displayText
      slug
      settings {
        key
        value
      }
    }
  }
`;

export const usePageOfProjectTemplates = ({
  searchTerm = 'Template',
  skip
}) => {
  const { data, loading } = useQuery(GET_PAGE_OF_PROJECT_TEMPLATES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      page: 1,
      pageSize: PAGE_SIZE + 1,
      searchTerm
    },
    skip
  });

  const projectTemplates = data?.projectTemplates || [];

  return {
    loading,
    projectTemplates
  };
};

export default usePageOfProjectTemplates;
