import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { List, RadioGroup, Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import OverAllocationOptionItem from './OverAllocationOptionItem';
import { useOverAllocationOptions } from './hooks/useOverAllocationOptions';

const useStyles = makeStyles(theme => ({
  optionList: {
    margin: theme.spacing(0, -2),
    paddingBottom: theme.spacing(0)
  },
  resourceAllocation: {
    fontSize: theme.typography.body2.fontSize
  },
  dateRange: {
    whiteSpace: 'nowrap'
  }
}));

const OverAllocationConfirmationArea = ({
  resourceDisplayText,
  taskAllocationDateRange,
  availableHours,
  existingUserTaskAllocationHours,
  isResourceManager
}) => {
  const classes = useStyles();

  const {
    values: { allocationMode },
    setFieldValue
  } = useFormikContext();

  const { formatMessage } = useIntl();
  const handleOptionSelect = useCallback(
    id => setFieldValue('allocationMode', id),
    [setFieldValue]
  );

  const overAllocationOptions = useOverAllocationOptions({
    formatMessage,
    isResourceManager,
    existingUserTaskAllocationHours,
    availableHours
  });
  const overAllocationAlertTitle = isResourceManager
    ? 'changeTaskResourceDialog.overAllocationOptions.resourceManagerOverAllocationAlertTitle'
    : 'changeTaskResourceDialog.overAllocationOptions.projectManagerOverAllocationAlertTitle';

  return (
    <>
      <Typography className={classes.resourceAllocation}>
        <FormattedMessage
          id={overAllocationAlertTitle}
          values={resourceDisplayText}
        />
        <span className={classes.dateRange}>
          <FormattedMessage
            id="changeTaskResourceDialog.taskAllocationDateRange"
            values={taskAllocationDateRange}
          />
        </span>
      </Typography>

      <RadioGroup
        aria-label="overAllocation"
        name="overAllocation"
        value={allocationMode}
      >
        <List className={classes.optionList} component="div" dense={false}>
          {overAllocationOptions.map(option => (
            <OverAllocationOptionItem
              key={option.optionId}
              value={option.optionId}
              primaryText={option.primaryText}
              secondaryText={option.secondaryText}
              onSelect={handleOptionSelect}
              id={option.optionId}
            />
          ))}
        </List>
      </RadioGroup>
    </>
  );
};

OverAllocationConfirmationArea.propTypes = {
  resourceDisplayText: PropTypes.object,
  taskAllocationDateRange: PropTypes.object,
  availableHours: PropTypes.number,
  existingUserTaskAllocationHours: PropTypes.number,
  isResourceManager: PropTypes.bool
};

export default OverAllocationConfirmationArea;
