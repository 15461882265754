import PropTypes from 'prop-types';
import React from 'react';
import { CollapsibleDetailsPanel } from '~/modules/common/components';
import { SideCard } from '~/modules/common/components/SideCard';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import { useMeContext } from '~/modules/me/useMeContext';
import { useProjectTemplateSettings } from '~/modules/common/hooks/project/useProjectTemplateSettings';
import AdditionalInfo from './AdditionalInfo';
import BasicInfo from './BasicInfo';
import Clients from './Clients';
import Groups from './Groups';
import Contacts from './Contacts';
import Addresses from './Addresses';

export const ProjectInfoDetails = ({ ...rest }) => {
  const isMobile = useIsBreakpointDown('sm');

  return isMobile ? (
    <CollapsibleDetailsPanel>
      <InfoCard {...rest} />
    </CollapsibleDetailsPanel>
  ) : (
    <InfoCard {...rest} />
  );
};

export const InfoCard = ({ showEdit, ...projectDetails }) => {
  const {
    groupSettings,
    legacyDepartmentDisabled,
    legacyEmployeeTypeDisabled,
    featureFlags: {
      isPsaPrpPsaPpmMergerEnabled,
      isPsaPrpAddContactDetailsToTheProjectEnabled
    }
  } = useMeContext();

  const {
    projectId,
    billingType,
    slackChannel,
    projectName,
    projectSlug,
    code,
    startDate,
    endDate,
    earnedRevenueScript,
    defaultScheduleRule,
    projectManagementType,
    projectManagerReference,
    coManagers,
    isProjectLeaderApprovalRequired,
    program,
    portfolio,
    description,
    client,
    clientRepresentative,
    costCenter,
    department,
    division,
    employeeType,
    location,
    serviceCenter,
    customFieldValues,
    extensionFieldValues,
    permittedActionUris,
    timeAndExpenseEntryType,
    isTimeEntryAllowed,
    projectTemplateSetting,
    addresses,
    contacts
  } = projectDetails;

  const templateSettings = useProjectTemplateSettings({
    projectTemplateSetting,
    isPsaPrpPsaPpmMergerEnabled
  });

  const projectPermissions = {
    canEditProject: permittedActionUris.includes(
      'urn:replicon:project-action:edit-project'
    ),
    canViewClient: permittedActionUris.includes(
      'urn:replicon:project-action:view-client'
    ),
    canEditClient: permittedActionUris.includes(
      'urn:replicon:project-action:edit-client'
    ),
    canViewProgram: permittedActionUris.includes(
      'urn:replicon:project-action:view-program'
    ),
    canEditProgram: permittedActionUris.includes(
      'urn:replicon:project-action:edit-program'
    ),
    canViewCustomFields: permittedActionUris.includes(
      'urn:replicon:project-action:view-custom-fields'
    ),
    canEditCustomFields: permittedActionUris.includes(
      'urn:replicon:project-action:edit-custom-fields'
    ),
    canViewCoManagers: permittedActionUris.includes(
      'urn:replicon:project-action:view-project-sharing'
    ),
    canEditCoManagers: permittedActionUris.includes(
      'urn:replicon:project-action:edit-project-sharing'
    ),
    canViewPortfolio: isPsaPrpPsaPpmMergerEnabled
      ? templateSettings?.hasPortfolio &&
        permittedActionUris.includes(
          'urn:replicon:project-action:view-portfolio'
        )
      : permittedActionUris.includes(
          'urn:replicon:project-action:view-portfolio'
        ),
    canEditPortfolio: isPsaPrpPsaPpmMergerEnabled
      ? templateSettings?.hasPortfolio &&
        permittedActionUris.includes(
          'urn:replicon:project-action:edit-portfolio'
        )
      : permittedActionUris.includes(
          'urn:replicon:project-action:edit-portfolio'
        )
  };

  const hasEnabledGroups = Boolean(
    (groupSettings.division.isEnabled &&
      permittedActionUris.includes(
        'urn:replicon:project-action:view-division'
      )) ||
      (groupSettings.serviceCenter.isEnabled &&
        permittedActionUris.includes(
          'urn:replicon:project-action:view-service-center'
        )) ||
      (groupSettings.costCenter.isEnabled &&
        permittedActionUris.includes(
          'urn:replicon:project-action:view-cost-center'
        )) ||
      (groupSettings.location.isEnabled &&
        permittedActionUris.includes(
          'urn:replicon:project-action:view-location'
        )) ||
      (groupSettings.department.isEnabled &&
        permittedActionUris.includes(
          'urn:replicon:project-action:view-department'
        )) ||
      (groupSettings.employeeType.isEnabled &&
        permittedActionUris.includes(
          'urn:replicon:project-action:view-employee-type'
        ))
  );
  const hasGroups = Boolean(
    costCenter ||
      department ||
      division ||
      employeeType ||
      location ||
      serviceCenter
  );

  const editGroups =
    permittedActionUris.includes(
      'urn:replicon:project-action:edit-employee-type'
    ) ||
    permittedActionUris.includes('urn:replicon:project-action:edit-location') ||
    permittedActionUris.includes('urn:replicon:project-action:edit-division') ||
    permittedActionUris.includes(
      'urn:replicon:project-action:edit-department'
    ) ||
    permittedActionUris.includes('urn:replicon:project-action:edit-location') ||
    permittedActionUris.includes(
      'urn:replicon:project-action:edit-service-center'
    );

  const isProjectEditable = showEdit && projectPermissions.canEditProject;
  const isCoManagersEditable = showEdit && projectPermissions.canEditCoManagers;
  const showGroups = hasEnabledGroups && (hasGroups || isProjectEditable);
  const enabledCustomFieldValues =
    (projectPermissions.canViewCustomFields &&
      customFieldValues &&
      customFieldValues.length > 0 &&
      customFieldValues.filter(
        ({ customFieldDefinition }) => customFieldDefinition.isEnabled
      )) ||
    [];

  const hasAdditionalFields =
    (enabledCustomFieldValues && enabledCustomFieldValues.length > 0) ||
    (extensionFieldValues && extensionFieldValues.length > 0);

  return (
    <SideCard>
      <BasicInfo
        showEdit={isProjectEditable || isCoManagersEditable}
        projectId={projectId}
        projectPermissions={projectPermissions}
        slackChannel={slackChannel}
        projectName={projectName}
        projectSlug={projectSlug}
        code={code}
        earnedRevenueScript={earnedRevenueScript}
        startDate={startDate}
        endDate={endDate}
        defaultScheduleRule={defaultScheduleRule}
        projectManagementType={projectManagementType}
        projectManagerReference={projectManagerReference}
        coManagers={coManagers}
        isProjectLeaderApprovalRequired={isProjectLeaderApprovalRequired}
        program={program}
        portfolio={portfolio}
        description={description}
        permittedActionUris={permittedActionUris}
        timeAndExpenseEntryType={timeAndExpenseEntryType}
        isTimeEntryAllowed={isTimeEntryAllowed}
        templateSettings={templateSettings}
        billingType={billingType}
      />
      {projectPermissions.canViewClient && (
        <Clients
          canViewClient={projectPermissions.canViewClient}
          canEditClient={projectPermissions.canEditClient}
          showEdit={isProjectEditable}
          projectId={projectId}
          client={client}
          clientRepresentative={clientRepresentative}
          projectManagerReference={projectManagerReference}
        />
      )}

      {showGroups && (
        <Groups
          showEdit={isProjectEditable && editGroups}
          hasGroups={hasGroups}
          projectId={projectId}
          groupSettings={groupSettings}
          costCenter={costCenter}
          department={department}
          division={division}
          employeeType={employeeType}
          location={location}
          serviceCenter={serviceCenter}
          legacyDepartmentDisabled={legacyDepartmentDisabled}
          legacyEmployeeTypeDisabled={legacyEmployeeTypeDisabled}
          permittedActionUris={permittedActionUris}
        />
      )}
      {isPsaPrpAddContactDetailsToTheProjectEnabled && (
        <>
          <Contacts
            projectId={projectId}
            contacts={contacts}
            showEdit={isProjectEditable}
          />
          <Addresses
            projectId={projectId}
            addresses={addresses}
            showEdit={isProjectEditable}
          />
        </>
      )}

      {hasAdditionalFields && (
        <AdditionalInfo
          projectId={projectId}
          showEdit={isProjectEditable}
          customFieldValues={enabledCustomFieldValues}
          extensionFieldValues={extensionFieldValues}
          canViewCustomFields={projectPermissions.canViewCustomFields}
          canEditCustomFields={projectPermissions.canEditCustomFields}
        />
      )}
    </SideCard>
  );
};

InfoCard.propTypes = {
  showEdit: PropTypes.bool
};

export default ProjectInfoDetails;
