import React from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useHasPermission } from '~/modules/common/permissions/useHasPermission';
import { TaskStatus as TaskStatusType } from '~/types';
import {
  NotStartedTaskHoursRow,
  NotStartedTaskDateRow,
  InProgressTaskHoursRow,
  InProgressTaskDateRow,
  CompleteTaskHoursRow,
  CompleteTaskDateRow,
  EstimateAtCompletion,
  TaskStatus
} from './DataCell';
import useStyles from './useStyles';

export const MobileTaskStatusSection = ({ task, facts, canViewEstimate }) => {
  const intl = useIntl();
  const classes = useStyles();
  const showInProgressTaskData =
    !task.isClosed && task.taskStatus === TaskStatusType.Inprogress;
  const canViewTasks = useHasPermission({
    actionUri: 'urn:replicon:project-action:view-tasks'
  });

  return (
    <>
      <div
        className={classNames(classes.table, classes.wrapper)}
        role="table"
        aria-label={intl.formatMessage({ id: 'taskStatus.taskStatusDetails' })}
      >
        <TaskStatus task={task} classes={classes} />
        {canViewEstimate && (
          <NotStartedTaskHoursRow intl={intl} facts={facts} classes={classes} />
        )}
        {showInProgressTaskData && canViewTasks && (
          <InProgressTaskHoursRow
            intl={intl}
            facts={facts}
            classes={classes}
            canViewEstimate={canViewEstimate}
          />
        )}
        {task.isClosed && canViewTasks && (
          <CompleteTaskHoursRow
            intl={intl}
            facts={facts}
            classes={classes}
            canViewEstimate={canViewEstimate}
          />
        )}
        <NotStartedTaskDateRow intl={intl} facts={facts} classes={classes} />
        {showInProgressTaskData && (
          <InProgressTaskDateRow
            intl={intl}
            facts={facts}
            classes={classes}
            canViewEstimate={canViewEstimate}
          />
        )}
        {task.isClosed && (
          <CompleteTaskDateRow
            intl={intl}
            facts={facts}
            classes={classes}
            canViewEstimate={canViewEstimate}
          />
        )}
      </div>
      {showInProgressTaskData && <EstimateAtCompletion classes={classes} />}
    </>
  );
};

MobileTaskStatusSection.propTypes = {
  task: PropTypes.object.isRequired,
  facts: PropTypes.object,
  canViewEstimate: PropTypes.bool
};

export default MobileTaskStatusSection;
