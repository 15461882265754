import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { ReportProblemOutlined } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { TaskStatus } from '~/types';
import {
  ProjectIcon,
  TaskIcon,
  TaskParentIcon
} from '~/modules/common/components';
import DateRange from '~/modules/common/components/DateRange';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  containerPadding: {
    paddingLeft: theme.spacing(0.5)
  },
  taskName: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    wordBreak: 'break-word'
  },
  titleIcon: {
    marginRight: theme.spacing(1.25),
    fill: theme.palette.text.secondary
  },
  headerContainer: {
    display: 'flex'
  },
  iconContainer: {
    margin: 'auto'
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.spacing(1.5)
  },
  dateRangeContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  statusPill: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(0.5, 2),
    margin: theme.spacing(1, 0),
    fontSize: theme.spacing(1.5),
    fontWeight: '600',
    color: theme.palette.text.main,
    whiteSpace: 'nowrap'
  },
  notStarted: {
    backgroundColor: theme.palette.taskStatus.NOTSTARTED.color
  },
  inProgress: {
    backgroundColor: theme.palette.taskStatus.INPROGRESS.color
  },
  completed: {
    backgroundColor: theme.palette.taskStatus.COMPLETED.color
  },
  icon: {
    fontSize: theme.spacing(2.25),
    fill: theme.palette.error.main
  },
  code: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 'inherit',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: theme.spacing(1)
  }
}));

const useDateStyles = makeStyles(theme => ({
  date: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 400,
    color: theme.palette.text.secondary
  },
  spacer: {
    color: theme.palette.text.secondary
  }
}));

const TaskCardTitle2 = ({
  name,
  code,
  hasChildren = false,
  isProject = false,
  startDate,
  endDate,
  taskStatus
}) => {
  const classes = useStyles();
  const dateClasses = useDateStyles();
  const missingDateMessage =
    !startDate && !endDate
      ? 'projectTasksPage.datesAreMissing'
      : !startDate
      ? 'projectTasksPage.startDateMissing'
      : 'projectTasksPage.endDateMissing';

  const IconComponent = isProject
    ? ProjectIcon
    : hasChildren
    ? TaskParentIcon
    : TaskIcon;

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.iconContainer}>
          <IconComponent className={classes.titleIcon} />
        </div>
        <div>
          <Typography className={classes.taskName} variant="h6">
            {name}
            {code && (
              <Typography
                component="span"
                variant="body2"
                className={classes.code}
              >
                {code}
              </Typography>
            )}
          </Typography>
          <div className={classes.dateContainer}>
            <div className={classes.dateRangeContainer}>
              {!startDate && <ReportProblemOutlined className={classes.icon} />}
              <DateRange
                classes={dateClasses}
                start={startDate}
                end={endDate}
              />
              {!endDate && <ReportProblemOutlined className={classes.icon} />}
            </div>
            {(!startDate || !endDate) && (
              <FormattedMessage id={missingDateMessage} />
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames(classes.statusPill, {
          [classes.notStarted]: taskStatus === TaskStatus.Notstarted,
          [classes.inProgress]: taskStatus === TaskStatus.Inprogress,
          [classes.completed]: taskStatus === TaskStatus.Completed
        })}
      >
        <FormattedMessage id={`projectTasksPage.tasksStatus.${taskStatus}`} />
      </div>
    </div>
  );
};

TaskCardTitle2.propTypes = {
  hasChildren: PropTypes.bool,
  isProject: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  taskStatus: PropTypes.string,
  name: PropTypes.string,
  code: PropTypes.string
};

export default TaskCardTitle2;
