import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ProjectAllocationIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <>
      <path d="m2 4v16h20v-14h-10l-2-2h-8zm10.002 5c1.1 0 1.998 0.89996 1.998 2 0 1.1-0.89801 1.998-1.998 1.998-1.1 0-2.002-0.89801-2.002-1.998 0-1.1 0.90192-2 2.002-2zm0 5c0.99004 0 1.9293 0.21006 2.7793 0.58008 0.74003 0.32001 1.2188 1.0396 1.2188 1.8496v0.57031h-8v-0.57031c0-0.81003 0.47872-1.5296 1.2188-1.8496 0.85003-0.37001 1.7932-0.58008 2.7832-0.58008z" />
    </>
  </SvgIcon>
));

export default ProjectAllocationIcon;
