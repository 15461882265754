import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { ErrorSharp as ErrorIcon } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import TaskPath from '~/modules/common/components/entities/task/TaskPath';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import { RowLeftItemActions } from './RowLeftItemActions';

const useTaskStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  taskName: {
    ...theme.typography.body2,
    color: theme.palette.text.primary
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(3),
    justifyContent: 'center'
  },
  codeLabel: {
    ...theme.typography.body2,
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  roleLabel: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary
  },
  noRoleLabel: {
    fontStyle: 'italic'
  },
  errorIcon: {
    fontSize: theme.typography.h5.fontSize,
    marginRight: theme.spacing(0.3),
    color: theme.palette.error.main
  }
}));

const RowLeftItem = ({
  fullPath,
  task,
  projectRole,
  user,
  projectId,
  hasTaskAllocation,
  handleRemoveTaskAssignment,
  isRowOverDistributed,
  resetResourceAllocationChart
}) => {
  const taskClasses = useTaskStyles();
  const classes = useStyles();
  const {
    projectObjectPermissions: { canEditTasks }
  } = useProjectContext();
  const { formatMessage } = useIntl();
  const { code } = task;
  const taskPath = useMemo(
    () => ({
      path: fullPath.map(p => p.displayText)
    }),
    [fullPath]
  );

  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <TaskPath task={taskPath} classes={taskClasses}>
            <Typography component="span" className={classes.codeLabel}>
              {code}
            </Typography>
          </TaskPath>

          <Typography
            className={classNames(classes.roleLabel, {
              [classes.noRoleLabel]: !projectRole
            })}
          >
            {projectRole ? (
              projectRole.displayText
            ) : (
              <FormattedMessage id="taskAssignments.noRole" />
            )}
          </Typography>
        </div>
        {isRowOverDistributed && (
          <Tooltip
            title={formatMessage({ id: 'taskAssignments.rowOverAllocation' })}
            placement="right"
          >
            <ErrorIcon className={classes.errorIcon} />
          </Tooltip>
        )}
      </div>
      {canEditTasks && (
        <RowLeftItemActions
          task={task}
          user={user}
          projectId={projectId}
          hasTaskAllocation={hasTaskAllocation}
          handleRemoveTaskAssignment={handleRemoveTaskAssignment}
          resetResourceAllocationChart={resetResourceAllocationChart}
        />
      )}
    </>
  );
};

RowLeftItem.propTypes = {
  task: PropTypes.object,
  fullPath: PropTypes.array,
  projectRole: PropTypes.object,
  user: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  hasTaskAllocation: PropTypes.bool.isRequired,
  handleRemoveTaskAssignment: PropTypes.func.isRequired,
  isRowOverDistributed: PropTypes.bool,
  resetResourceAllocationChart: PropTypes.func.isRequired
};

export default RowLeftItem;
