import { useTotalResourceAvailableDurationForDateRange } from '~/modules/tasks/hooks/useTotalResourceAvailableDurationForDateRange';
import {
  isoStringToObject,
  mapIsoStringtoUtcObject
} from '~/modules/common/dates/convert';
import useProjectAllocationForResourceUser from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/hooks/useProjectAllocationForResourceUser';
import { getTotalHoursForDateRangeFromScheduleRules } from '~/modules/resourcing/common/util';
import { isNullOrUndefined } from '~/modules/common/util';

export const useTaskResourceUserOverAllocationSummary = ({
  resourceUserId,
  startDate,
  endDate,
  projectUri,
  taskAllocationHours
}) => {
  const { availableDuration } = useTotalResourceAvailableDurationForDateRange({
    endDate: isoStringToObject(endDate),
    resourceUserId,
    startDate: isoStringToObject(startDate),
    skip: !resourceUserId
  });

  const { resourceAllocation } = useProjectAllocationForResourceUser({
    projectId: projectUri,
    userId: resourceUserId
  });

  let projectAllocationHours;

  if (resourceAllocation) {
    projectAllocationHours = getTotalHoursForDateRangeFromScheduleRules({
      scheduleRules: resourceAllocation.scheduleRules,
      start: mapIsoStringtoUtcObject(startDate),
      end: mapIsoStringtoUtcObject(endDate)
    });
  }

  return {
    isOverAllocated: availableDuration < 0,
    isOverDistributed: isNullOrUndefined(projectAllocationHours)
      ? false
      : projectAllocationHours - taskAllocationHours < 0
  };
};

export default useTaskResourceUserOverAllocationSummary;
