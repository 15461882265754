import { PropTypes } from 'prop-types';
import { TaskStatus } from '~/types';
import {
  mapRepliconDateToUtcObject,
  mapIsoStringtoUtcObject
} from '~/modules/common/dates/convert';
import {
  getEstimatedHoursVariation,
  getEstimatedCostVariation,
  getEstimatedProjectCostVariation
} from '~/modules/projects/tasks/components/Gantt/components/util';

export const getVariationDetails = ({ task }) => {
  const {
    taskStatus,
    closedOnDate,
    initialEstimatedHours,
    rolledUpSummary,
    rolledUpCostSummary,
    endDate
  } = task;

  const luxonCompletionDate =
    rolledUpSummary?.estimatedCompletionDate &&
    mapIsoStringtoUtcObject(rolledUpSummary?.estimatedCompletionDate);
  const luxonClosedOnDate =
    closedOnDate && mapRepliconDateToUtcObject(closedOnDate);

  const luxonEndDate = endDate && mapRepliconDateToUtcObject(endDate);

  const luxonEstimatedCompletionDate =
    taskStatus === TaskStatus.Completed
      ? luxonClosedOnDate || luxonCompletionDate || luxonEndDate
      : luxonCompletionDate || luxonEndDate;

  const variationDays =
    luxonEstimatedCompletionDate && luxonEndDate
      ? luxonEstimatedCompletionDate.diff(luxonEndDate, 'days').values.days || 0
      : null;
  const { variationStatus, variation } = getEstimatedHoursVariation({
    estimatedHours: initialEstimatedHours,
    totalEstimatedAtCompletionHours:
      rolledUpSummary?.totalEstimatedAtCompletionHours
  });

  const { variationCost, costVariationStatus } = getEstimatedCostVariation({
    estimatedCost: rolledUpCostSummary?.initialEstimatedCostInProjectCurrency,
    totalEstimatedAtCompletionCost:
      rolledUpCostSummary?.totalEstimatedAtCompletionCostInProjectCurrency
  });

  const {
    projectVariationCost,
    projectCostVariationStatus
  } = getEstimatedProjectCostVariation({
    budgetedCost: rolledUpCostSummary?.budgetedCostInProjectCurrency,
    totalEstimatedAtCompletionCost:
      rolledUpCostSummary?.totalEstimatedAtCompletionCostInProjectCurrency
  });

  return {
    variationDays,
    variationStatus,
    variationHours: variation,
    variationCost,
    costVariationStatus,
    projectVariationCost,
    projectCostVariationStatus
  };
};

const useCalculateVariationDetails = ({ task }) =>
  getVariationDetails({ task });

useCalculateVariationDetails.propTypes = {
  task: PropTypes.object.isRequired
};

export default useCalculateVariationDetails;
