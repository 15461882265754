import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

export const MUTATE_PARENT_TASK_DATE_ROLLUP_SETTING = gql`
  mutation updateParentTaskDateRollupSetting(
    $projectId: String!
    $optionUri: String!
  ) {
    updateParentTaskDateRollupSetting(
      projectId: $projectId
      optionUri: $optionUri
    )
  }
`;

export const PARENT_TASK_DATE_ROLLUP_QUERY = gql`
  query getProjectDetails($projectId: String!) {
    project(projectId: $projectId) {
      id
      isTaskDateRollupEnabled
    }
  }
`;

export const PARENT_TASK_DATE_ROLLUP_OPTION = {
  SetExplicitly: 'urn:replicon:parent-task-date-rollup-option:set-explicitly',
  CalculateAutomatically:
    'urn:replicon:parent-task-date-rollup-option:calculated-from-descendants'
};

export const updateCache = ({ projectId }) => (cache, { data }) => {
  const storedResult = cache.readQuery({
    query: PARENT_TASK_DATE_ROLLUP_QUERY,
    variables: {
      projectId
    }
  });

  const returnedOptionUri = data?.updateParentTaskDateRollupSetting;

  cache.writeQuery({
    query: PARENT_TASK_DATE_ROLLUP_QUERY,
    variables: {
      projectId
    },
    data: {
      project: {
        ...storedResult.project,
        isTaskDateRollupEnabled:
          returnedOptionUri ===
          PARENT_TASK_DATE_ROLLUP_OPTION.CalculateAutomatically
      }
    }
  });
};

export const useUpdateParentTaskDateRollupSetting = () => {
  const [setParentTaskDateRollup, { loading }] = useMutation(
    MUTATE_PARENT_TASK_DATE_ROLLUP_SETTING
  );

  return {
    setParentTaskDateRollup: async (projectId, optionUri) => {
      await setParentTaskDateRollup({
        variables: { projectId, optionUri },
        update: updateCache({ projectId })
      });
    },
    loading
  };
};

export default useUpdateParentTaskDateRollupSetting;
