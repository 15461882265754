import { Grid, Typography, makeStyles } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDownSharp';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { UserAvatar } from '~/modules/common/components/Avatars';
import { PlaceholderIcon } from '~/modules/common/components/Icons';
import useKeyBoardEventHandlers from '~/modules/common/hooks/useKeyBoardEventHandlers';
import { useDialogState, useHasFeatureFlag } from '~/modules/common/hooks';
import AddResourceEstimateDialog from '../../AddResourceEstimateDialog/AddResourceEstimateDialog';
import { ResourceRoleDropdownEditor } from './components';

export const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.grey[300],
    margin: theme.spacing(0, 1.25, 0, 0.75),
    width: '1.5em',
    height: '100%',
    borderRadius: theme.spacing(10),
    pointerEvents: 'none'
  },
  avatar: {
    margin: theme.spacing(0, 1.25, 0, 0.75),
    pointerEvents: 'none'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    minWidth: theme.spacing(10),
    padding: `${theme.spacing(0.5, 0)} !important`
  },
  noResourceText: {
    color: theme.palette.text.secondary
  },
  resourceText: {
    ...theme.typography.body2,
    padding: theme.spacing(0.5, 0, 0),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1,
    wordBreak: 'break-all'
  },
  roleText: {
    ...theme.typography.caption,
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    lineHeight: 1
  },
  displayNames: {
    pointerEvents: 'none',
    padding: theme.spacing(0, 3.25, 0, 0)
  },
  hover: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer'
    }
  },
  arrowDropdown: {
    pointerEvents: 'none',
    fill: theme.palette.text.secondary
  }
}));

export const InnerResourceRoleGridItem = ({
  canEditTasks,
  resourceEstimate,
  rowIndex,
  isReadOnlyView,
  task
}) => {
  const classes = useStyles();
  const resourceUserName = resourceEstimate?.resourceUser?.displayText;

  const [anchorEl, setAnchorEl] = useState(null);
  const { open, openDialog, closeDialog } = useDialogState(false, {
    disabledBackDropClick: true,
    disableEscapeKeyDown: true
  });

  const isRmpTaskAllocationPhase2Enabled = useHasFeatureFlag({
    featureFlag: 'isRmpTaskAllocationPhase2Enabled'
  });

  const onOpen = useCallback(
    event => {
      if (!isReadOnlyView && canEditTasks) {
        if (isRmpTaskAllocationPhase2Enabled && !resourceUserName) {
          event.stopPropagation();
          openDialog();
        } else {
          setAnchorEl(event.target);
        }
      }
    },
    [
      isRmpTaskAllocationPhase2Enabled,
      isReadOnlyView,
      canEditTasks,
      resourceUserName,
      openDialog
    ]
  );

  const { handleKeyDown } = useKeyBoardEventHandlers({ onEnterClick: onOpen });

  return (
    <>
      <Grid
        item
        className={classNames(classes.container, {
          [classes.hover]: !isReadOnlyView && canEditTasks
        })}
        onClick={onOpen}
        onKeyDown={handleKeyDown}
        tabIndex={!isReadOnlyView && canEditTasks ? 0 : -1}
        data-qe-id="ResourceRoleGridItem"
      >
        {resourceUserName ? (
          <UserAvatar
            user={resourceEstimate?.resourceUser}
            className={classes.avatar}
            size="small"
          />
        ) : (
          <PlaceholderIcon className={classes.icon} />
        )}
        <div className={classes.displayNames}>
          <Typography
            className={classNames(classes.resourceText, {
              [classes.noResourceText]: !resourceUserName
            })}
          >
            {resourceUserName || (
              <FormattedMessage id="taskResourceEstimates.noResourceSelected" />
            )}
          </Typography>
          <Typography className={classes.roleText}>
            {resourceEstimate?.projectRole?.displayText || (
              <FormattedMessage id="taskResourceEstimates.noRole" />
            )}
          </Typography>
        </div>
        {!isReadOnlyView && canEditTasks && (
          <ArrowDropDownIcon className={classes.arrowDropdown} />
        )}
      </Grid>

      {Boolean(anchorEl) && (
        <ResourceRoleDropdownEditor
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          resourceEstimate={resourceEstimate}
          rowIndex={rowIndex}
        />
      )}
      {open && (
        <AddResourceEstimateDialog
          task={task}
          isOpen={open}
          onClose={closeDialog}
          resourceEstimate={resourceEstimate}
        />
      )}
    </>
  );
};

InnerResourceRoleGridItem.propTypes = {
  canEditTasks: PropTypes.bool,
  resourceEstimate: PropTypes.object.isRequired,
  rowIndex: PropTypes.number,
  isReadOnlyView: PropTypes.bool,
  task: PropTypes.object
};

export const ResourceRoleGridItem = ({
  canEditTasks,
  resourceEstimate,
  rowIndex,
  isReadOnlyView,
  task
}) => {
  return (
    <InnerResourceRoleGridItem
      canEditTasks={canEditTasks}
      resourceEstimate={resourceEstimate}
      rowIndex={rowIndex}
      isReadOnlyView={isReadOnlyView}
      task={task}
    />
  );
};

ResourceRoleGridItem.propTypes = {
  canEditTasks: PropTypes.bool,
  resourceEstimate: PropTypes.object.isRequired,
  rowIndex: PropTypes.number,
  isReadOnlyView: PropTypes.bool,
  task: PropTypes.object.isRequired
};

export const ResourceRoleGridItemReadOnly = ({
  resourceEstimate,
  canEditTasks
}) => {
  return (
    <InnerResourceRoleGridItem
      canEditTasks={canEditTasks}
      resourceEstimate={resourceEstimate}
      isReadOnlyView
    />
  );
};

ResourceRoleGridItemReadOnly.propTypes = {
  canEditTasks: PropTypes.bool,
  resourceEstimate: PropTypes.object.isRequired
};

export default ResourceRoleGridItem;
