import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import {
  Grid,
  FormControlLabel,
  TextField,
  makeStyles,
  Typography
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  GridItem,
  ReadOnlyField,
  User,
  DateField,
  ReadOnlyContainer
} from '~/modules/common/components';
import SlackLogo from '../../common/slack.svg';
import ProjectCoManagerSelect from './ProjectCoManagerSelect';
import {
  projectType,
  projectLeaderApprovalField,
  getSelectedTimeAndExpenseEntryType
} from './BasicInfo.util';

const resourceLabels = {
  name: <FormattedMessage id="projectBasicInfoCard.name" />,
  code: <FormattedMessage id="projectBasicInfoCard.code" />,
  slackChannel: <FormattedMessage id="projectBasicInfoCard.slackChannel" />,
  startDate: <FormattedMessage id="projectBasicInfoCard.startDate" />,
  endDate: <FormattedMessage id="projectBasicInfoCard.endDate" />,
  projectManagerReference: (
    <FormattedMessage id="projectBasicInfoCard.projectManager" />
  ),
  projectManagementType: (
    <FormattedMessage id="projectBasicInfoCard.projectType" />
  ),
  pmApprovalRequired: (
    <FormattedMessage id="projectBasicInfo.pmApprovalRequired" />
  ),
  program: <FormattedMessage id="projectBasicInfoCard.program" />,
  portfolio: <FormattedMessage id="projectBasicInfoCard.portfolio" />,
  allowEntryWithoutTasks: (
    <FormattedMessage id="projectBasicInfoCard.allowEntryWithoutTasks" />
  ),
  contractType: <FormattedMessage id="earnedRevenue.contractType" />,
  description: <FormattedMessage id="projectBasicInfoCard.description" />,
  timeAndExpenseEntryLabel: (
    <FormattedMessage id="projectBasicInfo.timeAndExpenseEntry" />
  ),
  allowTimeEntryAgainstTasksOnlyLabel: (
    <FormattedMessage id="projectBasicInfo.allowTimeEntryAgainstTasksOnly.label" />
  )
};

const getAllowTimeEntryAgainstTasksOnly = isTimeEntryAllowed => (
  <FormattedMessage
    id={
      isTimeEntryAllowed
        ? 'projectBasicInfo.allowTimeEntryAgainstTasksOnly.options.no'
        : 'projectBasicInfo.allowTimeEntryAgainstTasksOnly.options.yes'
    }
  />
);

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: theme.typography.caption.fontSize
  },
  user: {
    paddingTop: 0
  },
  slackLogo: {
    width: '18px'
  }
}));

export const BasicInfoReadOnly = ({
  formik: { values, setFieldValue },
  projectPermissions,
  templateSettings,
  billingType
}) => {
  const classes = useStyles();
  const userClasses = useMemo(() => ({ name: classes.user }), [classes.user]);
  const formControlClasses = useMemo(() => ({ label: classes.label }), [
    classes.label
  ]);
  const inputProps = useMemo(
    () => ({
      disableUnderline: true,
      style: { color: 'rgb(50, 50, 50)' }
    }),
    []
  );

  const handleCoManagerChange = useCallback(
    (vals, _) => {
      setFieldValue('coManagers', vals || []);
    },
    [setFieldValue]
  );

  const {
    name,
    code,
    startDate,
    endDate,
    description,
    program,
    portfolio,
    projectManagerReference,
    coManagers,
    isProjectLeaderApprovalRequired,
    projectTypeReference,
    slackChannel,
    timeAndExpenseEntryType,
    earnedRevenueScript,
    isTimeEntryAllowed
  } = values;
  const {
    featureFlags: {
      isPsaEarnedRevenueEnabled,
      isPsaPrpPsaPpmMergerEnabled,
      isPsaPrpEnhancedAllowEntryWithoutTasksDropdownEnabled
    },
    hasViewProjectBillingOptions
  } = useMeContext();

  const projectLeaderApprovalRequired = useMemo(
    () => projectLeaderApprovalField(isProjectLeaderApprovalRequired, null),
    [isProjectLeaderApprovalRequired]
  );

  const projectTimeAndExpenseEntryType = getSelectedTimeAndExpenseEntryType({
    isTimeEntryAllowed,
    hasViewProjectBillingOptions: isPsaPrpPsaPpmMergerEnabled
      ? templateSettings?.hasBilling && hasViewProjectBillingOptions
      : hasViewProjectBillingOptions,
    timeAndExpenseEntryType,
    isPsaPrpEnhancedAllowEntryWithoutTasksDropdownEnabled
  });

  const showTimeAndExpenseEntryTypeField =
    (isPsaPrpPsaPpmMergerEnabled ? templateSettings?.hasBilling : true) &&
    hasViewProjectBillingOptions &&
    billingType?.id !== 'urn:replicon:billing-type:non-billable';

  return (
    <Grid container>
      <GridItem rightPadding>
        <ReadOnlyField label={resourceLabels.name} value={name} />
      </GridItem>
      <GridItem>
        <ReadOnlyField label={resourceLabels.code} value={code} />
      </GridItem>
      <GridItem>
        <DateField
          variant="standard"
          fullWidth
          clearable={false}
          label={resourceLabels.startDate}
          value={startDate}
          editable={false}
          underline={false}
        />
      </GridItem>
      <GridItem>
        <DateField
          variant="standard"
          fullWidth
          clearable={false}
          label={resourceLabels.endDate}
          value={endDate}
          editable={false}
          underline={false}
        />
      </GridItem>
      <GridItem>
        <ReadOnlyContainer label={resourceLabels.projectManagerReference}>
          <User user={projectManagerReference} classes={userClasses} />
        </ReadOnlyContainer>
      </GridItem>
      <GridItem>
        <ReadOnlyContainer>
          <FormControlLabel
            classes={formControlClasses}
            control={projectLeaderApprovalRequired}
            label={resourceLabels.pmApprovalRequired}
            disabled
          />
        </ReadOnlyContainer>
      </GridItem>
      {projectPermissions.canEditCoManagers && (
        <GridItem fullWidth>
          <ReadOnlyContainer>
            <ProjectCoManagerSelect
              coManagers={coManagers}
              onChange={handleCoManagerChange}
            />
          </ReadOnlyContainer>
        </GridItem>
      )}
      {projectPermissions.canViewPortfolio && (
        <GridItem>
          <ReadOnlyField
            label={resourceLabels.portfolio}
            value={portfolio?.displayText}
          />
        </GridItem>
      )}
      {projectPermissions.canViewProgram && (
        <GridItem>
          <ReadOnlyField
            label={resourceLabels.program}
            value={program?.displayText}
          />
        </GridItem>
      )}
      <GridItem>
        <ReadOnlyContainer label={resourceLabels.slackChannel}>
          <img src={SlackLogo} alt="Slack Logo" className={classes.slackLogo} />
          <Typography variant="body2">{slackChannel}</Typography>
        </ReadOnlyContainer>
      </GridItem>
      {isPsaEarnedRevenueEnabled && (
        <GridItem>
          <ReadOnlyField
            label={resourceLabels.contractType}
            value={earnedRevenueScript?.name}
          />
        </GridItem>
      )}
      <GridItem>
        <ReadOnlyField
          label={resourceLabels.projectManagementType}
          value={projectType(projectTypeReference)}
        />
      </GridItem>
      <GridItem fullWidth>
        {description ? (
          <ReadOnlyContainer label={resourceLabels.description}>
            <TextField
              variant="standard"
              value={description}
              disabled
              fullWidth
              multiline
              rows={1}
              hiddenLabel
              InputProps={inputProps}
            />
          </ReadOnlyContainer>
        ) : (
          <ReadOnlyField
            label={resourceLabels.description}
            value={description}
          />
        )}
      </GridItem>
      {isPsaPrpEnhancedAllowEntryWithoutTasksDropdownEnabled ? (
        <>
          {showTimeAndExpenseEntryTypeField && (
            <GridItem>
              <ReadOnlyField
                label={resourceLabels.timeAndExpenseEntryLabel}
                value={projectTimeAndExpenseEntryType}
              />
            </GridItem>
          )}
          <GridItem>
            <ReadOnlyField
              label={resourceLabels.allowTimeEntryAgainstTasksOnlyLabel}
              value={getAllowTimeEntryAgainstTasksOnly(isTimeEntryAllowed)}
            />
          </GridItem>
        </>
      ) : (
        <GridItem>
          <ReadOnlyField
            label={resourceLabels.allowEntryWithoutTasks}
            value={projectTimeAndExpenseEntryType}
          />
        </GridItem>
      )}
    </Grid>
  );
};

BasicInfoReadOnly.propTypes = {
  formik: PropTypes.object,
  projectPermissions: PropTypes.object,
  templateSettings: PropTypes.object,
  billingType: PropTypes.object
};

export default connect(BasicInfoReadOnly);
