import React from 'react';
import PropTypes from 'prop-types';
import {
  OverviewChart,
  OverviewChartLoading
} from '~/modules/common/charts/dashboard';
import { useMeContext } from '~/modules/me/useMeContext';
import { useProjectOverviewSummary } from './hooks';

export const ProjectOverviewChart = ({ chartDataInput }) => {
  const me = useMeContext();
  const {
    featureFlags: { isPsaPrp2024q4BugFixesEnabled }
  } = me;

  const {
    projectId,
    dateRange,
    isProjectCurrencyChanged,
    isBillPlanChanged,
    projectPermissions: { canViewProjectCostData, canViewProjectBillingData }
  } = chartDataInput;

  const {
    costAmountSummary,
    revenueAmountSummary,
    profitAmountSummary,
    timeEnteredSummary,
    loading
  } = useProjectOverviewSummary({
    projectId,
    dateRange
  });

  if (loading || isProjectCurrencyChanged || isBillPlanChanged)
    return <OverviewChartLoading />;

  return (
    <OverviewChart
      showCost={
        isPsaPrp2024q4BugFixesEnabled ? canViewProjectCostData : undefined
      }
      costAmountSummary={costAmountSummary}
      revenueAmountSummary={revenueAmountSummary}
      profitAmountSummary={profitAmountSummary}
      timeEnteredSummary={timeEnteredSummary}
      showRevenue={Boolean(canViewProjectBillingData)}
    />
  );
};

ProjectOverviewChart.propTypes = {
  chartDataInput: PropTypes.object.isRequired
};

export default ProjectOverviewChart;
