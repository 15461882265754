import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { RemoveCircleSharp } from '@material-ui/icons';
import { useProjectLinkActions } from '~/modules/projects/project/ProjectLinksTab/hooks/useProjectLinkActions';
import DeleteConfirmationDialog from '~/modules/projects/project/ProjectLinks/DeleteConfirmationDialog';
import { PROJECT_LINK_TYPE } from '~/modules/projects/project/ProjectLinksTab/enum';
import { useProjectLinkProjectsContext } from '~/modules/projects/project/ProjectLinksTab/ProjectLinkProjectsContext';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '100%'
  },
  linkTypeLabel: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1)
  },
  deleteButton: {
    padding: theme.spacing(0)
  }
}));

export const ProjectLinkType = ({
  field,
  record,
  classes: overridenClasses
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles({ classes: overridenClasses });
  const link = record[field];

  const {
    onRemoveLinkClick,
    onRemoveConfirm,
    closeRemoveDialog,
    isRemoving,
    showRemoveDialog
  } = useProjectLinkActions();
  const { canEditProject } = useProjectLinkProjectsContext();

  return (
    <>
      <div className={classes.container}>
        <>
          <Typography className={classes.linkTypeLabel}>
            <FormattedMessage id={`projectLink.${link.linkType}`} />
          </Typography>
          {canEditProject && link.linkType !== PROJECT_LINK_TYPE.SELF && (
            <IconButton
              className={classes.deleteButton}
              onClick={onRemoveLinkClick}
              aria-label={formatMessage({ id: 'button.remove' })}
            >
              <RemoveCircleSharp />
            </IconButton>
          )}
        </>
      </div>
      {showRemoveDialog && (
        <DeleteConfirmationDialog
          open={showRemoveDialog}
          onRemove={() => onRemoveConfirm(link)}
          onCancel={closeRemoveDialog}
          link={link}
          isLoading={isRemoving}
        />
      )}
    </>
  );
};

ProjectLinkType.propTypes = {
  classes: PropTypes.object,
  field: PropTypes.string,
  record: PropTypes.object
};

export default ProjectLinkType;
