import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useIntl } from 'react-intl';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  omitForbiddenFields,
  buildCreateTaskResourceUserAllocationOptimisticResponse,
  buildUpdateTaskResourceUserAllocationOptimisticResponse
} from '~/modules/common/components/TaskDrawer/common/hooks/taskAllocationSaveUtil';
import { TASK_RESOURCE_USER_ALLOCATIONS_SUMMARY_QUERY } from '~/modules/common/components/TaskDrawer/common/hooks/useGetTaskResourceUserAllocationsSummaryForUser';
import { BULK_GET_TASK_RESOURCE_ALLOCATIONS_QUERY } from '~/modules/resourcing/common/hooks/useBulkGetTaskResourceUserAllocations';
import { calculateTaskAllocationScheduleRulesOnDrag } from '~/modules/resourcing/common/hooks/useTaskAllocationDragIndicators';
import {
  buildRuleConstraintsForPeriod,
  getDateRangeFromScheduleRules,
  getTotalHoursForDateRangeFromScheduleRules,
  mergePeriodRuleIntoScheduleRules
} from '~/modules/resourcing/common/util';
import {
  dateToMidnightUTCObject,
  mapIsoStringtoUtcObject
} from '~/modules/common/dates/convert';
import { useUserAllocationsSummaryContext } from '../components/ResourceAllocationChartRow/UserAllocationsSummaryContext';

export const tryLoadCachedTaskResourceUserAllocationsSummaryQuery = ({
  proxy,
  variables
}) => {
  try {
    return proxy.readQuery({
      query: TASK_RESOURCE_USER_ALLOCATIONS_SUMMARY_QUERY,
      variables
    });
  } catch (e) {
    return null;
  }
};

export const updateTaskResourceUserAllocationsSummaryForUserCache = ({
  periodDetails,
  projectId,
  userId,
  proxy
}) => {
  const variables = {
    userId,
    filter: {
      projectId
    }
  };
  const results = tryLoadCachedTaskResourceUserAllocationsSummaryQuery({
    proxy,
    variables
  });

  if (!results) return;

  const { getTaskResourceUserAllocationsSummaryForUser } = results;

  const {
    periodStartDate,
    periodEndDate,
    newPeriodTaskAllocatedHours: newPeriodHours,
    periodTaskAllocatedHours: originalPeriodHours
  } = periodDetails;

  const totalUserHours = getTotalHoursForDateRangeFromScheduleRules({
    start: periodStartDate,
    end: periodEndDate,
    scheduleRules:
      getTaskResourceUserAllocationsSummaryForUser?.scheduleRules || [],
    useVersion2: true
  });

  const scheduleRule = {
    dateRange: {
      startDate: periodStartDate.toISO(),
      endDate: periodEndDate.toISO()
    },
    do: buildRuleConstraintsForPeriod({
      start: periodStartDate,
      end: periodEndDate,
      totalHours:
        (totalUserHours || 0) - (originalPeriodHours || 0) + newPeriodHours,
      quantity: 1,
      excludeWeekdays: [],
      load: 100
    })
  };

  const scheduleRules = getTaskResourceUserAllocationsSummaryForUser
    ? mergePeriodRuleIntoScheduleRules(
        getTaskResourceUserAllocationsSummaryForUser.scheduleRules,
        true
      )(scheduleRule)
    : [scheduleRule];

  const updatedTaskResourceUserAllocationSummary = {
    scheduleRules,
    startDate: scheduleRule.dateRange.startDate,
    endDate: scheduleRule.dateRange.endDate,
    totalHours: newPeriodHours,
    userId
  };

  proxy.writeQuery({
    query: TASK_RESOURCE_USER_ALLOCATIONS_SUMMARY_QUERY,
    variables,
    data: {
      getTaskResourceUserAllocationsSummaryForUser: updatedTaskResourceUserAllocationSummary
    }
  });
};

export const updateCacheOnCreateTaskAllocation = ({
  userId,
  projectId,
  taskId,
  periodDetails
}) => (proxy, { data }) => {
  const {
    createTaskResourceUserAllocation: { taskResourceUserAllocation }
  } = data;

  proxy.writeQuery({
    query: BULK_GET_TASK_RESOURCE_ALLOCATIONS_QUERY,
    variables: {
      filter: {
        userUri: userId,
        projectUri: projectId,
        taskUris: [taskId]
      }
    },
    data: { taskResourceUserAllocationsForUser: [taskResourceUserAllocation] }
  });

  updateTaskResourceUserAllocationsSummaryForUserCache({
    periodDetails,
    projectId,
    userId,
    proxy
  });
};

export const updateCacheOnEditTaskAllocation = ({
  userId,
  projectId,
  periodDetails
}) => proxy => {
  updateTaskResourceUserAllocationsSummaryForUserCache({
    periodDetails,
    projectId,
    userId,
    proxy
  });
};

export const filterScheduleRules = scheduleRules =>
  scheduleRules.filter(rule => rule.do.setHours > 0);

export const setSnackBarMessageOnDrag = ({
  updatedTaskAllocationScheduleRules,
  startDate: dragStartDate,
  endDate: dragEndDate,
  setSnackBarState,
  formatMessage,
  taskAllocation,
  isRmpTaskAllocationPhase2Enabled
}) => {
  if (isRmpTaskAllocationPhase2Enabled) return;

  const { startDate, endDate } = taskAllocation;
  const isLeftExpanded = dragStartDate < mapIsoStringtoUtcObject(startDate);
  const isRightExpanded = dragEndDate > mapIsoStringtoUtcObject(endDate);

  const {
    startDate: newStartDate,
    endDate: newEndDate
  } = getDateRangeFromScheduleRules(updatedTaskAllocationScheduleRules);

  if (
    (isLeftExpanded && mapIsoStringtoUtcObject(newStartDate) > dragStartDate) ||
    (isRightExpanded && mapIsoStringtoUtcObject(newEndDate) < dragEndDate)
  ) {
    setSnackBarState({
      open: true,
      message: formatMessage({
        id: 'taskAllocationChangeSnackbar.limitedTimeToAllocationMessage'
      })
    });
  }
};
export const useTaskAssignmentTimelineEditorChangeHandlers = ({
  updateTaskResourceUserAllocation,
  createTaskResourceUserAllocation,
  taskResourceUserAllocation,
  taskResourceEstimate,
  projectId,
  userId,
  openReleaseTaskDialogsContainer,
  setSnackBarState
}) => {
  const {
    tenantSlug,
    featureFlags: { isRmpTaskAllocationPhase2Enabled }
  } = useMeContext();
  const { formatMessage } = useIntl();

  const {
    userTaskAllocationsSummaryScheduleRules,
    resourceAllocationScheduleRules
  } = useUserAllocationsSummaryContext();

  const onPeriodClose = useCallback(
    ({ allocation, periodDetails }) => {
      const { scheduleRules, id, taskUri, projectUri } = allocation;
      const filteredScheduleRules = filterScheduleRules(scheduleRules);
      const newTaskAllocationId =
        !taskResourceUserAllocation &&
        `urn:replicon-tenant:${tenantSlug}:psa-task-allocation:${uuidv4()}`;

      if (taskResourceUserAllocation && filteredScheduleRules.length === 0) {
        openReleaseTaskDialogsContainer();
      } else {
        const refetchQueries = [
          {
            query: TASK_RESOURCE_USER_ALLOCATIONS_SUMMARY_QUERY,
            variables: {
              userId,
              filter: {
                projectId
              }
            }
          }
        ];

        taskResourceUserAllocation
          ? updateTaskResourceUserAllocation({
              variables: {
                input: omitForbiddenFields({
                  taskAllocationId: id,
                  taskUri,
                  projectUri,
                  scheduleRules
                })
              },
              optimisticResponse: buildUpdateTaskResourceUserAllocationOptimisticResponse(
                {
                  id,
                  taskResourceEstimate,
                  projectId,
                  userId,
                  scheduleRules
                }
              ),
              update:
                periodDetails &&
                updateCacheOnEditTaskAllocation({
                  userId,
                  projectId,
                  periodDetails,
                  originalTaskResourceUserAllocation: taskResourceUserAllocation
                }),
              refetchQueries
            })
          : createTaskResourceUserAllocation({
              variables: {
                input: omitForbiddenFields({
                  taskAllocationId: newTaskAllocationId,
                  taskUri: taskResourceEstimate.task.id,
                  projectUri: projectId,
                  allocationUserUri: userId,
                  scheduleRules,
                  roleUri: taskResourceEstimate.projectRole?.id
                })
              },
              optimisticResponse: buildCreateTaskResourceUserAllocationOptimisticResponse(
                {
                  id: newTaskAllocationId,
                  taskResourceEstimate,
                  projectId,
                  userId,
                  scheduleRules
                }
              ),
              update:
                periodDetails &&
                updateCacheOnCreateTaskAllocation({
                  userId,
                  projectId,
                  taskId: taskResourceEstimate.task.id,
                  periodDetails
                }),
              refetchQueries
            });
      }
    },
    [
      createTaskResourceUserAllocation,
      openReleaseTaskDialogsContainer,
      projectId,
      taskResourceEstimate,
      taskResourceUserAllocation,
      tenantSlug,
      updateTaskResourceUserAllocation,
      userId
    ]
  );

  const onAllocationChange = useCallback(
    ({ startDate, endDate, taskAllocation }) => {
      const dragStartDate = dateToMidnightUTCObject(startDate.toISO());
      const dragEndDate = dateToMidnightUTCObject(endDate.toISO());
      const updatedTaskAllocationScheduleRules = calculateTaskAllocationScheduleRulesOnDrag(
        {
          startDate: dragStartDate,
          endDate: dragEndDate,
          taskAllocation,
          resourceAllocationScheduleRules,
          userTaskAllocationsSummaryScheduleRules,
          isRmpTaskAllocationPhase2Enabled
        }
      );

      setSnackBarMessageOnDrag({
        taskAllocation,
        updatedTaskAllocationScheduleRules,
        startDate: dragStartDate,
        endDate: dragEndDate,
        setSnackBarState,
        formatMessage,
        isRmpTaskAllocationPhase2Enabled
      });

      onPeriodClose({
        allocation: {
          ...taskResourceUserAllocation,
          scheduleRules: updatedTaskAllocationScheduleRules
        }
      });
    },
    [
      formatMessage,
      isRmpTaskAllocationPhase2Enabled,
      onPeriodClose,
      resourceAllocationScheduleRules,
      setSnackBarState,
      taskResourceUserAllocation,
      userTaskAllocationsSummaryScheduleRules
    ]
  );

  return { onPeriodClose, onAllocationChange };
};

export default useTaskAssignmentTimelineEditorChangeHandlers;
