import React, { useState, useMemo, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { UserWithRoles } from '~/modules/common/components/User';
import { SimpleAutocomplete } from '~/modules/common/components/SearchAutocomplete';
import { MORE_AVAILABLE_OPTION_ID } from '~/modules/common/components/SearchAutocomplete/SearchAutocomplete';
import MoreResult from '~/modules/common/components/MoreResult';
import { User } from '~/modules/common/components';
import { usePageOfProjectResourceDropdownOptions } from '../ResourceUsersDropdown/hooks/usePageOfProjectResourceDropdownOptions';
import useDropdownHandlers from './useDropdownHandlers';

const PAGE_SIZE = 200;

export const renderOption = option =>
  option.id === MORE_AVAILABLE_OPTION_ID ? (
    <MoreResult message={option.displayText} />
  ) : (
    <UserWithRoles user={option} />
  );

const useUserStyles = makeStyles(theme => ({
  label: {
    maxWidth: theme.spacing(19)
  },
  name: {
    fontSize: theme.typography.body1.fontSize
  }
}));

export const getOptionLabel = option => option?.displayText || '';

export const getOptionSelected = (option, selected) =>
  option.displayText === selected.displayText;

export const ProjectTeamMemberDropdown = ({
  autoFocus,
  projectId,
  assignedRole,
  onResourceChange,
  value,
  selectedResources,
  placeholder,
  variant = 'standard',
  ariaLabel,
  label,
  className,
  taskId,
  hasError,
  helperText
}) => {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');

  const { loading, users, hasMore } = usePageOfProjectResourceDropdownOptions({
    isAvailabilityEnabled: false,
    projectId,
    searchTerm,
    pageSize: PAGE_SIZE,
    assignedTaskId: taskId
  });

  const userClasses = useUserStyles();
  const {
    handleOnChange,
    getOptionDisabled,
    handleInputChange,
    getGroupBy,
    getOptions
  } = useDropdownHandlers({
    onResourceChange,
    setSearchTerm,
    value,
    formatMessage,
    assignedRole,
    selectedResources
  });

  const renderTags = useCallback(
    (values, getTagProps) =>
      values.map((option, index) => (
        <User
          classes={userClasses}
          user={option}
          disablePadding
          key={option.displayText}
          rootProps={getTagProps({ index })}
        />
      )),
    [userClasses]
  );

  const options = loading ? [] : getOptions({ users, hasMore });

  const dropdownValue = useMemo(() => (value ? [value] : []), [value]);

  return (
    <SimpleAutocomplete
      autoFocus={autoFocus}
      clearOnBlur
      loading={loading}
      size="small"
      fullWidth
      multiple
      options={options}
      variant={variant}
      dataQeId="TaskResourceDropdown"
      noOptionsText={formatMessage({
        id: 'taskResourceAssignments.noOptions'
      })}
      value={dropdownValue}
      placeholder={value ? '' : placeholder}
      groupBy={getGroupBy}
      hasMore={hasMore}
      onChange={handleOnChange}
      getOptionLabel={getOptionLabel}
      loadingText={formatMessage({
        id: 'taskResourceAssignments.loading'
      })}
      getOptionSelected={getOptionSelected}
      renderTags={renderTags}
      renderOption={renderOption}
      onInputChange={handleInputChange}
      getOptionDisabled={getOptionDisabled}
      ariaLabel={ariaLabel}
      inputLabel={label}
      className={className}
      hasError={hasError}
      helperText={helperText}
    />
  );
};

ProjectTeamMemberDropdown.propTypes = {
  autoFocus: PropTypes.bool,
  onResourceChange: PropTypes.func.isRequired,
  assignedRole: PropTypes.object,
  value: PropTypes.object,
  selectedResources: PropTypes.array,
  projectId: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  ariaLabel: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  taskId: PropTypes.string,
  hasError: PropTypes.bool,
  helperText: PropTypes.string
};

export default ProjectTeamMemberDropdown;
