import {
  Divider,
  Grid,
  InputAdornment,
  TextField,
  makeStyles
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ArrowDropDown } from '@material-ui/icons';
import { useProjectTemplateSettings } from '~/modules/common/hooks/project/useProjectTemplateSettings';
import {
  RoleDropdown,
  TimeAndExpenseEntryTypeDropdown
} from '~/modules/tasks/components';
import { getError, hasError } from '~/util';
import { TASK_ESTIMATE_CALCULATION_TYPES } from '~/modules/common/enums';
import { DateField, Hours, Money2 } from '~/modules/common/components';
import { GroupTimesheetAccessDropdown } from '~/modules/common/components/TaskDrawer/EditTask/components';
import TaskCustomFields from '~/modules/common/components/TaskDrawer/EditTask/TaskCustomFields';
import { isNumeric } from '~/modules/common/numbers';
import TaskNameField from './TaskNameField';
import { TaskResourceEstimates } from './components';

const useStyles = makeStyles(theme => ({
  container: {
    height: 300,
    width: 400
  },
  loadingContainer: {
    position: 'fixed',
    left: '50%',
    top: '50%'
  },
  validationIndicator: {
    color: theme.palette.grey[600]
  },
  label: {
    fontSize: theme.typography.body2.fontSize
  },
  dateInput: {
    paddingLeft: 0,
    '& button': {
      paddingRight: 0
    }
  },
  fieldLabel: {
    whiteSpace: 'nowrap',
    fontSize: theme.typography.body2.fontSize
  },
  date: {
    cursor: 'pointer'
  },
  dateAdornedEnd: {
    paddingRight: 0
  },
  dateAdornmentRoot: {
    top: '50%',
    right: 0,
    color: theme.palette.text.secondary,
    position: 'absolute',
    pointerEvents: 'none'
  },
  input: {
    fontSize: 'inherit'
  },
  customContainer: {
    padding: theme.spacing(1)
  },
  dropDownIcon: {
    marginRight: theme.spacing(2)
  },
  zeroPaddingField: {
    padding: 0
  },
  readOnly: {
    marginLeft: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(4, -2.5, 2.5)
  }
}));

const useNumberInputWithoutValueStyles = makeStyles(theme => ({
  label: {
    right: theme.spacing(3),
    left: 0,
    transformOrigin: 'left top',
    '&.Mui-focused': {
      right: 'unset',
      left: 0,
      transformOrigin: 'left top'
    }
  }
}));

const useNumberInputWithValueStyles = makeStyles(theme => ({
  label: {
    left: 0,
    right: 'unset',
    transformOrigin: 'left top'
  }
}));

const useGridItemStyle = makeStyles(theme => ({
  gridItem: {
    minWidth: 240
  }
}));

const getFieldLabels = isExpenseProductEnabled => formatMessage => ({
  code: formatMessage({ id: 'projectTasksPage.code' }),
  description: formatMessage({ id: 'projectTasksPage.description' }),
  endDate: formatMessage({ id: 'projectTasksPage.plannedEndDate' }),
  estimatedCost: formatMessage({ id: 'projectTasksPage.estimatedCost' }),
  estimatedHours: formatMessage({ id: 'projectTasksPage.estimatedHours' }),
  name: formatMessage({ id: 'projectTasksPage.name' }),
  startDate: formatMessage({ id: 'projectTasksPage.plannedStartDate' }),
  taskRole: formatMessage({
    id: 'projectTasksPage.taskRole'
  }),
  timeAndExpenseEntryType: formatMessage({
    id: isExpenseProductEnabled
      ? 'projectTasksPage.timeAndExpenseEntryAllowed'
      : 'projectTasksPage.timeEntryAllowed'
  })
});

const getInputProps = ariaLabel => ({ 'aria-label': ariaLabel });
const dateProps = {
  InputProps: {
    endAdornment: (
      <InputAdornment position="end">
        <ArrowDropDown />
      </InputAdornment>
    )
  }
};

export const AddTaskForm2 = ({
  me,
  milestoneCheckbox,
  readOnly,
  isLoading,
  isMobile,
  project,
  projectSlug,
  values,
  errors,
  onNameChange,
  onCodeChange,
  onStartDateChange,
  onEndDateChange,
  onAssignedRoleChange,
  onDescriptionChange,
  onEstimatedHoursChange,
  onEstimatedCostAmountChange,
  onEstimatedCostCurrencyChange,
  onTimeAndExpenseEntryTypeChange,
  onResourceEstimateAdd,
  setFieldValue,
  onTimesheetAccessChange,
  customFieldDefinitions,
  dialogWidth
}) => {
  const classes = useStyles();
  const numberInputWithoutValueClasses = useNumberInputWithoutValueStyles();
  const gridItemClasses = useGridItemStyle();
  const numberInputWithValueClasses = useNumberInputWithValueStyles();

  const { isExpenseProductEnabled, hasViewProjectBillingOptions } = me;
  const { formatMessage } = useIntl();
  const fieldLabels = getFieldLabels(isExpenseProductEnabled)(formatMessage);

  const onAddRoleDialogCancelButtonClick = useCallback(() => {
    const { role, users } = { role: null, users: null };

    onAssignedRoleChange(role, users);
  }, [onAssignedRoleChange]);

  const {
    featureFlags: { PSAPRPTaskEstimateCalculation, isPsaPrpPsaPpmMergerEnabled }
  } = me;

  const isTaskAssignmentsEnabled =
    PSAPRPTaskEstimateCalculation ===
      TASK_ESTIMATE_CALCULATION_TYPES.TASKASSIGNMENT && !isMobile;

  const {
    name,
    code,
    startDate,
    endDate,
    assignedUser,
    assignedRole = null,
    description,
    estimatedHours,
    estimatedCost,
    isMilestone,
    isTimeEntryAllowed,
    timeAndExpenseEntryType,
    timesheetAccess,
    assignedUserRoleId,
    resourceEstimates,
    copyParentTaskResourceAllocations,
    areStartAndEndDatesRequired,
    ...customFields
  } = values;

  const { permittedActionUris, billingType, projectTemplateSetting } = project;

  const templateSettings = useProjectTemplateSettings({
    projectTemplateSetting,
    isPsaPrpPsaPpmMergerEnabled
  });

  const { hasBilling } = templateSettings || {};

  if (isLoading || readOnly) {
    return (
      <div className={classes.container}>
        <CircularProgress className={classes.loadingContainer} size={24} />
      </div>
    );
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={3} className={gridItemClasses.gridItem}>
          <TaskNameField
            variant="outlined"
            name={name}
            nameLabel={fieldLabels.name}
            onNameChange={onNameChange}
            readOnly={readOnly}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={3} className={gridItemClasses.gridItem}>
          <TextField
            fullWidth
            variant="outlined"
            disabled={readOnly}
            value={code}
            onChange={onCodeChange}
            helperText={getError(errors, 'code')}
            label={fieldLabels.code}
            inputProps={getInputProps(fieldLabels.code)}
            error={hasError(errors, 'code')}
          />
        </Grid>
        <Grid item xs={12} sm={3} className={gridItemClasses.gridItem}>
          <RoleDropdown
            variant="outlined"
            value={assignedRole}
            onChange={onAssignedRoleChange}
            disabled={readOnly}
            label={fieldLabels.taskRole}
            projectSlug={projectSlug}
            onAddRoleDialogCancelButtonClick={onAddRoleDialogCancelButtonClick}
          />
        </Grid>
        <Grid item xs={12} sm={3} className={gridItemClasses.gridItem}>
          <TimeAndExpenseEntryTypeDropdown
            showBillingOptions={
              isPsaPrpPsaPpmMergerEnabled
                ? hasBilling && hasViewProjectBillingOptions
                : hasViewProjectBillingOptions
            }
            variant="outlined"
            labelWidth={200}
            value={timeAndExpenseEntryType && timeAndExpenseEntryType.id}
            onChange={onTimeAndExpenseEntryTypeChange}
            label={fieldLabels.timeAndExpenseEntryType}
            disabled={Boolean(
              billingType?.id === 'urn:replicon:billing-type:non-billable'
            )}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={3} className={gridItemClasses.gridItem}>
          <DateField
            editable
            variant="outlined"
            value={startDate}
            onChange={onStartDateChange}
            label={fieldLabels.startDate}
            ariaLabel={fieldLabels.startDate}
            required={areStartAndEndDatesRequired}
            error={hasError(errors, 'startDate')}
            helperText={getError(errors, 'startDate')}
          />
        </Grid>
        <Grid item xs={12} sm={3} className={gridItemClasses.gridItem}>
          <DateField
            editable
            variant="outlined"
            value={endDate}
            onChange={onEndDateChange}
            label={fieldLabels.endDate}
            ariaLabel={fieldLabels.endDate}
            required={areStartAndEndDatesRequired}
            error={hasError(errors, 'endDate')}
            helperText={getError(errors, 'endDate')}
          />
        </Grid>
        {permittedActionUris.includes(
          'urn:replicon:project-action:edit-project-estimates'
        ) && (
          <>
            <Grid item xs={12} sm={3} className={gridItemClasses.gridItem}>
              <Hours
                variant="outlined"
                classes={
                  estimatedHours
                    ? numberInputWithValueClasses
                    : numberInputWithoutValueClasses
                }
                isEditible
                value={estimatedHours}
                onValueChange={onEstimatedHoursChange}
                label={fieldLabels.estimatedHours}
                ariaLabel={fieldLabels.estimatedHours}
              />
            </Grid>
            {permittedActionUris.includes(
              'urn:replicon:project-action:edit-cost-data'
            ) && (
              <Grid item xs={12} sm={3} className={gridItemClasses.gridItem}>
                <Money2
                  numberInputClasses={
                    estimatedCost?.amount
                      ? numberInputWithValueClasses
                      : numberInputWithoutValueClasses
                  }
                  variant="outlined"
                  amount={estimatedCost?.amount}
                  onAmountChange={onEstimatedCostAmountChange}
                  currency={estimatedCost?.currency}
                  onCurrencyChange={onEstimatedCostCurrencyChange}
                  label={fieldLabels.estimatedCost}
                  ariaLabel={fieldLabels.estimatedCost}
                  showStartAdornment={isNumeric(estimatedCost?.amount)}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={6} className={gridItemClasses.gridItem}>
          <GroupTimesheetAccessDropdown
            projectId={project.id}
            timesheetAccess={timesheetAccess}
            onTimesheetAccessChange={onTimesheetAccessChange}
          />
        </Grid>
        {!isMobile && (
          <Grid item xs={12} sm={6}>
            <TextField
              minRows={1}
              maxRows={5}
              fullWidth
              multiline
              variant="outlined"
              value={description}
              onChange={onDescriptionChange}
              inputProps={getInputProps(fieldLabels.description)}
              label={fieldLabels.description}
              error={hasError(errors, 'description')}
              helperText={getError(errors, 'description')}
            />
          </Grid>
        )}
        {isMobile && !isLoading && !readOnly && milestoneCheckbox()}
        <Grid container item spacing={2} justifyContent="space-between">
          <TaskCustomFields
            editable
            align="left"
            variant="outlined"
            customFieldDefinitions={customFieldDefinitions}
            values={customFields}
            errors={errors}
            dateProps={dateProps}
          />
        </Grid>
      </Grid>

      {isTaskAssignmentsEnabled &&
        permittedActionUris.includes(
          'urn:replicon:project-action:edit-resource-task-allocations'
        ) && (
          <>
            <Divider className={classes.divider} />
            <TaskResourceEstimates
              taskEstimatedCost={estimatedCost}
              taskEstimatedHours={estimatedHours}
              onResourceEstimateAdd={onResourceEstimateAdd}
              resourceEstimates={resourceEstimates}
              setFieldValue={setFieldValue}
              projectSlug={projectSlug}
              projectId={project.id}
              errors={errors}
              dialogWidth={dialogWidth}
            />
          </>
        )}
    </>
  );
};

AddTaskForm2.propTypes = {
  me: PropTypes.object,
  milestoneCheckbox: PropTypes.func,
  readOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  values: PropTypes.object,
  project: PropTypes.object,
  projectSlug: PropTypes.string,
  errors: PropTypes.object,
  onNameChange: PropTypes.func,
  onCodeChange: PropTypes.func,
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
  onAssignedRoleChange: PropTypes.func,
  onDescriptionChange: PropTypes.func,
  onEstimatedHoursChange: PropTypes.func,
  onEstimatedCostAmountChange: PropTypes.func,
  onEstimatedCostCurrencyChange: PropTypes.func,
  onTimeAndExpenseEntryTypeChange: PropTypes.func,
  onTimesheetAccessChange: PropTypes.func,
  onResourceEstimateAdd: PropTypes.func,
  setFieldValue: PropTypes.func,
  customFieldDefinitions: PropTypes.array,
  dialogWidth: PropTypes.number
};

export default AddTaskForm2;
