import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from '@material-ui/core';
import { MANAGED_URI } from '~/modules/projects/projectTypes';

const allowTimeEntry = <FormattedMessage id="timeAndExpenseEntryOptions.yes" />;
const timeEntryNotAllowed = (
  <FormattedMessage id="timeAndExpenseEntryOptions.urn:replicon:time-and-expense-entry-type:no" />
);

export const selectedTimeAndExpenseEntryType = ({
  isTimeEntryAllowed,
  hasViewProjectBillingOptions,
  timeAndExpenseEntryType
}) => {
  if (!isTimeEntryAllowed) return timeEntryNotAllowed;

  if (!hasViewProjectBillingOptions) return allowTimeEntry;

  if (timeAndExpenseEntryType && timeAndExpenseEntryType.id)
    return (
      <FormattedMessage
        id={`timeAndExpenseEntryOptions.${timeAndExpenseEntryType.id}`}
      />
    );

  return '';
};

export const selectedTimeAndExpenseEntryType2 = timeAndExpenseEntryType => {
  if (timeAndExpenseEntryType?.id)
    return (
      <FormattedMessage
        id={`timeAndExpenseEntryOptions.${timeAndExpenseEntryType.id}`}
      />
    );

  return '';
};

export const getSelectedTimeAndExpenseEntryType = ({
  isTimeEntryAllowed,
  hasViewProjectBillingOptions,
  timeAndExpenseEntryType,
  isPsaPrpEnhancedAllowEntryWithoutTasksDropdownEnabled
}) => {
  return !isPsaPrpEnhancedAllowEntryWithoutTasksDropdownEnabled
    ? selectedTimeAndExpenseEntryType({
        isTimeEntryAllowed,
        hasViewProjectBillingOptions,
        timeAndExpenseEntryType
      })
    : selectedTimeAndExpenseEntryType2(timeAndExpenseEntryType);
};

export const projectType = typeUri => (
  <FormattedMessage
    id={`projectList.${typeUri === MANAGED_URI ? 'managed' : 'unmanaged'}`}
  />
);

export const projectLeaderApprovalField = (
  isProjectLeaderApprovalRequired,
  handlePMApprovalChange
) => (
  <Checkbox
    data-qe-id="ProjectManagerApprovalCheckbox"
    checked={Boolean(isProjectLeaderApprovalRequired)}
    name="isProjectLeaderApprovalRequired"
    onChange={handlePMApprovalChange}
    color="primary"
    value="primary"
  />
);
