import { gql } from 'graphql-tag';
import { extensionFieldValueFragment } from '~/modules/extensionFields/fragments';
import { CustomFieldValueFragmentDoc } from '~/types';

export const taskDetailsQuery = gql`
  query Eager_getTaskDetails(
    $taskId: String!,
    $page: Int!,
    $pageSize: Int!,
    $effectiveTimestamp: DateTime!,
    $effectiveTimestamp2: Date2!,
    ${`$isRolledUpTaskEstimateCalculationMethodEnabled: Boolean = false`},
    ${`$includeRolledUpCostSummary: Boolean = false`},
    ${`$isPsaPrpPsaPpmMergerEnabled: Boolean = false`},
    ${`$isPsaRmpTaskAllocation1Enabled: Boolean = false`}
  ) {
    task(taskId: $taskId) {
      id
      uri
      slug
      displayText
      name
      code
      description
      totalActualHours
      estimatedCompletionHours
      estimatedCompletionDate
      effectiveStartDate
      initialEstimatedHours
      initialEstimatedCost {
        amount
        currency {
          id
          displayText
        }
      }
      totalWorkToDate: totalWorkToDate2(effectiveTimestamp: $effectiveTimestamp)
      startDate
      endDate
      isTimeEntryAllowed
      isMilestone
      timeAndExpenseEntryType {
        uri
      }
      customFields {
        ...CustomFieldValue
      }
      extensionFieldValues {
        ...ExtensionFieldValue
      }
      progressStatus: progressStatus2(effectiveTimestamp: $effectiveTimestamp2)
      taskStatus
      overriddenTaskStatus
      fullPath {
        id
        uri
        slug
        displayText
      }
      projectReference {
        id
        displayText
        slug
      }
      assignedUser {
        id
        displayText
      }
      project {
        id
        uri
        slug
        displayText
        isTaskDateRollupEnabled
        permittedActionUris
        billingType {
          id
          displayText
        }
        defaultBillingCurrency {
          id
          displayText
        }
        projectTemplateSetting @include(if: $isPsaPrpPsaPpmMergerEnabled) {
          key
          value
        }
        isTaskDateRollupEnabled
      }
      parent {
        id
        uri
        slug
        displayText
      }
      taskEntries {
        id
        hoursWorked
      }
      isClosed
      isClosedByTaskInheritance
      closedOnDate: closedOnDate2
      effectiveClosedOnDate: effectiveClosedOnDate2
      assignedRole {
        id
        displayText
      }
      pendingTaskEntryCount
      assignedUserRoleId
      costTypeUri
      rolledUpSummary @include(if: $isRolledUpTaskEstimateCalculationMethodEnabled) {
        id
        actualHours
        totalEstimatedAtCompletionHours
        totalEstimatedRemainingHours,
        earliestTimeEntryDate,
        estimatedCompletionDate
      }
      rolledUpCostSummary @include(if: $includeRolledUpCostSummary) {
        actualCostInProjectCurrency {
          amount
          currency {
            symbol
          }
        }
        totalEstimatedAtCompletionCostInProjectCurrency {
          amount
          currency {
            symbol
          }
        }
        totalEstimatedRemainingCostInProjectCurrency {
          amount
          currency {
            symbol
          }
        }
        initialEstimatedCostInProjectCurrency {
          amount
          currency {
            symbol
          }
        }
      }
      timesheetAccessAssignments @include(if: $isPsaRmpTaskAllocation1Enabled) {
          id
          displayText
          group {
            id
            displayText
          }
      }
      hasChildren @include(if: $isPsaRmpTaskAllocation1Enabled)
      children(page: $page, pageSize: $pageSize) @include(if: $isRolledUpTaskEstimateCalculationMethodEnabled) {
        name
        estimatedCompletionDate: estimatedCompletionDate2
        endDate
        closedOnDate: closedOnDate2
        initialEstimatedHours
        taskStatus
        overriddenTaskStatus
        rolledUpSummary {
          actualHours,
          totalEstimatedAtCompletionHours,
          totalEstimatedRemainingHours,
          earliestTimeEntryDate,
          estimatedCompletionDate
        }
        rolledUpCostSummary @include(if: $includeRolledUpCostSummary) {
          actualCostInProjectCurrency {
            amount
            currency {
              symbol
            }
          }
          totalEstimatedAtCompletionCostInProjectCurrency {
            amount
            currency {
              symbol
            }
          }
          totalEstimatedRemainingCostInProjectCurrency {
            amount
            currency {
              symbol
            }
          }
          initialEstimatedCostInProjectCurrency {
            amount
            currency {
              symbol
            }
          }
        }
      }
      resourceEstimatesSummary @include(if: $isPsaRmpTaskAllocation1Enabled) {
        resourceEstimatesCount
        resourceEstimatesWithUsersCount
      }
    }
  }
  ${extensionFieldValueFragment}
  ${CustomFieldValueFragmentDoc}
`;
