import React, { useRef, useMemo, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import '@replicon/dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAppContext } from '~/modules/App/AppContext';
import { useMeContext } from '~/modules/me';
import { DEFAULT_PAGE_SIZE } from '~/modules/projects/tasks/hooks/useProjectTasks';
import { useLocalStorage } from '~/modules/common/hooks';
import HideFooterContext from '~/modules/navigation/components/PageLayout/HideFooterContext';
import {
  useOnEventHandlers,
  useGanttTaskCollapseExpandHandler
} from './hooks/handlers';
import {
  useStyles,
  useGanttData,
  useGanttTemplates,
  useGanttConfiguration,
  useGanttConfigureEffect,
  useGanttEventCallbacks,
  useGanttReloadDataEffect,
  useGanttTaskStyles
} from './hooks';

const useLoadingStyles = makeStyles(() => ({
  loadingOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'clear',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2100
  }
}));

export const Gantt = ({
  tasks,
  projectDetails,
  rolledUpSummary,
  rolledUpCostSummary,
  ganttScale,
  ganttRef,
  setGanttRef,
  setTaskId,
  setAnchorPosition,
  setTaskTimeEntryDateRange,
  setMoveTaskError,
  openMoveTaskErrorDialog,
  openAddTaskDialog,
  openAssignmentPopover,
  openRoleAssignmentPopover,
  moveTask,
  focusedTaskId,
  editable,
  canViewEstimate,
  canViewCostData,
  hasEditTaskPermissions,
  isRTL,
  openRescheduleTaskDialog,
  isRescheduleTaskDialogEnabled,
  setRescheduleTaskState
}) => {
  const me = useMeContext();
  const {
    featureFlags: {
      isPsaPrpTaskCodeOnTaskCreationEnabled,
      isPsaRmpTaskAllocation1Enabled
    }
  } = me;

  const setHideFooter = useContext(HideFooterContext);

  useEffect(() => {
    setHideFooter(isPsaRmpTaskAllocation1Enabled);

    return () => {
      setHideFooter(false);
    };
  });

  const classes = useStyles({
    ganttScale,
    isPsaPrpTaskCodeOnTaskCreationEnabled
  });
  const taskNameClasses = useGanttTaskStyles();
  const loadingClasses = useLoadingStyles();
  const intl = useIntl();
  const history = useHistory();
  const ganttContainerRef = useRef();
  const [expandedTasks, setExpandedTasks] = useLocalStorage('expanded-tasks', {
    id: '',
    tasks: []
  });

  if (expandedTasks.id !== projectDetails.id) {
    setExpandedTasks({ id: projectDetails.id, tasks: [projectDetails.id] });
  }
  const ganttConfiguration = useGanttConfiguration({
    me,
    intl,
    classes: isPsaRmpTaskAllocation1Enabled ? taskNameClasses : classes,
    ganttScale,
    editable,
    hasEditTaskPermissions
  });
  const ganttTemplates = useGanttTemplates({
    me,
    intl,
    classes: isPsaRmpTaskAllocation1Enabled ? taskNameClasses : classes,
    canViewEstimate,
    isRTL,
    ganttScale,
    isTaskDateRollupEnabled: projectDetails.isTaskDateRollupEnabled
  });
  const ganttData = useGanttData({
    me,
    intl,
    classes,
    tasks,
    projectDetails,
    rolledUpSummary,
    rolledUpCostSummary,
    editable,
    hasEditTaskPermissions
  });
  const { collapseTask, expandTask } = useGanttTaskCollapseExpandHandler({
    expandedTasks,
    setExpandedTasks
  });
  const handlers = useOnEventHandlers({
    me,
    intl,
    isRescheduleTaskDialogEnabled,
    history,
    editable,
    hasEditTaskPermissions,
    ganttRef,
    setGanttRef,
    setTaskId,
    setAnchorPosition,
    setTaskTimeEntryDateRange,
    setMoveTaskError,
    openMoveTaskErrorDialog,
    openAssignmentPopover,
    openRoleAssignmentPopover,
    openAddTaskDialog,
    moveTask,
    collapseTask,
    expandTask,
    canViewEstimate,
    expandedTasks,
    openRescheduleTaskDialog,
    setRescheduleTaskState
  });
  const { attachEvents, detachEvents } = useGanttEventCallbacks({
    me,
    isRTL,
    isTaskDateRollupEnabled: projectDetails.isTaskDateRollupEnabled,
    ...handlers
  });

  useGanttConfigureEffect({
    me,
    intl,
    ganttScale,
    ganttContainerRef,
    ganttConfiguration,
    ganttTemplates,
    ganttData,
    setGanttRef,
    attachEvents,
    detachEvents,
    expandedTasks,
    editable,
    canViewEstimate,
    canViewCostData,
    isRTL
  });

  useGanttReloadDataEffect({
    ganttData,
    focusedTaskId
  });

  const {
    state: { recalculatingGanttChartData }
  } = useAppContext();

  const footerMessageCount = useMemo(
    () => ({
      count: DEFAULT_PAGE_SIZE
    }),
    []
  );

  return (
    <>
      <div
        className={classNames(
          me.isRolledUpTaskEstimateCalculationMethodEnabled
            ? classes.ganttContainer2
            : classes.ganttContainer,
          classes.fullWidth,
          classes.fullHeight
        )}
      >
        {recalculatingGanttChartData ? (
          <div className={loadingClasses.loadingOverlay}>
            <CircularProgress size={35} />
          </div>
        ) : null}
        <div ref={ganttContainerRef} className={classes.ganttContent} />
      </div>
      {tasks.length >= DEFAULT_PAGE_SIZE && (
        <span className={classes.ganttContainerFooter}>
          <FormattedMessage
            id="projectTasksPage.footerMessage"
            values={footerMessageCount}
          />
        </span>
      )}
    </>
  );
};

Gantt.propTypes = {
  tasks: PropTypes.array,
  projectDetails: PropTypes.object,
  rolledUpSummary: PropTypes.object,
  rolledUpCostSummary: PropTypes.object,
  ganttScale: PropTypes.string,
  ganttRef: PropTypes.object,
  setGanttRef: PropTypes.func,
  setTaskId: PropTypes.func,
  setAnchorPosition: PropTypes.func,
  setTaskTimeEntryDateRange: PropTypes.func,
  setMoveTaskError: PropTypes.func,
  openMoveTaskErrorDialog: PropTypes.func,
  openAddTaskDialog: PropTypes.func,
  openAssignmentPopover: PropTypes.func,
  openRoleAssignmentPopover: PropTypes.func,
  openRescheduleTaskDialog: PropTypes.func,
  moveTask: PropTypes.func,
  focusedTaskId: PropTypes.string,
  editable: PropTypes.bool,
  canViewEstimate: PropTypes.bool,
  canViewCostData: PropTypes.bool,
  hasEditTaskPermissions: PropTypes.bool,
  isRTL: PropTypes.bool,
  setRescheduleTaskState: PropTypes.func,
  isRescheduleTaskDialogEnabled: PropTypes.bool
};

export default React.memo(Gantt);
