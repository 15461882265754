import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { SideCardSectionHeader } from '~/modules/common/components/SideCard';
import { useDialogState } from '~/modules/common/hooks';
import { ContactInfoGenericList } from '~/modules/projects/project/common/components/ContactInfo';
import EditableContactInfoDialog from './ContactDialog/EditableContactInfoDialog';
import ContactInfoListTable from './ContactDialog/ContactInfoListTable';
import { Contact } from './Contact';

const title = (
  <FormattedMessage id="projectBasicInfoCard.contactInfo.contacts" />
);

const Contacts = ({ projectId, contacts, showEdit }) => {
  const { open, openDialog, closeDialog } = useDialogState(false, {
    disabledBackDropClick: true
  });
  const [isReadOnly, setIsReadOnly] = useState(false);

  const onEditClick = useCallback(() => {
    setIsReadOnly(false);
    openDialog();
  }, [openDialog]);

  const handleViewDetails = useCallback(() => {
    setIsReadOnly(true);
    openDialog();
  }, [openDialog]);

  return (
    <div data-qe-id="Contacts">
      <SideCardSectionHeader
        title={title}
        onEditClick={onEditClick}
        dataQeId="EditContactSection"
        showEdit={showEdit}
        ariaLabelKey="button.contactsEditButton"
      />
      <ContactInfoGenericList
        items={contacts}
        noDataMessageId="projectBasicInfoCard.contactInfo.noContacts"
        handleViewDetails={handleViewDetails}
      >
        <Contact />
      </ContactInfoGenericList>
      <EditableContactInfoDialog
        projectId={projectId}
        contacts={contacts}
        open={open}
        onClose={closeDialog}
        isReadOnly={isReadOnly}
      >
        <ContactInfoListTable isReadOnly={isReadOnly} />
      </EditableContactInfoDialog>
    </div>
  );
};

Contacts.propTypes = {
  projectId: PropTypes.string.isRequired,
  contacts: PropTypes.array.isRequired,
  showEdit: PropTypes.bool.isRequired
};

export default Contacts;
