import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import {
  SideCardSectionHeader,
  SideCardSectionDetail
} from '~/modules/common/components/SideCard';
import CustomFieldValueText from '~/modules/customFields/components/CustomFieldValueText';
import ExtensionFieldValueText from '~/modules/extensionFields/components/ExtensionFieldValueText';
import { useDialogState } from '~/modules/common/hooks';
import EditAdditionalInfoDialog from './EditAdditionalInfoDialog';

const additionalInfoLabel = (
  <FormattedMessage id="projectBasicInfoCard.additionalInfo" />
);

const extensionFieldStyles = makeStyles(theme => ({
  detail: {
    'white-space': 'pre-wrap'
  }
}));

const hasCustomFieldValue = customFieldValue => {
  const { text, date, dropDownOptionUri, number } = customFieldValue;

  return text || date || dropDownOptionUri || number;
};

const hasExtensionFieldValue = extensionFieldValue => {
  const { numericValue, fileValue, tag, textValue } = extensionFieldValue;

  return numericValue || fileValue || tag || textValue;
};

export const AdditionalInfo = ({
  projectId,
  showEdit,
  customFieldValues,
  extensionFieldValues,
  canViewCustomFields,
  canEditCustomFields
}) => {
  const extensionFieldStylesClasses = extensionFieldStyles();

  const { open, openDialog, closeDialog } = useDialogState(false, {
    disabledBackDropClick: true
  });

  const hasCustomFieldValues =
    canViewCustomFields &&
    customFieldValues &&
    customFieldValues.filter(hasCustomFieldValue).length > 0;

  const hasCustomFieldDefinitions =
    canViewCustomFields && customFieldValues && customFieldValues.length > 0;

  const hasExtensionFieldValues =
    extensionFieldValues &&
    extensionFieldValues.filter(hasExtensionFieldValue).length > 0;

  const hasExtensionFieldDefinitions =
    extensionFieldValues && extensionFieldValues.length > 0;

  return (
    (showEdit || hasCustomFieldValues || hasExtensionFieldValues) && (
      <>
        <SideCardSectionHeader
          title={additionalInfoLabel}
          onEditClick={openDialog}
          dataQeId="EditAdditionalInfoSection"
          showEdit={showEdit && canEditCustomFields}
          ariaLabelKey="button.additionalInfoEditButton"
        />
        {hasCustomFieldDefinitions &&
          customFieldValues.map(c => (
            <SideCardSectionDetail
              key={c.customField.id}
              label={c.customField.displayText}
              detail={<CustomFieldValueText customFieldValue={c} />}
            />
          ))}
        {hasExtensionFieldDefinitions &&
          extensionFieldValues.map(e => (
            <SideCardSectionDetail
              key={e.definition.id}
              label={e.definition.displayText}
              classes={extensionFieldStylesClasses}
              detail={<ExtensionFieldValueText extensionFieldValue={e} />}
            />
          ))}
        <EditAdditionalInfoDialog
          title={additionalInfoLabel}
          open={open}
          onClose={closeDialog}
          projectId={projectId}
          customFieldValues={customFieldValues}
          extensionFieldValues={extensionFieldValues}
          canViewCustomFields={canViewCustomFields}
          canEditCustomFields={canEditCustomFields}
        />
      </>
    )
  );
};

AdditionalInfo.propTypes = {
  projectId: PropTypes.string,
  showEdit: PropTypes.bool,
  customFieldValues: PropTypes.array,
  extensionFieldValues: PropTypes.array,
  canViewCustomFields: PropTypes.bool,
  canEditCustomFields: PropTypes.bool
};

export default AdditionalInfo;
