import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDownSharp';
import { useIntl } from 'react-intl';
import DatePicker from '../DatePicker';
import CalendarDateInput from './CalendarDateInput';

const useStyles = makeStyles(theme => ({
  adornment: {
    marginLeft: 0
  }
}));

const getAriaLabel = ariaLabel => ({ 'aria-label': ariaLabel });

export const CalendarDate = ({
  value,
  onChange, // Remove from rest
  label,
  fullWidth = true,
  className,
  keyboard,
  format = 'D',
  ...rest
}) => {
  const classes = useStyles();

  const defaultPickerProps = {
    className,
    autoOk: true,
    label,
    clearable: true,
    value,
    fullWidth,
    onChange
  };

  const { formatMessage } = useIntl();

  const InputAdornmentProps = useMemo(
    () => ({
      className: classes.adornment
    }),
    []
  );

  const dialogProps = useMemo(
    () => ({
      TransitionProps: {
        role: 'presentation'
      },
      'aria-hidden': true,
      PaperProps: {
        ...getAriaLabel(formatMessage({ id: 'calendar.datePicker' }))
      }
    }),
    [formatMessage]
  );

  if (keyboard) {
    return (
      <KeyboardDatePicker
        {...defaultPickerProps}
        TextFieldComponent={CalendarDateInput}
        InputAdornmentProps={InputAdornmentProps}
        keyboardIcon={<ArrowDropDownIcon />}
        DialogProps={dialogProps}
        leftArrowButtonProps={getAriaLabel(
          formatMessage({ id: 'dateButton.previous' })
        )}
        rightArrowButtonProps={getAriaLabel(
          formatMessage({ id: 'dateButton.next' })
        )}
        format={format}
        dateFormat={format}
        onValueChange={onChange}
        {...rest}
      />
    );
  }

  return (
    <DatePicker
      DialogProps={dialogProps}
      leftArrowButtonProps={getAriaLabel(
        formatMessage({ id: 'dateButton.previous' })
      )}
      rightArrowButtonProps={getAriaLabel(
        formatMessage({ id: 'dateButton.next' })
      )}
      {...defaultPickerProps}
      format={format}
      {...rest}
    />
  );
};

CalendarDate.propTypes = {
  className: PropTypes.string,
  value: PropTypes.shape({
    day: PropTypes.number,
    month: PropTypes.number,
    year: PropTypes.number
  }),
  onChange: PropTypes.func,
  onValueChange: PropTypes.func,
  label: PropTypes.node,
  fullWidth: PropTypes.bool,
  keyboard: PropTypes.bool,
  format: PropTypes.string
};

export default CalendarDate;
