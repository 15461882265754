import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import SimpleDropdown from '~/modules/common/components/SimpleDropdown';
import { useProjectLinkTypes } from '~/modules/common/components/SearchBox/Facets/ProjectLinkTypeFacet/enhancers/useProjectLinkTypes';
import { useStyles } from './useStyles';

const linkType = <FormattedMessage id="projectLink.linkType" />;

export const ProjectLinkTypeDropdown = ({
  value,
  onChange,
  disabled = false
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const { projectLinkTypes } = useProjectLinkTypes(intl);
  const options = projectLinkTypes.map(x => ({
    id: x.id,
    displayText: x.label
  }));
  const selectProps = useMemo(() => ({ className: classes.leftDropDown }), [
    classes.leftDropDown
  ]);

  return (
    <SimpleDropdown
      ariaLabel={intl.formatMessage({
        id: 'projectLink.projectLinkTypeDropdown'
      })}
      variant="filled"
      label={linkType}
      noneOption={false}
      options={options}
      onChange={onChange}
      value={options.find(o => o.id === value)}
      selectProps={selectProps}
      disabled={disabled}
    />
  );
};

ProjectLinkTypeDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};
export default ProjectLinkTypeDropdown;
