import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SettingsButton from '~/modules/common/components/ListTableToolBar/SettingsButton';

import {
  GANTT_SCALE_DAY,
  GANTT_SCALE_WEEK,
  GANTT_SCALE_MONTH,
  GANTT_SCALE_YEAR,
  GANTT_SCALE_QUARTER
} from '~/modules/projects/tasks/hooks/useGanttScale';
import ShowClosedTasksCheckbox from '~/modules/projects/tasks/components/common/ShowClosedTasksCheckbox';
import TaskStatusLegends from '~/modules/common/components/TaskStatusSection/TaskStatusLegends';
import ChartScaleSelect from './ChartScaleSelect';
import ProjectTasksImportExportMenu from './ProjectTasksImportExportMenu';

const useToolbarStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: theme.spacing(6.25)
  },
  legends: {
    padding: theme.spacing(0, 0, 1.25, 0)
  },
  spacer: {
    flexGrow: 1,
    flexShrink: 1
  },
  checkBox: {
    padding: theme.spacing(2)
  },
  dateDropdown: {
    paddingLeft: theme.spacing(2)
  }
}));

export const Toolbar = ({
  projectId,
  openSettingsDialog,
  ganttRef,
  ganttScale,
  projectName,
  setGanttScale,
  showClosedTasks,
  showTaskRollupSettings,
  toggleShowClosedTasks
}) => {
  const classes = useToolbarStyles();

  return (
    <div className={classes.root}>
      <div className={classes.legends}>
        <TaskStatusLegends />
      </div>
      <div className={classes.spacer} />
      <ShowClosedTasksCheckbox
        checked={showClosedTasks}
        onClick={toggleShowClosedTasks}
        className={classes.checkBox}
      />
      <ChartScaleSelect
        className={classes.dateDropdown}
        ganttScale={ganttScale}
        setGanttScale={setGanttScale}
      />
      <ProjectTasksImportExportMenu
        ganttRef={ganttRef}
        projectId={projectId}
        projectName={projectName}
        includeClosedTasks={showClosedTasks}
      />
      {showTaskRollupSettings && (
        <SettingsButton
          data-qe-id="ProjectTaskList"
          onClick={openSettingsDialog}
        />
      )}
    </div>
  );
};

Toolbar.propTypes = {
  projectId: PropTypes.string,
  projectName: PropTypes.string,
  ganttRef: PropTypes.object,
  ganttScale: PropTypes.oneOf([
    GANTT_SCALE_DAY,
    GANTT_SCALE_WEEK,
    GANTT_SCALE_MONTH,
    GANTT_SCALE_QUARTER,
    GANTT_SCALE_YEAR
  ]),
  openSettingsDialog: PropTypes.func,
  setGanttScale: PropTypes.func,
  showClosedTasks: PropTypes.bool,
  showTaskRollupSettings: PropTypes.bool,
  toggleShowClosedTasks: PropTypes.func
};

export default Toolbar;
