import React, { useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import SearchAutocomplete from './SearchAutocomplete';
import AutocompleteInput from './AutocompleteInput';

export const SimpleAutocomplete = ({
  autoFocus = false,
  value,
  onChange,
  loading = false,
  selectOnFocus = false,
  noOptionsText,
  fullWidth,
  options,
  loadingText,
  getOptionSelected,
  placeholder,
  getOptionLabel,
  renderInput,
  variant = 'filled',
  dataQeId,
  renderOption,
  inputLabel,
  hiddenLabel,
  optionPropText,
  hasError,
  helperText,
  alwaysShowHelperText,
  formControlMargin,
  optionTypeText,
  hasMore = false,
  ariaLabel,
  dropdownListId,
  isDropdownListOpen,
  ...rest
}) => {
  const { formatMessage } = useIntl();

  const optionSelected =
    !getOptionSelected && optionPropText
      ? (option, selected) =>
          option[optionPropText] === selected && selected[optionPropText]
      : getOptionSelected;
  const optionLabel =
    !getOptionLabel && optionPropText
      ? option => (option && option[optionPropText]) || ''
      : getOptionLabel;

  const textLoading =
    loadingText || formatMessage({ id: 'taskBeatTable.loading' });

  const input =
    renderInput ||
    (params => (
      <AutocompleteInput
        placeholder={placeholder}
        label={inputLabel}
        hiddenLabel={hiddenLabel}
        variant={variant}
        showError={hasError}
        errorMessage={helperText}
        helperText={helperText}
        alwaysShowHelperText={alwaysShowHelperText}
        margin={formControlMargin}
        dataQeId={dataQeId && `${dataQeId}_Input`}
        autoFocus={autoFocus}
        ariaLabel={ariaLabel}
        ariaControls={isDropdownListOpen ? dropdownListId : null}
        {...params}
      />
    ));

  const change = useCallback(
    (event, values, reason) => onChange(values, event, reason),
    [onChange]
  );

  return (
    <SearchAutocomplete
      dataQeId={dataQeId}
      loading={loading}
      options={options}
      selectOnFocus={selectOnFocus}
      fullWidth={fullWidth}
      renderOption={renderOption}
      loadingText={textLoading}
      value={value}
      onChange={change}
      getOptionLabel={optionLabel}
      renderInput={input}
      noOptionsText={noOptionsText}
      getOptionSelected={optionSelected}
      optionTypeText={optionTypeText}
      hasMore={hasMore}
      dropdownListId={isDropdownListOpen ? dropdownListId : null}
      {...rest}
    />
  );
};

SimpleAutocomplete.propTypes = {
  autoFocus: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  loading: PropTypes.bool,
  noOptionsText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  options: PropTypes.array.isRequired,
  freeSolo: PropTypes.bool,
  groupBy: PropTypes.func,
  filterOptions: PropTypes.func,
  getOptionLabel: PropTypes.func,
  renderInput: PropTypes.func,
  dataQeId: PropTypes.string,
  getOptionSelected: PropTypes.func,
  multiple: PropTypes.bool,
  optionComponent: PropTypes.node,
  classes: PropTypes.object,
  loadingText: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  renderOption: PropTypes.func,
  inputLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hiddenLabel: PropTypes.bool,
  selectOnFocus: PropTypes.bool,
  fullWidth: PropTypes.bool,
  optionPropText: PropTypes.string,
  hasError: PropTypes.bool,
  helperText: PropTypes.any,
  alwaysShowHelperText: PropTypes.bool,
  formControlMargin: PropTypes.string,
  optionTypeText: PropTypes.string,
  hasMore: PropTypes.bool,
  ariaLabel: PropTypes.string,
  dropdownListId: PropTypes.string,
  isDropdownListOpen: PropTypes.bool
};

export default forwardRef((props, ref) => (
  <SimpleAutocomplete forwardedRef={ref} {...props} />
));
