import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useMeContext } from '~/modules/me';
import { ResourceCostModeType } from '~/types';
import { mapRepliconDateToUtcObject } from '../../dates/convert';

export const DEFAULT_PAGE_SIZE = 40;

export const RESOURCE_USERS_QUERY = gql`
  query Eager_GetResourceUsersPaginated(
    $page: Int
    $pagesize: Int
    $filter: GetResourceUsersFilter
    $nonMatchingfilter: GetResourceUsersFilter
    $sort: [ResourceUserSort!]
    $isAvailabilityEnabled: Boolean!
    $isProjectRoleEnabled: Boolean!
    $fetchNonMatchingUsers: Boolean!
    $scoreComputeType: ScoreComputeType
    $startDate: Date!
    $isRoleCostMode: Boolean!
  ) {
    matchingUsers: resourceUsers2(
      page: $page
      pagesize: $pagesize
      filter: $filter
      sort: $sort
      scoreComputeType: $scoreComputeType
    ) {
      id
      uri
      slug
      displayText
      startDate
      endDate
      currentCostRate {
        amount
        currency {
          id
          symbol
          displayText
        }
      }
      availabilityPercentage @include(if: $isAvailabilityEnabled)
      roles @include(if: $isProjectRoleEnabled) {
        projectRole {
          uri
          name
        }
        isPrimary
      }
      primaryRoleCostRate(date: $startDate) @include(if: $isRoleCostMode) {
        amount
        currency {
          id
          symbol
          displayText
        }
      }
    }
    nonMatchingUsers: resourceUsers2(
      page: $page
      pagesize: $pagesize
      filter: $nonMatchingfilter
      sort: $sort
    ) @include(if: $fetchNonMatchingUsers) {
      id
      uri
      slug
      displayText
      startDate
      endDate
      currentCostRate {
        amount
        currency {
          id
          symbol
          displayText
        }
      }
      availabilityPercentage @include(if: $isAvailabilityEnabled)
      roles @include(if: $isProjectRoleEnabled) {
        projectRole {
          uri
          name
        }
        isPrimary
      }
      primaryRoleCostRate(date: $startDate) @include(if: $isRoleCostMode) {
        amount
        currency {
          id
          symbol
          displayText
        }
      }
    }
  }
`;

const hasMoreRows = rows =>
  rows.length !== 0 && rows.length % DEFAULT_PAGE_SIZE === 0;

export const hasMoreRows2 = ({ data, includeUsersWithExcludeRoles }) => {
  const hasMoreMatchingUsers = data?.matchingUsers?.length > DEFAULT_PAGE_SIZE;
  const hasMoreNonMatchingUsers =
    data?.nonMatchingUsers?.length > DEFAULT_PAGE_SIZE;

  return includeUsersWithExcludeRoles
    ? hasMoreNonMatchingUsers
    : hasMoreMatchingUsers;
};

const useResourceUsers = ({
  sort,
  isProjectRoleEnabled,
  isAvailabilityEnabled,
  userSearchText,
  userRole,
  availabilityPercentageInRange,
  skip = false,
  resourceCostMode
}) => {
  const {
    featureFlags: { isRmpTaskAllocationPhase2Enabled }
  } = useMeContext();
  const startDate = mapRepliconDateToUtcObject(
    availabilityPercentageInRange?.dateRange.startDate
  );

  const isRoleCostMode = resourceCostMode === ResourceCostModeType.Rolecost;

  const { data, loading } = useQuery(RESOURCE_USERS_QUERY, {
    variables: {
      page: 1,
      pagesize: isRmpTaskAllocationPhase2Enabled
        ? DEFAULT_PAGE_SIZE + 1
        : DEFAULT_PAGE_SIZE,
      filter: {
        name: userSearchText || undefined,
        availabilityPercentageInRange,
        roles: userRole ? [userRole.id] : undefined
      },
      nonMatchingfilter: {
        name: userSearchText || undefined,
        availabilityPercentageInRange,
        excludeRoles: userRole ? [userRole.id] : undefined
      },
      fetchNonMatchingUsers: Boolean(userRole && userRole.id),
      sort,
      isAvailabilityEnabled,
      isProjectRoleEnabled,
      startDate,
      isRoleCostMode
    },
    context: {
      debounceKey: 'user-availability-role-search',
      debounceTimeout: 250
    },
    fetchPolicy: 'network-only',
    skip
  });

  const resourceUsers = (data?.matchingUsers || []).slice(0, DEFAULT_PAGE_SIZE);
  const resourceUsersLength = resourceUsers.length;
  const userWithExcludeRoles = (data?.nonMatchingUsers || []).slice(
    0,
    DEFAULT_PAGE_SIZE
  );

  const includeUsersWithExcludeRoles =
    userRole && resourceUsers.length < DEFAULT_PAGE_SIZE;

  return {
    resourceUsers: loading
      ? []
      : [
          ...resourceUsers.map(r => ({ ...r, isRoleExists: true })),
          ...(includeUsersWithExcludeRoles
            ? userWithExcludeRoles.map(r => ({ ...r, isRoleExists: false }))
            : [])
        ],
    isLoading: loading,
    hasMoreRows: isRmpTaskAllocationPhase2Enabled
      ? hasMoreRows2({ data, includeUsersWithExcludeRoles })
      : hasMoreRows([...resourceUsers, ...userWithExcludeRoles]),
    showAllUsers: userRole ? resourceUsersLength < DEFAULT_PAGE_SIZE : true
  };
};

export default useResourceUsers;
