import PropTypes from 'prop-types';
import React from 'react';
import {
  getTotalHoursForDateRangeFromScheduleRules,
  roundToDecimals
} from '~/modules/resourcing/common/util/scheduleUtil';
import {
  mapScaleToPeriodResolution,
  getRemainingAndAvailableComparableValues
} from '~/modules/resourcing/common/util';
import { PERIOD_SCALE_ENUM } from '~/modules/common/charts/timeline';
import { useResourceAllocationSeriesData } from '~/modules/resourcing/common/hooks';
import { useMeContext } from '~/modules/me/useMeContext';
import { getAllocationPeriodEditorDateRangeFromAllocation } from '../ResourceAllocationPeriodEditPopover/components/ResourceAllocationEditor/getAllocationPeriodEditorDateRange';
import TaskAllocationPeriodEditPopover from '../TaskAllocationPeriodEditPopover';
import { useOnChangeHandlers } from './useOnChangeHandlers';

const TaskAllocationTimelineEditorOverlay = ({
  allocationPeriodEditTarget,
  handleAllocationPeriodClose,
  onChange,
  loadingResourceAllocation,
  resourceAllocationScheduleRules,
  setNextPeriod,
  setPreviousPeriod,
  taskResourceUserAllocation,
  userId,
  userTaskAllocationsSummaryLoading,
  userTaskAllocationsSummaryScheduleRules,
  resourceAvailabilitySummaryByPeriodMap
}) => {
  const {
    anchorEl,
    startDate,
    endDate,
    allocatedHours = 0,
    hasAllocationPartialEnd,
    hasAllocationPartialStart
  } = allocationPeriodEditTarget;

  const {
    featureFlags: { isRmpTaskAllocationPhase2Enabled }
  } = useMeContext();

  const {
    user: { scheduleDurationByPeriodMap }
  } = taskResourceUserAllocation;

  const scheduledHours =
    scheduleDurationByPeriodMap[startDate.toISO()]?.hours || 0;

  const {
    periodStartDate = startDate,
    periodEndDate = endDate
  } = taskResourceUserAllocation?.scheduleRules
    ? getAllocationPeriodEditorDateRangeFromAllocation({
        endDate,
        scheduleRules: taskResourceUserAllocation.scheduleRules,
        startDate,
        isPartial: hasAllocationPartialEnd || hasAllocationPartialStart
      })
    : {};

  const totalTaskAllocatedHours = userTaskAllocationsSummaryScheduleRules
    ? getTotalHoursForDateRangeFromScheduleRules({
        start: periodStartDate,
        end: periodEndDate,
        scheduleRules: userTaskAllocationsSummaryScheduleRules
      })
    : 0;

  const resourceAllocatedHours = resourceAllocationScheduleRules
    ? getTotalHoursForDateRangeFromScheduleRules({
        start: periodStartDate,
        end: periodEndDate,
        scheduleRules: resourceAllocationScheduleRules
      })
    : 0;

  const allocatedHoursRoundedValue = roundToDecimals(allocatedHours);

  const otherTaskAllocationHours = totalTaskAllocatedHours - allocatedHours;
  const remainingHours = roundToDecimals(
    resourceAllocatedHours - otherTaskAllocationHours
  );

  const fetchRemainingAndAvailableValues = getRemainingAndAvailableComparableValues(
    {
      scheduledHours,
      otherProjectAllocationHours:
        resourceAvailabilitySummaryByPeriodMap?.[startDate]?.allocatedDuration -
        resourceAllocatedHours,
      initialAllocatedHours: allocatedHours,
      initialProjectAllocation: resourceAllocatedHours,
      initialTaskAllocationSum: totalTaskAllocatedHours,
      remainingHours,
      isRmpTaskAllocationPhase2Enabled
    }
  );

  const {
    resourceAllocationSeriesData,
    loading: scheduleLoading
  } = useResourceAllocationSeriesData({
    periodResolution: mapScaleToPeriodResolution(PERIOD_SCALE_ENUM.DAYS),
    dateRange: {
      startDate: periodStartDate,
      endDate: periodEndDate
    },
    filter: {
      userIds: [userId]
    },
    includeToBeHiredAndRequested: false,
    fetchPolicy: 'no-cache'
  });

  const { onAllocationChange } = useOnChangeHandlers({
    userTaskAllocationsSummaryScheduleRules,
    resourceAllocationScheduleRules,
    taskResourceUserAllocation,
    periodStartDate,
    periodEndDate,
    onChange,
    periodTaskAllocatedHours: allocatedHours,
    effectiveUserScheduleSeriesData: (resourceAllocationSeriesData || []).map(
      seriesData => ({
        hours: Math.max(
          seriesData.totalSchedule - seriesData.timeoff - seriesData.holiday,
          0
        ),
        date: seriesData.dateRange.startDate
      })
    )
  });

  return (
    <TaskAllocationPeriodEditPopover
      anchorEl={anchorEl}
      periodDetails={allocationPeriodEditTarget}
      allocatedHours={allocatedHoursRoundedValue}
      handleAllocationPeriodClose={handleAllocationPeriodClose}
      setNextPeriod={setNextPeriod}
      onAllocationChange={onAllocationChange}
      setPreviousPeriod={setPreviousPeriod}
      scheduledHours={scheduledHours}
      taskResourceUserAllocation={taskResourceUserAllocation}
      isLoading={
        userTaskAllocationsSummaryLoading ||
        loadingResourceAllocation ||
        scheduleLoading
      }
      fetchRemainingAndAvailableValues={fetchRemainingAndAvailableValues}
    />
  );
};

TaskAllocationTimelineEditorOverlay.propTypes = {
  allocationPeriodEditTarget: PropTypes.object,
  handleAllocationPeriodClose: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  loadingResourceAllocation: PropTypes.bool,
  resourceAllocationScheduleRules: PropTypes.array,
  setNextPeriod: PropTypes.func,
  setPreviousPeriod: PropTypes.func,
  taskResourceUserAllocation: PropTypes.object,
  userId: PropTypes.string,
  userTaskAllocationsSummaryLoading: PropTypes.bool,
  userTaskAllocationsSummaryScheduleRules: PropTypes.array,
  resourceAvailabilitySummaryByPeriodMap: PropTypes.object
};

export default TaskAllocationTimelineEditorOverlay;
