import { useCallback } from 'react';
import { compareDateObjects } from '~/modules/common/dates/compare';
import {
  ADD_TASK_RESOURCE_ESTIMATE_DIALOG_SETTING,
  ESTIMATED_HOURS_MAX
} from './constants';

export const useAddResourceEstimateDialogChangeHandlers = ({
  values,
  setValues,
  setFieldValue,
  putKeyValueSettings
}) => {
  const onRoleChange = useCallback(role => setFieldValue('role', role), [
    setFieldValue
  ]);

  const onAddRoleDialogCancel = useCallback(() => setFieldValue('role', null), [
    setFieldValue
  ]);

  const onInitialEstimatedHoursChange = useCallback(
    ({ target: { value: estimatedHours } }) =>
      setFieldValue(
        'initialEstimatedHours',
        estimatedHours && Math.min(estimatedHours, ESTIMATED_HOURS_MAX)
      ),
    [setFieldValue]
  );

  const onCreateTaskAllocationChange = useCallback(
    ({ target: { checked } }) => {
      setFieldValue('isCreateTaskAllocationChecked', checked);
      putKeyValueSettings({
        variables: {
          input: {
            key: ADD_TASK_RESOURCE_ESTIMATE_DIALOG_SETTING,
            settings: {
              isCreateTaskAllocationEnabled: checked
            }
          }
        }
      });
    },
    [setFieldValue, putKeyValueSettings]
  );

  const onStartDateChange = useCallback(
    startDate => {
      const { endDate } = values;
      const isStartDateAfterEndDate =
        compareDateObjects(startDate, endDate) === 1;

      setValues({
        ...values,
        startDate,
        endDate: isStartDateAfterEndDate ? startDate : endDate
      });
    },
    [setValues, values]
  );

  const onEndDateChange = useCallback(
    endDate => {
      const { startDate } = values;
      const isEndDateBeforeStartDate =
        compareDateObjects(endDate, startDate) === -1;

      setValues({
        ...values,
        startDate: isEndDateBeforeStartDate ? endDate : startDate,
        endDate
      });
    },
    [setValues, values]
  );

  const onResourceChange = useCallback(
    resource => {
      setFieldValue('resource', resource);

      const { role } = values;
      const projectRole = resource?.roles?.find(r => r.isPrimary)?.projectRole;

      if (!role && projectRole) {
        setFieldValue('role', {
          id: projectRole.uri,
          displayText: projectRole.name
        });
      }
    },
    [setFieldValue, values]
  );

  return {
    onRoleChange,
    onAddRoleDialogCancel,
    onInitialEstimatedHoursChange,
    onCreateTaskAllocationChange,
    onStartDateChange,
    onEndDateChange,
    onResourceChange
  };
};

export default useAddResourceEstimateDialogChangeHandlers;
