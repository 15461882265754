export const getPrimaryRole = ({ selectedResource }) => {
  const projectRole = selectedResource?.roles?.find(r => r.isPrimary)
    ?.projectRole;

  if (projectRole) {
    return {
      id: projectRole.uri,
      displayText: projectRole.name
    };
  }

  return null;
};

const useTaskResourceEstimateChangeHandlers = ({
  onResourceEstimateChange,
  resourceEstimate
}) => ({
  onRemove: () => {
    onResourceEstimateChange({
      assignment: resourceEstimate,
      isDelete: true
    });
  },
  onEstimatedHoursChange: hours =>
    onResourceEstimateChange({
      assignment: {
        ...resourceEstimate,
        estimatedHours: hours,
        estimatedCost: {
          amount: hours * 100,
          currencySymbol: '$'
        }
      }
    }),
  onResourceChange: selectedResource =>
    onResourceEstimateChange({
      assignment: {
        ...resourceEstimate,
        resource: selectedResource,
        role: resourceEstimate.role || getPrimaryRole({ selectedResource })
      }
    }),
  onRoleChange: role =>
    onResourceEstimateChange({
      assignment: {
        ...resourceEstimate,
        role
      }
    }),
  onAllocationDateChange: dateRange =>
    onResourceEstimateChange({
      assignment: {
        ...resourceEstimate,
        dateRange
      }
    })
});

export default useTaskResourceEstimateChangeHandlers;
