/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from 'react';
import { compareDateObjects } from '~/modules/common/dates/compare';
import {
  useProjectObjectPermissions,
  useProjectTemplateSettings
} from '~/modules/common/hooks/project';
import { ProjectLinkType } from '~/types';
import { getDefaultLinkObject } from '~/modules/projects/project/ProjectLinks';

const transformDropdownResult = result => ({
  displayText: result && result.label,
  id: result && result.value
});

export const useFieldHandlers = ({
  values,
  setFieldValue,
  endDate,
  startDate,
  setIsBillPlanEnabled,
  setIsPortfolioEnabled,
  isPsaPrpPsaPpmMergerEnabled,
  isPsaPrpProjectLinkEnabled
}) => {
  const handleStartDateChange = useCallback(
    newStartDate => {
      setFieldValue('startDate', newStartDate);

      const isStartDateAfterEndDate =
        compareDateObjects(newStartDate, endDate) === 1;

      if (isStartDateAfterEndDate) {
        setFieldValue('endDate', newStartDate);
      }
    },
    [endDate, setFieldValue]
  );

  const handleEndDateChange = useCallback(
    newEndDate => {
      setFieldValue('endDate', newEndDate);

      const isEndDateBeforeStartDate =
        compareDateObjects(newEndDate, startDate) === -1;

      if (isEndDateBeforeStartDate) {
        setFieldValue('startDate', newEndDate);
      }
    },
    [startDate, setFieldValue]
  );

  const handleProgramChange = useCallback(
    value => {
      setFieldValue('program', transformDropdownResult(value));
    },
    [setFieldValue]
  );

  const handlePortfolioChange = useCallback(
    value => {
      setFieldValue('portfolio', value);
    },
    [setFieldValue]
  );

  const handleEarnedRevenueScriptChange = useCallback(
    value => setFieldValue('earnedRevenueScript', value),
    [setFieldValue]
  );

  const handleClientChange = useCallback(
    value => {
      setFieldValue('client', transformDropdownResult(value));
    },
    [setFieldValue]
  );

  const handleProjectManagerChange = useCallback(
    (_, value) => setFieldValue('projectManager', value),
    [setFieldValue]
  );

  const handleProjectChange = useCallback(
    (value, _) => {
      const { canViewBillingContracts } = useProjectObjectPermissions({
        project: value || {}
      });

      if (isPsaPrpPsaPpmMergerEnabled) {
        const templateSettings = useProjectTemplateSettings({
          projectTemplateSetting: value?.projectTemplateSetting,
          isPsaPrpPsaPpmMergerEnabled
        });

        setIsBillPlanEnabled(templateSettings?.hasBilling);
        setIsPortfolioEnabled(templateSettings?.hasPortfolio);

        setFieldValue(
          'canCopyBillPlan',
          templateSettings?.hasBilling && Boolean(canViewBillingContracts)
        );
        setFieldValue(
          'isCopyBillPlanChecked',
          templateSettings?.hasBilling && Boolean(canViewBillingContracts)
        );

        if (!templateSettings?.hasPortfolio) setFieldValue('portfolio', null);
      } else {
        setFieldValue('canCopyBillPlan', Boolean(canViewBillingContracts));
        setFieldValue(
          'isCopyBillPlanChecked',
          Boolean(canViewBillingContracts)
        );
      }

      if (isPsaPrpProjectLinkEnabled) {
        const { project, projectLinks } = values;

        const targetProjectIndex = projectLinks.findIndex(
          link => link?.targetProject?.id === project?.id
        );
        const newTargetProject = value
          ? { id: value.id, displayText: value.displayText }
          : null;

        if (targetProjectIndex !== -1) {
          setFieldValue(
            `projectLinks[${targetProjectIndex}].targetProject`,
            newTargetProject
          );
        } else if (newTargetProject) {
          setFieldValue('projectLinks', [
            ...projectLinks,
            {
              ...getDefaultLinkObject(ProjectLinkType.Isduplicatedby),
              targetProject: newTargetProject
            }
          ]);
        }
      }
      setFieldValue('project', value);
    },
    [
      values,
      isPsaPrpPsaPpmMergerEnabled,
      setFieldValue,
      setIsBillPlanEnabled,
      setIsPortfolioEnabled,
      isPsaPrpProjectLinkEnabled
    ]
  );

  const handleCopyTasksChecked = useCallback(
    (_, checked) => {
      setFieldValue('isCopyTasksChecked', checked);
    },
    [setFieldValue]
  );

  const handleCopyRateCardChange = useCallback(
    value => {
      setFieldValue('rateCardCopyOption', value.id);
    },
    [setFieldValue]
  );

  const handleCopyResourceRequestsChecked = useCallback(
    (_, checked) => {
      setFieldValue('isCopyResourceRequestsChecked', checked);
    },
    [setFieldValue]
  );

  const handleCopyBillPlanChecked = useCallback(
    (_, checked) => {
      setFieldValue('isCopyBillPlanChecked', checked);
    },
    [setFieldValue]
  );

  return {
    handleStartDateChange,
    handleEndDateChange,
    handleProgramChange,
    handlePortfolioChange,
    handleEarnedRevenueScriptChange,
    handleClientChange,
    handleProjectManagerChange,
    handleProjectChange,
    handleCopyTasksChecked,
    handleCopyRateCardChange,
    handleCopyResourceRequestsChecked,
    handleCopyBillPlanChecked
  };
};
