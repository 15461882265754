import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { roundToDecimals } from '~/modules/resourcing/common/util';
import { useTotalResourceAvailableDurationForDateRangeCallback } from '~/modules/tasks/hooks/useTotalResourceAvailableDurationForDateRange';
import PopoverContentLayout from '../../common/components/PopoverContentLayout';
import {
  FieldLabel,
  AllocationEstimateInfoComponent
} from '../../common/components';
import useFormChangeHandlers from '../hooks/useFormChangeHandlers';
import { UserOverAllocationChip } from '../../../components/UserOverAllocationChip';
import AvailabilityInfoComponent from './AvailabilityInfoComponent';

const useStyles = makeStyles(() => ({
  label: {
    width: '120%'
  }
}));

const useLayoutStyles = makeStyles(() => ({
  title: {
    paddingTop: 0
  }
}));

const EditorPopoverContent = ({
  resourceAllocation,
  otherTaskAllocationsSummary,
  initialEstimatedHours,
  estimatedAtCompletionHours,
  taskResourceUserAllocation,
  userUri
}) => {
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();
  const { values, errors, setValues } = useFormikContext();

  const {
    startDate,
    endDate,
    projectAvailableHours,
    allocatedHours,
    resourceAvailabilityHours
  } = values;

  const { scheduleRules: resourceAllocationScheduleRules } =
    resourceAllocation || {};
  const {
    scheduleRules: otherTaskAllocationsSummaryScheduleRules
  } = otherTaskAllocationsSummary;
  const roundedAllocatedHours = roundToDecimals(allocatedHours);
  const roundedResourceAvailabilityHours = roundToDecimals(
    resourceAvailabilityHours
  );
  const totalAvailabilityHours =
    roundedResourceAvailabilityHours - roundedAllocatedHours;

  const roundedProjectAvailableHours = roundToDecimals(projectAvailableHours);

  const {
    getTotalResourceAvailableHoursForDateRange
  } = useTotalResourceAvailableDurationForDateRangeCallback();

  const {
    onStartDateChange,
    onEndDateChange,
    onTaskAllocationHoursChange
  } = useFormChangeHandlers({
    values,
    setValues,
    startDate,
    endDate,
    resourceAllocationScheduleRules,
    otherTaskAllocationsSummaryScheduleRules,
    initialEstimatedHours,
    getTotalResourceAvailableHoursForDateRange,
    userUri
  });

  const availabilityField = useMemo(
    () => ({
      labelComponent: (
        <FieldLabel
          variant="body2"
          messageId="taskAllocationEditor.resourceAvailability"
          className={classes.label}
        />
      ),
      hoursValue: roundedResourceAvailabilityHours,
      infoComponent: startDate && endDate && resourceAllocation && (
        <AvailabilityInfoComponent
          endDate={endDate}
          startDate={startDate}
          projectAvailableHours={roundedProjectAvailableHours}
          otherTaskAllocationsSummaryScheduleRules={
            otherTaskAllocationsSummaryScheduleRules
          }
          resourceAllocationScheduleRules={resourceAllocationScheduleRules}
          taskAllocationHours={roundedAllocatedHours}
        />
      )
    }),
    [
      classes.label,
      endDate,
      otherTaskAllocationsSummaryScheduleRules,
      resourceAllocation,
      resourceAllocationScheduleRules,
      roundedAllocatedHours,
      roundedProjectAvailableHours,
      roundedResourceAvailabilityHours,
      startDate
    ]
  );

  const allocationField = useMemo(
    () => ({
      labelComponent: (
        <FieldLabel
          variant="body2"
          messageId="taskAllocationEditor.taskAllocation"
        />
      ),
      hoursValue: roundedAllocatedHours,
      infoComponent: (
        <AllocationEstimateInfoComponent
          taskResourceUserAllocationId={taskResourceUserAllocation?.id}
          estimatedAtCompletionHours={estimatedAtCompletionHours}
          initialEstimatedHours={initialEstimatedHours}
          allocatedHours={roundedAllocatedHours}
        />
      )
    }),
    [
      estimatedAtCompletionHours,
      initialEstimatedHours,
      roundedAllocatedHours,
      taskResourceUserAllocation?.id
    ]
  );

  const totalAvailabilityField = useMemo(
    () => ({
      labelComponent: (
        <FieldLabel
          variant="body2"
          messageId="taskAllocationEditor.totalAvailability"
        />
      ),
      hoursValue: totalAvailabilityHours,
      infoComponent: totalAvailabilityHours < 0 && (
        <UserOverAllocationChip startDate={startDate} endDate={endDate} />
      )
    }),
    [endDate, startDate, totalAvailabilityHours]
  );

  const dateRangePickerProps = useMemo(
    () => ({
      onStartDateChange,
      onEndDateChange,
      errors
    }),
    [errors, onEndDateChange, onStartDateChange]
  );

  return (
    <PopoverContentLayout
      startDate={startDate}
      endDate={endDate}
      onHoursChange={onTaskAllocationHoursChange}
      dateRangePickerProps={dateRangePickerProps}
      availabilityField={availabilityField}
      remainingField={totalAvailabilityField}
      allocationField={allocationField}
      classes={layoutClasses}
    />
  );
};

EditorPopoverContent.propTypes = {
  resourceAllocation: PropTypes.object,
  otherTaskAllocationsSummary: PropTypes.object,
  initialEstimatedHours: PropTypes.number,
  estimatedAtCompletionHours: PropTypes.number,
  taskResourceUserAllocation: PropTypes.object,
  userUri: PropTypes.string
};

export default EditorPopoverContent;
