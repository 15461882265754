import { useCallback } from 'react';
import { CHANGE_TASK_RESOURCE_USER_ALLOCATION_MODE } from '../enum';
import { useChangeTaskResource } from './useChangeTaskResource';

export const useOnSubmitHandler = ({
  existingResourceUserId,
  newResourceUserId,
  onClose,
  onChangeTaskResourceSuccess,
  projectId,
  setIsSaving,
  taskId,
  allocationMode,
  availableHours,
  taskAllocationHours
}) => {
  const { changeTaskResource } = useChangeTaskResource();

  const onSubmit = useCallback(async () => {
    setIsSaving(true);
    const hoursToAllocate =
      allocationMode ===
      CHANGE_TASK_RESOURCE_USER_ALLOCATION_MODE.ALLOCATE_WHAT_FITS
        ? availableHours
        : allocationMode ===
          CHANGE_TASK_RESOURCE_USER_ALLOCATION_MODE.ALLOCATE_FULL_AMOUNT
        ? taskAllocationHours
        : null;
    const {
      data: {
        changeTaskResourceUser: { taskResourceEstimateId }
      }
    } = await changeTaskResource({
      existingResourceUserId,
      newResourceUserId,
      projectId,
      taskId,
      allocationMode: !hoursToAllocate
        ? CHANGE_TASK_RESOURCE_USER_ALLOCATION_MODE.REMOVE_ALLOCATION
        : null,
      hoursToAllocate
    });

    onChangeTaskResourceSuccess &&
      (await onChangeTaskResourceSuccess({
        newTaskResourceEstimateId: taskResourceEstimateId
      }));
    onClose();
  }, [
    setIsSaving,
    changeTaskResource,
    existingResourceUserId,
    newResourceUserId,
    projectId,
    taskId,
    allocationMode,
    availableHours,
    taskAllocationHours,
    onChangeTaskResourceSuccess,
    onClose
  ]);

  return { onSubmit };
};

export default useOnSubmitHandler;
